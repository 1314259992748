import Service from './Service';

class Chatbot extends Service {
  initialize() {
    this.__chatbotChain = [];
  }
  chainIncludes(node) {
    return !!(this.__chatbotChain && this.__chatbotChain.includes(node));
  }
  splitChain(node) {
    const index = this.__chatbotChain.indexOf(node);
    if (index < 0) return;
    this.__chatbotChain = this.__chatbotChain.slice(0, index + 1);
  }
  addToChain(node) {
    if (!this.__chatbotChain.includes(node)) this.__chatbotChain.push(node);
    this.emitStateUpdate();
  }
}
const singleton = new Chatbot();
export default singleton;
