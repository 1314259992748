import Node from './node';
import { BRANCH } from './nodetypes';

export default class Branches extends Node {
  addChild(_child, index) {
    // Dont add duplicates
    const existing = this.findExistingBranch(_child.text);
    if (existing && existing.children > 0) return;
    super.addChild(_child, index);
  }
  deleteChild(_child) {
    // Dont delete if exists in root
    // const branchesInRoot = this.__getAllBranchesInRoot();
    // if (branchesInRoot && branchesInRoot.find((bir) => bir.text === _child.text)) return;
    super.deleteChild(_child);
  }
  findExistingBranch = (text) => this.children.find((child) => child.text === text);
  __getAllBranchesInRoot() {
    if (!this.parents[0].root) return null;
    let branchesInRoot = this.__recursivelyGetAllNodesByType(this.parents[0].root, BRANCH);
    this.children.forEach((branch) => {
      const branchesInBranch = this.__recursivelyGetAllNodesByType(branch, BRANCH);
      branchesInRoot = [...branchesInRoot, ...branchesInBranch];
    })
    return branchesInRoot
  }
}
