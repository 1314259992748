/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import styled from 'styled-components';
import Navigation from '../../services/Navigation';
import Constants from '../../submodules/logictry_config/constants';
import UserAccount from '../../services/UserAccount';
import StatefulTextField from '../../components/StatefulTextField';
import StatefulButton from '../../components/StatefulButton';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import LoadingIndicator from '../../components/LoadingIndicator';
const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  h1 {
    margin-bottom: 2rem;
  }
`;

const { innerHeight } = window;

export default class ResetPasswordPage extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      warning: '',
      state: '',
    };
  }
  resetPressed = () => {
    if (!this.state.email) return this.setState({ warning: 'Email missing' });
    this.setState({ warning: '', state: 'resetting' });
    return UserAccount.resetPassword(this.state.email).then(({ error }) => {
      if (error === 'UserNotFound') {
        UserAccount.account.email = this.state.email;
        setTimeout(() => {
          Navigation.push(Navigation.register);
        }, 250);
      } else this.setState({ warning: '', state: 'complete' });
    });
  }
  render() {
    const { state } = this.state;
    if (state === 'resetting') return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    return (
      <div style={{ position: 'absolute', top: 0, right: 0, left: 0, height: Constants.isIphone ? `${innerHeight - 124}px` : '100%' }}>
        <FullScreenVerticalCenterContent>
          <Form>
            <h1>Reset Password</h1>
            {this.state.state !== 'complete' && <StatefulTextField
              value={this.state.email}
              onChange={(email) => { this.setState({ email }); }}
              label="Email"
              type="email"
              name="email"
              maxLength={256}
              autoComplete="true"
              style={{ minWidth: 120, maxWidth: '80%', width: 400 }}
            />}
            {this.state.state !== 'complete' && <div style={{ marginTop: 50, marginBottom: 50 }}><StatefulButton
              onClick={this.resetPressed}
              text="Reset Password"
              warning={this.state.warning}
              state={this.state.state}
            /></div>}
            {this.state.state === 'complete' && <div>Email sent</div>}
          </Form>
        </FullScreenVerticalCenterContent>
      </div>
    );
  }
}
