import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ClickableDiv from '../ClickableDiv/index';
import Constants from '../../submodules/logictry_config/constants';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  > div {
    border-radius: 8px;
    position: fixed;
    background: white;
    box-shadow: 0px 2px 10px #999999;
    > div {
      padding: 10px 20px;
      &:hover {
        background-color: rgba(0,0,0,0.08);
      }
    }
  }
`;

export default class ContextMenu extends React.PureComponent {
  static propTypes = {
    menu: PropTypes.array,
    onClose: PropTypes.func,
    coordX: PropTypes.number,
    coordY: PropTypes.number,
  };

  componentDidMount() {
    // document.addEventListener('contextmenu', this._handleContextMenu);
    document.addEventListener('click', this._handleClick);
    // document.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount() {
    // document.removeEventListener('contextmenu', this._handleContextMenu);
    document.removeEventListener('click', this._handleClick);
    // document.removeEventListener('scroll', this._handleScroll);
  }
  _handleContextMenu = () => {
    if (!this.root) return;
    const { coordX, coordY } = this.props;
    const screenW = window.innerWidth;
    const screenH = window.innerHeight;
    const rootW = this.root.offsetWidth;
    const rootH = this.root.offsetHeight;
    const right = (screenW - coordX) > rootW;
    const left = !right;
    const top = (screenH - coordY) > rootH;
    const bottom = !top;
    if (right) this.root.style.left = `${coordX + 5}px`;
    if (left) this.root.style.left = `${coordX - rootW - 5}px`;
    if (top) this.root.style.top = `${coordY + 5}px`;
    if (bottom) this.root.style.top = `${coordY - rootH - 5}px`;
    this.root.focus();
  };
  _handleClick = () => {
    const { onClose } = this.props;
    onClose();
  }

  render() {
    const { menu } = this.props;
    return (
      <Wrapper>
        <div ref={(ref) => { this.root = ref; this._handleContextMenu(); }}>
          {menu.map((_item, i) => _item.label ? (
            <ClickableDiv key={_item.label} onClick={_item.onClick}>{_item.label}</ClickableDiv>
          ) : <div key={`divider_${i}`} style={{ width: '100%', height: 1, backgroundColor: Constants.MediumTextGray, padding: 0 }}></div>)}
        </div>
      </Wrapper>
    );
  }
}
