/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
import Tooltip from '../Tooltip/index';
import ClickableIcon from '../ClickableIcon/index';
import SocialSharePopup from './SocialSharePopup';

export default class SocialShare extends React.PureComponent {
  static propTypes = {
    tree: PropTypes.object,
  }
  state= {
    showSharePopup: false,
  }
  showShare = () => this.setState({ showSharePopup: true });
  hideAll = () => this.setState({ showSharePopup: false });
  render() {
    const { tree } = this.props;
    const { showSharePopup } = this.state;
    return (
      <div>
        <Tooltip text="Share"><ClickableIcon onClick={this.showShare} icon="fas fa-share-alt" /></Tooltip>
        {showSharePopup && <SocialSharePopup tree={tree} onClose={this.hideAll} />}
      </div>
    );
  }
}
