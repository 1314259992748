import React from 'react';
import styled from 'styled-components';
// import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import UserCache from '../../services/cache/UserCache';
import TreeCache from '../../services/cache/TreeCache';
import AutoSaveTree from '../../services/AutoSaveTree';
import Navigation from '../../services/Navigation';
import SidebarService from '../../services/SidebarService';
import GrayOut from '../../services/TreeDisplay';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import TreeAnswerBox from '../TreeAnswerPage/TreeAnswerBox';
import LoadingIndicator from '../../components/LoadingIndicator';
// import MindMapObjectToTree from '../../utils/TreeParsers/MindMapObjectToTree';
import TreeMetaBox from '../TreeAnswerPage/TreeMetaBox';
import Sidebar from '../TreeAnswerPage/Sidebar';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb';
import WindowSize from '../../services/WindowSize';
import TreeGraphBox from '../TreeGraphBox/index';
import TreeDocBox from '../TreeDocBox';

const Wrapper = styled.div`
  
`;
const EditorWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  > div:first-child {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
  }
`;
const ContentArea = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const UserInput = styled.textarea`
  padding: 10px;
  width: 100%;
  height: 100%;
`;
const __inputText = `[Title]
[Description]
[Root]
[Branches]`;
const EditingDisabled = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  color: white;
  background-color: rgba(0,0,0,0.08);
  > div {
    box-shadow: rgb(0 0 0 / 30%) 0px 0px 4px 0px;
    background-color: ${Constants.PrimaryColor};
    position: absolute;
    width: 100%;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 2rem;
    line-height: 2rem;
    > div {
      color: white;
      font-weight: bold;
      font-size: ${Constants.SemiSmallFontSize};
    }
  }
`;

export default class TreeEditPage extends React.PureComponent {
  constructor(props) {
    super(props);
    SidebarService.updateSelectType('Tree');
    SidebarService.updateShowVersions(false);
  }
  state = {
    inputText: null,
  }
  componentDidMount() {
    WindowSize.onStateUpdate(this);
    SidebarService.onStateUpdate(this.updateState);
    SidebarService.undo = () => {
      const { tree } = this;
      tree.backward();
      this.state.inputText = tree.text;
      if (this.inputRef) this.inputRef.value = this.state.inputText;
      this.forceUpdate();
    };
    SidebarService.redo = () => {
      const { tree } = this;
      tree.forward();
      this.state.inputText = tree.text;
      if (this.inputRef) this.inputRef.value = this.state.inputText;
      this.forceUpdate();
    };
    GrayOut.onStateUpdate(this);
    TreeCache.onStateUpdate(this.treeCacheUpdated);
    UserCache.onStateUpdate(this);
    this.treeCacheUpdated();
  }
  componentDidUpdate() {
    if (this.tree && this.tree._id && this.tree._id !== Navigation.currentLocation[2]) this.treeCacheUpdated();
  }
  componentWillUnmount = () => {
    WindowSize.offStateUpdate(this);
    SidebarService.offStateUpdate(this.updateState);
    GrayOut.offStateUpdate(this);
    TreeCache.offStateUpdate(this.treeCacheUpdated);
    UserCache.offStateUpdate(this);
    if (this.tree && this.tree._id) this.tree.offStateUpdate(this.treeUpdated);
    this.tree = null;
  }
  inputKeyDown = (e) => {
    if (e.key === 'Tab' || (e.metaKey && (e.key === '[' || e.key === ']'))) {
      e.preventDefault();
      e.stopPropagation();
      const { selectionStart, selectionEnd } = this.inputRef;
      const textBeforeEdit = this.inputRef.value.replace(/\r?\n/, '\r\n');
      const textBeforeEditArray = textBeforeEdit.split(/\r?\n/);
      let startIndex = 0;
      let endIndex = 1;
      let totalCount = 0;
      let startCount = 0;
      textBeforeEditArray.forEach((text, i) => {
        totalCount += text.length;
        if (i > 0) totalCount += 1;
        if (selectionStart > totalCount) {
          startIndex += 1;
          startCount += text.length + 1;
        }
        if (selectionEnd > totalCount) endIndex += 1;
      });
      let textArray = textBeforeEditArray.slice(startIndex, endIndex);
      if (e.key === '[' || (e.key === 'Tab' && e.shiftKey)) {
        if (!textArray.every((_string) => !_string || _string.slice(0, 2) === '  ')) return;
        textArray = textArray.map((_string) => _string ? _string.slice(2) : _string);
      } else {
        textArray = textArray.map((_string) => `  ${_string}`);
      }
      const textAfterEditArray = [...textBeforeEditArray.slice(0, startIndex), ...textArray, ...textBeforeEditArray.slice(endIndex)];
      const textAfterEdit = textAfterEditArray.join('\r\n');
      this.inputRef.value = textAfterEdit;
      const textDifferenceLength = textAfterEditArray.reduce((acc, val) => acc + val.length, 0) - textBeforeEditArray.reduce((acc, val) => acc + val.length, 0);
      if (e.key === '[') this.inputRef.setSelectionRange(Math.max(startCount, selectionStart - 2), Math.max(startCount, (selectionEnd + (textDifferenceLength))));
      else this.inputRef.setSelectionRange(selectionStart + 2, selectionEnd + textDifferenceLength);
      this.textChanged();
    } else if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      const { selectionStart, selectionEnd, value } = this.inputRef;
      const textBeforeEdit = this.inputRef.value.replace(/\r?\n/, '\r\n');
      const textBeforeEditArray = textBeforeEdit.split(/\r?\n/);
      let startIndex = 0;
      let totalCount = 0;
      textBeforeEditArray.forEach((text, i) => {
        totalCount += text.length;
        if (i > 0) totalCount += 1;
        if (selectionStart > totalCount) {
          startIndex += 1;
        }
      });
      const currentLine = textBeforeEditArray[startIndex].search(/\S/);
      const tabSpacing = textBeforeEditArray[startIndex].substring(0, currentLine);
      this.inputRef.value = `${value.slice(0, selectionStart)}\r\n${tabSpacing}${value.slice(selectionStart)}`;
      this.inputRef.setSelectionRange(selectionStart + tabSpacing.length + 1, selectionEnd + tabSpacing.length + 1);
      this.textChanged();
    }
  }
  saveTree = () => {
    const { selectType } = SidebarService;

    // Sync changes
    if (selectType === 'Code') return SidebarService.updateSelectType('Tree');
    // if (selectType === 'Graph') this.checkGraphChanged();

    // Update or create tree
    return TreeCache.updateTree(this.tree);
  }
  checkout = () => {
    this.setCurrentTree(TreeCache.getTree(Navigation.currentLocation[2], true, true));
  }
  checkin = async () => {
    const id = Navigation.currentLocation[2];
    if (await this.saveTree()) return;
    this.setCurrentTree(TreeCache.getTree(id, true, null, true));
  }
  unlock = () => {
    this.setCurrentTree(TreeCache.updateTree(this.tree, 'Live'));
  }
  setCurrentTree(_tree) {
    if (!_tree) return;
    if (this.tree && this.tree.offStateUpdate) this.tree.offStateUpdate(this.treeUpdated);
    this.tree = _tree;
    GrayOut.setTree(this.tree);
    if (this.tree && this.tree.onStateUpdate) this.tree.onStateUpdate(this.treeUpdated);
  }
  textChanged = () => {
    this.state.inputText = this.inputRef.value;
  }
  checkGraphChanged = () => {
    // const { tree } = this;
    // this.tree = TreeCache.replaceTree(...MindMapObjectToTree(tree, window.mapModel.getIdea()));
  }
  checkTreeTextChanged = () => {
    const { tree } = this;
    const { inputText } = this.state;
    tree.updateChildrenJsonOrText({ text: inputText });
  }
  updateState = () => {
    const { selectType } = SidebarService;
    if (selectType === this.state.selectType) return;
    GrayOut.clearAllActiveObjects();
    // if (this.state.selectType === 'Graph') this.checkGraphChanged();
    if (this.state.selectType === 'Doc') this.checkTreeTextChanged();
    if (this.state.selectType === 'Code') this.checkTreeTextChanged();
    if (selectType === 'Code') this.state.inputText = this.tree.getTreeTemplateText();
    this.state.selectType = selectType;
    this.treeUpdated();
  }
  treeCacheUpdated = () => {
    let { inputText } = this.state;
    if (Navigation.currentLocation[2] === 'create') {
      if (!this.tree || this.tree._id) {
        if (!inputText || (this.tree && this.tree._id)) {
          inputText = __inputText;
          this.state.inputText = __inputText;
        }
        this.setCurrentTree(TreeCache.createNew(inputText));
      }
    } else {
      this.setCurrentTree(TreeCache.getTree(Navigation.currentLocation[2], true));
      if (!this.tree || this.tree.error) return this.forceUpdate();
      this.state.inputText = this.tree.getTreeTemplateText();
    }
    return this.forceUpdate();
  }
  treeUpdated = () => {
    const { selectType } = this.state;
    if (selectType === 'Code') {
      this.state.inputText = this.tree.getTreeTemplateText();
      if (this.inputRef) this.inputRef.value = this.state.inputText;
    }
    this.forceUpdate();
    if (this.tree && this.tree._id) {
      AutoSaveTree.autoSaveTemplate(this.tree);
    }
  }
  viewAsProject = () => {
    const { tree } = this;
    if (tree.isEditor) this.saveTree();
    Navigation.push(`${Navigation.trees}/${tree._id}`);
  }
  render() {
    const { selectType } = SidebarService;
    const { mobile } = WindowSize;
    const { inputText } = this.state;

    // Check if loading
    const { tree } = this;
    if (!tree || tree.loading) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;

    // Check if errors
    const { isEditor, isLocked, isCheckedOut, editor, isOwnerOrAdmin, type, isDisabled, isTemplateAndShowingAPreviousVersion } = tree;
    if (tree.error) {
      return <FullScreenVerticalCenterContent><h1>App Not Found</h1></FullScreenVerticalCenterContent>;
    }
    if (type !== 'Template') return (<FullScreenVerticalCenterContent><h1>Editing not allowed</h1></FullScreenVerticalCenterContent>);

    // Get if checked out
    let checkedOutTo;
    if (!isLocked && isCheckedOut && !isEditor) {
      const editors = UserCache.getUsersByIds([editor._id]);
      checkedOutTo = editors && editors[0];
    }

    const rightStyle = { right: mobile ? 0 : 240 };
    const showDeleted = tree.isDeleted;
    return (
      <Wrapper key={`${tree.key}_${isDisabled ? 'disabled' : 'enabled'}`} tabIndex={-1}>
        <DrawerBreadcrumb><TreeMetaBox key={tree._id} editing /></DrawerBreadcrumb>
        <EditorWrapper>
          <div style={rightStyle}>
            {selectType === 'Tree' && (
              <div>
                <TreeAnswerBox tree={tree} />
              </div>
            )}
            {selectType === 'Code' && (
              <ContentArea>
                <UserInput
                  defaultValue={inputText}
                  onBlur={this.textChanged}
                  onKeyDown={this.inputKeyDown}
                  onKeyUp={this.onKeyUp}
                  placeholder="Raw App Text"
                  tabIndex={-1}
                  ref={(e) => { this.inputRef = e; }}
                />
              </ContentArea>
            )}
            {selectType === 'Graph' && (
              <ContentArea>
                <TreeGraphBox editing tree={tree} />
              </ContentArea>
            )}
            {selectType === 'Doc' && (
              <ContentArea>
                <TreeDocBox
                  editing
                  tree={tree}
                  onChange={(text) => { this.state.inputText = text; }} />
              </ContentArea>
            )}
          </div>
        </EditorWrapper>
        {!mobile && <Sidebar showEditor tree={tree} saveChanges={this.saveTree} checkout={this.checkout} checkin={this.checkin} unlock={this.unlock} />}
        {showDeleted && <EditingDisabled style={rightStyle}><div>App has been deleted</div></EditingDisabled>
        || isOwnerOrAdmin && !isLocked && isCheckedOut && !isEditor && <EditingDisabled style={rightStyle}><div><div>App already being edited - {checkedOutTo ? `by ${checkedOutTo.fullname || checkedOutTo.username}` : 'by someone else'}</div></div></EditingDisabled>
        || isOwnerOrAdmin && !isLocked && !isCheckedOut && !isEditor && <EditingDisabled style={rightStyle}><div><div>Not currently editable - click start editing to begin</div></div></EditingDisabled>
        || isOwnerOrAdmin && isLocked && <EditingDisabled style={rightStyle}><div><div>App locked - unlock to edit</div></div></EditingDisabled>
        || !isOwnerOrAdmin && <EditingDisabled style={rightStyle}><div><div>Not allowed to edit - must be an owner or admin to edit</div></div></EditingDisabled>
        || isTemplateAndShowingAPreviousVersion && <EditingDisabled style={rightStyle}><div><div>Auto saving disabled - you must click save to make this version the current version</div></div></EditingDisabled>}
      </Wrapper>
    );
  }
}
