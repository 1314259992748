import SessionApi from './server/SessionApi';
import LocalStorage from './LocalStorage';
const session = new LocalStorage('logictry.session');

class SessionManager {
  constructor() {
    this.userIsActive = true;
    this.appActive = null;
    this.time = 0;
    this.appsTime = {};
    document.addEventListener('mousemove', this.setUserActive);
    document.addEventListener('keydown', this.setUserActive);
    document.addEventListener('touchstart', this.setUserActive);
    document.addEventListener('visibilitychange', this.visibilityChanged);
    setInterval(this.updateActiveTime, 1000);
    this.setUserActive();
  }
  updateActiveTime = () => {
    if (!this.userIsActive) return;
    this.time += 1;
    if (this.appActive) {
      if (!this.appsTime[this.appActive]) this.appsTime[this.appActive] = 0;
      this.appsTime[this.appActive] += 1;
    }
    if (this.time >= 10) {
      const { time, appsTime } = this;
      const subdomains = window.location.hostname.split('.');
      const _id = subdomains.length > 2 && subdomains[0] || '';
      if (_id) return;
      if (this.userIsActive) SessionApi.update({ time, appsTime, lastUpdate: session.state.lastUpdate }, {}, { _id }).then((result) => {
        const lastUpdate = result && result.lastUpdate;
        session.state['lastUpdate'] = lastUpdate;
        session.saveState();
      });
      this.time = 0;
      this.appsTime = {};
    }
  }
  setUserActive = () => {
    this.userIsActive = true;
    clearTimeout(this.__setUserInactiveTimeout);
    this.__setUserInactiveTimeout = setTimeout(() => {
      this.userIsActive = false;
    }, 60000);
  }
  setAppActive = (app) => {
    this.appActive = app;
  }
  clearAppActive = (app) => {
    if (this.appActive !== app) return;
    this.appActive = null;
  }
  visibilityChanged = () => {
    if (document.visibilityState === 'hidden') this.userIsActive = false;
    else this.userIsActive = true;
  }
}

const singleton = new SessionManager();
export default singleton;
