export default class Model {
  _observers = new Set();
  key = (Math.floor(Math.random() * 10000000000000000)).toString();

  get observerCount() {
    return this._observers.size;
  }

  onStateUpdate(cb) {
    this._observers.add(cb);
  }
  offStateUpdate(cb) {
    this._observers.delete(cb);
  }

  emitStateUpdate(_update) {
    this._observers.forEach((o) => {
      if (o.forceUpdate) return o.forceUpdate();
      return o(this, _update);
    });
  }

  arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (!(a.every((_val) => b.includes(_val)))) return false;
    if (!(b.every((_val) => a.includes(_val)))) return false;
    return true;
  }
}
