import DOMPurify from 'dompurify';

const PURIFY_SETTINGS = {
  FORCE_BODY: true,
  ADD_TAGS: ['link', 'iframe', 'html', 'head', 'body'],
  ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target'],
};

export default function purifyhtml(html, stopPropagation) {
  let sanitizedHtml = html ? html.replace(/\r?\n/g, '<br/>').replace(/^\s+|\s+$|\s+(?=\s)/g, "") : '';
  sanitizedHtml = DOMPurify.sanitize(sanitizedHtml, PURIFY_SETTINGS);
  sanitizedHtml = DOMPurify.sanitize(sanitizedHtml, PURIFY_SETTINGS);
  sanitizedHtml = sanitizedHtml.replace(/<link /g, '<link rel="stylesheet" ').replace(/^\s+|\s+$|\s+(?=\s)/g, "").trim();
  if (stopPropagation) sanitizedHtml = sanitizedHtml.replace(/<a /g, `<a onmousedown="event.stopPropagation()" rel="noopener noreferrer" `);
  else sanitizedHtml = sanitizedHtml.replace(/<a /g, `<a rel="noopener noreferrer" `);
  return sanitizedHtml;
}
