/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-unresolved
import ObjectCache from './CacheService';
import UserAccount from '../UserAccount';
import AssetApi from '../server/AssetApi';
import Asset from '../../models/asset';
// eslint-disable-next-line import/no-cycle
import Company from '../Company';

class AssetCache extends ObjectCache {
  api = AssetApi;
  createNew = (imageUrl) => new Asset(null, UserAccount.account._id, [], [], UserAccount.account._id, '', '', imageUrl, null, null, null, null, null, Company.company && Company.company._id || '');
  createObject = (_results) => {
    const results = JSON.parse(JSON.stringify(_results));
    return new Asset(results._id, results.owner, results.users, results.teams, UserAccount.account._id, results.title, results.description, results.link, results.createdTime, results.updateTime, results.state, results.shareSetting, results.version, results.company, results.type, results.background);
  }
  revertObject = (_object) => {
    const results = this.objectJson[_object._id];
    this.objects[_object._id] = this.createObject(results);
    this.emitStateUpdate();
  };
  getObjectJson = (_model) => _model.getJson();
}

const singleton = new AssetCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
