import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
// import WindowSize from '../../services/WindowSize';
import GrayOut from '../../services/TreeDisplay';
import Popout from '../../services/Popout';
import WysiwygComponent from '../../submodules/logictry_wysiwyg/src/react';
import RenderedHtmlToTextArea from '../../components/RenderedHtmlToTextArea/index';


const Wrapper = styled.div`
  z-index: 0;
  position: relative;
  margin: ${Constants.defaultLinePadding} 0 0 ${Constants.TreeContentIconIndent};
  display: flex;
  align-items: center;
`;
const SingleLineWrapper = styled.div`
  margin: ${Constants.defaultLinePadding} 0 ${Constants.defaultLinePadding} ${Constants.TreeContentIconIndent};
  padding: 0.75rem 1.5rem;
  border: 1px solid rgb(193, 193, 193);
  border-radius: 8px;
`;
// const PopoutIcon = styled.i`
//   position: absolute;
//   top: 10px;
//   right: 5px;
// `;

export default class InputBox extends React.PureComponent {
  static propTypes = {
    input: PropTypes.object,
  }
  componentDidMount() {
    const { input } = this.props;
    input.onStateUpdate(this.sendInputText);
    Popout.onStateUpdate(this.popoutUpdate);
    this.sendInputText();
  }
  componentWillUnmount() {
    const { input } = this.props;
    input.offStateUpdate(this.sendInputText);
    Popout.offStateUpdate(this.popoutUpdate);
  }
  onFocus = () => {
    const { input } = this.props;
    GrayOut.addActiveObject(input);
  }
  popoutUpdate = () => {
    const { tree } = GrayOut;
    const { input } = this.props;
    const { id, msg } = Popout.state || {};
    if (tree._id !== id) return;
    input.updateText(msg);
  }
  sendInputText = () => {
    const { tree } = GrayOut;
    const { input } = this.props;
    Popout.sendMessage({ id: tree._id, msg: input.text, keywordsFoundBySearch: input.keywordsFoundBySearch });
  }
  popoutClicked = () => {
    const { tree } = GrayOut;
    const { input } = this.props;
    Popout.open({ id: tree._id, msg: input.text, keywordsFoundBySearch: input.keywordsFoundBySearch });
    this.forceUpdate();
  }
  render() {
    // const { isApp } = Constants;
    // const { mobile } = WindowSize;
    const { input } = this.props;
    const { parent } = input;
    const { showSingleSearchBar, backgroundColor, borderColor, placeholderText } = parent;
    const { tree } = GrayOut;
    const { isDisabled } = tree;
    /**
     *
     */
    if (showSingleSearchBar) {
      return (
        <SingleLineWrapper style={{ background: backgroundColor || null, border: borderColor && `1px solid ${borderColor}` || null }}>
          <RenderedHtmlToTextArea node={input} placeholderText={placeholderText && placeholderText.text || 'Search'} />
        </SingleLineWrapper>
      )
    }
    return (
      <Wrapper>
        <WysiwygComponent disabled={isDisabled} node={input} height="200px" width="100%" />
        {/* {!isDisabled && !mobile && <PopoutIcon role="button" className="fas fa-external-link-alt" onClick={this.popoutClicked} />} */}
      </Wrapper>
    );
  }
}
