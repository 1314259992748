/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import styled from 'styled-components';
import LoadingIndicator from '../../components/LoadingIndicator';
import Constants from '../../submodules/logictry_config/constants';
import Popout from '../../services/Popout';
import Navigation from '../../services/Navigation';
import Node from '../../models/node';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import WysiwygComponent from '../../submodules/logictry_wysiwyg/src/react';
import FixedFullscreenDiv from '../../components/FixedFullscreenDiv';

const Wrapper = styled(FixedFullscreenDiv)`
  padding: 0px;
`;
const StatusBar = styled.div`
  text-align: center;
  * {
    font-size: 12px;
    color: ${Constants.DarkTextColor};
  }
`;

export default class PopoutPage extends React.PureComponent {
  componentDidMount() {
    Popout.onStateUpdate(this.popoutUpdate);
    Popout.startListener();
    this.key = Navigation.currentLocation[2];
  }
  popoutUpdate = () => {
    const { id, msg, keywordsFoundBySearch } = Popout.state;
    if (id !== this.key) window.close();
    else if (msg !== undefined) {
      if (!this.node) {
        this.node = new Node(msg);
        this.node.parents = [{
          onTextChanged: () => {
            Popout.sendMessage({ id: this.key, msg: this.node.text });
          },
        }];
        return this.forceUpdate();
      }
      return this.node.updateText(msg);
    } else if (keywordsFoundBySearch !== undefined) {
      this.node.keywordsFoundBySearch = keywordsFoundBySearch;
      this.node.emitStateUpdate();
    }
    return null;
  }

  render() {
    if (!this.node) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const { state } = Popout;
    return (
      <Wrapper>
        <StatusBar><a target="_blank" href={`${window.location.origin}/apps/${state.id}`}>Opened from project</a></StatusBar>
        <WysiwygComponent focusOnInit node={this.node} height="calc(100vh - 54px)" />
      </Wrapper>
    );
  }
}
