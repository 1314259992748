/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import '../../services/Errors';

const Wrapper = styled.div`
  color: ${Constants.DarkTextColor};
`;

export default class Application extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
  }
  render() {
    return (
      <Wrapper>
        {this.props.children}
      </Wrapper>
    );
  }
}
