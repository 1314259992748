import React from 'react';
import { PropTypes } from 'prop-types';
import DrawerService from '../../services/Drawer';

export default class DrawerBreadcrumb extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
  };
  componentDidMount() {
    DrawerService.updateChildren(this.props.children);
  }
  render() {
    return null;
  }
}
