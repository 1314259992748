import Node from './node';
import { SORT_ASCENDING_ATTR, SORT_DESCENDING_ATTR, NONE_ATTR } from './nodeattributes';

export default class DataSchemaField extends Node {
  constructor(_text, _attributes, _children, _owner) {
    super(_text, _attributes, _children, _owner);
    this.__defineNodeAttribute('sort', [{ name: 'No Sorting', value: NONE_ATTR, default: true }, { name: 'Sort Ascending', value: SORT_ASCENDING_ATTR }, { name: 'Sort Descending', value: SORT_DESCENDING_ATTR }])
  }
  clone() {
    return new DataSchemaField(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
  get sortAscending() {
    return this.attributes.includes(SORT_ASCENDING_ATTR);
  }
  get sortDescending() {
    return this.attributes.includes(SORT_DESCENDING_ATTR);
  }
}
