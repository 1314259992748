/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */

import Node from './node';
import { CALENDAR_EVENT_INFO } from './nodetypes';

export default class CalendarEvent extends Node {
  clone() {
    return new CalendarEvent(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
  get calendarEventInfos() {
    return this.children.filter((_child) => _child.isType(CALENDAR_EVENT_INFO));
  }
}
