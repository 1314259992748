// Node Attributes
export const ADD_ATTR = 'Add';
export const AND_ATTR = 'And';
export const ALLOW_FILTERING_SELECTIONS_ATTR = 'AllowFilteringSelections';
export const ALLOW_INLINE_NOTES_ATTR = 'AllowInlineNotes';
export const ALLOW_SAVING_PROGRESS_ATTR = 'AllowSavingProgress';
export const ALLOW_SELECTIONS_ATTR = 'AllowSelections';
export const ATTRIBUTE_ATTR = 'Attribute';
export const AUTO_RUN_ATTR = 'AutoRun';
export const AVERAGE_ATTR = 'Average';
export const BACKGROUND_COLOR_ATTR = 'BackgroundColor=';
export const BACKGROUND_IMAGE_ATTR = 'BackgroundImage=';
export const BACKGROUND_VIDEO_ATTR = 'BackgroundVideo=';
export const BORDER_COLOR_ATTR = 'BorderColor=';
export const CARD_ATTR = 'Card';
export const CENTER_ATTR = 'Center';
export const COLLAPSIBLE_ATTR = 'Collapsible';
export const COLOR_ATTR = 'Color';
export const COMMA_DELIMITED_ATTR = 'CommaDelimited';
export const CONSTANT_ATTR = 'Constant';
export const CONTENT_HEIGHT_ATTR = 'ContentHeight';
export const CONTENT_WIDTH_ATTR = 'ContentWidth';
export const CURRENT_TIME_ATTR = 'CurrentTime';
export const DATE_ATTR = 'Date';
export const DATE_TIME_ATTR = 'DateTime';
export const DECIMALS_ATTR = 'Decimals=';
export const DEFAULT_COLLAPSED_ATTR = 'DefaultCollapsed';
export const DEFAULT_EXPANDED_ATTR = 'DefaultExpanded';
export const DEFAULT_VALUE_ATTR = 'DefaultValue=';
export const DESCRIPTION_ATTR = 'Description';
export const DISTANCE_ATTR = 'Distance';
export const DIVIDE_ATTR = 'Divide';
export const DROPDOWN_ATTR = 'Dropdown';
export const ELSE_ATTR = 'Else';
export const ELSE_IF_ATTR = 'ElseIf';
export const EMAIL_ATTR = 'Email';
export const END_ATTR = 'End';
export const EQUALS_ATTR = 'Equals';
export const EXCLUDE_ATTR = 'Exclude';
export const EXCLUDE_EMPTY_CELLS_ATTR = 'ExcludeEmptyCells';
export const FIXED_ATTR = 'Fixed';
export const FIXED_HEIGHT_ATTR = 'FixedHeight';
export const FONT_FAMILY_ATTR = 'FontFamily=';
export const FONT_SIZE_ATTR = 'FontSize=';
export const FONT_SIZE_H1_ATTR = 'FontSizeH1=';
export const FONT_SIZE_H2_ATTR = 'FontSizeH2=';
export const FONT_SIZE_H3_ATTR = 'FontSizeH3=';
export const FORM_ATTR = 'Form';
export const FULL_TEXT_SEARCH_ATTR = 'FullTextSearch';
export const GOOGLE_DRIVE_ATTR = 'GoogleDrive';
export const GOOGLE_SPREADSHEET_ATTR = 'GoogleSpreadsheet';
export const GO_TO_NEXT_ON_SELECTION = 'GoToNextOnSelection';
export const GREATER_THAN_ATTR = 'GreaterThan';
export const HIDE_DISPLAY_TEXT_ATTR = 'HideDisplayText';
export const HIDE_FILTERED_CHOICES_ATTR = 'HideFilteredChoices';
export const HIDE_NAV_BUTTONS_ATTR = 'HideNavButtons';
export const HIDE_NODE_ATTR = 'HideNode';
export const HIDE_PAGE_TITLES_ATTR = 'HidePageTitles';
export const HIDE_PRINT_ATTR = 'HidePrint';
export const HIDE_RADIO_BUTTONS_ATTR = 'HideRadioButtons';
export const HIDE_SHOW_ALL_ATTR = 'HideShowAll';
export const HIDE_SUBMIT_BUTTON_ATTR = 'HideSubmitButton';
export const HIDE_TEXT_ATTR = 'HideText';
export const HIDE_UNSELECTED_CHOICES_ATTR = 'HideUnselectedChoices';
export const HORIZONTAL_ATTR = 'Horizontal';
export const HTML_ATTR = 'Html';
export const IF_ATTR = 'If';
export const IGNORE_FOR_EACH_ANSWER_ATTR = 'IgnoreForEachAnswer';
export const INCLUDE_ATTR = 'Include';
export const INCLUDE_EMPTY_CELLS_ATTR = 'IncludeEmptyCells';
export const KEYWORD_SEARCH_ATTR = 'KeywordSearch';
export const LATITUDE_ATTR = 'Latitude';
export const LESS_THAN_ATTR = 'LessThan';
export const LIST_ATTR = 'List';
export const LOCATION_ATTR = 'Location';
export const LOGICTRY_SPREADSHEET_ATTR = 'LogictrySpreadsheet';
export const LONGITUDE_ATTR = 'Longitude';
export const MAX_ATTR = 'Max';
export const MAX_DEPTH_ATTR = 'MaxDepth=';
export const MAX_LIST_SIZE_ATTR = 'MaxListSize=';
export const MAX_WIDTH_ATTR = 'MaxWidth=';
export const MIN_ATTR = 'Min';
export const MIN_MATCH_CHAR_LENGTH_ATTR = 'MinMatchCharLength';
export const MULTIPLE_ANSWERS_ATTR = 'MultipleAnswers';
export const MULTIPLY_ATTR = 'Multiply';
export const NO_BORDER_ATTR = 'NoBorder';
export const NODE_ATTR = 'Node';
export const NONE_ATTR = 'None';
export const NOT_ATTR = 'Not';
export const NOT_APPLICABLE_ATTR = 'N/A';
export const NOT_EQUALS_ATTR = 'NotEquals';
export const NUMBER_ATTR = 'Number';
export const OPEN_AI_ATTR = 'OpenAI';
export const OPEN_ENDED_ATTR = 'OpenEnded';
export const OR_ATTR = 'Or';
export const PADDING_ATTR = 'Padding=';
export const PDF_ATTR = 'Pdf';
export const PIPE_DELIMITED_ATTR = 'PipeDelimited';
export const POST_ATTR = 'Post';
export const PRESELECT_ATTR = 'Preselect';
export const PREVENT_AUTO_EXPAND_ATTR = 'PreventAutoExpand';
export const PREVENT_FILTERING_RECOMPUTE_ATTR = 'PreventFilterLogicUpdate';
export const PREVENT_FILTERING_SELECTIONS_ATTR = 'PreventFilteringSelections';
export const PREVENT_SAVING_PROJECTS_ATTR = 'PreventSavingProjects';
export const PREVENT_SELECTIONS_ATTR = 'PreventSelections';
export const PRIMARY_COLOR_ATTR = 'PrimaryColor=';
export const PROJECTS_ATTR = 'Projects';
export const RADIO_BUTTONS_LIST_ATTR = 'RadioButtonsList';
export const READ_ONLY_ATTR = 'ReadOnly';
export const READ_WRITE_ATTR = 'ReadWrite';
export const RESTFUL_API_ATTR = 'RestfulApi';
export const RESULT_ONLY_ATTR = 'ResultOnly';
export const REQUIRED_ATTR = 'Required';
export const ROUND_ATTR = 'Round';
export const SAVE_ON_SUBMIT_ATTR = 'SaveOnSubmit=';
export const SELECT_ALL_NOT_FILTERED_ATTR = 'SelectAllNotFiltered';
export const SEMICOLON_DELIMITED_ATTR = 'SemicolonDelimited';
export const SHOW_CHILDREN_AS_SIBLINGS_ATTR = 'ShowChildrenAsSiblings';
export const SHOW_IF_FILTERED_ATTR = 'ShowIfFiltered';
export const SHOW_INFO_PANEL_ATTR = 'ShowInfoPanel';
export const SHOW_PROGRESS_ATTR = 'ShowProgress';
export const SHOW_TEXT_ATTR = 'ShowText';
export const SINGLE_ANSWER_ATTR = 'SingleAnswer';
export const SORT_ASCENDING_ATTR = 'SortAscending';
export const SORT_DESCENDING_ATTR = 'SortDescending';
export const SPREADSHEET_ID_ATTR = 'SpreadsheetID';
export const START_ATTR = 'Start';
export const STRING_MATCH_ATTR = 'StringMatch';
export const SUBTRACT_ATTR = 'Subtract';
export const TABLE_ATTR = 'Table';
export const TEXT_ATTR = 'Text';
export const THRESHOLD_ATTR = 'Threshold';
export const TIME_ATTR = 'Time';
export const UPDATE_RATE_ATTR = 'UpdateRate=';
export const TITLE_ATTR = 'Title';
export const UNIQUE_ID_ATTR = 'UniqueID=';
export const VERTICAL_ATTR = 'Vertical';
export const WIKI_LAYOUT_ATTR = 'WikiLayout';
export const WRAP_ATTR = 'Wrap';
export const WRITE_ONLY_ATTR = 'WriteOnly';
export const WYSIWYG_ATTR = 'WYSIWYG';
export const XML_ATTR = 'XML';
