/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-unresolved
import ObjectCache from './CacheService';
import LogicBaseUserReportApi from '../server/LogicBaseUserReportApi';
import UserAccount from '../UserAccount';
import LogicBaseUserReport from '../../models/logicbaseuserreport';

class LogicBaseUserReportCache extends ObjectCache {
  api = LogicBaseUserReportApi;
  createObject = (_results) => {
    const results = JSON.parse(JSON.stringify(_results));
    return new LogicBaseUserReport(results._id, results.o, results.u, results.d, results.s);
  }
  revertObject = (_object) => {
    const results = this.objectJson[_object._id];
    this.objects[_object._id] = this.createObject(results);
    this.emitStateUpdate();
  };
  getObjectJson = (_model) => _model.getJson();
}
const singleton = new LogicBaseUserReportCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
