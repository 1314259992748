import WindowSize from '../services/WindowSize';

/* eslint-disable no-param-reassign */
export default function smoothscrollfix(ref) {
  if (!ref) return;
  if (!WindowSize.mobile || !WindowSize.isSafari) return;
  // Fixes the scroll at top problem ios
  let timeout;
  checkBounds();
  ref.onscroll = () => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      checkBounds();
    }, 10);
  }
  function checkBounds() {
    if (isNotScrollable()) return;
    if (isTop()) {
      ref.scrollTop = 1;
    } else if (isBottom()) {
      ref.scrollTop -= 1;
    }
  }
  function isNotScrollable() {
    return ref.offsetHeight === ref.scrollHeight;
  }
  function isTop() {
    return ref.scrollTop === 0;
  }
  function isBottom() {
    return (ref.scrollTop + ref.clientHeight) === ref.scrollHeight;
  }
}
