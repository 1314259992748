/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */

import Node from './node';
import { CALENDAR_EVENT, CALENDAR_TIME_ZONE } from './nodetypes';

export default class Calendar extends Node {
  calendarEventsSelected = [];
  clone() {
    return new Calendar(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
  get calendarEvents() {
    return this.children.filter((_child) => _child.isType(CALENDAR_EVENT));
  }
  get calendarTimeZone() {
    return this.children.find((_child) => _child.isType(CALENDAR_TIME_ZONE));
  }
  updateCalendarEventsSelected(_calendarEventsFound) {
    this.calendarEventsSelected = _calendarEventsFound;
    this.emitStateUpdate();
  }
}
