import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

const Div = styled.div`

`;

export default class ClickableDiv extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    style: PropTypes.object,
    children: PropTypes.any,
    innerRef: PropTypes.func,
  };
  render() {
    const { onClick, onFocus, onBlur, style, children, innerRef, onMouseDown, onMouseEnter, onMouseLeave } = this.props;
    return <Div ref={innerRef} style={style} tabIndex={-1} role="button" onClick={onClick} onMouseDown={onMouseDown} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onFocus={onFocus} onBlur={onBlur}>{children}</Div>;
  }
}
