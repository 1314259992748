import React from 'react';
import { PropTypes } from 'prop-types';
import GrayOut from '../../services/TreeDisplay';
import SpreadsheetCache from '../../services/cache/SpreadsheetCache';
// eslint-disable-next-line import/no-cycle
import CreateChild from './CreateChild';


export default class SearchBox extends React.PureComponent {
  static propTypes = {
    search: PropTypes.object,
  }
  componentDidMount() {
    const { search } = this.props;
    search.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
    SpreadsheetCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { search } = this.props;
    search.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
    SpreadsheetCache.offStateUpdate(this);
  }
  render() {
    const { search } = this.props;
    const { searchText } = search;
    return searchText && <CreateChild key={searchText.key} node={searchText} />;
  }
}
