/* eslint-disable no-param-reassign */
import Node from './node';
import { FILTER, ANSWER, FOR_EACH_ANSWER, CHECKLIST_ITEM, DATA, QUESTION, CHECKLIST, CONDITIONAL, CALCULATION } from './nodetypes';

export default class Logic extends Node {
  onNodeUpdated(_node) {
    if (_node.isOneOfTypes([QUESTION, FILTER, CHECKLIST, CONDITIONAL, CALCULATION, DATA])) this.update(_node);
  }
  update(_node) {
    this.setBusy(true);
    if (this.updateTimeout) clearTimeout(this.updateTimeout);
    this.updateTimeout = setTimeout(() => {
      this.updateTimeout = null;
      this.__update(_node);
      this.setBusy(false);
    }, this.updateRate);
  }
  onTreeInitialized() {
    this.update();
  }
  onRemovedParents() {
    if (this.updateTimeout) clearTimeout(this.updateTimeout);
  }
  isExcludeBoundary(_node) {
    return _node.attributes[0] === this.attributes[0] && _node.text === this.text && !this.exclude && _node.exclude;
  }
  __recursivelyGetNodeType(node, type) {
    let nodeFound = node.children.find((_child) => _child.attributes[0] === type && _child.text === this.text);
    if (nodeFound) return nodeFound;
    node.children.forEach((_child) => {
      const foundNode = this.__recursivelyGetNodeType(_child, type);
      if (foundNode) nodeFound = foundNode;
    });
    if (nodeFound) return nodeFound;
    return null;
  }
  __recursivelyGetAllQuestionsAndFilters(node) {
    let activeFilters = [];
    node.children.forEach((_child) => {
      if (_child.isOneOfTypes([FILTER, QUESTION])) {
        activeFilters.push(_child);
      }
      if (_child.isType(ANSWER) && !_child.hasUserVoted()) return;
      if ([FOR_EACH_ANSWER].includes(_child.attributes[0])) return;
      const childResults = this.__recursivelyGetAllQuestionsAndFilters(_child);
      activeFilters = [...activeFilters, ...childResults];
    });
    return activeFilters;
  }
  __recursivelyGetAllFilters(node) {
    let activeFilters = [];
    node.children.forEach((_child) => {
      if (_child.isType(FILTER)) {
        activeFilters.push(_child);
      }
      if (_child.isType(ANSWER) && !_child.hasUserVoted()) return;
      if ([FOR_EACH_ANSWER].includes(_child.attributes[0])) return;
      const childResults = this.__recursivelyGetAllFilters(_child);
      activeFilters = [...activeFilters, ...childResults];
    });
    return activeFilters;
  }
  __recursivelyGetAllDataNodes(node) {
    const dataNodes = [];
    node.children.forEach((_child) => {
      if (_child.isType(CONDITIONAL) && !_child.conditionalValue) return;
      if (_child.isOneOfTypes([ANSWER, CHECKLIST_ITEM]) && !_child.checked) return;
      if (this.isExcludeBoundary(_child)) return;
      if (_child.isOneOfTypes([FOR_EACH_ANSWER])) return;
      if (_child.isType(DATA)) {
        const { spreadsheet } = _child;
        if (!spreadsheet || spreadsheet.error || this.parents[0].editing) return;
        if (dataNodes.find((dn) => dn.spreadsheet._id === spreadsheet._id)) return;
        dataNodes.push(_child);
      } else {
        this.__recursivelyGetAllDataNodes(_child).forEach((dataNode) => {
          if (dataNodes.find((dn) => dn.spreadsheet._id === dataNode.spreadsheet._id)) return;
          dataNodes.push(dataNode);
        });
      }
    });
    return dataNodes;
  }
}
