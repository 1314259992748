import Api from './Api';
import Server from './index';

class OpenAIApi extends Api {
  url = 'openai';
  async patchEmbeddingsById(_id, _object) {
    const url = `/${this.url}/embeddings/${_id}`;
    return Server.__patchRequest(url, _object);
  }
}

const singleton = new OpenAIApi();
export default singleton;
