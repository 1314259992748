import Data from './data';
import { QUESTION } from './nodetypes';

export default class Autofill extends Data {
  updateSpreadsheet(_spreadsheet) {
    this.spreadsheet = _spreadsheet;
  }
  clone() {
    return new Autofill(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }

  updateOrAddRows() {
    if (!this.spreadsheet) return null;

    // Get the parent question
    const question = this.parents[this.parents.length - 1];
    
    // Iterate through parent answers
    const updates = [];
    question.answers.forEach((_answer) => {
      const { uniqueId } = _answer;
      const update = [{ question: question.text, answer: _answer.text, project: this.parents[0]._id, uniqueId }];
      _answer.children.forEach((c) => {
        if (!c.isType(QUESTION) || !c.answers[0]) return;
        update.push({ question: c.text, answer: c.answers[0].text });
      });
      updates.push(update);
    });

    return this.spreadsheet.updateOrAddRows(updates);
  }
}
