import Node from './node';

export default class Presentation extends Node {
  constructor(_text, _attributes, _children, _owner) {
    super(_text, _attributes, _children, _owner);
    _children.forEach((c) => c.onStateUpdate(() => { this.emitStateUpdate() }));
  }
  clone() {
    return new Presentation(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner, this);
  }
}
