import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadingIndicator from '../../components/LoadingIndicator';
import Navigation from '../../services/Navigation';
import Constants from '../../submodules/logictry_config/constants';
import DatabaseCollectionCache from '../../services/cache/DatabaseCollectionCache';
import DatabaseCollectionDocumentCache from '../../services/cache/DatabaseCollectionDocumentCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import { getDatabaseCollectionDocuments } from '../../services/Pagination';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb/index';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
  padding: 2rem;
  font-size: ${Constants.SmallFontSize};
  white-space: pre-wrap;
  overflow: auto;
`;

export default class DatabaseCollectionPage extends React.PureComponent {
  componentDidMount() {
    DatabaseCollectionCache.onStateUpdate(this);
    DatabaseCollectionDocumentCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    DatabaseCollectionCache.offStateUpdate(this);
    DatabaseCollectionDocumentCache.offStateUpdate(this);
  }
  render() {
    const databaseCollectionDocuments = getDatabaseCollectionDocuments(Navigation.currentLocation[2]);
    const { currentPage } = databaseCollectionDocuments;
    if (!databaseCollectionDocuments.initialized) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;

    return (
      <Wrapper>
        <DrawerBreadcrumb></DrawerBreadcrumb>
        {currentPage.map((doc) => (
          <div key={JSON.stringify(doc.document)}>{JSON.stringify(doc.document, null, 2)}</div>
        ))}
      </Wrapper>
    );
  }
}