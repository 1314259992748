/* eslint-disable no-nested-ternary */
import React from 'react';

import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import RenderedHtmlToTextArea from '../../components/RenderedHtmlToTextArea/index';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';


const Wrapper = styled.div`
  margin: 0px 0px 0px ${Constants.TreeContentIconIndent};
  > div:first-child {
    > div:first-child {
      margin: ${Constants.defaultLinePadding} 0px; 
    }
    display: flex;
    align-items: center;
  }
`;

export default class NodeBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    placeholderText: PropTypes.string,
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
  }
  render() {
    const { node, placeholderText } = this.props;
    const { editing } = GrayOut;
    if (!editing) return <CreateChildren node={node} />;

    return (
      <Wrapper>
        <div>
          <RenderedHtmlToTextArea node={node} placeholderText={placeholderText || node.attributes[0]} />
        </div>
        <CreateChildren node={node} />
      </Wrapper>
    );
  }
}
