import Service from './Service';
import UserAccount from './UserAccount';
import CompanyCache from './cache/CompanyCache';
import TeamCache from './cache/TeamCache';
// eslint-disable-next-line import/no-cycle
import { getAccountTeams } from './Pagination';
import Constants from '../submodules/logictry_config/constants';

const companyId = window.logictry && window.logictry.company && window.logictry.company._id || (!!Constants.isDevelopment && localStorage.companyId);

class Company extends Service {
  constructor() {
    super();
    CompanyCache.onStateUpdate(this.companyUpdated);
    TeamCache.onStateUpdate(this.updateTeams);
    UserAccount.onStateUpdate(this.updateAccount);
  }
  get isExpected() {
    return !!(companyId);
  }
  get subscriptionVerified() {
    return this.company && this.company.subscriptions && this.company.subscriptions.length > 0;
  }
  get loginError() {
    return ((UserAccount.isLoggedIn) && this.isExpected && ((this.company && this.company.error) || (this.companyTeams && this.companyTeams.error) || (this.company && this.company.subscriptions && this.company.subscriptions.error)));
  }
  get isAuthorizedToLogIn() {
    if (!this.isExpected) return true;
    if (!UserAccount.isLoggedIn) return false;
    if (UserAccount.isDevAccount) return true;
    if (!this.isExpected) return true;
    if (!this.subscriptionVerified) return false;
    return this.company.subscriptionFound;
  }
  createCompany(longname, shortname, owner) {
    this.company = CompanyCache.createObject({
      longname,
      shortname,
      owner,
    });
    CompanyCache.create(this.company);
  }
  createCompanyTeam(name, description, shareSetting) {
    TeamCache.create(TeamCache.createObject({
      longname: name,
      description,
      shareSetting,
      company: this.company._id,
      users: [],
      teams: [],
    }));
  }
  updateCompany(team) {
    TeamCache.update(team);
  }
  updateAccount = () => {
    if (!UserAccount.isLoggedIn) {
      this.company = null;
      this.companyTeams = null;
      if (this.accountTeams) {
        this.accountTeams.offStateUpdate(this.updateTeams);
        this.accountTeams = null;
      }
      return;
    }
    this.companyUpdated();
  }
  companyUpdated = () => {
    if (!this.isExpected) return null;
    this.company = CompanyCache.get(companyId);
    if (this.subscriptionVerified) {
      this.accountTeams = getAccountTeams();
      this.accountTeams.onStateUpdate(this.updateTeams);
      return this.updateTeams();
    }
    return this.emitStateUpdate();
  }
  updateTeams = () => {
    if (!UserAccount.isLoggedIn || !this.company || !this.company._id) return this.emitStateUpdate();
    this.companyTeams = this.accountTeams.currentPage;
    return this.emitStateUpdate();
  }
  update = (_objectModel, newState) => {
    CompanyCache.update(_objectModel, newState);
  }
  revertObject = (_object) => {
    CompanyCache.revertObject(_object);
  }
}

const singleton = new Company();
export default singleton;
