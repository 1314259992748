import Service from './Service';
import UserAccount from './UserAccount';
import TreeCache from './cache/TreeCache';
import SaveTreeAndSubtrees from '../utils/TreeParsers/SaveTreeAndSubtrees';

class AutoSaveTree extends Service {
  autosaveTimeout = {};
  autoSaveProject = (tree) => {
    const { isLocked, project, isOwner } = tree;
    if (!(project && !isLocked && isOwner)) return;
    if (this.autosaveTimeout[tree._id]) clearTimeout(this.autosaveTimeout[tree._id]);
    this.autosaveTimeout[tree._id] = setTimeout(() => {
      delete this.autosaveTimeout[tree._id];
      SaveTreeAndSubtrees(tree);
    }, 2000);
  }
  autoSaveTemplate = (tree) => {
    const { isLocked, template, isEditor } = tree;
    if (!(template && !isLocked && isEditor)) return;
    if (this.autosaveTimeout[tree._id]) clearTimeout(this.autosaveTimeout[tree._id]);
    this.autosaveTimeout[tree._id] = setTimeout(() => {
      delete this.autosaveTimeout[tree._id];
      // Dont save if not current version
      if (tree.currentVersionDisplayed) return;
      // Dont save if not created
      if (!tree._id && tree.root.children.length === 0) return;
      // Dont save if not editor
      if (!tree.isEditor) return;
      // Autosave
      TreeCache.updateTree(tree);
    }, 2000);
  }
}

const singleton = new AutoSaveTree();
UserAccount.onStateUpdate(() => {
  if (!UserAccount.isLoggedIn) {
    Object.values(singleton.autosaveTimeout).forEach((autosave) => {
      clearTimeout(autosave);
    });
    singleton.autosaveTimeout = {};
  }
});
export default singleton;
