// import React from 'react';
// import styled from 'styled-components';

import Navigation from '../../services/Navigation';
import TreeCache from '../../services/cache/TreeCache';
import ObjectSettings from './index';


export default class ProjectTreeSettings extends ObjectSettings {
  template = (_tree) => Navigation.push(`${Navigation.apps}/${_tree.referenceTree}`);
  leave = (tree) => {
    TreeCache.updateTree(tree, 'Remove');
    this.setState({ confirmLeave: false });
  }
  lock = (tree) => {
    TreeCache.updateTree(tree, 'Locked');
    this.setState({ confirmLock: false });
  }
  unlock = (tree) => {
    TreeCache.updateTree(tree, 'Live');
    this.setState({ confirmUnlock: false });
  }
  restore = (tree) => {
    TreeCache.updateTree(tree, 'Live');
    this.setState({ confirmRestore: false });
  }
  delete = (tree) => {
    TreeCache.deleteTree(tree);
    this.setState({ confirmDelete: false });
  }
}
