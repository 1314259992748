import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import UserAccount from '../../services/UserAccount';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import LoadingIndicator from '../../components/LoadingIndicator';
import Popup from '../Popup/index';
import BoxPopup from '../../containers/TreePage/BoxPopup';
import { getAccountCollections } from '../../services/Pagination/index';
import Scrollable from '../Scrollable';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem 3rem;
  h1 {
    margin-bottom: 2rem;
    text-align: center;
  }
  > div:last-child {
    padding: 1.5rem 0 2rem;
    width: 100%;
    > div {
      margin: 0.5rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
`;

export default class CollectionsPopup extends BoxPopup {
  static propTypes = {
    onClose: PropTypes.func,
    app: PropTypes.object,
  }
  constructor() {
    super();
    const { account } = UserAccount;
    this.collections = getAccountCollections(account._id);
  }
  componentDidMount() {
    this.collections.onStateUpdate(this);
    LogicBaseCollectionCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    this.collections.offStateUpdate(this);
    LogicBaseCollectionCache.offStateUpdate(this);
  }
  addRemove = (collection) => {
    const { app } = this.props;
    if (!app || !collection) return;
    if (collection.apps.includes(app._id)) collection.apps = collection.apps.filter((id) => id !== app._id);
    else collection.apps.unshift(app._id);
    LogicBaseCollectionCache.update(collection);
  }
  onPagination = () => {
    if (this.collections.showMore && this.collections.allFound && this.collections.initialized) {
      this.collections.getMore();
    }
  }
  render() {
    const { onClose, app } = this.props;
    const { currentPage } = this.collections;
    if (!currentPage) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    return (
      <Popup
        showCloseButton
        allowBackgroundClose
        onClose={onClose}
      >
        <Scrollable
          vertical
          paginationHeight={200}
          onPagination={this.onPagination}
          style={{ width: '100%', maxHeight: 480 }}
        >
          <Wrapper key={currentPage.length}>
            <h1>Add / Remove Collections</h1>
            <div>
              {currentPage.map((collection) => (
                <div key={collection._id}>
                  <div>{collection.title}</div>
                  <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.addRemove(collection); }}>{collection.apps.includes(app._id) ? 'Remove' : 'Add'}</button>
                </div>
              ))}
            </div>
          </Wrapper>
        </Scrollable>
      </Popup>
    );
  }
}
