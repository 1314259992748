import Node from './node';
import { NODE_ATTR, CONSTANT_ATTR } from './nodeattributes';

export default class DataSchemaValue extends Node {
  constructor(_text, _attributes, _children, _owner) {
    super(_text, _attributes, _children, _owner);
    this.__defineNodeAttribute('type', [{ name: NODE_ATTR, value: NODE_ATTR, default: true }, { name: CONSTANT_ATTR, value: CONSTANT_ATTR }]);
  }
  clone() {
    return new DataSchemaValue(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
}
