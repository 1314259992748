/* eslint-disable no-useless-escape */
import Questionable from './questionable';

export default class Question extends Questionable {
  deleteChild(_child) {
    if (!this.parents[0].editing && this.children.length <= 1) return;
    super.deleteChild(_child);
    this.checkFixedOpenEnded();
  }
  clone() {
    return new Question(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner, this);
  }
}
