import Node from './node';
import { XML_ATTR, NONE_ATTR, CONSTANT_ATTR, ATTRIBUTE_ATTR } from './nodeattributes';
import { DATA_SCHEMA_FIELD, DATA_SCHEMA_FIELD_TITLE, DATA_SCHEMA_KEY, DATA_SCHEMA_VALUE } from './nodetypes';

export default class DataSchema extends Node {
  constructor(_text, _attributes, _children, _owner) {
    super(_text, _attributes, _children, _owner);
    this.__defineNodeAttribute('format', [{ name: 'JSON', value: NONE_ATTR, default: true }, { name: 'XML', value: XML_ATTR }])
  }
  get schema() {
    const schema = {
      array: {
        field: this.text || '',
        schema: [],
      }
    };
    const dataSchemaField = this.children.filter((c) => c.isType(DATA_SCHEMA_FIELD));
    dataSchemaField.forEach((field) => {
      const { text, sortOption } = field;
      const title = field.children.find((c) => c.isType(DATA_SCHEMA_FIELD_TITLE));
      schema.array.schema.push({
        field: text,
        title: title && title.text || text,
        sortOption
      });
    });
    return schema;
  }
  get postSchema() {
    return __recursivelyBuildPostSchema('', this.children, this.isXML);
  }
  get isXML() {
    return this.attributes.includes(XML_ATTR);
  }
  clone() {
    return new DataSchema(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
}
function __recursivelyBuildPostSchema(parentText, children, xml) {
  let attributeText = '';
  let childText = '';
  children.filter((c) => c.isOneOfTypes([DATA_SCHEMA_KEY])).forEach((node) => {
    let valueText;
    const valueNodes = node.children.filter((n) => n.isType(DATA_SCHEMA_VALUE));
    if (valueNodes.length > 0) {
      const valueNode = valueNodes[0];
      if (valueNode.typeOption === CONSTANT_ATTR) valueText = `${valueNode.text}`;
      else valueText = `{{${valueNode.text}}}`;
    }
    if (valueText) {
      if (xml) {
        if (node.layoutOption === ATTRIBUTE_ATTR) {
          attributeText += ` ${node.text}="${valueText}"`;
        } else {
          childText += `<${node.text}>${valueText}</${node.text}>`;
        }
      } else {
        childText += childText ? `, "${node.text}": "${valueText}"` : `"${node.text}": "${valueText}"`;
      }
    } else {
      if (xml) childText += __recursivelyBuildPostSchema(node.text, node.children);
    }
  });
  if (!parentText) return xml ? childText : `{ ${childText} }`;
  return xml ? `<${parentText}${attributeText}>${childText}</${parentText}>` : `{ ${childText} }`;
}
