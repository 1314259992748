import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';
import GrayOut from '../../services/TreeDisplay';
import RenderedHtmlToTextArea from '../../components/RenderedHtmlToTextArea/index';
import { EXPORT } from '../../models/nodetypes';
import CopyHtmlToClipboardButton from '../../components/CopyHtmlToClipboardButton/index';

const Wrapper = styled.div`
  margin: 0px 0px 0px ${Constants.TreeContentIconIndent};

  > div:first-child {
    > div:first-child {
      margin: ${Constants.defaultLinePadding} 0px;
    }
    display: flex;
    align-items: center;
  }
`;
const NonEditingWrapper = styled.div`
  position: relative;
  > div:nth-last-child(1), > div:nth-last-child(2) {
    opacity: 0;
  }
  :hover {
    > div:nth-last-child(1), > div:nth-last-child(2) {
      opacity: 1;
    } 
  }
`;
const Copy = styled.div`
  position: absolute;
  top: 2px;
  right: 40px;
  background-color: ${Constants.White};
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
`;

export default class ExportBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
  }
  createPaneRef = (e) => {
    this.nodeRef = e;
    this.forceUpdate();
  }
  render() {
    const { node } = this.props;
    const { editing } = GrayOut;

    if (editing) {
      return (
        <Wrapper>
          <div>
            <RenderedHtmlToTextArea node={node} placeholderText={EXPORT} />
          </div>
          <CreateChildren node={node} />
        </Wrapper>
      );
    }

    return (
      <NonEditingWrapper>
        <div ref={this.createPaneRef}>
          <CreateChildren node={node} />
        </div>
        <Copy><CopyHtmlToClipboardButton target={this.nodeRef} /></Copy>
      </NonEditingWrapper>
    );
  }
}
