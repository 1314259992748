export default function formatReplyCount(replyCount) {
  if (replyCount === 0) {
      return 'No replies';
  } else if (replyCount === 1) {
      return '1 reply';
  } else if (replyCount < 10) {
      return replyCount + ' replies';
  } else {
      return '9+ replies';
  }
}
