import Model from './model';

export default class LogicBaseCommentLike extends Model {
  constructor(_id, follower, comment) {
    super();
    this._id = _id;
    this.follower = follower;
    this.comment = comment;
  }
  getJson = () => {
    const { _id, follower, comment } = this;
    return {
      _id,
      fr: follower,
      c: comment,
    }
  }
}
