import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import LogicBaseAppCache from '../../services/cache/LogicBaseAppCache';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import UserAccount from '../../services/UserAccount';
import LogicBaseCollectionSubscriptionCache from '../../services/cache/LogicBaseCollectionSubscriptionCache';
import UserCache from '../../services/cache/UserCache';
import LogicBaseAppList from '../../components/LogicBaseAppList';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import LoadingIndicator from '../LoadingIndicator';
import Navigation from '../../services/Navigation';
import Scrollable from '../Scrollable';
import CollectionSubscribeButton from '../CollectionSubscribeButton';

const Wrapper = styled.div`
  height: 270px;
  ${Constants.MediaMobile} {
    height: 250px;
  }
  h2 {
    font-size: ${Constants.LargeFontSize};
    ${Constants.MediaMobile} {
      font-size: ${Constants.SemiLargeFontSize};
    }
    font-weight: 500;
    cursor: pointer;
  }
  p {
    margin: 0 0 0.5rem;
  }
`;

export default class LogicBaseCollectionRow extends React.PureComponent {
  static propTypes = {
    vertical: PropTypes.bool,
    fullscreen: PropTypes.bool,
    showAppId: PropTypes.string,
    onScroll: PropTypes.func,
  }
  state = {
    gridOrList: Navigation.currentSearch.contentlayout === 'grid' ? 'grid' : 'list'
  }
  componentDidMount() {
    LogicBaseAppCache.onStateUpdate(this);
    LogicBaseCollectionSubscriptionCache.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
  }
  componentWillUnmount() {
    LogicBaseAppCache.offStateUpdate(this);
    LogicBaseCollectionSubscriptionCache.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
  }
  render() {
    const { collection, vertical } = this.props;
    const { gridOrList } = this.state;
    const loading = <Wrapper key={collection._id}><FullScreenVerticalCenterContent key={collection._id}><LoadingIndicator /></FullScreenVerticalCenterContent></Wrapper>;
    const notFound = <Wrapper key={collection._id}><FullScreenVerticalCenterContent key={collection._id}><h1>Page Not Found</h1></FullScreenVerticalCenterContent></Wrapper>;
    const publicApps = LogicBaseAppCache.getByIds(collection.apps);
    if (!publicApps) return loading;
    const publicUser = LogicBaseUserCache.get(collection.owner);
    if (!publicUser) return loading;
    const users = UserCache.getUsersByIds([collection.owner]);
    if (!users) return loading;
    const user = users[0];
    if (!user || !user._id) return notFound;
    return vertical && (
      <LogicBaseAppList key={collection._id} showUser apps={publicApps} appCollection={collection.url} listView={gridOrList === 'list'} medium={gridOrList !== 'list'} />
    ) || (
      <Wrapper key={collection._id}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <h2 onClick={() => Navigation.push(`/collections/${collection.url}`)}>{collection.title}</h2>
          <CollectionSubscribeButton collection={collection} />
        </div>
        <Scrollable
          horizontal
          onScroll={this.onScroll}
          scrollRef={(e) => { this.topicsRef = e; }}
          rememberScrollPosition={`logicbasecollection_${collection.url}`}
        >
          <LogicBaseAppList showUser apps={publicApps} horizontal medium appCollection={collection.url} />
        </Scrollable>
      </Wrapper>
    )
  }
}
