import Service from './Service';

const __scriptsLoaded = {};
const LOADING = 'loading';
const LOADED = 'loaded';
const LOAD_ERROR = 'load_error';

class ScriptLoader extends Service {
  isLoaded(_script) {
    return __scriptsLoaded[_script] === LOADED;
  }
  load(_script) {
    if (__scriptsLoaded[_script]) {
      if (this.isLoaded(_script)) return setTimeout(() => {
        this.emitStateUpdate();
      });
      return;
    }
    if (Array.from(document.getElementsByTagName("script")).find((s) => s.src === _script)) {
      __scriptsLoaded[_script] = LOADED;
      setTimeout(() => {
        this.emitStateUpdate();
      });
    }

    __scriptsLoaded[_script] = LOADING;
    this.script = document.createElement('script');
    this.script.src = _script;
    this.script.async = true;
    this.script.defer = true;
    this.script.onload = () => {
      __scriptsLoaded[_script] = LOADED;
      setTimeout(() => {
        this.emitStateUpdate();
      });
    };
    this.script.onerror = () => {
      __scriptsLoaded[_script] = LOAD_ERROR;
      setTimeout(() => {
        this.emitStateUpdate();
      });
    }
    return document.body.appendChild(this.script);
  }
}

const singleton = new ScriptLoader();
export default singleton;
