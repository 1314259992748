import DatabaseCollectionCache from '../../services/cache/DatabaseCollectionCache';
import Navigation from '../../services/Navigation';
import CreatePopup from './CreatePopup';



export default class CreateDatabaseCollectionPopup extends CreatePopup {
  createTitle = "Create Database Collection"
  showName = 'Collection Name';
  saveAndClose = () => {
    const { title, description, name } = this.state;
    if (!name || /[^A-Za-z]+/.exec(name)) return this.close();
    const database = DatabaseCollectionCache.createNew(title, description, name, Navigation.currentLocation[2]);
    // if (this.props.team) database.addTeam(this.props.team);
    DatabaseCollectionCache.create(database);
    return this.close();
  }
}
