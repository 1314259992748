import Model from './model';

export default class LogicBaseRelationship extends Model {
  constructor(_id, follower, followee, requested) {
    super();
    this._id = _id;
    this.follower = follower;
    this.followee = followee;
    this.requested = requested;
  }
  getJson = () => {
    const { _id, follower, followee, requested } = this;
    return {
      _id,
      fr: follower,
      fe: followee,
      r: requested,
    }
  }
}
