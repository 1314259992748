/* eslint-disable import/no-cycle */
import React from 'react';
// import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import CreateChild from './CreateChild';

export default class CreateChildren extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    dontDisplay: PropTypes.array,
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
  }
  render() {
    const { node, dontDisplay } = this.props;
    const children = node.children.map((_child) => (
      <CreateChild key={_child.key} node={_child} dontDisplay={dontDisplay} />
    )).filter((_element) => _element !== null);
    if (children.length > 0) return children;
    return null;
  }
}
