import Model from './model';

export default class LogicBaseCollection extends Model {
  constructor(_id, title, description, image, url, apps, owner, followers, editors, updateTime, socialShare) {
    super();
    this._id = _id;
    this.title = title;
    this.description = description;
    this.image = image;
    this.url = url;
    this.apps = apps || 0;
    this.owner = owner;
    this.followers = followers || 0;
    this.editors = editors || [];
    this.updateTime = updateTime;
    this.socialShare = socialShare;
  }
  getJson = () => {
    const { _id, title, description, image, url, apps, owner, followers, editors, socialShare } = this;
    return {
      _id,
      t: title,
      d: description,
      i: image,
      u: url,
      a: apps,
      o: owner,
      fr: followers,
      e: editors,
      ss: socialShare
    }
  }
}
