// import React from 'react';
// import styled from 'styled-components';

import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import OpenAI from '../../services/OpenAI';
import Company from '../../services/Company';
import TreeCache from '../../services/cache/TreeCache';
import ObjectSettings from './index';
import { CATEGORY, TEXT } from '../../models/nodetypes';


export default class TemplateTreeSettings extends ObjectSettings {
  showEmbedCode = true;
  showShareLinks = true;
  edit = (_tree) => Navigation.push(`${Navigation.apps}/${_tree._id}/edit`);
  projects = (_tree) => Navigation.push(`${Navigation.apps}/${_tree._id}/analytics`);
  leave = (tree) => {
    TreeCache.updateTree(tree, 'Remove');
    this.setState({ confirmLeave: false });
  }
  lock = (tree) => {
    TreeCache.updateTree(tree, 'Locked');
    this.setState({ confirmLock: false });
  }
  unlock = (tree) => {
    TreeCache.updateTree(tree, 'Live');
    this.setState({ confirmUnlock: false });
  }
  restore = (tree) => {
    TreeCache.updateTree(tree, 'Live');
    this.setState({ confirmRestore: false });
  }
  delete = (tree) => {
    TreeCache.deleteTree(tree);
    this.setState({ confirmDelete: false });
  }
  duplicate = (tree) => {
    TreeCache.createTree(TreeCache.duplicateTree(tree));
    this.setState({ confirmDuplicate: false });
  }
  setHome = (_tree) => {
    if (!UserAccount.account.home) UserAccount.account.home = {};
    if (Company.company && Company.company._id) UserAccount.account.home[Company.company._id] = _tree._id;
    else UserAccount.account.home._id = _tree._id;
    UserAccount.updateUserProfile(UserAccount.account);
  }
  pushPublic = () => {
    this.setState({ pushPublicSettings: false });
  }
  updateVectors = async (tree) => {
    const nodes = tree.root.__recursivelyGetAllNodesByTypes(tree.root, [TEXT]);
    let information = [];
    let maxLength = 0;
    nodes.forEach(({ text, parents }) => {
      let preText = ''
      parents.forEach((parent) => {
        if (parent.isType(CATEGORY)) {
          preText += `${parent.text} -> `;
        }
      });
      information.push(`${preText}${text}`);
      const words = text.split(/\s+/);
      maxLength = Math.max(maxLength, words.length);
    });
    if (maxLength > 4000) return { error: 'TextTooLong' };
    return OpenAI.updateEmbedding(tree._id, information);
  }
}
