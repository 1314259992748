import Constants from '../submodules/logictry_config/constants';
import SparkMD5 from 'spark-md5';
import Model from './model';
import { ANSWER, AUTOFILL, DISPLAY_TEXT, FOR_EACH_ANSWER, INFO, KEYWORDS, NOTE, PLACEHOLDER_TEXT, QUESTION, ONLY_ONE_CHILD_ALLOWED, checkIfChildIsAllowed, ALLOW_HASH_KEY_INSTEAD_OF_TEXT_IN_PROJECT, CHATBOT, WIKI } from './nodetypes';
import { HORIZONTAL_ATTR, VERTICAL_ATTR, SHOW_TEXT_ATTR, HIDE_TEXT_ATTR, DEFAULT_EXPANDED_ATTR, DEFAULT_COLLAPSED_ATTR, EXCLUDE_ATTR, ALLOW_INLINE_NOTES_ATTR, PREVENT_AUTO_EXPAND_ATTR, MAX_LIST_SIZE_ATTR, UNIQUE_ID_ATTR, HIDE_NODE_ATTR, DEFAULT_VALUE_ATTR, DECIMALS_ATTR, SHOW_CHILDREN_AS_SIBLINGS_ATTR, GO_TO_NEXT_ON_SELECTION, PRIMARY_COLOR_ATTR, UPDATE_RATE_ATTR, NONE_ATTR, BACKGROUND_COLOR_ATTR, BORDER_COLOR_ATTR, BACKGROUND_VIDEO_ATTR, BACKGROUND_IMAGE_ATTR } from './nodeattributes';
import findAllHandlebars from '../utils/parsevariables';
const DirectionOptions = [{ name: 'Vertical', value: VERTICAL_ATTR }, { name: 'Horizontal', value: HORIZONTAL_ATTR }];

export const TextVisibilitySettings = [HIDE_TEXT_ATTR, SHOW_TEXT_ATTR, HIDE_NODE_ATTR];
const defaultCategorySettings = [DEFAULT_COLLAPSED_ATTR, DEFAULT_EXPANDED_ATTR];
export const TextVisbilityOptions = [{ name: 'Show Text', value: SHOW_TEXT_ATTR }, { name: 'Hide Text', value: HIDE_TEXT_ATTR }, { name: 'Hide Node', value: HIDE_NODE_ATTR }];
const expandedOptions = [{ name: 'Collapsed', value: DEFAULT_COLLAPSED_ATTR }, { name: 'Expanded', value: DEFAULT_EXPANDED_ATTR }];

export default class Node extends Model {
  text = '';
  attributes = [];
  children = [];
  parents = [];
  owner;
  constructor(_text, _attributes, _children, _owner) {
    super();
    this.text = _text || '';
    this.updateAttributes(_attributes);
    this.children = Array.from(new Set(_children || []));
    if (_owner) this.owner = _owner;
    this.children = this.__filterChildren(this.children);
    this.__sortNodes();
    this.__defineNodeAttribute('showChildrenAs', [{ name: 'Show Children As Children', value: NONE_ATTR, default: true }, { name: 'Show Children As Siblings', value: SHOW_CHILDREN_AS_SIBLINGS_ATTR }]);
  }
  clone() {
    return new Node(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
  get borderColor() {
    const color = this.attributes.find((a) => a.includes(BORDER_COLOR_ATTR));
    if (color) return color.split('=')[1];
    return null;
  }
  get backgroundColor() {
    const color = this.attributes.find((a) => a.includes(BACKGROUND_COLOR_ATTR));
    if (color) return color.split('=')[1];
    return null;
  }
  updateBackgroundColor(_color) {
    this.attributes = this.attributes.filter((_attr) => !_attr.includes(BACKGROUND_COLOR_ATTR));
    if (!_color) return this.__attributeChanged();
    return this.addAttribute(`${BACKGROUND_COLOR_ATTR}${_color}`);
  }
  get backgroundVideo() {
    const backgroundVideo = this.attributes.find((a) => a.includes(BACKGROUND_VIDEO_ATTR));
    if (backgroundVideo) return backgroundVideo.split('=')[1];
    return null;
  }
  get backgroundImage() {
    const backgroundImage = this.attributes.find((a) => a.includes(BACKGROUND_IMAGE_ATTR));
    if (backgroundImage) return backgroundImage.split('=')[1];
    return null;
  }
  updateBackgroundImage(_image) {
    this.attributes = this.attributes.filter((_attr) => !_attr.includes(BACKGROUND_IMAGE_ATTR));
    if (!_image) return this.__attributeChanged();
    return this.addAttribute(`${BACKGROUND_IMAGE_ATTR}${_image}`);
  }
  updatePrimaryColor(_color) {
    this.attributes = this.attributes.filter((_attr) => !_attr.includes(PRIMARY_COLOR_ATTR));
    if (!_color) return this.__attributeChanged();
    return this.addAttribute(`${PRIMARY_COLOR_ATTR}${_color}`);
  }
  get parent() {
    return this.parents.slice(-1)[0];
  }
  get horizontal() {
    return this.attributes.includes(HORIZONTAL_ATTR);
  }
  get vertical() {
    // Default is vertical
    if (!this.horizontal) return true;
    return this.attributes.includes(VERTICAL_ATTR);
  }
  get directionOptions() {
    return DirectionOptions;
  }
  get updateRate() {
    const updateRate = this.attributes.find((a) => a.includes(UPDATE_RATE_ATTR));
    if (updateRate) return parseInt(updateRate.split('=')[1], 10);
    return 100;
  }
  updateDirectionOption(_attribute) {
    this.attributes = this.attributes.filter((_attr) => ![HORIZONTAL_ATTR, VERTICAL_ATTR].includes(_attr));
    if (_attribute !== VERTICAL_ATTR) this.addAttribute(_attribute);
    else this.__attributeChanged();
  }
  get goToNextOnSelection() {
    return !![this, ...this.parents].find((node) => node.isType(CHATBOT) || node.attributes.includes(GO_TO_NEXT_ON_SELECTION));
  }
  get showChildrenAsSiblings() {
    return !![this, ...this.parents].find((node) => node.isType(CHATBOT)) || this.attributes.includes(SHOW_CHILDREN_AS_SIBLINGS_ATTR);
  }
  get isInsideChatbot() {
    return !!this.parents.find((node) => node.isType(CHATBOT));
  }
  get isInsideWiki() {
    return !!this.parents.find((node) => node.isType(WIKI));
  }
  get textVisibilitySetting() {
    return this.attributes.find((a) => TextVisibilitySettings.includes(a)) || SHOW_TEXT_ATTR;
  }
  get defaultCategorySetting() {
    return this.attributes.find((a) => defaultCategorySettings.includes(a)) || DEFAULT_COLLAPSED_ATTR;
  }
  get isTextHidden() {
    return this.textVisibilitySetting === HIDE_TEXT_ATTR || this.isNodeHidden;
  }
  get isNodeHidden() {
    return this.textVisibilitySetting === HIDE_NODE_ATTR;
  }
  get isDefaultExpanded() {
    return this.defaultCategorySetting === DEFAULT_EXPANDED_ATTR;
  }
  get displayText() {
    return this.children.find((_child) => _child.isType(DISPLAY_TEXT));
  }
  get displayTextOrText() {
    if (this.displayText) return this.displayText.text;
    return this.text;
  }
  get formattedText() {
    return this.text;
  }
  get formattedProjectText() {
    if (this.displayText) return this.displayText.textWithMatchedVariables;
    return this.formattedText;
  }
  get keywords() {
    return this.children.filter((_child) => _child.isType(KEYWORDS));
  }
  get placeholderText() {
    return this.children.find((_child) => _child.isType(PLACEHOLDER_TEXT));
  }
  get info() {
    return this.children.find((_child) => _child.isType(INFO));
  }
  get questions() {
    return this.children.filter((_child) => _child.isType(QUESTION));
  }
  get autofill() {
    return this.children.find((_child) => _child.isType(AUTOFILL));
  }
  get note() {
    return this.children.find((_child) => _child.isType(NOTE));
  }
  get answers() {
    return this.children.filter((_child) => _child.isType(ANSWER));
  }
  get forEachAnswer() {
    return this.children.filter((_child) => _child.isType(FOR_EACH_ANSWER));
  }
  get exclude() {
    return this.attributes.includes(EXCLUDE_ATTR);
  }
  get keywordsFound() {
    return new Set([...(this.keywordsFoundBySearch || []), ...(this.keywordsFoundByQuestion || [])]);
  }

  get maxListSize() {
    const maxListSize = this.attributes.find((a) => a.includes(MAX_LIST_SIZE_ATTR));
    if (maxListSize) return parseInt(maxListSize.split('=')[1], 10);
    return 50;
  }
  get preventAutoExpand() {
    return this.attributes.includes(PREVENT_AUTO_EXPAND_ATTR);
  }
  get showInlineNotes() {
    return this.attributes.includes(ALLOW_INLINE_NOTES_ATTR);
  }
  get expandedOptions() {
    return expandedOptions;
  }
  get uniqueId() {
    const uniqueId = this.attributes.find((a) => a.includes(UNIQUE_ID_ATTR));
    if (uniqueId) return uniqueId.split('=')[1];
    this.addAttribute(`${UNIQUE_ID_ATTR}${this.key}`);
    return this.key;
  }
  get primaryColor() {
    const param = this.attributes.find((a) => a.includes(PRIMARY_COLOR_ATTR));
    return !this.parents[0].editing && param && param.split('=')[1] || Constants.PrimaryColor;
  }
  get primaryColorSetting() {
    const param = this.attributes.find((a) => a.includes(PRIMARY_COLOR_ATTR));
    if (param) return param.split('=')[1];
    return '';
  }
  get defaultValue() {
    const param = this.attributes.find((a) => a.includes(DEFAULT_VALUE_ATTR));
    if (param) return param.split('=')[1];
    return '';
  }
  get type() {
    return this.attributes[0];
  }
  get decimals() {
    const decimals = this.attributes.find((a) => a.includes(DECIMALS_ATTR));
    if (decimals) return Math.min(parseInt(decimals.split('=')[1], 10), 10);
    return 0;
  }
  get isDynamicHeightElement() {
    return false;
  }
  setBusy(busy) {
    this.parents[0].onBusy(this, busy);
  }
  updateDecimals(_decimals) {
    if (_decimals < 0 || _decimals > 10) return this.__attributeChanged();
    this.attributes = this.attributes.filter((_attr) => !_attr.includes(DECIMALS_ATTR));
    if (parseInt(_decimals, 10) === 0) return this.__attributeChanged();
    return this.addAttribute(`${DECIMALS_ATTR}${_decimals}`);
  }
  updateDefaultValue(_defaultValue) {
    this.attributes = this.attributes.filter((_attr) => !_attr.includes(DEFAULT_VALUE_ATTR));
    if (!_defaultValue) return this.__attributeChanged();
    return this.addAttribute(`${DEFAULT_VALUE_ATTR}${_defaultValue}`);
  }

  setExpanded(_expanded, _force) {
    if (_expanded && !_force && this.preventAutoExpand) return;
    if (this.expanded === _expanded) return;
    this.expanded = _expanded;
    this.emitStateUpdate();
  }
  getSiblingDirectlyBefore(_node) {
    const currentIndex = this.children.findIndex((_child) => _child === _node);
    if (currentIndex > 0) return this.children[currentIndex - 1];
    return null;
  }
  getSiblingDirectlyAfter(_node) {
    const currentIndex = this.children.findIndex((_child) => _child === _node);
    return this.children[currentIndex + 1];
  }
  updateExpandedOption(_attribute) {
    this.attributes = this.attributes.filter((_attr) => ![DEFAULT_COLLAPSED_ATTR, DEFAULT_EXPANDED_ATTR].includes(_attr));
    if (_attribute !== DEFAULT_COLLAPSED_ATTR) this.addAttribute(_attribute);
    else this.__attributeChanged();
  }
  updateTextVisibilityOption(_attribute) {
    this.attributes = this.attributes.filter((_attr) => !TextVisibilitySettings.includes(_attr));
    if (_attribute !== SHOW_TEXT_ATTR) this.addAttribute(_attribute);
    else this.__attributeChanged();
  }
  canAddChild(type) {
    if (ONLY_ONE_CHILD_ALLOWED.includes(type) && this.children.find((c) => c.isType(type))) return false;
    return true;
  }
  addChild(_child, index) {
    if (!this.__addChild(_child, index)) return;
    if (this.parents && this.parents[0]) this.parents[0].onNodeAdded(_child, [...this.parents, this]);
    this.emitStateUpdate();
  }
  __addChild(_child, index) {
    // Adding children should first check if it is a restricted type
    if (!_child) return false;
    if (typeof index === 'number' && index >= 0) this.children.splice(index, 0, _child);
    else this.children.push(_child);
    this.children = this.__filterChildren(this.children);
    this.__sortNodes();
    return true;
  }
  deleteChild(_child) {
    const foundIndex = this.children.findIndex((child) => child === _child);
    if (foundIndex < 0) return;
    if (this.parents && this.parents[0]) this.parents[0].onNodeDeleted(this.children[foundIndex]);
    this.children.splice(foundIndex, 1);
    this.emitStateUpdate();
  }
  updateAttributes(_attributes) {
    (_attributes || []).forEach((_attr) => {
      if (!this.attributes.includes(_attr)) this.attributes.push(_attr);
    });
  }
  addAttribute(_attribute) {
    this.attributes.push(_attribute);
    this.__attributeChanged();
  }
  updateText(_text) {
    this.text = _text;
    this.__updateTreeOnNodeUpdated();
    this.emitStateUpdate();
  }
  moveChildUp(_child) {
    const currentIndex = this.children.findIndex((child) => child === _child);
    if (currentIndex < 0) return;
    const newIndex = currentIndex - 1;
    if (newIndex < 0 || newIndex >= this.children.length) return;
    this.children.splice(newIndex, 0, this.children.splice(currentIndex, 1)[0]);
    if (this.parents && this.parents[0]) this.parents[0].onNodeMoved();
    this.emitStateUpdate();
  }
  moveChildDown(_child) {
    const currentIndex = this.children.findIndex((child) => child === _child);
    if (currentIndex < 0) return;
    const newIndex = currentIndex + 1;
    if (newIndex < 0 || newIndex >= this.children.length) return;
    this.children.splice(newIndex, 0, this.children.splice(currentIndex, 1)[0]);
    if (this.parents && this.parents[0]) this.parents[0].onNodeMoved();
    this.emitStateUpdate();
  }
  isMatchingNode(_node) {
    if (this.attributes[0] !== _node.attributes[0]) return false;
    if (this.text === _node.text) return true;

    // Allow hashkey checking if its a supported type
    if (ALLOW_HASH_KEY_INSTEAD_OF_TEXT_IN_PROJECT.includes(this.attributes[0])) {
      if (this.text.length === 32 && SparkMD5.hash(_node.text) === this.text) return true;
      if (_node.text.length === 32 && SparkMD5.hash(this.text) === _node.text) return true;
    }
    return false;
  }
  isType(_type) {
    return this.attributes[0] === _type;
  }
  isOneOfTypes(_types) {
    return _types.includes(this.attributes[0]);
  }

  // Text Variables
  updateTextVariable(_var, value) {
    if (!this.textVariables) this.textVariables = {};
    this.textVariables[_var] = value;
    this.emitStateUpdate();
  }
  get textWithMatchedVariables() {
    let textToDisplay = this.text;
    const matchedVariables = findAllHandlebars(textToDisplay);
    // If no matched variables return text
    if (matchedVariables.length === 0) return textToDisplay;
    if (!this.textVariables) return textToDisplay;
    matchedVariables.forEach((_var) => {
      if (this.textVariables[_var]) textToDisplay = textToDisplay.replace(`{{${_var}}}`, this.textVariables[_var]);
    });
    return textToDisplay;
  }

  __updateAllParentsOnNodeUpdated() {
    const parentsReversed = this.parents.slice(1).reverse();
    parentsReversed.forEach((parent) => {
      if (parent.onNodeUpdated) parent.onNodeUpdated(this);
    });
  }
  __updateDirectParentOnNodeUpdated(update) {
    if (!this.parents || this.parents.length === 0) return;
    const parent = this.parent;
    if (!parent || !parent.onNodeUpdated) return;
    parent.onNodeUpdated(this, update);
  }
  __updateTreeOnNodeUpdated() {
    if (!this.parents || this.parents.length === 0) return;
    const tree = this.parents[0];
    if (!tree || !tree.onNodeUpdated) return;
    tree.onNodeUpdated(this);
  }
  __getFirstInstanceOfNodeInParents(type, text) {
    return [...this.parents].reverse().find((_f) => _f.isType(type) && _f.text === text);
  }
  __filterChildren() {
    return this.children.filter((_child) => checkIfChildIsAllowed(this, _child));
  }
  __sortNodes() {
    const orderOfTypes = ONLY_ONE_CHILD_ALLOWED;
    this.children.sort((a, b) => {
      const aIndex = orderOfTypes.indexOf(a.attributes[0]);
      const bIndex = orderOfTypes.indexOf(b.attributes[0]);
      if (aIndex > bIndex) return -1;
      if (aIndex < bIndex) return 1;
      return 0;
    });
  }
  __attributeChanged() {
    if (this.parents && this.parents[0]) this.parents[0].onAttributeChanged();
    this.emitStateUpdate();
  }
  __updateAttribute(_attribute, types, defaultType) {
    this.attributes = this.attributes.filter((_attr) => !types.includes(_attr));
    if (_attribute !== defaultType) this.addAttribute(_attribute);
    else this.__attributeChanged();
  }
  __recursivelyGetAllNodesByType(node, type) {
    let nodes = [];
    node.children.forEach((_child) => {
      if (_child.isType(type)) {
        nodes.push(_child);
      }
      const childResults = this.__recursivelyGetAllNodesByType(_child, type);
      nodes = [...nodes, ...childResults];
    });
    return nodes;
  }
  __recursivelyGetAllNodesByTypes(node, types) {
    let nodes = [];
    node.children.forEach((_child) => {
      if (_child.isOneOfTypes(types)) {
        nodes.push(_child);
      }
      const childResults = this.__recursivelyGetAllNodesByTypes(_child, types);
      nodes = [...nodes, ...childResults];
    });
    return nodes;
  }
  __defineNodeAttribute = (attribute, options) => {
    const attributeTypes = options.map((o) => o.value);
    const defaultType = options.find((o) => o.default).value;
    Object.defineProperty(this, `${attribute}Option`, {
      get() {
        return this.attributes.find((_attr) => attributeTypes.includes(_attr)) || defaultType;
      },
      set(_attribute) {
        this.attributes = this.attributes.filter((_attr) => !attributeTypes.includes(_attr));
        if (_attribute !== defaultType) this.addAttribute(_attribute);
        else this.__attributeChanged();
      }
    });
    Object.defineProperty(this, `${attribute}Options`, {
      get() {
        return options;
      },
    });
  };
  __defineNodeProperty = (property, prefix) => {
    Object.defineProperty(this, property, {
      get() {
        return (this.attributes.find((a) => a.includes(prefix)) || '').split('=')[1];
      },
      set(_value) {
        this.attributes = this.attributes.filter((_attr) => !_attr.includes(prefix));
        if (!_value) return this.__attributeChanged();
        return this.addAttribute(`${prefix}${_value}`);
      }
    });
  };
}
