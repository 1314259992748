import Node from './node';
import { SEARCH_TEXT } from './nodetypes';
import { WYSIWYG_ATTR, TEXT_ATTR } from './nodeattributes';

export default class Search extends Node {
  constructor(_text, _attributes, _children, _owner) {
    super(_text, _attributes, _children, _owner);
    this.__defineNodeAttribute('inputType', [{ name: 'Wysiwyg', value: WYSIWYG_ATTR, default: true }, { name: 'Text Box', value: TEXT_ATTR }]);
  }
  get searchText() {
    return this.children.find((c) => c.isType(SEARCH_TEXT));
  }
  get showSingleSearchBar() {
    return this.attributes.includes(TEXT_ATTR);
  }
  clone() {
    return new Search(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
}
