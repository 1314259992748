/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
import findAllHandlebars from '../utils/parsevariables';
import { TEXT, TEXT_LOGIC, DATA, DISPLAY_TEXT } from './nodetypes';
import Logic from './logic';
import { __recursivelyGetAllQuestionableNodes } from './findnode';

export default class TextLogic extends Logic {
  clone() {
    return new TextLogic(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
  __update() {
    const textNodes = this.__recursivelyGetAllTextNodes(this);
    const rootNodes = __recursivelyGetAllQuestionableNodes(this);

    textNodes.forEach((textNode) => {
      // Find all the handlebar matches
      const matchedVariables = findAllHandlebars(textNode.text);

      // Create all the derived rows
      const matchedNodes = matchedVariables.map((v) => rootNodes.filter((d) => !d.parents.includes(textNode) && d.text.trim() === unescape(v.trim())));

      // Replace the matched variables
      matchedVariables.forEach((_var, j) => {
        const matchedNodeNodes = matchedNodes[j];
        let variableText = '';
        matchedNodeNodes.forEach((matchedNodeNode) => {
          if (matchedNodeNode.answersChecked) {
            const { answersChecked } = matchedNodeNode;
            if (answersChecked && answersChecked.length > 0) {
              variableText = answersChecked.map((a) => a.formattedProjectText).join(' | ');
            }
          } else if (matchedNodeNode.calculatedValueFormatted) {
            variableText = matchedNodeNode.calculatedValueFormatted;
          }
        });
        textNode.updateTextVariable(_var, variableText);
      });
    });
  }
  __recursivelyGetAllTextNodes(node) {
    let nodes = [];
    node.children.forEach((_child) => {
      if (_child.isType(TEXT_LOGIC)) return;
      if (_child.isOneOfTypes([DATA, DISPLAY_TEXT, TEXT])) {
        nodes.push(_child);
      }
      const childResults = this.__recursivelyGetAllTextNodes(_child);
      nodes = [...nodes, ...childResults];
    });
    return nodes;
  }
}
