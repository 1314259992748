import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Constants from '../../submodules/logictry_config/constants';

const Wrapper = styled.div`
  margin: 10px 0px;
  width: 90%;

  input {
    color: ${Constants.DarkTextColor};
    padding: 4px 0px;
    border-radius: 0px;
    ${Constants.InputBorderBottom}
  }

  display: flex;
  flex-direction: column;

`;

class StatefulTextField extends React.PureComponent {
  state = {
    loaded: false,
  }
  onChange = (e) => {
    if (this.props.maxLength && e.target.value.length > this.props.maxLength) return this.props.onChange(this.props.value);
    let newValue = e.target.value;
    if (this.props.dontAllowSpaces) newValue = newValue.replaceAll(' ', '');
    return this.props.onChange(newValue);
  }
  render() {
    return (
      <Wrapper
        style={{ width: this.props.width || 300, maxWidth: '80vw', opacity: this.props.disabled ? 0.2 : 1, height: (this.props.hideUnderline ? 30 : 'unset'), overflow: 'hidden' }}
      >
        {this.props.multiline ? (
          <textarea
            ref={(e) => { if (this.props.autoFocusOnLoad && e && !this.state.loaded) { this.state.loaded = true; e.focus(); } }}
            value={this.props.value || ''}
            onFocus={this.props.onFocus}
            onChange={this.onChange}
            onBlur={this.props.onComplete}
            // eslint-disable-next-line
            onKeyDown={this.props.onKeyDown}
            label={this.props.label}
            placeholder={this.props.placeholder || this.props.label}
            type={this.props.type || 'text'}
            name={this.props.name}
            style={{ width: '100%' }}
            disabled={this.props.disabled}
            rows={this.props.rows}
            autoComplete={this.props.autoComplete && "on" || "off"}
            autoCapitalize="off"
            autoCorrect="off"
          />
        ) : (
          <input
            ref={(e) => { if (this.props.autoFocusOnLoad && e && !this.state.loaded) { this.state.loaded = true; e.focus(); } }}
            value={this.props.value || ''}
            onFocus={this.props.onFocus}
            onChange={this.onChange}
            onBlur={this.props.onComplete}
            // eslint-disable-next-line
            onKeyDown={this.props.onKeyDown}
            label={this.props.label}
            placeholder={this.props.placeholder || this.props.label}
            type={this.props.type || 'text'}
            name={this.props.name}
            style={{ width: '100%', textAlign: this.props.centerText ? 'center' : null }}
            disabled={this.props.disabled}
            rows={this.props.rows}
            autoComplete={this.props.autoComplete && "on" || "off"}
            autoCapitalize="off"
            autoCorrect="off"
          />
        )}
        <div style={{ marginTop: 5, textAlign: 'center', color: 'red' }}>{this.props.warning ? this.props.warning : null }</div>
      </Wrapper>
    );
  }
}


StatefulTextField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  width: PropTypes.any,
  onKeyDown: PropTypes.func,
  warning: PropTypes.string,
  hideUnderline: PropTypes.bool,
  onFocus: PropTypes.func,
  centerText: PropTypes.bool,
  autoComplete: PropTypes.bool,
  autoFocusOnLoad: PropTypes.bool,
  dontAllowSpaces: PropTypes.bool,
};

export default StatefulTextField;
