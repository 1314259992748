/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SparkMD5 from 'spark-md5';
import Constants from '../../submodules/logictry_config/constants';
import AssetApi from '../../services/server/AssetApi';
import UserAccount from '../../services/UserAccount';
import Company from '../../services/Company';
import Popup from '../Popup';
import ProgressBar from '../ProgressBar';
import ConfirmDialog from '../ConfirmDialog/index';
import AppConstants from '../../submodules/logictry_config/s3_config.json';


const Wrapper = styled.div`
  width: 28px;
  position: relative;
  cursor: pointer;
  input {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;
    cursor: pointer;
  }
  i {
    cursor: pointer;
  }
`;
const PopupContent = styled.div`
  padding: 2rem;

`;


export default class UploadFolder extends React.PureComponent {
  static propTypes = {
    onComplete: PropTypes.func,
  }
  state = {
    totalUploading: 0,
    totalComplete: 0,
    error: '',
  }
  // updateProgress = () => {
  //   if (evt.lengthComputable) {
  //     const percentComplete = (evt.loaded / evt.total) * 100;  
  //   }
  // }
  onChange = async (e) => {
    if (!e.target || !e.target.files || !e.target.files.length) return this.setState({ error: 'You must have at least one file' });
    let path;
    let signingUrl;
    if (Company.company && Company.company._id) {
      path = `${Company.company._id}/${UserAccount.account._id}/`;
      signingUrl = `${AppConstants.S3.signingUrls.companyfiles}`;
    } else {
      path = `${UserAccount.account._id}/`;
      signingUrl = `${AppConstants.S3.signingUrls.userfiles}`;
    }
    const fileArray = Array.from(e.target.files);
    let totalSize = 0;
    let minDirectorySize = Infinity;
    fileArray.forEach((file) => {
      totalSize += file.size;
      minDirectorySize = Math.min(minDirectorySize, file.webkitRelativePath.split('/').length);
    });

    // Ensure 10 MB Limit
    if (totalSize > 20000000) return this.setState({ error: 'There is a 20 MB limit' });

    // Find the index.html at the root folder
    const indexFound = fileArray.find(({ webkitRelativePath }) => {
      return webkitRelativePath.split('/').slice(minDirectorySize - 1).join('/') === 'index.html';
    });
    if (!indexFound) return this.setState({ error: 'You must have one index.html file to upload' });

    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    const computeFileHash = async (i) => {
      let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice,
        file = fileArray[i],
        chunkSize = 2097152,
        chunks = Math.ceil(file.size / chunkSize),
        currentChunk = 0;
      fileReader.onload = async (e) => {
        spark.append(e.target.result);
        currentChunk++;
        if (currentChunk < chunks) {
          loadNext();
        } else {
          i += 1;
          if (fileArray[i]) computeFileHash(i);
          else this.uploadFile(path, fileArray, signingUrl, minDirectorySize, spark.end());
        }
      };
      fileReader.onerror = () => {
        this.setState({ error: 'Upload Failed', errorDescription: 'Something went wrong, please try again.', overlayText: this.UploadText, imagePreview: null });
      };
      function loadNext() {
        var start = currentChunk * chunkSize,
            end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
      }
      loadNext();
    }
    computeFileHash(0);
  }
  uploadFile = async (path, fileArray, signingUrl, minDirectorySize, hash) => {
    // Get SigningUrls
    const results = await AssetApi.getFolderSignedUrls(signingUrl, {
      files: fileArray.map(({ webkitRelativePath, type }) => ({
        path: `${path}${hash}/`,
        objectName: webkitRelativePath.split('/').slice(minDirectorySize - 1).join('/'),
        contentType: type,
      }))
    });
    if (!results || results.error) return this.setState({ error: 'Unable to upload at this time' });

    // Upload
    this.setState({ error: '', totalUploading: results.length, totalComplete: 0 });
    return results.forEach(({ file, sign }) => {
      const request = new XMLHttpRequest();
      request.onreadystatechange = (evt) => {
        if (evt.target.readyState === 4) {
          const { totalComplete, totalUploading } = this.state;
          const newTotalComplete = totalComplete + 1;
          const isComplete = totalUploading === newTotalComplete
          if (isComplete) {
            const indexFileFound = results.find(({ file: resultFile }) => resultFile.objectName === 'index.html');
            this.props.onComplete(indexFileFound.sign.signedUrl.split('?')[0]);
          } else {
            this.setState({ totalComplete: newTotalComplete });
          }
        }
      };
      const matchedFile = fileArray.find(({ webkitRelativePath }) => webkitRelativePath.split('/').slice(minDirectorySize - 1).join('/') === file.objectName);
      if (!matchedFile) return;
      request.open('PUT', sign.signedUrl);
      request.setRequestHeader('Content-Disposition', 'inline');
      request.setRequestHeader('Content-Type', matchedFile.type);
      request.send(matchedFile);
    });
  }

  render() {
    const { totalUploading, totalComplete, error } = this.state;
    const progress = totalComplete / totalUploading * 100;
    return (
      <Wrapper>
        <i className="fas fa-cloud-upload-alt"></i>
        <input
          type="file"
          webkitdirectory="true"
          directory="true"
          multiple
          onChange={this.onChange}
        />
        {totalUploading !== totalComplete && <Popup>
          <PopupContent>
            <h1>Upload Progress</h1>
            <ProgressBar progress={progress} primaryColor={Constants.PrimaryColor} />
          </PopupContent>
        </Popup>}
        {error && <ConfirmDialog
          open
          text="Upload Failed"
          description={error}
          onOk={() => this.setState({ error: false })}
        />}
      </Wrapper>
    );
  }
}
