import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    color: ${Constants.White};
  }
`;

export default function DisabledFree() {
  return (
    <Wrapper>
      <h1>To access this feature, you must upgrade your account.</h1>
    </Wrapper>
  );
}