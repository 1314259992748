import Node from './node';
import { DATA, DATA_SCHEMA } from './nodetypes';
import { XML_ATTR, POST_ATTR, AUTO_RUN_ATTR, LOGICTRY_SPREADSHEET_ATTR } from './nodeattributes';

export default class Action extends Node {
  updateData = null;
  clone() {
    return new Action(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
  get autoRun() {
    return this.attributes.includes(AUTO_RUN_ATTR);
  }
  get steps() {
    const steps = [];
    this.children.forEach((actionStep) => {
      const data = actionStep.children.find((c) => c.isType(DATA));
      if (!data || !data.isWritable) return;
      const { isRestfulApi } = data;
      if (isRestfulApi) {
        const url = data.text;
        const body = data.postSchema;
        const dataSchema = data.children.find((c) => c.isType(DATA_SCHEMA));
        if (!url || !body || !dataSchema || !dataSchema.isXML) return;
        steps.push({ type: POST_ATTR, url, body, format: dataSchema.isXML ? XML_ATTR : null, delimiter: data.delimiterString });
      } else {
        const body = data.postSchema;
        steps.push({ type: LOGICTRY_SPREADSHEET_ATTR, _id: data.text, body, delimiter: data.delimiterString });
      }
    });
    return steps;
  }
}
