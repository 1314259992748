/* eslint-disable react/no-danger */
/* eslint-disable no-useless-escape */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import Note from '../../models/note';
import RenderedHtmlToTextArea from '../../components/RenderedHtmlToTextArea/index';
import RawHtml from '../../components/RawHtml/index';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  > *:first-child {
    flex: 1;
    margin-left: ${Constants.TreeContentIconIndent};
    margin-top: ${Constants.defaultLinePadding};
    margin-bottom: ${Constants.defaultLinePadding};
  }
`;

export default class TextBox extends React.PureComponent {
  static propTypes = {
    text: PropTypes.object,
  };
  componentDidMount() {
    const { text } = this.props;
    text.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { text } = this.props;
    text.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
  }
  checkFocus = () => {
    const { text } = this.props;
    if (this.inputRef && GrayOut.isActive(text)) {
      this.inputRef.focus({ preventScroll: true });
    }
  }
  render() {
    const { text } = this.props;
    const isNote = text instanceof Note;
    return (
      <Wrapper>
        {(isNote) ? (
          <RenderedHtmlToTextArea node={text} placeholderText={text.attributes[0]} />
        ) : (
          <RawHtml innerRef={(e) => { this.inputRef = e; this.checkFocus(); }} html={text.text} />
        )}
      </Wrapper>
    );
  }
}

