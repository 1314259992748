import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import WindowSize from '../../services/WindowSize';
import Button from '../Button/index';
import Constants from '../../submodules/logictry_config/constants';
import Popup from '../Popup/index';

const DialogActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 10px;
`;
const Text = styled.div`
  padding: 1rem;
  h2, p {
    text-align: center;
  }
  h2 {
    margin-bottom: 2rem;
  }
`;

export default class ConfirmDialog extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onNo: PropTypes.func,
    onYes: PropTypes.func,
    onOk: PropTypes.func,
    text: PropTypes.string,
    description: PropTypes.string,
    allowBackgroundClose: PropTypes.bool,
  }
  componentDidMount() {
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
  }

  render() {
    const { open, onNo, onYes, onOk, text, description, allowBackgroundClose } = this.props;
    const minWidth = 200;
    const maxWidth = WindowSize.mobile ? 'calc(100vw - 68px)' : 400;
    if (!open) return null;
    return (
      <Popup
        showCloseButton={allowBackgroundClose}
        onClose={() => {
          if (!allowBackgroundClose) return;
          if (onNo) onNo();
          if (onOk) onOk();
        }}
        allowBackgroundClose={allowBackgroundClose}
      >
        <div style={{ display: 'grid', alignItems: 'center', justifyContent: 'center', transition: '0.5s', minWidth, maxWidth, padding: '1rem' }}>
          <div>
            <Text>
              <h2>{text}</h2>
              {description && <p>{description}</p>}
            </Text>
            <DialogActions>
              {onNo && <Button style={{ fontWeight: '200', color: Constants.CancelTextColor }} onClick={onNo}>
                No
              </Button>}
              {onYes && <Button style={{ fontWeight: 'bold', color: Constants.ConfirmTextColor }} onClick={onYes} color="primary">
                Yes
              </Button>}
              {onOk && <Button style={{ fontWeight: 'bold', color: Constants.ConfirmTextColor }} onClick={onOk} color="primary">
                OK
              </Button>}
            </DialogActions>
          </div>
        </div>
      </Popup>
    );
  }
}
