/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-unresolved
import ObjectCache from './CacheService';
import UserAccount from '../UserAccount';
import DatabaseCollectionDocumentApi from '../server/DatabaseCollectionDocumentApi';
import DatabaseCollectionDocument from '../../models/databasecollectiondocument';
// eslint-disable-next-line import/no-cycle

class DatabaseCollectionDocumentCache extends ObjectCache {
  api = DatabaseCollectionDocumentApi;
  createNew = () => new DatabaseCollectionDocument({});
  createObject = (_results) => {
    const results = JSON.parse(JSON.stringify(_results));
    return new DatabaseCollectionDocument(results);
  }
  revertObject = (_object) => {
    const results = this.objectJson[_object._id];
    this.objects[_object._id] = this.createObject(results);
    this.emitStateUpdate();
  };
  getObjectJson = (_model) => _model.getJson();
}

const singleton = new DatabaseCollectionDocumentCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
