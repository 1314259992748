import Node from './node';
import { CONTENT_HEIGHT_ATTR, NONE_ATTR, COLLAPSIBLE_ATTR } from './nodeattributes';

const sizingOptions = [{ name: 'Normal Size', value: NONE_ATTR }, { name: 'Content Size', value: CONTENT_HEIGHT_ATTR }];
const resizingOptions = [{ name: 'Not Collapsible', value: NONE_ATTR }, { name: 'Collapsible', value: COLLAPSIBLE_ATTR }];

export default class Pane extends Node {
  get contentHeight() {
    return this.attributes.includes(CONTENT_HEIGHT_ATTR);
  }
  get collapsible() {
    return this.attributes.includes(COLLAPSIBLE_ATTR);
  }
  get resizingOptions() {
    return resizingOptions;
  }
  updateResizingOption(_attribute) {
    this.attributes = this.attributes.filter((_attr) => ![NONE_ATTR, COLLAPSIBLE_ATTR].includes(_attr));
    if (_attribute !== NONE_ATTR) this.addAttribute(_attribute);
    else this.__attributeChanged();
  }
  get sizingOptions() {
    return sizingOptions;
  }
  updateSizingOption(_attribute) {
    this.attributes = this.attributes.filter((_attr) => ![NONE_ATTR, CONTENT_HEIGHT_ATTR].includes(_attr));
    if (_attribute !== NONE_ATTR) this.addAttribute(_attribute);
    else this.__attributeChanged();
  }
  clone() {
    return new Pane(this.text, this.attributes, this.children.map((c) => c.clone()));
  }
}
