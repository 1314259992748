/* eslint-disable no-else-return */
// import React from 'react';
// import styled from 'styled-components';
// import { PropTypes } from 'prop-types';
import Service from './Service';
import UserAccount from './UserAccount';
import GrayOut from './TreeDisplay';
import { TOP_LEVEL_NODES, PROJECT_ONLY_TYPES, BRANCHES, BRANCH, NODE_CHILDREN_TYPES, ADVANCED_ACCOUNT_TYPES, DEV_ACCOUNT_TYPES, NOTE, REPORT, EMAIL } from '../models/nodetypes';
import Tree from '../models/tree';

class TreeCreateNode extends Service {
  createLast() {
    const { activeObject, activeObjectParents, tree } = GrayOut;
    if (!activeObject || !activeObjectParents) return;
    const index = activeObjectParents.slice(-1)[0].children.indexOf(activeObject) + 1;
    const newChild = tree.createType(activeObject.attributes[0], activeObjectParents.slice(-1)[0], index, activeObject.attributes, null, true);
    if (!newChild) return;
    GrayOut.addActiveObject(newChild, true);
  }
  createNode(type, node, parents, index, attributes, text) {
    const options = this.getCreateOptions(node);
    if (!options.includes(type)) return;
    const { tree } = GrayOut;
    // eslint-disable-next-line no-param-reassign
    if (node.isType(BRANCH)) node = tree.branches.findExistingBranch(node.text);
    const newChild = tree.createType(type, node, index, attributes, text, true);
    GrayOut.addActiveObject(newChild, true);
  }
  duplicateNode(node) {
    const parent = node.parent;
    const index = parent.children.indexOf(node) + 1;
    const newChild = this.__duplicateNode(node, parent, index);
    if (!newChild) return;
    GrayOut.addActiveObject(newChild, true);
  }
  __duplicateNode(node, newParent, index) {
    const { tree } = GrayOut;
    const newChild = tree.createType(node.attributes[0], newParent, index, node.attributes, node.text, true, true);
    node.children.forEach((child) => this.__duplicateNode(child, newChild));
    return newChild;
  }
  createNote() {
    const { activeObject, activeObjectParents } = GrayOut;
    this.createNode(NOTE, activeObject, activeObjectParents, 0);
  }
  isMoveToBranchAllowed(node) {
    if (node.isOneOfTypes([...TOP_LEVEL_NODES, BRANCH])) return false;
    if ((NODE_CHILDREN_TYPES[BRANCH] || []).includes(node.attributes[0])) return true;
    return false;
  }
  isDuplicateAllowed(node) {
    if (node.isOneOfTypes(TOP_LEVEL_NODES)) return false;
    const options = this.getCreateOptions(node.parent);
    if (!options.includes(node.type)) return false;
    return true;
  }
  isDeleteAllowed(node) {
    if (node.isOneOfTypes(TOP_LEVEL_NODES)) return false;
    return true;
  }
  createBranch(node) {
    const { tree } = GrayOut;
    if (!this.isMoveToBranchAllowed(node)) return;
    let branchText = `Branch ${node.text}`;
    let found = true;
    let i = 0;
    while (found) {
      i += 1;
      // eslint-disable-next-line no-loop-func
      found = tree.branches.children.find((b) => b.text === branchText);
      if (found) branchText = `Branch ${node.text} (${i})`;
    }
    this.initializeMove(node);
    const parent = node.parent;
    const currentIndex = parent.children.indexOf(node);
    const newBranchUnderBranches = tree.createType(BRANCH, tree.branches, null, null, branchText, true);
    tree.createType(BRANCH, parent, currentIndex, null, branchText, true);
    this.finishMove(newBranchUnderBranches, newBranchUnderBranches.parents);
  }
  getCreateOptions(node) {
    const { tree } = GrayOut;
    const { editing } = tree;
    if (!node || !node.attributes || !node.parents) return [];
    if (node === tree) return [];
    const type = node.attributes[0];
    if (!type) return [];

    const parent = node.parent;
    if (node.isType(BRANCH) && !parent.isType(BRANCHES)) return [];
    if (parent.isType(REPORT)) return [];
    if (parent.isType(EMAIL)) return [];
    const createOptions = [...new Set(NODE_CHILDREN_TYPES[type] || [])].sort((a, b) => {
      if (a < b) return -1;
      if (b < a) return 1;
      return 0;
    });;
    const { isDevAccount } = UserAccount;
    return createOptions.filter((option) => {
      if (editing && PROJECT_ONLY_TYPES.includes(option)) return false;
      if (isDevAccount) {
        if (!DEV_ACCOUNT_TYPES.includes(option)) return false;
      } else {
        if (!ADVANCED_ACCOUNT_TYPES.includes(option)) return false;
      }

      // Handle special types
      if (!node.canAddChild(option, true)) return false;
      return true;
    });
  }
  isNodeAllowedToMove(_node) {
    if (!_node) return false;
    if (_node.isOneOfTypes(TOP_LEVEL_NODES)) return false;
    return true;
  }
  isNodeMoveAllowed(_node, _newParent, _newParentParents) {
    if (!_newParent || !_node) return false;
    const { tree } = GrayOut;
    const isBranchUnderBranches = tree.branches.children.find((b) => b === _node);
    if (isBranchUnderBranches) {
      if (_newParent.isType(BRANCHES)) return true;
      else return false;
    }

    // Cant move a node to under Branches or Tree
    if (_newParent && (_newParent.isType(BRANCHES) || _newParent instanceof Tree)) return false;
    if (_newParent && _newParent.isType(BRANCH) && _newParentParents.length !== 2) return false;

    // // Cant move a node under itself or its current parent chain
    if (_newParent && _newParent === _node) return false;
    if (_newParentParents && _newParentParents.find((_parent) => _parent === _node)) return false;

    // Check allowed children types
    if (!NODE_CHILDREN_TYPES[_newParent.attributes[0]]) return false;
    const allowMove = NODE_CHILDREN_TYPES[_newParent.attributes[0]].includes(_node.attributes[0]);
    return allowMove;
  }
  initializeMove(_node) {
    if (this.nodeToMove) this.cancelMove();
    if (!_node) return;
    if (!this.isNodeAllowedToMove(_node)) return;
    this.nodeToMove = _node;
    GrayOut.onStateUpdate(this.onActiveObjectUpdate);
    this.emitStateUpdate();
  }
  isMoveAllowed(_newParent, _newParentParents) {
    return this.isNodeMoveAllowed(this.nodeToMove, _newParent, _newParentParents);
  }
  cancelMove() {
    this.nodeToMove = null;
    GrayOut.offStateUpdate(this.onActiveObjectUpdate);
    this.emitStateUpdate();
  }
  onActiveObjectUpdate = () => {
    const { activeObject, activeObjectParents } = GrayOut;
    this.finishMove(activeObject, activeObjectParents);
  }


  finishMove(_newParent, _newParentParents, _newIndex) {
    const __nodeToMove = this.nodeToMove;
    this.cancelMove();
    const { tree } = GrayOut;

    // Check allowed to move
    if (!this.isNodeMoveAllowed(__nodeToMove, _newParent, _newParentParents)) return;

    // Delete from current parent and add to new parent
    const oldParent = __nodeToMove.parents[__nodeToMove.parents.length - 1];
    // eslint-disable-next-line no-param-reassign
    if (_newParent === oldParent && oldParent.children.indexOf(__nodeToMove) < _newIndex) _newIndex -= 1;
    oldParent.deleteChild(__nodeToMove);    

    if (oldParent.children.includes(__nodeToMove)) return;
    let node = _newParent;
    if (node.isType(BRANCH)) node = tree.branches.findExistingBranch(node.text);
    node.addChild(__nodeToMove, _newIndex);
    __nodeToMove.parents.forEach((parent) => parent.setExpanded(true));
    tree.onNodeMoved();
  }
}

const singleton = new TreeCreateNode();
export default singleton;
