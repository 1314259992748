import Model from './model';

export default class LogicBaseCollectionSubscription extends Model {
  constructor(_id, follower, collection) {
    super();
    this._id = _id;
    this.follower = follower;
    this.collection = collection;
  }
  getJson = () => {
    const { _id, follower, collection } = this;
    return {
      _id,
      fr: follower,
      c: collection,
    }
  }
}
