import Node from './node';
import ObjectRef from './objectref';

export default class Subtree extends Node {
  get objectRef() {
    return this.children.find((_child) => _child instanceof ObjectRef);
  }
  updateTree(_tree) {
    this.tree = _tree;
    this.emitStateUpdate();
  }
  updateText(_text) {
    this.tree = null;
    super.updateText(_text);
  }
  clone() {
    return new Subtree(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
}
