import React from 'react';
import styled from 'styled-components';
import Navigation from '../../services/Navigation';
import Popup from '../../components/Popup/index';

const NotAuthorizedWrapper = styled.div`
  box-shadow: rgb(0 0 0 / 70%) 0px 0px 4px 0px;
  max-width: 480px;
  width: calc(100vw - 2rem);
  margin: auto;
  padding: 3rem 2rem;
  border-radius: 0.5rem;
  span {
    font-weight: 600;
  }
  img {
    display: block;
    margin: auto;
    width: 12rem;
  }
  h1 {
    text-align: center;
    margin: 2rem 0 1.5rem;
  }
  a {
    text-decoration: underline;
    // font-weight: 600;
  }
  li {
    margin-top: 0.75rem;
  }
  > p:last-child {
    margin-top: 2rem;
    text-align: center;
  }
`;
const LogoutButton = styled.button`
  text-decoration: underline;
  margin: 0;
  padding: 0;
  font-weight: 600;
`;
const FAQ = styled.div`
  margin-top: 2rem;
  h3 {
    margin-top: 2rem;
    font-weight: 500;
  }
`;

let userIsNotAuthorized = Navigation.currentSearch.user_not_authorized;
export default class UserNotAuthorizedPopup extends React.PureComponent {
  render() {
    if (!userIsNotAuthorized) return null;
    setTimeout(() => {
      Navigation.push('/');
    }, 250);
    const companyName = window.logictry && window.logictry.company && (window.logictry.company.longname || window.logictry.company.shortname) || 'a Company';
    return <Popup showCloseButton allowBackgroundClose onClose={() => { userIsNotAuthorized = false; this.forceUpdate(); }}>
      <NotAuthorizedWrapper>
        <img alt="Logictry" src="https://logictrystatic.s3.amazonaws.com/images/logo062623.png" />
        <h1>User Not Authorized</h1>
        <p>You have attempted to login to <span>{companyName}'s</span> corporate account ({window.location.origin}) but your access was denied.</p>
        <p>You have a few options:</p>
        <ul>
          <li>If you are a licensed user and believe this was in error, please reach out to your company's admin.</li>
          <li>Go to your <a href="https://logictry.com">FREE personal account</a>.</li>
          <li><u>Close this window</u> to stay on {window.location.origin}.</li>
        </ul>
        {/* <FAQ>
          <details>
            <summary>Frequently Asked Questions:</summary>
            <h3>What is the difference between a personal and corporate account?</h3>
            <p>Your personal account is FREE and allows you to create intake forms, FAQs, decision trees, and more.</p>
            <p>Corporate accounts include:</p>
            <ul>
              <li>Pro content creation</li>
              <li>Private subdomain</li>
              <li>Team collaboration</li>
              <li>Website embedding</li>
              <li>Website customization</li>
              <li>White labelling</li>
              <li>Premiere support</li>
              <li>and more</li>
            </ul>
            <h3>How can I login to my FREE personal account?</h3>
            <p>If you already have an account, you simply navigate to <a href="https://logictry.com/login">https://logictry.com/login</a> to login.</p>
          </details>
        </FAQ> */}
      </NotAuthorizedWrapper>
    </Popup>;
  }
}