// This module properly logs and reports errors
import Server from './server/index';
import LocalStorage from './LocalStorage';
import UserAccount from './UserAccount';
import Constants from '../submodules/logictry_config/constants';
import Version from '../version.json';
const errors = new LocalStorage('logictry.errors');

const SuppressErrors = [`Failed to execute 'transaction' on 'IDBDatabase'`, 'Object Not Found Matching Id:', 'Cannot redefine property: googletag'];
const SuppressUserAgents = ['http://www.google.com/bot.html', 'HeadlessChrome'];
const SuppressStacks = ['chrome-extension://', 'webkit-masked-url://', 'moz-extension://', 'https://accounts.google.com'];

const ONE_HOUR_TIMEOUT = 60 * 60;

// Remove old errors
Object.keys(errors.state).forEach((key) => {
  if (typeof errors.state[key] !== 'object') return delete errors.state[key];

  const { lastUpdateTime, fatal } = errors.state[key];
  if (!lastUpdateTime) return null;

  const lastUpdate = parseInt(lastUpdateTime, 10);
  const delta = (Date.now() - lastUpdate) / 1000;

  // If after 1 hour, clear error
  if (delta > ONE_HOUR_TIMEOUT) delete errors.state[key];

  // If fatal and within 10 sec, assume was forcedReload
  if (fatal && delta < 10) errors.state[key].count += 1;
  return null;
});
errors.saveState();

// Handle errors or unhandledrejections
window.addEventListener('unhandledrejection', handleError);
window.addEventListener('error', handleError);

let _checkingReload = false;

// Handle errors and rejections
function handleError(e) {
  if (!e) return;
  const error = e.error || e.reason;
  if (!error) return;
  const message = error.message || JSON.stringify(error);
  const stack = error.stack;

  // Wrapping in timeout to allow for a repaint which may result in blank screen
  setTimeout(() => {
    const isFatal = document.getElementById('root').children.length === 0;
    reportError(message, stack, isFatal);
  });
}
export function reportError(message, stack, isFatal) {
  if (!isFatal) {
    if (message && SuppressErrors.find((error) => message.includes(error))) return;
    if (navigator.userAgent && SuppressUserAgents.find((agent) => navigator.userAgent.includes(agent))) return;
    if (stack && SuppressStacks.find((s) => stack.includes(s))) return;
  }

  const errorKey = stack && stack.split('\n', 2).join('') || message;

  // Initialize
  errors.state[errorKey] = errors.state[errorKey] || { startTime: Date.now(), count: 0 };
  const { count, lastUpdateTime } = errors.state[errorKey];

  // Save error timestamp to localstorage
  errors.state[errorKey].fatal = errors.state[errorKey].fatal || isFatal;
  errors.saveState();

  const checkReload = () => {
    _checkingReload = false;
    if (isFatal && count < 3) window.location = window.location.origin;
  };
  
  // Dont report same error except once per 15min
  if (lastUpdateTime) {
    const lastUpdate = parseInt(lastUpdateTime, 10);
    const delta = (Date.now() - lastUpdate) / 1000;
    if (delta < ONE_HOUR_TIMEOUT) {
      if (!_checkingReload) {
        _checkingReload = true;
        setTimeout(checkReload);
      }
      return;
    }
  }

  errors.state[errorKey].lastUpdateTime = Date.now();
  errors.saveState();

  // Get UserAccount info
  let account;
  if (UserAccount.isLoggedIn) {
    account = `_id: ${UserAccount.account._id}`;
    if (UserAccount.account.fullname) account = `${account}\r\nfullname: ${UserAccount.account.fullname}`;
    if (UserAccount.account.username) account = `${account}\r\nusername: ${UserAccount.account.username}`;
  } else {
    account = 'User not logged in';
  }

  // Email Log
  const errorLog = `${account}

Fatal=${isFatal}

${window.location.href}
v${Version.version}
${navigator.userAgent}
  
${message}

${stack}`;
  _checkingReload = true;

  if (Constants.isDevelopment) return window.alert(errorLog);
  Server.postError(errorLog).then(checkReload);
}
