/* eslint-disable no-param-reassign */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import Company from '../../services/Company';
import Popup from '../Popup/index';
import Button from '../Button/index';
import ClickableIcon from '../ClickableIcon/index';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 90vw;
  > div:nth-child(2) {
    border-top: 1px solid ${Constants.DarkGray};
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    > * {
      margin: 20px;
    }
    > div:first-child {
      display: flex;
      input {
        margin-right: 8px;
      }
    }
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const WebsiteInput = styled.div`
  > div:first-child {
    display: flex;
    > div {
      line-height: 40px;
    }
    input:nth-child(1) {
      width: 140px;
    }
    input:nth-child(2) {
      width: 300px;
    }
    * {
      margin: 10px;
    }
  }
  > div:last-child {
    margin-left: 20px;
  }
`;

export default class CompanyWebsitePopup extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
  }
  componentDidMount() {
    Company.onStateUpdate(this);
  }
  componentWillUnmount() {
    Company.offStateUpdate(this);
  }
  changePath = (website, prev, value) => {
    website[value] = website[prev];
    delete website[prev];
  }
  close = () => {
    const { company } = Company;
    const { onClose } = this.props;
    Company.revertObject(company);
    onClose();
  }
  saveAndClose = () => {
    const { company } = Company;
    const { onClose } = this.props;
    Company.update(company);
    onClose();
  }
  addChild = (website) => {
    if (website['']) return;
    website[''] = {
      _id: '',
    };
    this.forceUpdate();
  }
  deleteChild = (website, prev) => {
    delete website[prev];
    this.forceUpdate();
  }
  changeProperty = (website, value) => {
    website._id = value;
  }
  createWebsiteInputs = (level, parentWebsite, hideDelete) => {
    const website = parentWebsite[level];
    return (
      <WebsiteInput key={level || 'new'}>
        <div>
          <input disabled={hideDelete} tabIndex={0} onBlur={(e) => this.changePath(parentWebsite, level, e.target.value)} placeholder="Path" defaultValue={level || ''} />
          <input tabIndex={0} onChange={(e) => this.changeProperty(website, e.target.value)} placeholder="App ID" defaultValue={website._id || ''} />
          <ClickableIcon onClick={() => this.addChild(website)} icon="fas fa-plus" />
          {!hideDelete && <ClickableIcon onClick={() => this.deleteChild(parentWebsite, level)} icon="fas fa-trash" />}
        </div>
        <div>
          {Object.keys(website).filter((key) => key !== '_id').map((key) => this.createWebsiteInputs(key, website))}
        </div>
      </WebsiteInput>
    )
  }
  render() {
    const { company } = Company;
    company.website = company.website || {};
    return (
      <Popup
        onClose={this.close}
        allowBackgroundClose
      >
        <Wrapper>
          <h1>Company Website</h1>
          <div key={JSON.stringify(company.website)}>
            <input style={{ width: 'calc(100% - 60px)' }} tabIndex={0} onBlur={(e) => { try { company.websiteSettings = JSON.parse(e.target.value); } catch(_) { company.websiteSettings = null; } }} placeholder="Website Settings" defaultValue={company.websiteSettings && JSON.stringify(company.websiteSettings) || ''} />
            {this.createWebsiteInputs('root', { root: company.website }, true)}
          </div>
          <ButtonsWrapper>
            <Button onClick={this.close} color="primary" autoFocus>
              Cancel
            </Button>
            <Button onClick={this.saveAndClose} color="primary" autoFocus>
              Save
            </Button>
          </ButtonsWrapper>
        </Wrapper>
      </Popup>
    );
  }
}
