import Api from './Api';

class DatabaseCollectionDocumentsApi extends Api {
  __formatUrl(_query, _params) {
    if (_params.document) return `/collections/${_params.collection}/documents/${_params.document}${_query && `?${Object.keys(_query).map((_key) => `${_key}=${_query[_key]}`).join('&')}` || ''}`;
    return `/collections/${_params.collection}/documents${_query && `?${Object.keys(_query).map((_key) => `${_key}=${_query[_key]}`).join('&')}` || ''}`;
  }
}

const singleton = new DatabaseCollectionDocumentsApi();
export default singleton;
