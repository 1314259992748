import ObjectCache from './CacheService';
import UserAccount from '../UserAccount';
import GoogleSpreadsheetApi from '../server/GoogleSpreadsheetApi';
import Spreadsheet from '../../models/spreadsheet';

class GoogleSpreadsheetCache extends ObjectCache {
  api = GoogleSpreadsheetApi;
  createObject = (_results) => {
    const results = JSON.parse(JSON.stringify(_results));
    return new Spreadsheet(results.editor, results.owner, results.users, results.teams, UserAccount.account._id, results.sheets, results._id, results.title, results.description, results.createdTime, results.updateTime, results.state, results.sheetDiffs);
  }
  revertObject = (_object) => {
    const results = this.objectJson[_object._id];
    this.objects[_object._id] = this.createObject(results);
    this.emitStateUpdate();
  }
  getObjectJson(_model) {
    const current = _model.getJson().sheets[0];
    const old = this.objectJson[_model._id].sheets[0];
    const updates = [];
    current.forEach((row, i) => {
      if (i === 0) return;
      let changed = false;
      for (let j = 0; j < Math.max(old[i] && old[i].length || 0, row.length); j += 1) {
        const oldValue = old[i] && old[i][j] && old[i][j].value || '';
        const newValue = row[j] && row[j].value || '';
        if (oldValue !== newValue) changed = true;
      }
      if (!changed) return;
      const update = {};
      current[0].forEach((c) => { update[c.value] = ''; });
      row.forEach(({ value }, j) => { update[current[0][j].value] = value; });
      updates.push(update);
    });
    if (updates.length === 0) return this.emitStateUpdate();
    const updatesToSend = {
      _id: _model._id,
      sheets: [JSON.parse(JSON.stringify(current))],
      updates
    };
    return updatesToSend;
  }
  updateObject(updatesToSend) {
    const { _id, sheets } = updatesToSend;
    // eslint-disable-next-line no-param-reassign
    delete updatesToSend.sheets;
    return { _id, sheets };
  }
  isIdInvalid = (id) => id.length < 20;
}
const singleton = new GoogleSpreadsheetCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
