import React from 'react';
import { convertProjectsToSpreadsheet } from '../../components/ExportCSVButton';
import SpreadsheetWidget from '../../components/SpreadsheetWidget';
import UserCache from '../../services/cache/UserCache';

export default class SpreadsheetResults extends React.PureComponent {
  componentDidMount() {
    UserCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserCache.offStateUpdate(this);
  }
  render() {
    const { pagination, analyticsTree } = this.props;
    const trees = pagination.currentPage;
    const roots = trees.map(({ root }) => root);
    const results = convertProjectsToSpreadsheet(analyticsTree, roots, null, true);
    const users = UserCache.getUsersByIds(Array.from(new Set(trees.map(({ owner }) => owner))));
    if (!users) return null;

    const grid = [[]];
    Object.keys(results).forEach((key, j) => {
      grid[0][j] = { value: key };
      for (let i = 0; i < roots.length; i += 1) {
        if (!grid[i + 1]) grid[i + 1] = [];
        grid[i + 1][j] = { value: (results[key][i] || ['']).join(' | ') };
      }
    });

    grid[0].unshift({ value: 'Last Update Time' });
    grid[0].unshift({ value: 'Created Time' });
    grid[0].unshift({ value: 'Email' });
    grid[0].unshift({ value: 'Fullname' });
    for (let i = 0; i < trees.length; i += 1) {
      if (!grid[i + 1]) grid[i + 1] = [];
      grid[i + 1].unshift({ value: trees[i].updateTime.toUTCString() })
      grid[i + 1].unshift({ value: trees[i].createdTime.toUTCString() })
      const user = users.find(({ _id }) => _id === trees[i].owner);
      grid[i + 1].unshift({ value: user && user.email || '' })
      grid[i + 1].unshift({ value: user && user.fullname || '' })
    }

    return (
      <SpreadsheetWidget
        // key={key}
        data={grid}
        showRowsAndCols
        allowClickingLinks
      />
    );
  }
}
