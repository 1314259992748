/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import Navigation from '../../services/Navigation';
import TreeCache from '../../services/cache/TreeCache';
import SpreadsheetCache from '../../services/cache/SpreadsheetCache';
import GoogleSpreadsheetCache from '../../services/cache/GoogleSpreadsheetCache';
import RestfulApiCache from '../../services/cache/RestfulApiCache';
import RenderedHtmlToTextArea from '../../components/RenderedHtmlToTextArea/index';
import ClickableDiv from '../../components/ClickableDiv/index';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';
import Dropdown from '../../components/Dropdown';
import { AccountSpreadsheetsOpen, getTemplateProjects, AccountTemplatesOpen } from '../../services/Pagination/index';
import LoadingIndicator from '../../components/LoadingIndicator';
import { convertProjectsToSpreadsheet } from '../../components/ExportCSVButton';

const Wrapper = styled.div`
  margin: 0px 0px 0px ${Constants.TreeContentIconIndent};

  > div:first-child {
    > div:first-child {
      margin: ${Constants.defaultLinePadding} 0px;
    }
    display: flex;
    align-items: center;
    &:hover {
      opacity: 1 !Important;
    }
    > i {
      cursor: pointer;
    }
  }
`;
const TreeList = styled.div`
  display: flex;
  align-items: center;
  color: ${Constants.DarkTextColor};
  width: 100%;
  > div:first-child {
    margin-right: 12px;
    border-radius: 8px;
    min-width: 40px;
    width: 40px;
    min-height: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: ${Constants.White};
    font-size: ${Constants.SmallFontSize};
  }
  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;
const SpreadsheetLink = styled.div`

`;

export default class DataBox extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object,
    placeholderText: PropTypes.string,
  }
  componentDidMount() {
    const { data } = this.props;
    data.onStateUpdate(this);
    GrayOut.onStateUpdate(this.dataUpdate);
    SpreadsheetCache.onStateUpdate(this.getSpreadsheet);
    GoogleSpreadsheetCache.onStateUpdate(this.getSpreadsheet);
    RestfulApiCache.onStateUpdate(this.getSpreadsheet);
    AccountSpreadsheetsOpen.onStateUpdate(this);
    AccountTemplatesOpen.onStateUpdate(this);
    TreeCache.onStateUpdate(this.getSpreadsheet);
    this.getSpreadsheet();
  }
  componentWillUnmount() {
    const { data } = this.props;
    data.offStateUpdate(this);
    GrayOut.offStateUpdate(this.dataUpdate);
    SpreadsheetCache.offStateUpdate(this.getSpreadsheet);
    GoogleSpreadsheetCache.offStateUpdate(this.getSpreadsheet);
    RestfulApiCache.offStateUpdate(this.getSpreadsheet);
    AccountSpreadsheetsOpen.offStateUpdate(this);
    AccountTemplatesOpen.offStateUpdate(this);
    TreeCache.offStateUpdate(this.getSpreadsheet);
    if (this.spreadsheetUpdateTimeout) clearTimeout(this.spreadsheetUpdateTimeout);
  }
  getRestfulApiLink = (spreadsheet, schemaInvalid) => {
    const { data } = this.props;
    return <a target="_blank" href={data.text}><SpreadsheetLink>{`${schemaInvalid ? '(Schema Invalid) ' : ''}${spreadsheet ? ((spreadsheet.title && spreadsheet.title.text) ? spreadsheet.title.text : `Restful API`) : 'Loading...'}`}</SpreadsheetLink></a>;
  }
  getGoogleSpreadsheetLink = (spreadsheet) => {
    const { data } = this.props;
    const url = data.text.split('/');
    const googleUrl = `${url[0]}/edit${url[1] ? `#gid=${url[1]}` : ''}`;
    return <a target="_blank" href={`https://docs.google.com/spreadsheets/d/${googleUrl}`}><SpreadsheetLink>{`Google Spreadsheet${spreadsheet && spreadsheet.title && spreadsheet.title.text && `: ${spreadsheet.title.text}` || ''}`}</SpreadsheetLink></a>;
  }
  dataUpdate = () => {
    const { data } = this.props;
    const { text } = data;
    if (this.gettingTree === text) return this.forceUpdate();
    this.gettingTree = text;
    if (this.spreadsheetUpdateTimeout) clearTimeout(this.spreadsheetUpdateTimeout);
    this.spreadsheetUpdateTimeout = setTimeout(() => {
      this.getSpreadsheet();
    }, 500);
    return this.forceUpdate();
  }
  getSpreadsheet = () => {
    const { data } = this.props;
    const { isGoogleSpreadsheet, isReadable, isRestfulApi, isProjects, isTable, dataSchema, textWithMatchedVariables, delimiterString } = data;
    if (!isReadable || !data.text) return null;
    data.setBusy(true);
    let spreadsheet;
    if (isRestfulApi) {
      spreadsheet = RestfulApiCache.get(textWithMatchedVariables, dataSchema, delimiterString);
    } else if (isGoogleSpreadsheet) {
      spreadsheet = GoogleSpreadsheetCache.get(textWithMatchedVariables);
    } else if (isTable) {
      spreadsheet = SpreadsheetCache.createNew();
    } else if (isProjects) {
      const tree = TreeCache.getTree(textWithMatchedVariables);
      if (!tree) return;
      if (tree.error) spreadsheet = tree;
      else {
        this.projectsCache = getTemplateProjects(tree._id);
        const results = convertProjectsToSpreadsheet(tree, this.projectsCache.currentPage.map(({ root }) => root), true);
        if (!this.projectsCache.allFound) return;
        const grid = [];
        Object.keys(results).forEach((key, j) => {
          if (!grid[0]) grid[0] = [];
          grid[0][j] = { value: key };
          for (let i = 0; i < this.projectsCache.currentPage.length; i += 1) {
            if (!grid[i + 1]) grid[i + 1] = [];
            grid[i + 1][j] = { value: results[key][i] && results[key][i].join(delimiterString) || '' };
          }
        });
        spreadsheet = SpreadsheetCache.createObject({ _id: tree._id, title: tree.title.text, sheets: [grid] });
      }
    } else {
      spreadsheet = SpreadsheetCache.get(textWithMatchedVariables);
    }
    if (!spreadsheet) return;
    return data.updateSpreadsheet(spreadsheet);
  }
  spreadsheetChanged = (val) => {
    const { data } = this.props;
    data.updateText(val);
    this.getSpreadsheet();
  }
  render() {
    const { data, placeholderText } = this.props;
    const { editing, tree } = GrayOut;
    const { isDisabled } = tree;
    const { isGoogleSpreadsheet, isReadable, isLogictrySpreadsheet, isRestfulApi, isProjects, isTable, spreadsheet } = data;
    if (!editing) {
      if (isTable) return <CreateChildren node={data} />;
      return null;
    }
    const isActive = GrayOut.isActive(data);
    const { currentPage: currentSpreadsheets } = AccountSpreadsheetsOpen;
    const { currentPage: currentTemplates } = AccountTemplatesOpen;
    const { primaryColor } = GrayOut.tree.root;

    return (
      <Wrapper>
        <div>
          {(!data.text || GrayOut.isActive(data)) && (
            <TreeList>
              <div style={{ backgroundColor: primaryColor }}>
                <i className="fas fa-table"></i>
              </div>
              <div>
                <RenderedHtmlToTextArea node={data} placeholderText={placeholderText} />
              </div>
            </TreeList>
          ) || (
            <TreeList key={data.text}>
              <div style={{ backgroundColor: primaryColor }}>
                <i className="fas fa-table"></i>
              </div>
              <div>
                {(isReadable) && ((!spreadsheet && <LoadingIndicator />) || (spreadsheet && spreadsheet.error ? (
                  (spreadsheet.error === 'InvalidDataSchema') ? (
                    this.getRestfulApiLink(spreadsheet, true)
                  ) : (
                    <SpreadsheetLink>{`Error: ${spreadsheet.error}`}</SpreadsheetLink>
                  )
                ) : (
                  (isRestfulApi) ? (
                    this.getRestfulApiLink(spreadsheet)
                  ) : (isGoogleSpreadsheet) ? (
                    this.getGoogleSpreadsheetLink(spreadsheet)
                  ) : (isProjects) ? (
                    <SpreadsheetLink style={{ color: primaryColor }} role="button" tabIndex={-1} onClick={() => Navigation.push(`${Navigation.apps}/${data.text}`)}>{`${spreadsheet ? ((spreadsheet.title.text) ? spreadsheet.title.text : `Submission: ${spreadsheet._id}`) : ''}`}</SpreadsheetLink>
                  ) : (
                    <SpreadsheetLink style={{ color: primaryColor }} role="button" tabIndex={-1} onClick={() => Navigation.push(`${Navigation.spreadsheets}/${data.text}`)}>{`${spreadsheet ? ((spreadsheet.title.text) ? spreadsheet.title.text : `Spreadsheet: ${spreadsheet._id}`) : ''}`}</SpreadsheetLink>
                  )
                ))) || (
                  <div>
                    <RenderedHtmlToTextArea node={data} placeholderText={placeholderText || data.attributes[0]} />
                  </div>
                )}
                {spreadsheet && <ClickableDiv style={{ pointerEvents: isDisabled ? 'none' : null }} onClick={() => GrayOut.addActiveObject(data)}>Change Data Source</ClickableDiv>}
              </div>
            </TreeList>
          )}
        </div>
        {isActive && (isProjects && currentTemplates && (
          <Dropdown values={currentTemplates.map((s) => ({ name: s.title && s.title.text || s._id, value: s._id }))} onChange={this.spreadsheetChanged} />
        ) || isLogictrySpreadsheet && currentSpreadsheets && (
          <Dropdown values={currentSpreadsheets.map((s) => ({ name: s.title && s.title.text || s._id, value: s._id }))} onChange={this.spreadsheetChanged} />
        ))}
        <div>
          <CreateChildren node={data} />
        </div>
      </Wrapper>
    );
  }
}
