/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import MenuItem from '../Popup/MenuItem';

const MenuList = styled.div`
  max-height: 230px;
  overflow: auto;
`;
const AutofillWrapper = styled.div`
  max-width: calc(100vw - 20px);
  background-color: ${Constants.White};
  box-shadow: ${Constants.BoxShadow};
  border-radius: 8px;
`;

export default class Dropdown extends React.PureComponent {
  static propTypes = {
    values: PropTypes.array,
    onChange: PropTypes.func,
  }

  render() {
    const { values, onChange } = this.props;
    return (
      <AutofillWrapper>
        <MenuList>
          {values.map(({ name, value }, i) => (
            <MenuItem key={`${name}_${i}`} onMouseDown={() => onChange(value)}>{name}</MenuItem>
          ))}
        </MenuList>
      </AutofillWrapper>
    );
  }
}
