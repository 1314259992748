import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

const Wrapper = styled.div`
  width: 28px;
  input {
    cursor: pointer;
    width: 28px;
  }
`;

// eslint-disable-next-line react/prefer-stateless-function
export default class ColorPicker extends React.PureComponent {
  static propTypes = {
    color: PropTypes.string,
    onChange: PropTypes.func,
  }
  changed = (e) => {
    this.props.onChange(e.target.value);
  }

  render() {
    const { color } = this.props;
    return (
      <Wrapper>
        <input onChange={this.changed} onInput={this.changed} type="color" value={color || '#ff0000'}></input>
      </Wrapper>
    );
  }
}
