import Model from './model';

export default class LogicBaseUserReport extends Model {
  constructor(_id, owner, user, description, status) {
    super();
    this._id = _id;
    this.owner = owner;
    this.user = user;
    this.description = description;
    this.status = status;
  }
  getJson = () => {
    const { _id, owner, user, description, status } = this;
    return {
      _id,
      o: owner,
      u: user,
      d: description,
      s: status,
    }
  }
}
