import Node from './node';
import { UPDATE_RATE_ATTR } from './nodeattributes';

export default class Chatbot extends Node {
  get updateRate() {
    const updateRate = this.attributes.find((a) => a.includes(UPDATE_RATE_ATTR));
    if (updateRate) return parseInt(updateRate.split('=')[1], 10);
    return 1500;
  }
  clone() {
    return new Chatbot(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
}
