import React from 'react';
import styled from 'styled-components';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb';
import Company from '../../services/Company';
import LoadingIndicator from '../../components/LoadingIndicator';
import TreeAnswerPage from '../TreeAnswerPage/index';
import Scrollable from '../../components/Scrollable/index';

const TextContent = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  * {
    text-align: center;
  }
  h1, h2 {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
`;

export default class CompanyPage extends React.PureComponent {
  componentDidMount() {
    Company.onStateUpdate(this);
  }
  componentWillUnmount() {
    Company.offStateUpdate(this);
  }
  render() {
    if (Company.loginError) return <FullScreenVerticalCenterContent><h1>User Not Authorized</h1></FullScreenVerticalCenterContent>;
    if (!Company.subscriptionVerified) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    return (
      <TreeAnswerPage />
    );
  }
}
