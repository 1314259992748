import Service from './Service';
import Storage from './Storage';

class ContentDisplay extends Service {
  gridOrList = Storage.state.listGridView || 'grid';
  updateGridOrList(type) {
    Storage.setListGridView(type);
    this.gridOrList = type;
    this.emitStateUpdate();
  }
}
const singleton = new ContentDisplay();
export default singleton;
