import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import WindowSize from '../../services/WindowSize';
import purifyhtml from '../../submodules/logictry_purifyhtml';
import changeRelativeLinksToAbsoluteForNativeApp from '../../utils/changeRelativeLinksToAbsoluteForNativeApp';

const RawNoMargin = styled.div``;
const HiddenDiv = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  opacity: 0;
  height: 0px;
  pointer-events: none;
  height: 0px;
`;

export default class RawHtml extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onMouseDown: PropTypes.func,
    style: PropTypes.object,
    className: PropTypes.string,
    html: PropTypes.string,
    innerRef: PropTypes.func,
  };
  onRef = () => {
    if (!this.__hiddenDivRef || !this.__rawNoMarginRef) return;
    const { width } = this.__hiddenDivRef.getBoundingClientRect();
    this.__rawNoMarginRef.style.minWidth = `${Math.min(width, 180)}px`;
  }
  render() {
    const { innerRef, onClick, onFocus, onBlur, onMouseDown, html, style, className } = this.props;
    const { mobile } = WindowSize;
    const __html =  changeRelativeLinksToAbsoluteForNativeApp(purifyhtml(html, !!onMouseDown));
    return (
      <>
        {mobile && <HiddenDiv dangerouslySetInnerHTML={{ __html }} ref={(e) => {
          this.__hiddenDivRef = e;
          this.onRef();
        }} />}
        <RawNoMargin
          role={(onClick || onMouseDown) ? 'button' : ''}
          ref={(e) => {
            this.__rawNoMarginRef = e;
            if (innerRef) innerRef(e);
            this.onRef();
          }}
          style={style}
          className={className}
          tabIndex={-1}
          onMouseDown={onMouseDown}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          dangerouslySetInnerHTML={{ __html }}
        />
      </>
    );
  }
}
