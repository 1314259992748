/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
import { CHART, CHART_LOGIC } from './nodetypes';
import Logic from './logic';

export default class ChartLogic extends Logic {
  clone() {
    return new ChartLogic(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
  __update() {
    const dataNodes = this.__recursivelyGetAllDataNodes(this);
    const chartNodes = this.__recursivelyGetAllChartNodes(this);

    const data = [];
    dataNodes.forEach((_datanode) => {
      if (!_datanode.spreadsheet || _datanode.spreadsheet.error || this.parents[0].editing) return;
      data.push(_datanode.spreadsheet.sheets[0].data);
    });

    chartNodes.forEach((c) => {
      const xAxis = c.children.find((_c) => _c.attributes.includes('XAxis'));
      const yAxis = c.children.find((_c) => _c.attributes.includes('YAxis'));
      const barChartData = [];
      data.forEach((_data) => {
        const xCol = _data[0].findIndex((d) => d.value === xAxis.text);
        const yCol = _data[0].findIndex((d) => d.value === yAxis.text);
        if (xCol < 0 || yCol < 0) return;
        _data.slice(1).forEach((row) => {
          barChartData.push({
            name: row[xCol] && row[xCol].value || '',
            value: row[yCol] && parseFloat(row[yCol].value) || 0,
          });
        });
      });
      c.updateChartData(barChartData);
    });
  }
  __recursivelyGetAllChartNodes(node) {
    let nodes = [];
    node.children.forEach((_child) => {
      if (_child.isType(CHART_LOGIC)) return;
      if (_child.isOneOfTypes([CHART])) {
        nodes.push(_child);
        return;
      }
      const childResults = this.__recursivelyGetAllChartNodes(_child);
      nodes = [...nodes, ...childResults];
    });
    return nodes;
  }
}
