import Answerable from './answerable';
import { SEARCH_LOGIC } from './nodetypes';

export default class SearchText extends Answerable {
  constructor(_text, _attributes, _children, _owner, _votes, _cloned, _project) {
    /**
     * Children can be answers and categories
     */
    super(_text, _attributes, _children, _owner);
    this.cloned = _cloned;
    /**
     * The answer if new has an owner
     * Votes are the users that have upvoted the answer
     */
    this.votes = JSON.parse(JSON.stringify(_votes || []));
    if (_project) this.votes.forEach((vote) => {
      // eslint-disable-next-line no-param-reassign
      vote.project = _project; 
    });
  }
  checkedChanged() {
    const search = this.parent;
    const searchLogic = this.__getFirstInstanceOfNodeInParents(SEARCH_LOGIC, search.text);
    if (searchLogic) searchLogic.onNodeUpdated(this);
  }
  updateText(_text) {
    // eslint-disable-next-line no-param-reassign
    if (this.parents[0].editing) return super.updateText(_text);
    this.text = _text;
    this.owner = this.parents[0].userLoggedIn;
    this.updateUserVote();
    this.checkedChanged();
    this.__updateTreeOnNodeUpdated();
    return this.emitStateUpdate();
  }
  clone() {
    return new SearchText(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner, this.votes, this);
  }
  isMatchingAttributes() {
    return true;
  }
}
