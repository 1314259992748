// import React from 'react';
// import styled from 'styled-components';

import ObjectSettings from './index';
// import DatabaseCollectionDocumentApi from '../../services/server/DatabaseCollectionDocumentApi';
import DatabaseCollectionCache from '../../services/cache/DatabaseCollectionCache';

export default class DatabaseCollectionSettings extends ObjectSettings {
  delete = async (databaseCollection) => {
    // const count = await DatabaseCollectionDocumentApi.getCount(null, { collection: databaseCollection._id });
    // let error;
    // if (count && count.count === 0) DatabaseCollectionCache.delete(databaseCollection);
    // else error = { title: 'Delete failed - database collection not empty', description: 'You must delete all documents before you can delete the database collection' };
    DatabaseCollectionCache.delete(databaseCollection);
    this.setState({ confirmDelete: false });
  }
}
