export const FONTS = [
  'Arial',
  'Comic Sans MS',
  'Courier New',
  'Georgia',
  'Helvetica',
  'Impact',
  'Lato',
  'Montserrat',
  'Nunito',
  'Open Sans',
  'Oswald',
  'Palatino',
  'Poppins',
  'Raleway',
  'Roboto',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana',
  'Lucida Console'
];

export function loadFont(fontName) {
  // Define the fonts object with names and URLs
  const fonts = {
    "Roboto": "https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap",
    "Open Sans": "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap",
    "Lato": "https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap",
    "Montserrat": "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap",
    "Poppins": "https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap",
    "Raleway": "https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap",
    "Nunito": "https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap",
    "Oswald": "https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap"
  };

  // Check if the font is in the object
  if (!fonts[fontName]) {
      return;
  }

  // Get the URL for the font
  const fontUrl = fonts[fontName];

  // Check if the font is already included
  const existingLink = Array.from(document.head.getElementsByTagName('link'))
      .find(link => link.href === fontUrl);

  if (!existingLink) {
      // Create and add the link element if the font is not already included
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = fontUrl;
      document.head.appendChild(link);
  }
}
export function getFontInclude(fontName) {
  // Define the fonts object with names and URLs
  const fonts = {
    "Roboto": "https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap",
    "Open Sans": "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap",
    "Lato": "https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap",
    "Montserrat": "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap",
    "Poppins": "https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap",
    "Raleway": "https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap",
    "Nunito": "https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap",
    "Oswald": "https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap"
  };

  // Check if the font is in the object
  if (!fonts[fontName]) {
      return;
  }

  // Get the URL for the font
  const fontUrl = fonts[fontName];

  return `<link rel="stylesheet" href="${fontUrl}">`;
}
