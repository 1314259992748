/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { getCollectionMostFollowers, getCollectionsSubscribed } from '../../services/Pagination';
import WindowSize from '../../services/WindowSize';
import Navigation from '../../services/Navigation';
import Featured from '../../services/Featured';
import UserAccount from '../../services/UserAccount';
import LogicBaseAppLists from '../../components/LogicBaseAppLists';
import Scrollable from '../../components/Scrollable';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import LogicBaseCollectionSubscriptionCache from '../../services/cache/LogicBaseCollectionSubscriptionCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import LoadingIndicator from '../../components/LoadingIndicator';
import LogicBaseCollectionRow from '../../components/LogicBaseCollectionRow';

const Wrapper = styled.div`
  height: 100%;
  inset: 0;
  overflow: hidden;
  position: absolute;
`;
const Banner = styled.div`
  background-size: contain;
  height: 340px;
  width: 100%;
  color: white;
  font-size: 2em;
  border-radius: 1rem;
  h1 {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0;
  }
  div {
    margin: 0;
    font-size: 1rem;
  }
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 1200px) {
    flex-direction: column;
    text-align: center;
    height: 400px;
    h1 {
      font-size: 1.8rem;
    }
    div {
      font-size: 0.9rem;
    }
  }
  > div {
    flex: 1;
    height: 100%;
  }
  > div:first-child {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 0.5rem;
    padding: 2rem;
    @media (max-width: 1200px) {
      flex: unset;
      height: unset;
    }
  }
  > div:last-child {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 100%;
    flex: 1;
    > div:first-child {
      display: flex;
      align-items: center;
      margin: 2rem auto;
      @media (max-width: 1200px) {
        margin: 1rem auto;
      }
      @media (max-width: 400px) {
        gap: 1rem;
      }
      gap: 2rem;
      > div:first-child {
        > div:first-child {
          font-size: 0.75rem;
          text-transform: uppercase;
        }
        > div:nth-child(2) {
          font-size: 1.25rem;
          font-weight: 500;
          @media (max-width: 400px) {
            font-size: 1rem;
          }
        }
      }
      > div:last-child {
        border: 2px solid white;
        border-radius: 2rem;
        padding: 0 1rem;
        line-height: 2.5rem;
        text-align: center;
        font-size: 0.9rem;
        font-weight: 600;
        transition: 0.3s all;
        &:hover {
          background: white;
          color: black;
        }
      }
    }
    > div:last-child {
      filter: brightness(1.5);
      flex: 1;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transform: translateY(2px);
    }
  }
`;
const Content = styled.div`
  inset: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding-bottom: 4rem;
`;
export default class LogicBasePage extends React.PureComponent {
  constructor() {
    super();
    this.collections = getCollectionMostFollowers('');
  }
  state = {
    q: Navigation.currentLocation[1] === 'search' && Navigation.currentLocation[2] && unescape(Navigation.currentLocation[2]) || Navigation.currentSearch.q || '',
    featuredExpert: null
  }
  componentDidMount() {
    this.collections.onStateUpdate(this);
    LogicBaseCollectionCache.onStateUpdate(this);
    LogicBaseCollectionSubscriptionCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
    Featured.onStateUpdate(this);
  }
  componentWillUnmount() {
    this.collections.offStateUpdate(this);
    LogicBaseCollectionCache.offStateUpdate(this);
    LogicBaseCollectionSubscriptionCache.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
    Featured.offStateUpdate(this);
  }
  onPagination = () => {
    if (this.subscriptions && this.subscriptions.showMore && this.subscriptions.allFound && this.subscriptions.initialized) {
      this.subscriptions.getMore();
      this.forceUpdate();
    } else if (this.collections.showMore && this.collections.allFound && this.collections.initialized) {
      this.collections.getMore();
      this.forceUpdate();
    }
  }
  render() {
    const { mobile } = WindowSize;
    const { q } = this.state;
    if (q) {
      const showTopics = (!q || q === 'topic');
      if (mobile) return <>
        <div style={{ top: (q || mobile) ? 0 : '3rem', padding: '0 1rem' }}>
          <LogicBaseAppLists showUser />
        </div>
      </>
      return (
        <Wrapper>
          <Content>
            <div style={{ top: (!showTopics || mobile) ? 0 : '3rem' }}>
              <LogicBaseAppLists showUser />
            </div>
          </Content>
        </Wrapper>
      );
    }
    const loading = <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const collections = this.collections.currentPage;
    if (!collections || (collections.length === 0 && !this.collections.allFound)) return loading;
    if (!Featured.featuredExpert) return loading;
    const { background, name, image, cta, featured } = Featured.featuredExpert;
    const featuredCollection = LogicBaseCollectionCache.get(featured);
    if (!featuredCollection) return loading;
    let collectionsToShow;
    if (UserAccount.isLoggedIn) {
      this.subscriptions = getCollectionsSubscribed(UserAccount.account._id);
      const subscriptions = this.subscriptions.currentPage;
      if (!subscriptions || (subscriptions.length === 0 && !this.subscriptions.allFound)) return loading;
      const subscribedCollections = LogicBaseCollectionCache.getByIds(subscriptions.map(({ collection }) => collection));
      if (!subscribedCollections) return loading;
      collectionsToShow = Array.from(new Set([featuredCollection, ...subscribedCollections.sort((a, b) => new Date(b.updateTime) - new Date(a.updateTime)), ...collections]));
    } else {
      collectionsToShow = Array.from(new Set([featuredCollection, ...collections]));
    }
    return (
      <Scrollable
        vertical
        style={{ position: mobile ? 'relative' : 'absolute', top: 0, left: 0, right: 0, bottom: 0, padding: mobile ? '1rem 1rem 2rem 1rem' : '0 2rem 2rem 2rem' }}
        paginationHeight={400}
        onPagination={this.onPagination}
        rememberScrollPosition={`logicbasepage`}
      >
        <Content>
          <Banner style={{ background }}>
            <div>
              <h1>Welcome to Logictry</h1>
              <div>Become an expert faster by learning from experts</div>
            </div>
            <div onClick={() => Navigation.site(cta)}>
              <div>
                <div>
                  <div>Featured Expert</div>
                  <div>{name}</div>
                </div>
                <div>View Book</div>
              </div>
              <div style={{ backgroundImage: `url(${image})` }} />
            </div>
          </Banner>
          {collectionsToShow.map((collection) => <LogicBaseCollectionRow key={collection._id} collection={collection} />)}
        </Content>
      </Scrollable>
    );
  }
}
