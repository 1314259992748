import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import AutoGrowTextArea from '../AutoGrowTextArea';
import GrayOut from '../../services/TreeDisplay';
import SidebarService from '../../services/SidebarService';
import { ALWAYS_SHOW_WYSIWYG_TYPES } from '../../models/nodetypes';
import WysiwygComponent from '../../submodules/logictry_wysiwyg/src/react';
import RawHtml from '../RawHtml/index';
import { WYSIWYG_ATTR } from '../../models/nodeattributes';

const Wrapper = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
`;
const ClickWrapper = styled.div`
  cursor: text;
`;
const RenderedHtml = styled.div`
  pointer-events: none;
  flex: 1;
  min-width: 120px;
  border-radius: 4px;
  padding: 0px;

`;
const PlaceholderHtml = styled(RenderedHtml)`
  > div {
    color: rgba(0,0,0,0.4);
  }
`;

export default class RenderedHtmlToTextArea extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    placeholderText: PropTypes.string,
    inputFormat: PropTypes.string,
    decimals: PropTypes.number,
    className: PropTypes.string,
  }
  state = {
    inputRendered: false,
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
    GrayOut.onStateUpdate(this.checkFocus);
    SidebarService.onStateUpdate(this);
    this.checkFocus();
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
    GrayOut.offStateUpdate(this.checkFocus);
    SidebarService.offStateUpdate(this);
  }
  clickHandler = () => {
    const { node } = this.props;
    GrayOut.addActiveObject(node);
  }
  checkFocus = () => {
    const { node } = this.props;
    const { wasPreviouslyActive } = this.state;
    if (!wasPreviouslyActive) {
      if (!GrayOut.isActive(node)) return null;
      this.state.wasPreviouslyActive = true;
      if (this.inputRef) this.inputRef.focus({ preventScroll: true });
      return this.forceUpdate();
    }
    if (!GrayOut.isActive(node)) {
      this.state.wasPreviouslyActive = false;
      return this.forceUpdate();
    }
    return null;
  }
  onBlur = () => {
    const { node } = this.props;
    GrayOut.removeActiveObject(node);
  }
  onKeyDown = (e) => {
    if (e.key === 'Escape') this.onBlur();
  }
  onChange = (value) => { this.changeValue(value); }
  onInnerRef = (e) => { this.inputRef = e; this.checkFocus(); }
  changeValue = (value) => {
    const { node, decimals } = this.props;
    if (node.isNumber) {
      if (!value) {
        node.updateText('');
        return;
      }
      const parsedValue = parseFloat(value).toFixed(decimals || 0).toString();
      if (!parsedValue || parsedValue === 'NaN') {
        this.inputRef.value = node.text;
        return;
      }
      node.updateText(parsedValue);
    } else {
      node.updateText(value);
    }
  }
  addActive = () => {
    const { node } = this.props;
    GrayOut.addActiveObject(node);
    this.inputRef.value = node.text;
  }
  render() {
    const { editing, tree } = GrayOut;
    const  { showWysiwyg } = SidebarService;
    const { isDisabled } = tree;
    const { node, placeholderText, inputFormat, className } = this.props;
    const showWysiwygInDisplay = (!editing && (node && node.isOneOfTypes(ALWAYS_SHOW_WYSIWYG_TYPES) || inputFormat === WYSIWYG_ATTR)) || (editing && showWysiwyg);
    const isActive = GrayOut.isActive(node);
    const flex = editing ? 1 : null;

    const maxHtmlHeight = `${Math.max(Math.min(window.innerHeight - 200, 800), 200)}px`;
    if (showWysiwygInDisplay && isActive) {
      this.state.inputRendered = true;
      const height = !editing && '200px' || maxHtmlHeight;
      return <div style={{ width: '100%' }} onKeyDown={this.onKeyDown}>
        <WysiwygComponent key={node.key} disabled={isDisabled} focusOnInit node={node} onBlur={this.onBlur} height={height} width="100%" />
      </div>;
    }

    const absolutePos = { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, opacity: 0 };
    const autoGrowStyle = isActive ? {} : absolutePos;
    const htmlStyle = isActive ? absolutePos : {};

    return (
      <Wrapper>
        <div style={{ ...autoGrowStyle, minHeight: Constants.defaultLineHeight }}>
          {(!showWysiwygInDisplay) && (
            <AutoGrowTextArea
              disabled={isDisabled}
              tabIndex={-1}
              innerRef={this.onInnerRef}
              onChange={this.onChange}
              onBlur={this.onBlur}
              onFocus={this.addActive}
              placeholder={placeholderText}
              defaultValue={node.text}
              className={className}
            />
          ) || <div />}
        </div>
        {(!isActive) && (node.text && (
          <RenderedHtml style={{ flex, minHeight: Constants.defaultLineHeight, ...htmlStyle }}>
            {(editing && !showWysiwyg) && (
              <div style={{ flex, minHeight: Constants.defaultLineHeight, ...htmlStyle, wordBreak: 'break-word' }}>
                {node.formattedText}
              </div>
            ) || (
              <RawHtml className={className} html={node.formattedText} style={{ transform: 'unset' }} />
            )}
          </RenderedHtml>
        ) || (
          <PlaceholderHtml style={{ flex, minHeight: Constants.defaultLineHeight, ...htmlStyle }}>
            <RawHtml className={className} html={placeholderText} style={{ transform: 'unset' }} />
          </PlaceholderHtml>
        ))}
        {!isActive && !isDisabled && showWysiwygInDisplay && <ClickWrapper
          onClick={this.clickHandler}
          style={{ ...absolutePos, opacity: 1 }}
        />}
      </Wrapper>
    );
  }
}
