import Node from './node';
import { CHECKLIST_ITEM } from './nodetypes';

export default class Checklist extends Node {
  constructor(_text, _attributes, _children, _owner) {
    super(_text, _attributes, _children, _owner);
    /**
     * Handle item updates
     */
    this.updateChildrenEnabled();
  }
  onNodeUpdated = (node) => {
    if (!node.isOneOfTypes([CHECKLIST_ITEM])) return;
    this.updateChildrenEnabled();
    this.__updateAllParentsOnNodeUpdated();
    this.__updateTreeOnNodeUpdated();
    this.emitStateUpdate();
  };
  get items() {
    return this.children.filter((_child) => _child.isType(CHECKLIST_ITEM));
  }
  updateChildrenEnabled() {
    let previousChild = true;
    this.items.forEach((_child) => {
      const previousChildOld = previousChild;
      previousChild = _child.checked;
      // eslint-disable-next-line no-param-reassign
      if (_child.checked || previousChildOld) _child.updateEnabledDisabled(true);
      else _child.updateEnabledDisabled(false);
    });
  }
  clone() {
    return new Checklist(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
}
