/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';

export default class OperatorBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
  }
  changeValue = (type, value) => {
    const { node } = this.props;
    if (type === 'text') node.updateText(value);
  }
  render() {
    const { node } = this.props;
    const { conditionalValue } = node;

    if (!conditionalValue) return null;
    return (
      <CreateChildren node={node} />
    );
  }
}
