import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import SpreadsheetWidget from '../../components/SpreadsheetWidget';
import WindowSize from '../../services/WindowSize';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import LoadingIndicator from '../../components/LoadingIndicator';
import purifyhtml from '../../submodules/logictry_purifyhtml';

const TableWrapper = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
  padding-left: ${Constants.TreeContentIconIndent};
  padding-top: ${Constants.defaultLinePadding};
  padding-bottom: ${Constants.defaultLinePadding};
  > div {
    position: relative;
    height: 100%;
    width: 100%;
  }
`;


export default class Table extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object,
  }
  state = {
    grid: [],
  }
  componentDidMount() {
    WindowSize.onStateUpdate(this);
    this.spreadsheetCacheUpdate();
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
  }

  onChange = (changes) => {
    let { grid } = this.state;
    grid = grid.map((row) => [...row]);
    changes.forEach(({ row, col, value }, i) => { // eslint-disable-line
      // Autoexpand rows
      if (row > (grid.length - 1)) {
        for (let j = grid.length - 1; j < row; j += 1) {
          grid.push([]);
        }
      }

      // Autoexpand columns
      if (col > (grid[row].length - 1)) {
        grid[row] = [...grid[row], ...(new Array(col - (grid[row].length - 1))).fill({ value: '' })];
      }

      if (!grid[row] && !value) return;
      if (!grid[row]) grid[row] = [];
      grid[row][col] = { ...grid[row][col], value };
    });
    grid.forEach((row, i) => {
      if (row.length !== grid[i].length) grid[i] = [...row, ...(new Array(grid[i].length - row.length)).fill({ value: '' })];
    });
    this.updateData(grid);
    this.setState({ grid });
  }
  createColumn = (column) => {
    const { grid } = this.state;
    if (!grid[0]) grid[0] = [];
    if (column >= 0) grid.forEach((_row) => _row.splice(column, 0, { value: '' }));
    else grid.forEach((_row) => _row.push({ value: '' }));
    this.updateData(grid);
    this.forceUpdate();
  }
  createRow = (row) => {
    const { grid } = this.state;
    if (row >= 0) grid.splice(row, 0, []);
    else grid.push([]);
    this.updateData(grid);
    this.forceUpdate();
  }
  deleteColumn = (_update, column) => {
    const { grid } = this.state;
    if (!grid[0]) return;
    if (column >= 0) grid.forEach((_row) => _row.splice(column, 1));
    if (_update) {
      this.updateData(grid);
      this.forceUpdate();
    }
  }
  deleteRow = (_update, row) => {
    const { grid } = this.state;
    if (row >= 0) grid.splice(row, 1);
    if (_update) {
      this.forceUpdate();
    }
  }
  updateData = (grid) => {
    // this.props.data.updateText(JSON.stringify(grid), true);
    this.props.data.spreadsheet.sheets[0].updateData(grid);
  }
  spreadsheetCacheUpdate = () => {
    this.state.grid = [];
  
    if (!this.props.data.spreadsheet || !this.props.data.spreadsheet.sheets) return this.forceUpdate();
    return this.sheetUpdate();
    // this.props.spreadsheet.onStateUpdate(this.spreadsheetUpdate);
    // return this.props.spreadsheet.sheets[0].onStateUpdate(this.sheetUpdate);
  }
  sheetUpdate = () => {
    this.state.grid = [];
    this.props.data.spreadsheet.sheets[0].data.forEach((row, i) => {
      if (!this.state.grid[i]) this.state.grid[i] = [];
      row.forEach((val, j) => {
        this.state.grid[i][j] = { value: purifyhtml(val.value || '') };
      });
    });
    this.state.grid.forEach((row, i) => {
      if (row.length !== this.state.grid[i].length) this.state.grid[i] = [...row, ...(new Array(this.state.grid[i].length - row.length)).fill({ value: '' })];
    });
    return this.forceUpdate();
  }

  render() {
    const { spreadsheet } = this.props.data;
    if (!spreadsheet) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    return (
      <TableWrapper>
        <div>
          <SpreadsheetWidget
            // key={key}
            data={this.state.grid}
            onCellsChanged={this.onChange}
            // spreadsheetKey={key}
            onFinishEditing={() => this.forceUpdate()}
            // onCreateRow={(click) => this.createRow(click.row)}
            // onCreateColumn={(click) => this.createColumn(click.column)}
            // onDeleteRow={(click) => this.deleteRow(true, click.row)}
            // onDeleteColumn={(click) => this.deleteColumn(true, click.column)}
            allowEditing
          />
        </div>
      </TableWrapper>
    );
  }
}
