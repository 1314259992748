import Node from './node';

export default class Calculation extends Node {
  get calculatedValueFormatted() {
    if (!this.calculatedValue) return null;
    return parseFloat(this.calculatedValue).toFixed(this.decimals).toString();
  }
  updateCalculation(_calculation) {
    const newValue = (typeof _calculation !== 'number') ? null : _calculation.toString();
    if (newValue === this.calculatedValue) return;
    this.calculatedValue = newValue;
    this.__updateAllParentsOnNodeUpdated();
    this.emitStateUpdate();
  }
  clone() {
    return new Calculation(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
}
