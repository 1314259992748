import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Navigation from '../../services/Navigation';

const A = styled.a`
  cursor: pointer;
`;

export default function Link(props) {
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.absoluteUrl) return Navigation.site(props.absoluteUrl);
    if (!props.url) return null;
    if (e.metaKey || e.altKey) return Navigation.site(props.url);
    return Navigation.push(props.url);
  };
  return <A tabIndex={-1} onClick={onClick} href={props.url ? `${window.location.origin}${props.url}` : `${props.absoluteUrl}`}>{props.children}</A>;
}
Link.propTypes = {
  children: PropTypes.string,
  url: PropTypes.string,
  absoluteUrl: PropTypes.string,
};
