// import React from 'react';
// import styled from 'styled-components';

import ObjectSettings from './index';
import DatabaseCollectionApi from '../../services/server/DatabaseCollectionApi';
import DatabaseCache from '../../services/cache/DatabaseCache';

export default class DatabaseSettings extends ObjectSettings {
  delete = async (database) => {
    const count = await DatabaseCollectionApi.getCount({ database: database._id, state: 'Live' });
    let error;
    if (count && count.count === 0) DatabaseCache.delete(database);
    else error = { title: 'Delete failed - database not empty', description: 'You must delete all collections before you can delete the database' };
    this.setState({ confirmDelete: false, error });
  }
}
