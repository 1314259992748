import Service from './Service';
import Navigation from './Navigation';

class Popout extends Service {
  sendMessage(msg) {
    try {
      localStorage.setItem('logictry.popout', JSON.stringify(msg));
    } catch (e) {
      //
    }
  }
  open(msg) {
    if (this.popoutWindow) this.popoutWindow.close();
    try {
      localStorage.setItem('logictry.popout', JSON.stringify(msg));
      this.popoutWindow = Navigation.site(`${window.location.origin}/popout/${msg.id}`);
    } catch (e) {
      //
    }
  }
  startListener() {
    setInterval(() => {
      this.emitStateUpdate();
    }, 50);
  }
  stopListener() {

  }
  get state() {
    try {
      return JSON.parse(localStorage.getItem('logictry.popout'));
    } catch (e) {
      return {};
    }
  }
}

const singleton = new Popout();
export default singleton;
