import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { Wrapper as GoogleMapsWrapper } from "@googlemaps/react-wrapper";
import Popup from '../Popup/index';
import Button from '../Button/index';
import StatefulTextField from '../StatefulTextField/index';
import LogicBaseAppCache from '../../services/cache/LogicBaseAppCache';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import UserAccount from '../../services/UserAccount';
import ConfirmDialog from '../ConfirmDialog';
import StatefulButton from '../StatefulButton';

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid gray;
  padding: 1rem 3rem;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Coordinates = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
`;
let geocoder;
export default class PushPublicSettingsPopup extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    domainObject: PropTypes.object,
  }
  constructor(props) {
    super();
    const { domainObject } = props;
    this.state = {
      _id: domainObject._id,
      owner: domainObject.owner,
      url: '',
      title: '',
      description: '',
      image: '',
      topics: '',
      address: '',
      latitude: '',
      longitude: '',
      isPublic: false,
      confirmUnpublish: false,
      warning: ''
    };
    LogicBaseAppCache.get(domainObject._id);
  }
  componentDidMount() {
    LogicBaseAppCache.onStateUpdate(this.publicUpdated);
    LogicBaseUserCache.onStateUpdate(this);
    this.publicUpdated();
  }
  componentWillUnmount() {
    LogicBaseAppCache.offStateUpdate(this.publicUpdated);
    LogicBaseUserCache.offStateUpdate(this);
  }
  publicUpdated = () => {
    const { _id } = this.state;
    const publicApp = LogicBaseAppCache.get(_id);
    if (!publicApp) return;
    if (!publicApp._id) {
      const { domainObject } = this.props;
      return this.setState({
        url: `${this.prependString}${(domainObject.title.text || '').replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase()}`,
        title: domainObject.title.text || '',
        description: domainObject.description.text || '',
        image: domainObject.background && domainObject.background.image || '',
      });
    }
    const { url, title, description, image, color, topics, address, geo } = publicApp;
    const latitude = geo && geo.coordinates && geo.coordinates[1].toString() || '';
    const longitude = geo && geo.coordinates && geo.coordinates[0].toString() || '';
    const formattedUrl = url && !url.startsWith(this.prependString) && `${this.prependString}${url}` || url || this.prependString;
    return this.setState({
      url: formattedUrl,
      title: title || '',
      description: description || '',
      image: image || '',
      color: color || '',
      topics: (topics || []).join(', ') || '',
      address: address || '',
      latitude,
      longitude,
      isPublic: true,
    });
  }
  close = () => {
    const { onClose } = this.props;
    onClose(null);
  }
  saveAndClose = () => {
    const { onClose } = this.props;
    const { _id, owner, title, description, image, color, url, likes, views, address, latitude, longitude } = this.state;
    if (!url || url === this.prependString) return this.setState({ warning: 'URL Empty' });
    this.setState({ warning: '' });
    const topics = this.state.topics.split(',').filter((t) => t).map((t) => t.toLowerCase().trim());
    const geo = {
      type: 'Point',
      coordinates: [longitude, latitude]
    };
    const publicUrl = (url || '').toLowerCase().trim();
    const publicApp = LogicBaseAppCache.get(_id);
    if (publicApp && publicApp.url) {
      publicApp.url = publicUrl;
      publicApp.title = title;
      publicApp.description = description;
      publicApp.image = image;
      publicApp.color = color;
      publicApp.topics = topics;
      publicApp.owner = owner;
      publicApp.address = address;
      if (latitude && longitude) {
        publicApp.geo = geo;
      }
      LogicBaseAppCache.update(publicApp);
    } else {
      const newObject = {
        _id,
        o: owner,
        t: title,
        d: description,
        i: image,
        c: color,
        ts: topics,
        u: publicUrl,
        l: likes,
        v: views,
        a: address,
      }
      if (latitude && longitude) {
        newObject.g = geo;
      }
      LogicBaseAppCache.create(LogicBaseAppCache.createObject(newObject));
    }
    onClose();
  }
  onChange = (name, value) => {
    if (name === 'url') {
      let url = '';
      if (value.startsWith(this.prependString)) url = value.split(this.prependString)[1].replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase();
      value = `${this.prependString}${url}`;
    }
    this.setState({ [name]: value });
  }
  addressChange = () => {
    const { address } = this.state;
    geocoder = geocoder || new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK') {
        const location = results[0].geometry.location;
        this.setState({ latitude: location.lat(), longitude: location.lng() });
      }
    });
  }
  get prependString() {
    const { account } = UserAccount;
    return `${account.username.toLowerCase()}-`;
  }
  unpublish = () => {
    const { onClose } = this.props;
    const { _id } = this.state;
    const publicApp = LogicBaseAppCache.get(_id);
    if (!publicApp || !publicApp._id) return;
    this.setState({ confirmUnpublish: false });
    LogicBaseAppCache.delete(publicApp);
    onClose();
  }
  render() {
    const { account } = UserAccount;
    const publicUser = LogicBaseUserCache.get(account._id);
    const creator = publicUser && publicUser.creator;
    const { url, title, description, image, topics, address, latitude, longitude, isPublic, color, confirmUnpublish, warning } = this.state;
    return (
      <GoogleMapsWrapper apiKey={"AIzaSyAdipzE4FB6pGLDZkHEjcohieMnW-sEll8"}>
        <Popup
          onClose={this.close}
          allowBackgroundClose
        >
          <Wrapper>
            <h1>Public Settings</h1>
            <StatefulTextField
              value={url || ''}
              onChange={(val) => this.onChange('url', val)}
              label="Url"
              maxLength={256}
            />
            <StatefulTextField
              value={title || ''}
              onChange={(val) => this.onChange('title', val)}
              label="Title"
              maxLength={256}
            />
            <StatefulTextField
              value={description || ''}
              onChange={(val) => this.onChange('description', val)}
              label="Description"
              maxLength={400}
            />
            <StatefulTextField
              value={image || ''}
              onChange={(val) => this.onChange('image', val)}
              label="Image"
              maxLength={256}
            />
            <StatefulTextField
              value={color || ''}
              onChange={(val) => this.onChange('color', val)}
              label="Color"
              maxLength={256}
            />
            <StatefulTextField
              value={topics || ''}
              onChange={(val) => this.onChange('topics', val)}
              label="Topics"
              maxLength={256}
            />
            <StatefulTextField
              value={address || ''}
              onChange={(val) => this.onChange('address', val)}
              onComplete={this.addressChange}
              label="Address"
              maxLength={256}
            />
            <Coordinates>
              <StatefulTextField
                value={latitude || ''}
                onChange={(val) => this.onChange('latitude', val)}
                label="Latitude"
                maxLength={30}
                width={140}
              />
              <StatefulTextField
                value={longitude || ''}
                onChange={(val) => this.onChange('longitude', val)}
                label="Longitude"
                maxLength={30}
                width={140}
              />
            </Coordinates>
            <ButtonsWrapper>
              <Button onClick={this.close} color="primary" autoFocus>
                Cancel
              </Button>
              <StatefulButton
                  text={isPublic ? 'Update' : 'Publish'}
                  onClick={() => this.saveAndClose(creator)}
                  warning={warning}
                />
              {isPublic && <Button onClick={() => this.setState({ confirmUnpublish: true })} color="red" autoFocus>
                Unpublish
              </Button>}
            </ButtonsWrapper>
          </Wrapper>
        </Popup>
        <ConfirmDialog text="Are you sure you wish to unpublish?" description="This will remove the content from all collections, pins, and likes other users have given it.  This is permanent and cannot be undone." open={!!confirmUnpublish} onNo={() => this.setState({ confirmUnpublish: false })} onYes={this.unpublish} />
      </GoogleMapsWrapper>
    );
  }
}
