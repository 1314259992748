import React from 'react';
// import styled from 'styled-components';

import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import { getDatabaseCollections } from '../../services/Pagination/index';
import Box from './Box';
import DatabaseCollectionSettings from '../../components/ObjectSettings/DatabaseCollectionSettings';
import CreateDatabaseCollectionPopup from './CreateDatabaseCollectionPopup';
import { getAccountDatabasecollectionsSearch } from '../../services/Pagination/index';

export default class DatabaseCollectionsBox extends Box {
  missingTitleText = 'New Database Collection';
  paginationService = this.props.paginationService || (this.props.searchText || this.props.filterText) && getAccountDatabasecollectionsSearch(this.props.searchText, null, this.props.filterText) || getDatabaseCollections(Navigation.currentLocation[2]);
  backgroundColor = Constants.PrimaryColor;
  boxIcon = 'fas fa-table';
  open = (_tree) => Navigation.push(`/databasecollections/${_tree._id}`);
  createText = 'New Collection';
  createNewPopup = () => {
    const { isDevAccount } = UserAccount;
    if (!isDevAccount) return this.setState({ showCreatePopup: false, showUpgradePopup: true });
    return <CreateDatabaseCollectionPopup team={this.props.team} onClose={this.hideCreatePopup} />;
  }
  disableFree = true;
  objectSettings = DatabaseCollectionSettings;
}
