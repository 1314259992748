// import React from 'react';
// import styled from 'styled-components';

import AssetCache from '../../services/cache/AssetCache';
import ObjectSettings from './index';

export default class AssetSettings extends ObjectSettings {
  restore = (asset) => {
    AssetCache.update(asset, 'Live');
    this.setState({ confirmRestore: false });
  }
  leave = (asset) => {
    AssetCache.update(asset, 'Remove');
    this.setState({ confirmLeave: false });
  }
  delete = (asset) => {
    AssetCache.delete(asset);
    this.setState({ confirmDelete: false });
  }
}
