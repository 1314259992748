/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import styled from 'styled-components';
import UserAccount from '../../services/UserAccount';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import UserProfileHeader from '../../components/UserProfileHeader/index';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb';
import { getUserPublicApps } from '../../services/Pagination';
import LogicBaseAppList from '../../components/LogicBaseAppList/index';
import Scrollable from '../../components/Scrollable/index';
import LoadingIndicator from '../../components/LoadingIndicator';

const NoPublicApps = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
`;
const AppLists = styled.div`
  margin-top: 4rem;
  width: 100%;
`;
const ProfileWrapper = styled.div`
  max-width: 52rem;
  margin: auto;
  padding: 2rem 1rem;
`;

export default class ProfilePage extends React.PureComponent {
  constructor() {
    super();
    this.userPublicApps = getUserPublicApps(UserAccount.account._id);
    this.userPublicApps.onStateUpdate(this);
  }
  componentWillUnmount() {
    this.userPublicApps.offStateUpdate(this);
  }
  onPagination = () => {
    if (this.userPublicApps.showMore && this.userPublicApps.allFound && this.userPublicApps.initialized) {
      this.userPublicApps.getMore();
    }
  }
  render() {
    const apps = this.userPublicApps.currentPage;
    return (
      <Scrollable
        vertical
        paginationHeight={400}
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        onPagination={this.onPagination}
        rememberScrollPosition={`logicbaseaccount`}
      >
        <ProfileWrapper>
          <UserProfileHeader
            user={UserAccount.account}
          />
          <AppLists>
            {apps.length > 0 && <LogicBaseAppList apps={apps} listView /> || (
              <NoPublicApps>
                <div>No public apps</div>
              </NoPublicApps>
            )}
          </AppLists>
        </ProfileWrapper>
      </Scrollable>
    );
  }
}
