/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import GrayOut from '../../services/TreeDisplay';
import AutofillInput from '../../components/AutofillInput/index';
import RenderedHtmlToTextArea from '../../components/RenderedHtmlToTextArea/index';
import FocusableInput from './FocusableInput';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export default class UserAnswer extends React.PureComponent {
  static propTypes = {
    question: PropTypes.object,
    answer: PropTypes.object,
  }
  componentDidMount() {
    const { question } = this.props;
    question.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { question } = this.props;
    question.offStateUpdate(this);
  }
  render() {
    const { editing } = GrayOut;
    const { question, answer } = this.props;
    const { autofill, openEnded, userInputFormat, decimals, answers } = question;
    const { placeholderText } = answer;

    // eslint-disable-next-line no-nested-ternary
    const placeholder = !editing ?
      (placeholderText ? placeholderText.text : (answers && answers.find((a) => a.text && !a.owner) && 'Other (answer here)' || 'Answer here'))
      : `${openEnded ? 'Answer (user input if left blank)' : 'Answer (cannot be left blank)'}`;
    /**
     *
     */
    return (
      <Wrapper>
        {(['DateTime', 'Date', 'Time'].includes(question.userInputType)) ? (
          <FocusableInput node={answer} placeholder={placeholder} />
        ) : autofill ? (
          <AutofillInput question={question} answer={answer} autofill={autofill} placeholder={placeholder} />
        ) : (
          <RenderedHtmlToTextArea className="logictry-answer__text" decimals={decimals} node={answer} placeholderText={placeholder} inputFormat={userInputFormat} />
        )}
      </Wrapper>
    );
  }
}
