/* eslint-disable react/no-danger */
/* eslint-disable no-useless-escape */
import React from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom/client';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import UserAccount from '../../services/UserAccount';
// eslint-disable-next-line import/no-cycle
import CreateChild from './CreateChild';
import purifyhtml from '../../submodules/logictry_purifyhtml';
import WindowSize from '../../services/WindowSize';
import changeRelativeLinksToAbsoluteForNativeApp from '../../utils/changeRelativeLinksToAbsoluteForNativeApp';

const NonEditorWrapper = styled.div`
  padding-left: ${Constants.TreeContentIconIndent};
  padding-top: ${Constants.defaultLinePadding};
  padding-bottom: ${Constants.defaultLinePadding};
`;
const HiddenDiv = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  opacity: 0;
  height: 0px;
  pointer-events: none;
  height: 0px;
`;

export default class HtmlBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    className: PropTypes.string,
  };
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
    if (!node.text) return;

    const { editing } = GrayOut;
    if (editing) return;
    if (!Constants.isWidget) return;
    if (UserAccount.isLoggedIn) return;
    const parser = new DOMParser();
    const dom = parser.parseFromString(changeRelativeLinksToAbsoluteForNativeApp(node.text), 'text/html');
    const scripts = [...dom.getElementsByTagName('script')];
    this.__scripts = [];
    scripts.forEach((script) => {
      const _script = document.createElement('script');
      if (script.src) _script.src = script.src;
      else if (script.innerHTML) _script.innerHTML = script.innerHTML;
      else return;
      document.head.appendChild(_script);
      this.__scripts.push(_script);
    });
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
    this.removeChildren();
  }
  checkFocus = () => {
    const { node } = this.props;
    if (this.mountRef && GrayOut.isActive(node)) {
      this.mountRef.focus({ preventScroll: true });
    }
  }
  removeChildren = () => {
    if (this.rootNodes) {
      this.rootNodes.forEach((root) => {
        root.unmount();
      });
      this.rootNodes = null;
    }
    if (this.mountStyles) {
      this.mountStyles.forEach((node) => {
        document.head.removeChild(node);
      });
      this.mountStyles = null;
    }
  }
  onRef = (e, html) => {
    if (!e) return;
    if (e === this.mountRef && html === this.__lastHtml) return;
    this.removeChildren();
    const { node } = this.props;
    const { children } = node;
    this.__lastHtml = html;
    this.mountRef = e;
    const { mobile } = WindowSize;
    if (mobile) {
      const mobileDiv = this.mountRef.previousSibling;
      mobileDiv.textContent = html;
      const { width } = mobileDiv.getBoundingClientRect();
      this.mountRef.style.minWidth = `${Math.min(width, 180)}px`;
    }
    const parser = new DOMParser();
    const dom = parser.parseFromString(changeRelativeLinksToAbsoluteForNativeApp(html), 'text/html');
    const styles = [...dom.getElementsByTagName('style'), ...dom.getElementsByTagName('link')];
    styles.forEach((s) => {
      html = html.replace(s.outerHTML, '');
    });
    const onScriptLoaded = (e) => {
      const target = e && e.target;
      if (target) {
        const styleIndex = styles.indexOf(target);
        if (styleIndex < 0) return;
        styles.splice(styleIndex, 1);
      }
      if (styles.length > 0) return;
      this.mountRef.innerHTML = changeRelativeLinksToAbsoluteForNativeApp(purifyhtml(html));
      this.rootNodes = [];
      const mounts = this.mountRef.querySelectorAll("[id^='htmlbox__mount--']");
      mounts.forEach((_div) => {
        const _var = _div.id.split('htmlbox__mount--')[1];
        const child = children.find((c) => c.key === _var);
        if (!child) return;
        const mountNode = document.getElementById(`htmlbox__mount--${_var}`);
        if (!mountNode) return;
        mountNode.style.marginLeft = `-${Constants.TreeContentIconIndent}`;
        const rootNode = ReactDOM.createRoot(mountNode);
        this.rootNodes.push(rootNode);
        rootNode.render(<CreateChild node={child} />);
      });
      this.checkFocus();
    }
    this.mountStyles = [];
    if (styles.length === 0) return onScriptLoaded();
    return styles.forEach((style) => {
      this.mountStyles.push(style);
      document.head.appendChild(style);
      style.onload = onScriptLoaded;
      style.onerror = onScriptLoaded;
    });
  }
  render() {
    const { node, className } = this.props;
    const { mobile } = WindowSize;
    const { textWithMatchedVariables, isNodeHidden, isTextHidden } = node;
    const style = (isNodeHidden || isTextHidden) ? {
      height: 0,
      padding: 0,
      overflow: 'hidden',
    } : {};
    return (
      <NonEditorWrapper style={style}>
        {mobile && <HiddenDiv />}
        <div className={className} tabIndex={-1} id={textWithMatchedVariables} ref={(e) => this.onRef(e, textWithMatchedVariables)}></div>
      </NonEditorWrapper>
    );
  }
}

