// import React from 'react';
// import styled from 'styled-components';

import SpreadsheetCache from '../../services/cache/SpreadsheetCache';
import ObjectSettings from './index';

export default class SpreadsheetSettings extends ObjectSettings {
  leave = (spreadsheet) => {
    SpreadsheetCache.update(spreadsheet, 'Remove');
    this.setState({ confirmLeave: false });
  }
  lock = (spreadsheet) => {
    SpreadsheetCache.update(spreadsheet, 'Locked');
    this.setState({ confirmLock: false });
  }
  unlock = (spreadsheet) => {
    SpreadsheetCache.update(spreadsheet, 'Live');
    this.setState({ confirmUnlock: false });
  }
  restore = (spreadsheet) => {
    SpreadsheetCache.update(spreadsheet, 'Live');
    this.setState({ confirmRestore: false });
  }
  delete = (spreadsheet) => {
    SpreadsheetCache.delete(spreadsheet);
    this.setState({ confirmDelete: false });
  }
  duplicate = (spreadsheet) => {
    SpreadsheetCache.duplicate(spreadsheet);
    this.setState({ confirmDuplicate: false });
  }
}
