import React from 'react';
// import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import ScriptLoader from '../../services/ScriptLoader';


export default class WordCloud extends React.PureComponent {
  static propTypes = {
    words: PropTypes.object,
  }
  componentDidMount() {
    ScriptLoader.load('https://logictrystatic.s3.amazonaws.com/js/d3.min.js');
    ScriptLoader.onStateUpdate(this.loadd3Cloud);
  }
  componentWillUnmount() {
    ScriptLoader.offStateUpdate(this.loadd3Cloud);
  }
  loadd3Cloud = () => {
    if (!ScriptLoader.isLoaded('https://logictrystatic.s3.amazonaws.com/js/d3.min.js')) return;
    if (!ScriptLoader.isLoaded('https://logictrystatic.s3.amazonaws.com/js/cloud.min.js')) ScriptLoader.load('https://logictrystatic.s3.amazonaws.com/js/cloud.min.js');
    this.renderWordcloud();
  }
  renderWordcloud = () => {
    if (!ScriptLoader.isLoaded('https://logictrystatic.s3.amazonaws.com/js/d3.min.js')) return;
    if (!ScriptLoader.isLoaded('https://logictrystatic.s3.amazonaws.com/js/cloud.min.js')) return;
    const draw = (words) => {
      window.d3.select("#logictry_wordcloud_mount_ref").append("svg")
        .attr("width", layout.size()[0])
        .attr("height", layout.size()[1])
        .append("g")
        .attr("transform", `translate(${layout.size()[0] / 2},${layout.size()[1] / 2})`)
        .selectAll("text")
        .data(words)
        .enter().append("text")
        .style("font-size", (d) => `${d.size}px`)
        .style("font-family", "Impact")
        .attr("text-anchor", "middle")
        .attr("transform", (d) => (`translate(${[d.x, d.y]})rotate(${d.rotate})`))
        .text((d) => d.text);
    }
    const { words } = this.props;
    const layout = window.d3.layout.cloud()
      .size([500, 500])
      .words(Object.keys(words).map((text) => ({ text, size: 5 * words[text] })))
      .padding(5)
      // eslint-disable-next-line no-bitwise
      .rotate(() => ~~(Math.random() * 2) * 90)
      .font("Impact")
      .fontSize((d) => d.size)
      .on("end", draw);

    layout.start();
  }
  render() {
    return (
      <div id="logictry_wordcloud_mount_ref" />
    )
  }
}