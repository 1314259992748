import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Popup from '../Popup/index';
import Button from '../Button/index';

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid gray;
  padding: 2rem 3rem;
  max-width: 500px;
  word-break: break-all;

  > div {
    margin-top: 2rem;
    text-align: center;
    > p:first-child {
      text-decoration: underline;
    }
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;


export default class LinksPopup extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    domainObject: PropTypes.object,
  }
  render() {
    const { onClose, domainObject } = this.props;
    return (
      <div>
        <Popup
          onClose={onClose}
          allowBackgroundClose
        >
          <Wrapper>
            <h1>Link Settings</h1>
            <div>
              <p>The short link:</p>
              <p>{`${window.location.origin}/links/${domainObject._id}`}</p>
            </div>
            <div>
              <p>Redirects to:</p>
              <p>{`${domainObject.link}`}</p>
            </div>
            <ButtonsWrapper>
              <Button onClick={onClose} color="primary" autoFocus>
                OK
              </Button>
            </ButtonsWrapper>
          </Wrapper>
        </Popup>
      </div>
    );
  }
}
