/* eslint-disable */
export default function ScrollIt(destination, scrollableDiv, duration = 200, easing = 'linear', callback, horizontal) {
  if (!scrollableDiv) return;

  const easings = {
    linear(t) {
      return t;
    },
    easeInQuad(t) {
      return t * t;
    },
    easeOutQuad(t) {
      return t * (2 - t);
    },
    easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    easeInCubic(t) {
      return t * t * t;
    },
    easeOutCubic(t) {
      return (--t) * t * t + 1;
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    easeInQuart(t) {
      return t * t * t * t;
    },
    easeOutQuart(t) {
      return 1 - (--t) * t * t * t;
    },
    easeInOutQuart(t) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
    },
    easeInQuint(t) {
      return t * t * t * t * t;
    },
    easeOutQuint(t) {
      return 1 + (--t) * t * t * t * t;
    },
    easeInOutQuint(t) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
    }
  };

  const start = scrollableDiv ? (horizontal ? scrollableDiv.scrollLeft : scrollableDiv.scrollTop) : (horizontal ? window.pageXOffset : window.pageYOffset);
  const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

  let destinationOffsetToScroll = 0;
  if (destination && typeof destination === 'number') {
    destinationOffsetToScroll = destination;
  } else if (destination) {
    const currentY = (horizontal ? destination.getBoundingClientRect().x : destination.getBoundingClientRect().y);
    const { x, y, width, height } = scrollableDiv.getBoundingClientRect();
    destinationOffsetToScroll = (horizontal ? scrollableDiv.scrollLeft : scrollableDiv.scrollTop) + (currentY - ((horizontal ? x : y) + (horizontal ? width: height) / 2)) + 60;
  }

  if ('requestAnimationFrame' in window === false) {
    window.scroll(0, destinationOffsetToScroll);
    if (callback) {
      callback();
    }
    return;
  }

  function scroll() {
    const now = 'now' in window.performance ? performance.now() : new Date().getTime();
    const time = Math.min(1, ((now - startTime) / duration));
    const timeFunction = easings[easing](time);
    if (scrollableDiv) {
      if (horizontal) scrollableDiv.scrollLeft = Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start);
      else scrollableDiv.scrollTop = Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start);
    }
    else window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

    if (time === 1) {
      if (callback) {
        callback();
      }
      return;
    }

    requestAnimationFrame(scroll);
  }

  scroll();
}
