/* eslint-disable no-param-reassign */
/**
 * This file handles V1 to V2 Tree schema change
 */
let splitPaneNodes = [];
let paneNodes = [];

export default function TreeV0toV1(_type, _children) {
  const root = _children.find((_child) => _child.attributes[0] === 'Root');
  root.attributes.push('AllowInlineNotes');
  // Rename all nodes
  __recursivelyRenameNodes(root);
  __initializeNodes();
  if (_type === 'Template') {
    // If a template, apply restructuring otherwise projects are solved with matching algorithm
    __restructureSplitPanes(null, root);
    __initializeNodes();
  } else if (_type === 'Project') {
    // Convert search to search with searchText as child
    __restructureSearchAndSearchText(root);
  }
}
function __initializeNodes() {
  splitPaneNodes = [];
  paneNodes = [];
}
function __recursivelyRenameNodes(node) {
  // Calendar -> CalendarLogic
  // CalendarView -> Calendar
  // Files -> FileGroup
  // Filters -> FilterLogic
  // Item -> ChecklistItem
  // Input -> Search
  // Search -> SearchLogic
  // Subtree -> SubTree
  let text = node.attributes[0];
  if (text === 'Calendar') text = 'CalendarLogic';
  else if (text === 'CalendarView') text = 'Calendar';
  else if (text === 'Files') text = 'FileGroup';
  else if (text === 'Filters') text = 'FilterLogic';
  else if (text === 'Item') text = 'ChecklistItem';
  else if (text === 'Input') text = 'Search';
  else if (text === 'Search') text = 'SearchLogic';
  else if (text === 'Subtree') text = 'SubTree';
  node.attributes[0] = text;
  node.children.forEach(__recursivelyRenameNodes);
}
function __restructureSplitPanes(parent, node) {
  // SplitPane structure changed
  // Find SplitPane and move all preceding nodes and following nodes underneath a PANE and move the SplitPane as parent
  // Keep the SplitPane text on the new Pane and if Pane is empty, add text Pane1, Pane2, etc
  // If LogicBlocks have no name, add name SearchLogic1, SearchLogic2, etc

  // Found a splitPane
  if (node.attributes[0] === 'SplitPane' && node.children.some((c) => c.attributes[0] !== 'Pane')) {
    if (!(node.attributes.includes('Vertical') || node.attributes.includes('Horizontal'))) node.attributes.push('Vertical');
    splitPaneNodes.push(node);
    const splitPaneIndex = parent.children.findIndex((_child) => _child.attributes[0] === 'SplitPane');
    if (splitPaneNodes.length === 1) {
      // First splitPane should create the first SplitPane structure
      paneNodes.push({ text: '', attributes: node.attributes.includes('Vertical') ? ['Pane'] : ['Pane', 'ContentHeight'], children: parent.children.splice(0, splitPaneIndex) });
      paneNodes.push({ text: '', attributes: ['Pane'], children: node.children });
      node.children = [...paneNodes];
    } else if (splitPaneNodes.length === 2) {
      // All following splitPanes should find the preceding Pane to convert
      parent.children.splice(splitPaneIndex, 1);
      const lastPaneNode = paneNodes[paneNodes.length - 1];
      paneNodes.push({ text: '', attributes: ['Pane'], children: lastPaneNode.children });

      // Find new Parent nodes
      let newChildren;
      let previousChild;
      const parentNodes = __recursivelyFindNewParentNodes(parent);
      if (parentNodes && parentNodes.length > 2 && parentNodes[parentNodes.length - 1].attributes[0] === 'SplitPane') {
        parentNodes.forEach((_node, i) => {
          if (i > 0 && _node.attributes[0] !== 'SplitPane') {
            const { text } = _node;
            const newAttributes = [..._node.attributes];
            if ((newAttributes[0] === 'SearchLogic' || newAttributes[0] === 'FilterLogic') && !(newAttributes.includes('Exclude') || newAttributes.includes('Include'))) {
              newAttributes.push('Exclude');
            }
            const newChild = { text, attributes: newAttributes, children: [] };
            if (previousChild) previousChild.children = [newChild];
            else newChildren = [newChild];
            previousChild = newChild;
          }
        });
        previousChild.children = node.children;
      } else {
        newChildren = node.children;
      }

      paneNodes.push({ text: '', attributes: ['Pane'], children: newChildren });
      node.children = paneNodes.slice(-2);
      lastPaneNode.children = [node];
    }
  } else {
    node.children.forEach((child) => __restructureSplitPanes(node, child));
  }
}
function __recursivelyFindNewParentNodes(node) {
  if (node.attributes[0] === 'SplitPane') return [node];
  const parentChain = node.children.map(__recursivelyFindNewParentNodes).filter((t) => t);
  if (parentChain.length === 0) return null;
  return [node, ...parentChain[0]];
}
function __restructureSearchAndSearchText(node) {
  if (node.attributes[0] === 'Search') {
    const { text, votes, owner } = node;
    node.text = '';
    node.votes = null;
    node.owner = null;
    node.children = [{ text, attributes: ['SearchText'], children: [], votes, owner }];
    return;
  }
  node.children.forEach(__restructureSearchAndSearchText);
}
