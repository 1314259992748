import Service from '../Service';
import UserAccount, { LOGGED_IN, LOGGED_OUT } from '../UserAccount';
import Server from '../server';
import Spreadsheet from '../../models/spreadsheet';

class RestfulApiCache extends Service {
  initialize() {
    /**
     * Object keys are the id
     * Object values are the json
     */
    this.objectJson = {};
    /**
     * Object keys are the id
     * Object values are the objects
     */
    this.objects = {};
    /**
     * Object keys are the id
     * Object values are the json
     */
    this.objectSchema = {};
  }

  createObject = (_results) => {
    const results = JSON.parse(JSON.stringify(_results));
    return new Spreadsheet(results.editor, results.owner, results.users, results.teams, UserAccount.account._id, results.sheets, results._id, results.title, results.description, results.createdTime, results.updateTime, results.state, results.sheetDiffs);
  }
  isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  get(url, schema, delimiter) {
    const isLogictryUrl = url.startsWith('/v1/collections/') && url.includes('/documents');
    const logictryUrl = isLogictryUrl && url.split('/v1')[1];
    const urlRequest = (isLogictryUrl && logictryUrl) ? logictryUrl : `/restfulapi?url=${url}`;

    // Dont run if in transition
    if (![LOGGED_IN, LOGGED_OUT].includes(UserAccount.state)) return null;
    if (!url || !schema) return null;
    if (!isLogictryUrl && !this.isValidHttpUrl(url)) return { error: 'InvalidURL' };
    /**
     * Get Object
     */
    const schemaString = JSON.stringify(schema);
    if (this.objects[url] && ((this.objects[url].error && this.objects[url].error !== 'InvalidDataSchema') || this.objectSchema[url] === schemaString)) {
      return this.objects[url];
    }

    if (this.objectJson[url]) {
      this.objectSchema[url] = schemaString;
      this.__transformRawToSpreadsheet(url, schema, delimiter);
      return this.objects[url];
    }
    
    Server.__getRequest(urlRequest).then(async (results) => {
      if (!results) return;
      if (results.error) {
        this.objects[url] = results;
      } else {
        // eslint-disable-next-line no-param-reassign
        this.objectJson[url] = results;
        this.objectSchema[url] = schemaString;
        this.__transformRawToSpreadsheet(url, schema, delimiter);
      }
      this.emitStateUpdate();
    });
    return null;
  }
  __transformRawToSpreadsheet(url, schema, delimiter) {
    const results = this.objectJson[url];

    // Transform data
    const sheets = transformData(results, schema, delimiter);
    if (sheets) {
      const transformedResults = {
        _id: url,
        owner: null,
        users: [],
        teams: [],
        sheets,
      };
      const newObject = this.createObject(transformedResults);
      this.objects[url] = newObject;
    } else {
      this.objects[url] = { error: 'InvalidDataSchema' };
    }
  }
}
const singleton = new RestfulApiCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;

function transformData(rawData, schema, delimiter) {

  if (!schema) return null;
  const { array } = schema;
  if (!array || !array.schema || !Object.prototype.hasOwnProperty.call(array, 'field')) return null;
  const sheets = [];
  const titles = array.schema.map(({ title }) => ({ value: title }));
  sheets.push(titles);
  const rawArray = array.field ? array.field.split('.').reduce((o,i)=> o && o[i] || '', rawData) : rawData;
  if (!rawArray || !rawArray.forEach) return null;

  rawArray.forEach((row) => {
    const transformedRow = array.schema.map(({ field }) => {
      const rawValue = field && field.split('.').reduce((o,i)=> o && o[i] || '', row) || '';
      let value = '';
      if (typeof rawValue === 'string' || typeof rawValue === 'number') {
        value = rawValue.toString() || '';
      } else if (Array.isArray(rawValue)) {
        value = rawValue.map((val) => val.toString()).join(delimiter);
      } else if (typeof rawValue === 'object') {
        value = '';
      }
      return ({ value });
    });
    sheets.push(transformedRow);
  });
  return [sheets];
}
