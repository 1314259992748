import Service from './Service';
import Server from './server';
import UserAccount from './UserAccount';
import { XML_ATTR, POST_ATTR } from '../models/nodeattributes';
import TreeCache from './cache/TreeCache';

class CreateProject extends Service {
  initialize() {
    this.changeableImagesQueued = new Set();
  }
  queueChangeableImage(changeableImage) {
    this.changeableImagesQueued.add(changeableImage);
  }
  unqueueChangeableImage(changeableImage) {
    this.changeableImagesQueued.delete(changeableImage);
  }
  get numberOfQueuedUploads() {
    return this.changeableImagesQueued.size;
  }
  registerAndCreateProject(tree, email, team, cb) {
    if (this.isRegisteringAndCreatingProject) return;
    this.isRegisteringAndCreatingProject = true;
    this.emitStateUpdate();
    UserAccount.registerAccount({ email }, 'LoggedOutSave').then((r) => {
      if (!r || !r._id) {
        this.emitStateUpdate();
        this.isRegisteringAndCreatingProject = false;
        return cb && cb(false);
      }
      // eslint-disable-next-line no-param-reassign
      if (this.changeableImagesQueued.size > 0) {
        this.emitStateUpdate();
        this.changeableImagesQueued.forEach(async (changeableImage) => {
          await changeableImage.resumeUpload(r._id);
          this.changeableImagesQueued.delete(changeableImage);
          if (this.changeableImagesQueued.size > 0) return this.emitStateUpdate();
          this.__createProjectAfterRegister(tree, team, r, cb);
        });
      } else {
        this.__createProjectAfterRegister(tree, team, r, cb);
      }
    });
  }
  createProject(tree, team) {
    const steps = tree.getFormattedActionSteps();
    TreeCache.createTree(tree, null, team, steps);
  }
  __createProjectAfterRegister(tree, team, r, cb) {
    const steps = tree.getFormattedActionSteps();
    tree.userLoggedIn = r._id;
    TreeCache.createTree(tree, null, team, steps);
    this.isRegisteringAndCreatingProject = false;
    this.emitStateUpdate();
    return cb && cb(true);
  }
}

function RunActions(tree) {
  const steps = tree.getFormattedActionSteps();
  steps.forEach(RunStep);
}
function RunStep(step) {
  if (step && step.type === POST_ATTR && step.format === XML_ATTR) {
    const { url, body } = step;
    Server.__postRequest('/restfulapi', {
      url,
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/xml'
      }
    });
  }
}

const singleton = new CreateProject();
singleton.initialize();
export default singleton;

