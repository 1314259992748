/* eslint-disable */
import Constants from '../../../logictry_config/constants';
import purifyhtml from '../../../logictry_purifyhtml';

// The three different states
const HTML = 'HTML';
const SOURCE = 'SOURCE';
const BOTH = 'BOTH';

const WysiwygHtml = `<div style="background-color: white;width: 100%;height: 100%;" class="logictryWysiwygWidget">
  <style type="text/css">
    .logictryWysiwygFlex {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgb(193, 193, 193);
    }
    .logictryWysiwygTextBox {
      -webkit-user-select: text;
      user-select: text;
    }
    .logictryWysiwygTextBox, .logictryWysiwygSourceText {
      width: 100%;
      height: 100%;
      padding: 12px;
      overflow: scroll;
      background-color: white;
      min-height: 200px;
      display: inline-block;
    }
    .logictryWysiwygToolbar {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .logictryWysiwygFlex i, .logictryWysiwygFlex svg {
      padding: 4px;
      font-size: 12px;
    }
    .logictryWysiwygFlex i, .logictryWysiwygFlex img, .logictryWysiwygFlex select {
      cursor: pointer;
    }
    .logictryWysiwygIcon {
      position: relative;
      height: 32px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .logictryWysiwygIcon input {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 24px;
      height: 24px;
      opacity: 0;
    }
    .logictryWysiwygDivider {
      width: 1px;
      height: 32px;
      background-color: rgb(193, 193, 193);
    }
    .logictryWysiwygContent {
      position: relative;
      height: calc(100% - 33px);
    }
    .logictryWysiwygSourceText {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
    }
  </style>
  <div style="width: 100%;height: 100%;">
    <div class="logictryWysiwygFlex" onmousedown="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event);">
      <div class="logictryWysiwygIcon" onclick="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygToggleDocMode();">
        <svg class="logictryWysiwygCodeSVG" width="32" height="32">
          <defs>
            <linearGradient id="${HTML}" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style="stop-color: black;" />
              <stop offset="50%" style="stop-color: black;" />
              <stop offset="51%" style="stop-color: #BBB;" />
              <stop offset="100%" style="stop-color: #BBB;" />
            </linearGradient>
            <linearGradient id="${SOURCE}" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style="stop-color: #BBB;" />
              <stop offset="49%" style="stop-color: #BBB;" />
              <stop offset="50%" style="stop-color: black;" />
              <stop offset="100%" style="stop-color: black;" />
            </linearGradient>
            <linearGradient id="${BOTH}" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style="stop-color: black;" />
              <stop offset="50%" style="stop-color: black;" />
              <stop offset="100%" style="stop-color: black;" />
            </linearGradient>
          </defs>
          <g>
            <path d="M9.8 15.7c.3.3.3.8 0 1-.3.4-.9.4-1.2 0l-4.4-4.1a.8.8 0 010-1.2l4.4-4.2c.3-.3.9-.3 1.2 0 .3.3.3.8 0 1.1L6 12l3.8 3.7zM14.2 15.7c-.3.3-.3.8 0 1 .4.4.9.4 1.2 0l4.4-4.1c.3-.3.3-.9 0-1.2l-4.4-4.2a.8.8 0 00-1.2 0c-.3.3-.3.8 0 1.1L18 12l-3.8 3.7z"></path>
          </g>
        </svg>
      </div>
      <div class="logictryWysiwygDivider"></div>
      <div class="logictryWysiwygToolbar">
        <div class="logictryWysiwygIcon" onmousedown="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'bold');">
          <svg width="32" height="32"><path d="M7.8 19c-.3 0-.5 0-.6-.2l-.2-.5V5.7c0-.2 0-.4.2-.5l.6-.2h5c1.5 0 2.7.3 3.5 1 .7.6 1.1 1.4 1.1 2.5a3 3 0 01-.6 1.9c-.4.6-1 1-1.6 1.2.4.1.9.3 1.3.6s.8.7 1 1.2c.4.4.5 1 .5 1.6 0 1.3-.4 2.3-1.3 3-.8.7-2.1 1-3.8 1H7.8zm5-8.3c.6 0 1.2-.1 1.6-.5.4-.3.6-.7.6-1.3 0-1.1-.8-1.7-2.3-1.7H9.3v3.5h3.4zm.5 6c.7 0 1.3-.1 1.7-.4.4-.4.6-.9.6-1.5s-.2-1-.7-1.4c-.4-.3-1-.4-2-.4H9.4v3.8h4z" fill-rule="evenodd"></path></svg>
        </div>
        <div class="logictryWysiwygIcon" onmousedown="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'italic');">
          <svg width="32" height="32"><path d="M16.7 4.7l-.1.9h-.3c-.6 0-1 0-1.4.3-.3.3-.4.6-.5 1.1l-2.1 9.8v.6c0 .5.4.8 1.4.8h.2l-.2.8H8l.2-.8h.2c1.1 0 1.8-.5 2-1.5l2-9.8.1-.5c0-.6-.4-.8-1.4-.8h-.3l.2-.9h5.8z" fill-rule="evenodd"></path></svg>
        </div>
        <div class="logictryWysiwygIcon" onmousedown="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'underline');">
          <svg width="32" height="32"><path d="M16 5c.6 0 1 .4 1 1v5.5a4 4 0 01-.4 1.8l-1 1.4a5.3 5.3 0 01-5.5 1 5 5 0 01-1.6-1c-.5-.4-.8-.9-1.1-1.4a4 4 0 01-.4-1.8V6c0-.6.4-1 1-1s1 .4 1 1v5.5c0 .3 0 .6.2 1l.6.7a3.3 3.3 0 002.2.8 3.4 3.4 0 002.2-.8c.3-.2.4-.5.6-.8l.2-.9V6c0-.6.4-1 1-1zM8 17h8c.6 0 1 .4 1 1s-.4 1-1 1H8a1 1 0 010-2z" fill-rule="evenodd"></path></svg>
        </div>
        <div class="logictryWysiwygIcon" onmousedown="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'strikethrough');">
          <svg width="32" height="32" focusable="false"><g fill-rule="evenodd"><path d="M15.6 8.5c-.5-.7-1-1.1-1.3-1.3-.6-.4-1.3-.6-2-.6-2.7 0-2.8 1.7-2.8 2.1 0 1.6 1.8 2 3.2 2.3 4.4.9 4.6 2.8 4.6 3.9 0 1.4-.7 4.1-5 4.1A6.2 6.2 0 0 1 7 16.4l1.5-1.1c.4.6 1.6 2 3.7 2 1.6 0 2.5-.4 3-1.2.4-.8.3-2-.8-2.6-.7-.4-1.6-.7-2.9-1-1-.2-3.9-.8-3.9-3.6C7.6 6 10.3 5 12.4 5c2.9 0 4.2 1.6 4.7 2.4l-1.5 1.1Z"></path><path d="M5 11h14a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" fill-rule="nonzero"></path></g></svg>
        </div>
        <div class="logictryWysiwygDivider"></div>
        <div class="logictryWysiwygIcon">
          <svg width="32" height="32"><g fill-rule="evenodd"><path d="M3 18h18v3H3z"></path><path d="M8.7 16h-.8a.5.5 0 01-.5-.6l2.7-9c.1-.3.3-.4.5-.4h2.8c.2 0 .4.1.5.4l2.7 9a.5.5 0 01-.5.6h-.8a.5.5 0 01-.4-.4l-.7-2.2c0-.3-.3-.4-.5-.4h-3.4c-.2 0-.4.1-.5.4l-.7 2.2c0 .3-.2.4-.4.4zm2.6-7.6l-.6 2a.5.5 0 00.5.6h1.6a.5.5 0 00.5-.6l-.6-2c0-.3-.3-.4-.5-.4h-.4c-.2 0-.4.1-.5.4z"></path></g></svg>
          <input type="color" class="wysiwygWidgetForegroundColor" value="#ff0000">
        </div>
        <div class="logictryWysiwygIcon">
          <svg width="32" height="32"><g fill-rule="evenodd"><path d="M3 18h18v3H3z"></path><path fill-rule="nonzero" d="M7.7 16.7H3l3.3-3.3-.7-.8L10.2 8l4 4.1-4 4.2c-.2.2-.6.2-.8 0l-.6-.7-1.1 1.1zm5-7.5L11 7.4l3-2.9a2 2 0 012.6 0L18 6c.7.7.7 2 0 2.7l-2.9 2.9-1.8-1.8-.5-.6"></path></g></svg>
          <input type="color" class="wysiwygWidgetBackgroundColor" value="#ff0000">
        </div>
        <div class="logictryWysiwygDivider"></div>
        <div class="logictryWysiwygIcon" onmousedown="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'justifyleft');">
          <svg width="32" height="32"><path d="M5 5h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 110-2zm0 4h8c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 110-2zm0 8h8c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 010-2zm0-4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 010-2z" fill-rule="evenodd"></path></svg>
        </div>
        <div class="logictryWysiwygIcon" onmousedown="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'justifycenter');">
          <svg width="32" height="32"><path d="M5 5h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 110-2zm3 4h8c.6 0 1 .4 1 1s-.4 1-1 1H8a1 1 0 110-2zm0 8h8c.6 0 1 .4 1 1s-.4 1-1 1H8a1 1 0 010-2zm-3-4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 010-2z" fill-rule="evenodd"></path></svg>
        </div>
        <div class="logictryWysiwygIcon" onmousedown="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'justifyright');">
          <svg width="32" height="32"><path d="M5 5h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 110-2zm6 4h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm0 8h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm-6-4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 010-2z" fill-rule="evenodd"></path></svg>
        </div>
        <div class="logictryWysiwygDivider"></div>
        <div class="logictryWysiwygIcon" onmousedown="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'outdent');">
          <svg width="32" height="32"><path d="M7 5h12c.6 0 1 .4 1 1s-.4 1-1 1H7a1 1 0 110-2zm5 4h7c.6 0 1 .4 1 1s-.4 1-1 1h-7a1 1 0 010-2zm0 4h7c.6 0 1 .4 1 1s-.4 1-1 1h-7a1 1 0 010-2zm-5 4h12a1 1 0 010 2H7a1 1 0 010-2zm1.6-3.8a1 1 0 01-1.2 1.6l-3-2a1 1 0 010-1.6l3-2a1 1 0 011.2 1.6L6.8 12l1.8 1.2z" fill-rule="evenodd"></path></svg>
        </div>
        <div class="logictryWysiwygIcon" onmousedown="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'indent');">
          <svg width="32" height="32"><path d="M7 5h12c.6 0 1 .4 1 1s-.4 1-1 1H7a1 1 0 110-2zm5 4h7c.6 0 1 .4 1 1s-.4 1-1 1h-7a1 1 0 010-2zm0 4h7c.6 0 1 .4 1 1s-.4 1-1 1h-7a1 1 0 010-2zm-5 4h12a1 1 0 010 2H7a1 1 0 010-2zm-2.6-3.8L6.2 12l-1.8-1.2a1 1 0 011.2-1.6l3 2a1 1 0 010 1.6l-3 2a1 1 0 11-1.2-1.6z" fill-rule="evenodd"></path></svg>
        </div>
        <div class="logictryWysiwygDivider"></div>
        <div class="logictryWysiwygIcon" onmousedown="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'insertorderedlist');">
          <svg width="32" height="32"><path d="M10 17h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm0-6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm0-6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 110-2zM6 4v3.5c0 .3-.2.5-.5.5a.5.5 0 01-.5-.5V5h-.5a.5.5 0 010-1H6zm-1 8.8l.2.2h1.3c.3 0 .5.2.5.5s-.2.5-.5.5H4.9a1 1 0 01-.9-1V13c0-.4.3-.8.6-1l1.2-.4.2-.3a.2.2 0 00-.2-.2H4.5a.5.5 0 01-.5-.5c0-.3.2-.5.5-.5h1.6c.5 0 .9.4.9 1v.1c0 .4-.3.8-.6 1l-1.2.4-.2.3zM7 17v2c0 .6-.4 1-1 1H4.5a.5.5 0 010-1h1.2c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.3H4.4a.4.4 0 110-.8h1.3c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.3H4.5a.5.5 0 110-1H6c.6 0 1 .4 1 1z" fill-rule="evenodd"></path></svg>
        </div>
        <div class="logictryWysiwygIcon" onmousedown="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'insertunorderedlist');">
          <svg width="32" height="32"><path d="M11 5h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm0 6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm0 6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zM4.5 6c0-.4.1-.8.4-1 .3-.4.7-.5 1.1-.5.4 0 .8.1 1 .4.4.3.5.7.5 1.1 0 .4-.1.8-.4 1-.3.4-.7.5-1.1.5-.4 0-.8-.1-1-.4-.4-.3-.5-.7-.5-1.1zm0 6c0-.4.1-.8.4-1 .3-.4.7-.5 1.1-.5.4 0 .8.1 1 .4.4.3.5.7.5 1.1 0 .4-.1.8-.4 1-.3.4-.7.5-1.1.5-.4 0-.8-.1-1-.4-.4-.3-.5-.7-.5-1.1zm0 6c0-.4.1-.8.4-1 .3-.4.7-.5 1.1-.5.4 0 .8.1 1 .4.4.3.5.7.5 1.1 0 .4-.1.8-.4 1-.3.4-.7.5-1.1.5-.4 0-.8-.1-1-.4-.4-.3-.5-.7-.5-1.1z" fill-rule="evenodd"></path></svg>
        </div>
        <div class="logictryWysiwygDivider"></div>
        <div class="logictryWysiwygIcon" onmousedown="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'createlink');">
          <svg width="32" height="32"><path d="M6.2 12.3a1 1 0 011.4 1.4l-2.1 2a2 2 0 102.7 2.8l4.8-4.8a1 1 0 000-1.4 1 1 0 111.4-1.3 2.9 2.9 0 010 4L9.6 20a3.9 3.9 0 01-5.5-5.5l2-2zm11.6-.6a1 1 0 01-1.4-1.4l2-2a2 2 0 10-2.6-2.8L11 10.3a1 1 0 000 1.4A1 1 0 119.6 13a2.9 2.9 0 010-4L14.4 4a3.9 3.9 0 015.5 5.5l-2 2z" fill-rule="nonzero"></path></svg>
        </div>
        <div class="logictryWysiwygIcon" onmousedown="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'createimage');">
          <svg width="32" height="32"><path d="M5 15.7l3.3-3.2c.3-.3.7-.3 1 0L12 15l4.1-4c.3-.4.8-.4 1 0l2 1.9V5H5v10.7zM5 18V19h3l2.8-2.9-2-2L5 17.9zm14-3l-2.5-2.4-6.4 6.5H19v-4zM4 3h16c.6 0 1 .4 1 1v16c0 .6-.4 1-1 1H4a1 1 0 01-1-1V4c0-.6.4-1 1-1zm6 8a2 2 0 100-4 2 2 0 000 4z" fill-rule="nonzero"></path></svg>
        </div>
        <div class="logictryWysiwygIcon" onmousedown="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'createtable');">
          <svg width="32" height="32" focusable="false"><path fill-rule="nonzero" d="M19 4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h14ZM5 14v4h6v-4H5Zm14 0h-6v4h6v-4Zm0-6h-6v4h6V8ZM5 12h6V8H5v4Z"></path></svg>
        </div>
        <div class="logictryWysiwygDivider"></div>
        <select class="logictryTextFormatter" onmousedown="event.stopPropagation();" onchange="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, this[this.selectedIndex].value ? 'formatblock' : 'removeFormat',this[this.selectedIndex].value);this.selectedIndex=0;">
          <option selected value="">No Format</option>
          <option value="h1">Heading 1</option>
          <option value="h2">Heading 2</option>
          <option value="h3">Heading 3</option>
          <option value="p">Paragraph</option>
        </select>
        <div class="logictryWysiwygDivider"></div>
        <div class="logictryWysiwygIcon" onmousedown="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'record');">
          <svg class="logictryWysiwygRecordSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" > <!-- Outer Circle --> <circle cx="12" cy="12" r="10" stroke="black" stroke-width="2" fill="none" ></circle> <!-- Inner Circle --> <circle cx="12" cy="12" stroke="black" r="6" fill="none" ></circle> </svg>
        </div>
        <!-- <select onchange="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'fontname',this[this.selectedIndex].value);this.selectedIndex=0;">
          <option class="heading" selected>- font -</option>
          <option>Arial</option>
          <option>Arial Black</option>
          <option>Courier New</option>
          <option>Times New Roman</option>
        </select>
        <select onchange="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'fontsize',this[this.selectedIndex].value);this.selectedIndex=0;">
          <option class="heading" selected>- size -</option>
          <option value="1">Very small</option>
          <option value="2">A bit small</option>
          <option value="3">Normal</option>
          <option value="4">Medium-large</option>
          <option value="5">Big</option>
          <option value="6">Very big</option>
          <option value="7">Maximum</option>
        </select>
        <select onchange="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'forecolor',this[this.selectedIndex].value);this.selectedIndex=0;">
          <option class="heading" selected>- color -</option>
          <option value="red">Red</option>
          <option value="blue">Blue</option>
          <option value="green">Green</option>
          <option value="black">Black</option>
        </select>
        <select onchange="this.closest('div[class=logictryWysiwygWidget]').logictryWysiwygFormatDoc(event, 'backcolor',this[this.selectedIndex].value);this.selectedIndex=0;">
          <option class="heading" selected>- background -</option>
          <option value="red">Red</option>
          <option value="green">Green</option>
          <option value="black">Black</option>
        </select> -->
      </div>
    </div>
    <div class="logictryWysiwygContent">
      <div class="logictryWysiwygTextBox" contenteditable="true"></div>
      <textarea class="logictryWysiwygSourceText" style="resize: none;"></textarea>
    </div>
  </div>
</div>`;

// The three different display settings
const DISPLAY_SETTINGS = {
  SOURCE: {
    opacity: 0.1,
    pointerEvents: 'none',
    visibility: 'hidden',
    display: null,
    fill: SOURCE,
    width: '100%',
    borderLeft: null,
  },
  HTML: {
    opacity: 1,
    pointerEvents: null,
    visibility: null,
    display: 'none',
    fill: HTML,
    width: '100%',
    borderLeft: null,
  },
  BOTH: {
    opacity: 1,
    pointerEvents: null,
    visibility: null,
    display: null,
    fill: BOTH,
    width: '50%',
    borderLeft: '1px solid rgb(193, 193, 193)',
  }
}

// Setting this independently of the Wysiwyg to main the same state for all instances of Wysiwyg
let lastContentDisplaySetting = HTML;
let speechRecognition;

export default class Wysiwyg {
  constructor(_mount, _props) {
    // The mount node is the parent div that this will mount to
    this.mountNode = _mount;
    this.props = _props;
    this.contentDisplaySetting = lastContentDisplaySetting;
    this.wysiwygMount = document.createElement('div');
    this.wysiwygMount.style.width = '100%';
    this.wysiwygMount.style.height = '100%';

    // Create wrapper for mounting
    this.wrapper = document.createElement('div');
    this.wrapper.style.flex = '1';
    this.wrapper.style.border = `1px solid ${_props.borderColor || 'rgb(193, 193, 193)'}`;
    this.wrapper.style.borderRadius = `${_props.borderRadius || Constants.BoxRadius}px`;
    this.wrapper.style.overflow = 'hidden';
    this.wrapper.style.width = '100%';
    this.wrapper.style.height = '100%';

    // Mount the wysiwyg
    this.wrapper.appendChild(this.wysiwygMount);
    this.mountNode.appendChild(this.wrapper);

    // Initialize Content
    this.wysiwygMount.innerHTML = WysiwygHtml;

    this.undoStack = [];
    this.undoStackPosition = 0;

    // Initialize Html
    this.__updateHtmlTextBox(this.props.initialText);
    this.__updateSourceTextBox(this.props.initialText);

    // Set initial doc mode
    this.logictryWysiwygSetDocMode(this.contentDisplaySetting);

    // Add event listeners
    this.mountNode.getElementsByClassName('wysiwygWidgetForegroundColor')[0].addEventListener('input', this.foregroundHandler);
    this.mountNode.getElementsByClassName('wysiwygWidgetBackgroundColor')[0].addEventListener('input', this.backgroundHandler);
    document.addEventListener('keydown', this.keydownHandler);
    document.addEventListener('keyup', this.keyupHandler);
    document.addEventListener('mousedown', this.globalMouseDownHandler);
    this.__wysiwygWidget.addEventListener('mousedown', this.wysiwygMouseDownHandler);
    this.__htmlTextBox.addEventListener('keydown', this.keydownHandler);
    this.__htmlTextBox.addEventListener('keyup', this.keyupHandler);
    this.__sourceTextBox.addEventListener('keydown', this.keydownHandler);
    this.__sourceTextBox.addEventListener('keyup', this.keyupHandler);
    this.__htmlTextBox.addEventListener('paste', this.htmlPasteHandler);
    this.__sourceTextBox.addEventListener('paste', this.sourcePasteHandler);
    this.__htmlTextBox.addEventListener('mousedown', this.mouseDownHandler);

    // Global handlers
    this.__wysiwygWidget.logictryWysiwygFormatDoc = (e, sCmd, sValue) => {
      e.preventDefault();
      e.stopPropagation();
      if (!sCmd) return;
      if (sCmd === 'createlink') {
        this.createPopup({
          title: 'Create Link',
          inputs: ['Text', 'URL'],
        });
        return;
      }
      if (sCmd === 'createimage') {
        this.createPopup({
          title: 'Create Image',
          inputs: ['URL', 'Width', 'Height'],
        });
        return;
      }
      if (sCmd === 'record') {
        if ('webkitSpeechRecognition' in window) {
          if (speechRecognition) {
            this.mountNode.getElementsByClassName('logictryWysiwygRecordSVG')[0].children[1].style.fill = `none`;
            speechRecognition.continuous = false;
            speechRecognition.stop();
            speechRecognition = null;
          } else {
            this.mountNode.getElementsByClassName('logictryWysiwygRecordSVG')[0].children[1].style.fill = `red`;
            speechRecognition = new webkitSpeechRecognition();
            speechRecognition.continuous = true;
            speechRecognition.interimResults = true;
            speechRecognition.lang = 'en-US';
            speechRecognition.start();

            let finalTranscription = this.__sourceTextBox.value && `${this.__sourceTextBox.value || ''}<br>` || '';
            speechRecognition.onresult = (event) => {
              let interimTranscription = '';
              for (let i = event.resultIndex; i < event.results.length; ++i) {
                if (event.results[i].isFinal) {
                  finalTranscription += event.results[i][0].transcript;
                } else {
                  interimTranscription += event.results[i][0].transcript;
                }
              }
              this.__updateHtmlTextBox(finalTranscription + interimTranscription);
              this.keyupHandler();
            };
            speechRecognition.onend = () => {
              if (speechRecognition && speechRecognition.continuous) {
                speechRecognition.start();
              }
            };
          }
        } else {
          alert('Web Speech API is not supported in this browser.');
        }
        return;
      }
      if (sCmd === 'createtable') {
        document.execCommand('insertHtml', false, '<table style="border-collapse: collapse; width: 100%;" border="1"><tbody><tr><td></td><td></td></tr><tr><td></td><td></td></tr></tbody></table>');
      } else if (sCmd === 'removeFormat') {
        var selection = window.getSelection();
        if (selection && selection.anchorNode) {
          const { anchorNode } = selection;
          if (['H1', 'H2', 'H3', 'H4', 'H5', 'P'].includes(anchorNode.nodeName)) {
            anchorNode.outerHTML = anchorNode.innerHTML;
          }
          const { parentNode } = anchorNode;
          if (parentNode && ['H1', 'H2', 'H3', 'H4', 'H5', 'P'].includes(parentNode.nodeName)) parentNode.outerHTML = parentNode.innerHTML;
        }
      } else {
        document.execCommand(sCmd, false, sValue);
      }
      this.textChangeHandler();
      window.event.stopPropagation();
      window.event.preventDefault();
      setTimeout(this.updateToolbarFormatters);
    }
    this.__wysiwygWidget.logictryWysiwygToggleDocMode = () => {
      if (this.contentDisplaySetting === HTML) this.logictryWysiwygSetDocMode(SOURCE);
      else if (this.contentDisplaySetting === SOURCE) this.logictryWysiwygSetDocMode(BOTH);
      else this.logictryWysiwygSetDocMode(HTML);
    }
  }
  unmount = () => {
    // Remove event listeners
    this.mountNode.getElementsByClassName('wysiwygWidgetForegroundColor')[0].removeEventListener('input', this.foregroundHandler);
    this.mountNode.getElementsByClassName('wysiwygWidgetBackgroundColor')[0].removeEventListener('input', this.backgroundInputHandler);
    document.removeEventListener('keydown', this.keydownHandler);
    document.removeEventListener('keyup', this.keyupHandler);
    document.removeEventListener('mousedown', this.globalMouseDownHandler);
    this.__wysiwygWidget.removeEventListener('mousedown', this.wysiwygMouseDownHandler);
    this.__htmlTextBox.removeEventListener('keydown', this.keydownHandler);
    this.__htmlTextBox.removeEventListener('keyup', this.keyupHandler);
    this.__sourceTextBox.removeEventListener('keydown', this.keydownHandler);
    this.__sourceTextBox.removeEventListener('keyup', this.keyupHandler);
    this.__htmlTextBox.removeEventListener('paste', this.htmlPasteHandler);
    this.__sourceTextBox.removeEventListener('paste', this.sourcePasteHandler);
    this.__htmlTextBox.removeEventListener('mousedown', this.mouseDownHandler);
    if (this.keyupTimeout) clearTimeout(this.keyupTimeout);
    this.onBlur(true);
    this.mountNode.removeChild(this.wrapper);
    this.wrapper = null;
  }
  logictryWysiwygSetDocMode(newShowWysiwyg) {
    this.contentDisplaySetting = newShowWysiwyg;
    lastContentDisplaySetting = newShowWysiwyg;

    // Update the display settings to the current state;
    const { opacity, pointerEvents, visibility, display, fill, width, borderLeft } = DISPLAY_SETTINGS[this.contentDisplaySetting];
    this.__toolbar.style.opacity = opacity;
    this.__toolbar.style.pointerEvents = pointerEvents;
    this.__htmlTextBox.style.visibility = visibility;
    this.__sourceTextBox.style.display = display;
    this.__sourceTextBox.style.width = width;
    this.__htmlTextBox.style.width = width;
    this.__sourceTextBox.style.borderLeft = borderLeft;
    this.mountNode.getElementsByClassName('logictryWysiwygCodeSVG')[0].style.fill = `url(#${fill})`;

    // This property allows for setting a fixed defined height
    const { height, width: wysiwygWidth, focusOnInit } = this.props;
    this.__wysiwygWidget.style.width = wysiwygWidth;

    // Set the height and overflow
    if (height) {
      this.__htmlTextBox.style.maxHeight = height;
      this.__htmlTextBox.style.overflow = 'auto';
    } else if (this.contentDisplaySetting === SOURCE) {
      this.__htmlTextBox.style.height = '400px';
      this.__htmlTextBox.style.overflow = 'auto';
    } else {
      this.__htmlTextBox.style.height = null;
      this.__htmlTextBox.style.overflow = null;
    }

    // Set focus on content
    if (this.contentDisplaySetting === SOURCE) {
      this.__htmlTextBox.contentEditable = false;
      this.__sourceTextBox.setSelectionRange(0,0);
      if (focusOnInit) this.__sourceTextBox.focus();
    } else if (this.contentDisplaySetting === HTML) {
      this.__htmlTextBox.contentEditable = true;
      if (focusOnInit) this.__htmlTextBox.focus();
    } else if (this.contentDisplaySetting === BOTH) {
      this.__htmlTextBox.contentEditable = true;
      if (focusOnInit) this.__htmlTextBox.focus();
    }

    this.textChangeHandler();
  }
  getCurrentSourceText() {
    // 
    let flattenedText = '';
    let previousValue = '';
    this.__sourceTextBox.value.toString().split(/\r?\n/g).forEach((t, i) => {
      const nextValue = t.replace(/^\s+/g, '');
      const spacing = (i === 0 || (previousValue.endsWith('>') || (nextValue.startsWith('>') || nextValue.startsWith('<')))) ? '' : ' ';
      flattenedText = `${flattenedText}${spacing}${nextValue}`;
      previousValue = nextValue;
    })
    return flattenedText;
  }
  updateToolbarFormatters = () => {
    if (!this.__textFormatter) return;
    const currentSelection = window.getSelection();
    const { anchorNode } = currentSelection
    if (!anchorNode) return this.__textFormatter.value = '';
    if (['H1', 'H2', 'H3', 'H4', 'H5', 'P'].includes(anchorNode.nodeName)) return this.__textFormatter.value = anchorNode.nodeName.toLowerCase();
    const { parentNode } = anchorNode;
    if (['H1', 'H2', 'H3', 'H4', 'H5', 'P'].includes(parentNode.nodeName)) return this.__textFormatter.value = parentNode.nodeName.toLowerCase();
    return this.__textFormatter.value = '';
  }
  mouseDownHandler = () => {
    setTimeout(this.updateToolbarFormatters);
  }
  wysiwygMouseDownHandler = (e) => {
    e.stopPropagation();
  }
  globalMouseDownHandler = () => {
    this.onBlur();
  }
  keydownHandler = (e) => {
    setTimeout(this.updateToolbarFormatters);
    if (e.key === 'Enter' && e.ctrlKey) return;
    if (e.key === 'Tab' || e.key === 'Escape') return;
    e.stopPropagation();
    if (e.key === 'Enter') return;
    if (e.metaKey || e.altKey) {
      if (e.key === 'u') this.__wysiwygWidget.logictryWysiwygFormatDoc(e, 'underline');
      if (e.key === 'z') {
        e.preventDefault();
        e.stopPropagation();
        if (e.shiftKey) {
          if (this.undoStackPosition < this.undoStack.length - 1) {
            this.undoStackPosition += 1;
            const text = this.undoStack[this.undoStackPosition];
            this.__htmlTextBox.innerHTML = text;
            this.__updateSourceTextBox(text);
          }
        } else {
          if (this.undoStackPosition > 0) {
            this.undoStackPosition -= 1;
            const text = this.undoStack[this.undoStackPosition];
            this.__htmlTextBox.innerHTML = text;
            this.__updateSourceTextBox(text);
          }
        }
        this.__htmlTextBox.blur();
      }
    }
    if (document.activeElement === this.__htmlTextBox) {
      if (e.key === 'Backspace') {
        const selection = window.getSelection();
        if (!selection || selection.rangeCount <= 0) return;
        const range = selection.getRangeAt(0);
        const currentNode = range.commonAncestorContainer;
        const topLevelNode = recursivelyTraverseupToParent(currentNode, this.__htmlTextBox);
        function recursivelyTraverseupToParent(node, htmlTextBox) {
          const siblings = Array.from(node.parentNode.childNodes);
          const indexOfNode = siblings.indexOf(node);
          const previousSiblings = siblings.slice(0,indexOfNode);
          const textFound = previousSiblings.length > 0 && previousSiblings.find((s) => s.textContent.trim());
          if (node.parentNode === htmlTextBox) return node;
          if (textFound) return null;
          return recursivelyTraverseupToParent(node.parentNode, htmlTextBox);
        }
        if (range.startOffset === 0 && range.endOffset === 0 && topLevelNode && topLevelNode.previousSibling) {
          if (!topLevelNode.previousSibling.textContent.trim()) {
            e.preventDefault();
            e.stopPropagation();
            topLevelNode.previousSibling.remove();
            this.textChangeHandler();
          } else if (['#text', 'SPAN', 'B', 'I', 'U', 'STRIKE'].includes(topLevelNode.nodeName) && topLevelNode.previousSibling.nodeName !== '#text') {
            e.preventDefault();
            e.stopPropagation();
            topLevelNode.previousSibling.appendChild(topLevelNode);
            selection.setPosition(topLevelNode, 0);
            this.textChangeHandler();
          }
        } else if (currentNode === this.__htmlTextBox && range.startOffset !== 0 && range.startOffset === range.endOffset) {
          e.preventDefault();
          e.stopPropagation();
          const nodeToRemove = this.__htmlTextBox.childNodes[range.startOffset] || this.__htmlTextBox.childNodes[this.__htmlTextBox.childNodes.length - 1]
          selection.setPosition(nodeToRemove.previousSibling, 0);
          nodeToRemove.remove();
          this.textChangeHandler();
        }
      }
    }
  }
  keyupHandler = () => {
    if (this.keyupTimeout) clearTimeout(this.keyupTimeout)
    this.keyupTimeout = setTimeout(() => {
      this.textChangeHandler();
    }, 250);
  }
  htmlPasteHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const html = cleanPaste(e.clipboardData.getData('text/html') || '');
    const text = e.clipboardData.getData('text/plain') || '';
    document.execCommand(html ? 'insertHtml' : 'insertText', false, html ? html : text);
  }
  sourcePasteHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Paste the clipboardData with the current extra formatting (html)
    const text = (e.clipboardData.getData('text/plain') || '');
    const value = this.__sourceTextBox.value;

    // Save selection start and end position
    const start = this.__sourceTextBox.selectionStart;
    const end = this.__sourceTextBox.selectionEnd;
    const newValue = value.slice(0, start) + text + value.slice(end);

    // Update the value with our text inserted
    this.__sourceTextBox.value = newValue;

    // Update cursor to be at the end of insertion
    this.__sourceTextBox.selectionStart = start + text.length;
    this.__sourceTextBox.selectionEnd = this.__sourceTextBox.selectionStart;

    this.keyupHandler();
  }
  backgroundHandler = (e) => this.__wysiwygWidget.logictryWysiwygFormatDoc(e, 'backcolor', this.mountNode.getElementsByClassName('wysiwygWidgetBackgroundColor')[0].value);
  foregroundHandler = (e) => this.__wysiwygWidget.logictryWysiwygFormatDoc(e, 'forecolor', this.mountNode.getElementsByClassName('wysiwygWidgetForegroundColor')[0].value);

  onBlur = (unmounting) => {
    if (this.popupContainer) return;
    this.textChangeHandler();
    const { removeOnBlur, onBlur } = this.props;
    if (!unmounting && removeOnBlur) this.unmount();
    if (onBlur) onBlur();
  }
  textChangeHandler = () => {
    if (document.activeElement === this.__sourceTextBox) {
      const text = this.getCurrentSourceText();
      this.__lastSourceTextBoxValue = text;
      this.__updateHtmlTextBox(text || '');
    } else {
      this.__htmlTextBox.childNodes.forEach(__recursivelyCleanUpContentEditableHtml);
      const text = this.fomatHtmlTextBoxHtml();
      this.__lastHtmlTextBoxValue = text;
      this.__updateSourceTextBox(text);
    }
    const text = this.fomatHtmlTextBoxHtml();
    this.lastContent = text;
    const { onTextChanged, onRawTextChanged } = this.props;
    onTextChanged(text);
    if (onRawTextChanged) onRawTextChanged(this.__htmlTextBox.innerText);

    // Undo Stack
    if (text === this.undoStack[this.undoStackPosition]) {
      return
    };
    this.undoStack = this.undoStack.slice(0, this.undoStackPosition + 1);
    this.undoStack.push(text);
    this.undoStackPosition = this.undoStack.length - 1;
  }
  fomatHtmlTextBoxHtml = () => {
    let text = this.__htmlTextBox.innerHTML.trim().replaceAll(' style=""', '').replace(/\r?\n/g, '');
    if (text.endsWith('<br>') && !text.endsWith('<br><br>')) text = text.slice(0, -4);
    const onlyOne = __recursivelyTraverseIfThereIsOnlyChild(this.__htmlTextBox);
    if (onlyOne) return '';
    return text;
  }
  changeText = (text) => {
    // If the node has been removed
    if (!this.wrapper) return;

    if (this.lastContent !== text) {
      this.lastContent = text;
      this.__updateHtmlTextBox(text);
      this.__updateSourceTextBox(text);
    }
  }

  process(str) {
    const div = document.createElement('div');
    div.innerHTML = purifyhtml(str.trim());
    return this.format(div, 0).innerHTML.replaceAll('&amp;', '&').trim();
  }
  format(node, level) {
    const indentBefore = new Array(level++ + 1).join('  ');
    const indentAfter  = new Array(level - 1).join('  ');
    let textNode;
    for (let i = 0; i < node.children.length; i += 1) {
      textNode = document.createTextNode('\n' + indentBefore);
      node.insertBefore(textNode, node.children[i]);
      this.format(node.children[i], level);
      if (node.lastElementChild === node.children[i]) {
        textNode = document.createTextNode('\n' + indentAfter);
        node.appendChild(textNode);
      }
    }
    return node;
  }
  createPopup(config) {
    const sel = window.getSelection();
    if (!sel || sel.rangeCount <= 0) return;
    const range = sel.getRangeAt(0);
    sel.getRangeAt(0);
    const { title, inputs } = config;
    window.logictryWysiwygPopupClose = () => {
      window.logictryWysiwygPopupClose = null;
      document.body.removeChild(this.popupContainer);
      this.popupContainer = null;
    }
    window.logictryWysiwygPopupCreate = () => {
      window.logictryWysiwygPopupCreate = null;
      const values = {};
      inputs.forEach((input) => { values[input] = document.getElementById(`logictryWysiwyg_${input}`).value; });
      window.logictryWysiwygPopupClose();
      this.__htmlTextBox.focus();
      if (title === 'Create Link') {
        if (!values.URL || !values.Text) return;
        const link = document.createElement('a');
        link.href = values.URL;
        link.innerHTML = purifyhtml(values.Text);
        range.deleteContents();
        range.insertNode(link);
      } else if (title === 'Create Image') {
        if (!values.URL) return;
        const image = document.createElement('img');
        image.src = values.URL;
        image.style.maxWidth = '100%';
        if (values.Width) image.width = values.Width;
        if (values.Height) image.height = values.Height;
        range.deleteContents();
        range.insertNode(image);
      }
      sel.removeAllRanges();
      sel.addRange(range);
      this.textChangeHandler();
    }
    this.popupContainer = document.createElement('div');
    this.popupContainer.style.zIndex = '4';
    this.popupContainer.style.position = 'fixed';
    this.popupContainer.style.inset = '0px';
    this.popupContainer.style.display = 'flex';
    this.popupContainer.style.justifyContent = 'center';
    this.popupContainer.style.alignItems = 'center';
    this.popupContainer.style.backgroundColor = 'rgba(0,0,0,0.8)';
    const popup = document.createElement('div');
    popup.style.backgroundColor = 'white';
    popup.style.padding = '30px';
    popup.style.borderRadius = '8px';
    popup.innerHTML = `<h2>${title}</h2>${inputs.map((input) => {
      let defaultValue = '';
      if (title === 'Create Link' && input === 'Text') {
        defaultValue = sel.toString();
      } else if (title === 'Create Link' && input === 'URL') {
        defaultValue = sel.baseNode && __recursivelyGetParentHref(sel.baseNode) || '';
      }
      return `<div style="margin: 10px 0px;"><input id="logictryWysiwyg_${input}" style="padding: 4px 8px; border: 1px solid; border-radius: 2px; min-width: 240px;" value="${defaultValue}" placeholder=${input}></input></div>`;
    }).join('')}<div style="display:flex; justify-content: center;"><button style="margin: 10px;" onclick="window.logictryWysiwygPopupClose()">Cancel</button><button style="margin: 10px;" onclick="window.logictryWysiwygPopupCreate()">Create</button></div>`;
    this.popupContainer.appendChild(popup);
    document.body.appendChild(this.popupContainer);
  }
  __updateSourceTextBox(text) {
    this.__sourceTextBox.value = this.process(text);
    this.__lastSourceTextBoxValue = this.getCurrentSourceText();
  }
  __updateHtmlTextBox(text) {
    this.__htmlTextBox.innerHTML = purifyhtml(text);
    this.__lastHtmlTextBoxValue = this.__htmlTextBox.innerHTML;
  }
  get __wysiwygWidget() {
    return this.mountNode.getElementsByClassName('logictryWysiwygWidget')[0];
  }
  get __htmlTextBox() {
    return this.mountNode.getElementsByClassName('logictryWysiwygTextBox')[0];
  }
  get __sourceTextBox() {
    return this.mountNode.getElementsByClassName('logictryWysiwygSourceText')[0];
  }
  get __toolbar() {
    return this.mountNode.getElementsByClassName('logictryWysiwygToolbar')[0];
  }
  get __textFormatter() {
    return this.mountNode.getElementsByClassName('logictryTextFormatter')[0];
  }
}
function __recursivelyGetParentHref(node) {
  return node.href || node.parentNode && __recursivelyGetParentHref(node.parentNode);
}
var cleanPaste = function(html) {
  // Remove additional MS Word content
  html = html.replace(/<(\/)*(\\?xml:|meta|link|span|font|del|ins|st1:|[ovwxp]:)((.|\s)*?)>/gi, ''); // Unwanted tags
  html = html.replace(/ (id|class|style|type|start|dir|aria-level|role)=("(.*?)"|(\w*))/gi, ''); // Unwanted attributes
  html = html.replace(/<style(.*?)style>/gi, '');   // Style tags
  html = html.replace(/<script(.*?)script>/gi, ''); // Script tags
  html = html.replace(/<!--(.*?)-->/gi, '');        // HTML comments
  return html;
}
function __recursivelyCleanUpContentEditableHtml(node) {
  if (!node) return;
  node.childNodes.forEach(__recursivelyCleanUpContentEditableHtml);
  if (!node.parentNode) return;
  if (['H1', 'H2', 'H3', 'H4', 'H5', 'P', 'SPAN', 'B', 'I', 'U', 'STRIKE'].includes(node.nodeName) && node.childNodes.length === 0 && !node.className) {
    node.parentNode.removeChild(node);
  } else {
    if (!node.parentNode.parentNode) return;
    if (node.parentNode.parentNode.childNodes.length === 2 && node.parentNode.parentNode.childNodes[1].nodeName === 'BR') {
      node.parentNode.parentNode.replaceChildren(node.parentNode);
    }
    if (['SPAN'].includes(node.parentNode.nodeName)) {
      node.parentNode.parentNode.replaceChild(node, node.parentNode);
    } else if (['B', 'I', 'U'].includes(node.parentNode.nodeName)) {
      node.parentNode.removeAttribute("style");
    }
  }
}
function __recursivelyTraverseIfThereIsOnlyChild(node) {
  if (node.childNodes.length === 0 && node.nodeName === 'BR') {
    return true;
  }
  if (node.childNodes.length !== 1) {
    return false;
  }
  return __recursivelyTraverseIfThereIsOnlyChild(node.childNodes[0]);
}
