import Service from './Service';
import Constants from '../submodules/logictry_config/constants';

const checkMobile = () => (window.innerWidth < Constants.MobileWidth);

let _mobile;
const _iPhone10 = window.innerWidth === 375 && window.innerHeight >= 800;
function updateMobile() {
  _mobile = checkMobile();
}

const ua = window.navigator && window.navigator.userAgent || "";
// const isChromium = ua.indexOf("Chromium") !== -1;
const isWebkit = ua.indexOf("AppleWebKit") !== -1;
const isChrome = isWebkit && ua.indexOf("Chrome/") !== -1;
const isSafari = isWebkit && !isChrome;
// const isFirefox = ua.indexOf("Firefox/") !== -1;
// const isIE = ua.indexOf("MSIE ") !== -1 || ua.indexOf("Trident/") !== -1;
// let ieVersion = parseInt(ua.split("MSIE")[1], 10) || -1;
// if(isIE && ieVersion === -1 && ua.indexOf("Trident/7.0") !== -1) {
//   ieVersion = 11
// }

class WindowSize extends Service {
  get iPhone10() {
    return _iPhone10;
  }
  get isSafari() {
    return isSafari;
  }
  get mobile() {
    if (_mobile === undefined) updateMobile();
    return _mobile;
  }

  updateDimensions() {
    if (checkMobile() !== _mobile) {
      updateMobile();
      this.emitStateUpdate();
    }
  }
}

const singleton = new WindowSize();
updateMobile();
window.addEventListener('resize', () => singleton.updateDimensions());
export default singleton;
