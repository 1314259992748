/* eslint-disable react/no-danger */
/* eslint-disable global-require */
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { Wrapper } from "@googlemaps/react-wrapper";

const MapWrapper = styled.div`
  position: absolute;
  inset: 0;
  #map {
    position: absolute;
    inset: 0;
  }
`;
const contentString = (link, title, description) => `<div id="map__popup">
  <a href="${link}">
    <h3>${title}</h3>
  </a>
</div>`;

let map;
let location = { lat: 40, lng: -97.7511 };
function MyMapComponent({
  markers,
  zoom,
}) {
  const ref = useRef();
  useEffect(() => {
    // if (map) {
    //   var newLatLng = new window.google.maps.LatLng(location.lat, location.lng);
    //   map.setCenter(newLatLng);
    //   map.setZoom(zoom)
    // } else {
      map = new window.google.maps.Map(ref.current, {
        center: new window.google.maps.LatLng(location.lat, location.lng),
        zoom,
      });
      Object.values(markers).forEach(({ url, name, description, address, lat, lng, iconUrl, icon }) => {
        const infowindow = new window.google.maps.InfoWindow({
          content: contentString(url, name, description),
        });
        const marker = new window.google.maps.Marker({
          position: { lat, lng },
          map: map,
          title: address,
          icon: {
            url: iconUrl,
            scaledSize: new window.google.maps.Size(40, 40),
          },
        });
        marker.addListener("click", () => {
          infowindow.open({
            anchor: marker,
            map,
          });
        });
      });
    // }
  });

  return <div ref={ref} id="map" />;
}

const OpenStreetMapBox = ({ markers }) => (
  <MapWrapper>
    <Wrapper apiKey={"AIzaSyAdipzE4FB6pGLDZkHEjcohieMnW-sEll8"}>
      <MyMapComponent
        zoom={5}
        markers={markers}
      />
    </Wrapper>
  </MapWrapper>
);
OpenStreetMapBox.propTypes = {
  markers: PropTypes.object,
};
export default OpenStreetMapBox;
