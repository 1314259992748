import React from 'react';
// import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import TreeCache from '../../services/cache/TreeCache';
import TeamCache from '../../services/cache/TeamCache';
import CompanyService from '../../services/Company';
import SpreadsheetCache from '../../services/cache/SpreadsheetCache';
import DatabaseCache from '../../services/cache/DatabaseCache';
import DatabaseCollectionCache from '../../services/cache/DatabaseCollectionCache';
import AssetCache from '../../services/cache/AssetCache';
import LinkCache from '../../services/cache/LinkCache';
import Tree from '../../models/tree';
import Spreadsheet from '../../models/spreadsheet';
import Team from '../../models/team';
import Company from '../../models/company';
import Asset from '../../models/asset';
import Database from '../../models/database';
import DatabaseCollection from '../../models/databasecollection';
import Link from '../../models/link';

export default class BoxPopup extends React.PureComponent {
  static propTypes = {
    domainObject: PropTypes.object,
    onClose: PropTypes.func,
  }
  close = () => {
    const { domainObject, onClose } = this.props;
    if (domainObject instanceof Tree) {
      TreeCache.revertTree(domainObject);
    } else if (domainObject instanceof Spreadsheet) {
      SpreadsheetCache.revertObject(domainObject);
    } else if (domainObject instanceof Team) {
      TeamCache.revertObject(domainObject);
    } else if (domainObject instanceof Company) {
      CompanyService.revertObject(domainObject);
    } else if (domainObject instanceof Asset) {
      AssetCache.revertObject(domainObject);
    } else if (domainObject instanceof Database) {
      DatabaseCache.revertObject(domainObject);
    } else if (domainObject instanceof DatabaseCollection) {
      DatabaseCollectionCache.revertObject(domainObject);
    }
    onClose();
  }
  saveAndClose = () => {
    const { domainObject, onClose } = this.props;
    if (domainObject instanceof Tree) {
      TreeCache.updateTree(domainObject);
    } else if (domainObject instanceof Spreadsheet) {
      SpreadsheetCache.update(domainObject);
    } else if (domainObject instanceof Team) {
      TeamCache.update(domainObject);
    } else if (domainObject instanceof Company) {
      CompanyService.update(domainObject);
    } else if (domainObject instanceof Asset) {
      AssetCache.update(domainObject);
    } else if (domainObject instanceof Database) {
      DatabaseCache.update(domainObject);
    } else if (domainObject instanceof DatabaseCollection) {
      DatabaseCollectionCache.update(domainObject);
    } else if (domainObject instanceof Link) {
      LinkCache.update(domainObject);
    }
    onClose();
  }
}
