import ObjectCache from './CacheService';
import UserAccount from '../UserAccount';
import GoogleDriveApi from '../server/GoogleDriveApi';

class GoogleDriveCache extends ObjectCache {
  api = GoogleDriveApi;
  createObject = (_results) => {
    const results = JSON.parse(JSON.stringify(_results));
    return results;
  }
  revertObject = (_object) => {
    const results = this.objectJson[_object._id];
    this.objects[_object._id] = this.createObject(results);
    this.emitStateUpdate();
  }
  getObjectJson(_model) {
    return JSON.parse(JSON.stringify(_model));
  }
}
const singleton = new GoogleDriveCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
