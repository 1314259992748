import React from 'react';
// import styled from 'styled-components';

import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import { AccountDatabasesOpen, AccountDatabasesInvitedOpen, AccountDatabasesDeleted } from '../../services/Pagination/index';
import Box from './Box';
import DatabaseSettings from '../../components/ObjectSettings/DatabaseSettings';
import CreateDatabasePopup from './CreateDatabasePopup';
import { getAccountDatabasesSearch } from '../../services/Pagination/index';

export default class DatabasesBox extends Box {
  name = 'Databases';
  missingTitleText = 'New Database';
  paginationService = this.props.paginationService || this.props.showDeleted && AccountDatabasesDeleted || (this.props.searchText || this.props.filterText) && getAccountDatabasesSearch(this.props.searchText, null, this.props.filterText) || this.props.invitedTo && AccountDatabasesInvitedOpen || AccountDatabasesOpen;
  backgroundColor = Constants.PrimaryColor;
  boxIcon = 'fas fa-database';
  open = (_tree) => Navigation.push(`/databases/${_tree._id}`);
  createText = 'New Database';
  createNewPopup = () => {
    const { isDevAccount } = UserAccount;
    if (!isDevAccount) return this.setState({ showCreatePopup: false, showUpgradePopup: true });
    return <CreateDatabasePopup team={this.props.team} onClose={this.hideCreatePopup} />;
  }
  disableFree = false;
  objectSettings = DatabaseSettings;
}
