/* eslint-disable no-param-reassign */
import { ANSWER, FOR_EACH_ANSWER, FILTER, ONLY_ONE_CHILD_ALLOWED, ANSWER_CHILDREN_TYPES } from './nodetypes';
import { OR_ATTR, AND_ATTR, EXCLUDE_ATTR, INCLUDE_ATTR, NOT_APPLICABLE_ATTR, SORT_ASCENDING_ATTR, SORT_DESCENDING_ATTR, EXCLUDE_EMPTY_CELLS_ATTR } from './nodeattributes';
import Logic from './logic';
import { getChoicesFromData } from './sheet';
import findAllHandlebars from '../utils/parsevariables';

const EXCLUDE_STRING = `[${EXCLUDE_ATTR}]`;
const INCLUDE_STRING = `[${INCLUDE_ATTR}]`;
const NOT_APPLICABLE_STRING = `[${NOT_APPLICABLE_ATTR}]`;

export default class FilterLogic extends Logic {
  __update(_node) {
    if (_node && _node.PreventFilterLogicUpdate) return;
    if (!this.parents) return;
    if (this.parents[0].editing) return;

    // Get all spreadsheet data
    const dataNodes = this.__recursivelyGetAllDataNodes(this);
    const readDataNodes = [];
    dataNodes.forEach((d) => {
      if (d.isReadable) readDataNodes.push(d);
    });

    // Get all top level filters
    const { totalFilters, activeFilters } = this.getAllFilters(this);
    const topLevelFilters = this.getTopLevelFilters(this);

    // Get choices from ReadDataNodes
    const { childNodes, choicesAndData } = this.__getChoicesAndData(readDataNodes);

    // TotalChoices and TotalData
    const { totalChoices, totalData } = this.__getAllData(totalFilters, choicesAndData);

    // Recursively create children and filters
    this.recursivelyCreateChildrenAndFilter(activeFilters, topLevelFilters, totalChoices, totalData, childNodes);

    // Emit Updates
    activeFilters.forEach((_filter) => _filter.emitStateUpdate());

    // Update parents
    this.__updateAllParentsOnNodeUpdated();

    this.emitStateUpdate();
  }
  getAllFilters(node, _notActive) {
    let totalFilters = [];
    let activeFilters = [];
    node.children.forEach((_child) => {
      if (_child.isType(FILTER)) {
        totalFilters.push(_child);
        if (!_notActive) activeFilters.push(_child);
      }
      const setNotActive = _child.isType(ANSWER) && !_child.hasUserVoted();
      if (_child.isOneOfTypes([FOR_EACH_ANSWER])) return;
      if (this.isExcludeBoundary(_child)) return;
      const childResults = this.getAllFilters(_child, setNotActive || _notActive);
      totalFilters = [...totalFilters, ...childResults.totalFilters];
      activeFilters = [...activeFilters, ...childResults.activeFilters];
    });
    return { totalFilters, activeFilters };
  }
  getTopLevelFilters(node) {
    let totalFilters = [];
    node.children.forEach((_child) => {
      if (_child.isType(FILTER)) {
        totalFilters.push(_child);
        return;
      }
      if (_child.isOneOfTypes([FOR_EACH_ANSWER])) return;
      if (this.isExcludeBoundary(_child)) return;
      totalFilters = [...totalFilters, ...this.getTopLevelFilters(_child)];
    });
    return totalFilters;
  }
  __getChoicesAndData(dataNodes) {
    if (!this.__choicesAndDataLoadedAlready) this.__choicesAndDataLoadedAlready = {};
    const childNodes = {};
    const choicesAndData = [];
    // Filter out the special columns
    // Supporting Info and DisplayText nodes
    let totalRowsLoading = 0;
    const staggerMaxRowsLoading = 500;
    dataNodes.forEach((_datanode) => {
      if (!_datanode.spreadsheet || _datanode.spreadsheet.error || this.parents[0].editing) return;
      if (this.__choicesAndDataLoadedAlready[_datanode.spreadsheet._id]) {
        choicesAndData.push(this.__choicesAndDataLoadedAlready[_datanode.spreadsheet._id]);
        return;
      }
      if (totalRowsLoading >= staggerMaxRowsLoading) return;
      if (_datanode.spreadsheet.sheets[0].data.length === 0) return;

      // Create a copy of the choices and data
      let newData = JSON.parse(JSON.stringify(_datanode.spreadsheet.sheets[0].data));
      totalRowsLoading += newData.length;

      // Sort the newData based on dataSchema
      // Rename columns
      const { dataSchema } = _datanode;
      if (dataSchema && dataSchema.array && dataSchema.array.schema) {
        dataSchema.array.schema.forEach(({ field, title, sortOption }) => {
          const colIndex = newData[0].findIndex((c) => c && (c.value === field || c.value === title));
          if (colIndex < 0) return;

          // Rename to title
          newData[0][colIndex].value = title;

          // Apply sorting
          if (sortOption === SORT_ASCENDING_ATTR) {
            newData.sort((a, b) => {
              const aText = a[colIndex] && a[colIndex].value || '';
              const bText = b[colIndex] && b[colIndex].value || '';
              if (bText === field || bText === title) return 0;
              if (aText < bText) return -1;
              if (bText < aText) return 1;
              return 0;
            });
          } else if (sortOption === SORT_DESCENDING_ATTR) {
            newData.sort((a, b) => {
              const aText = a[colIndex] && a[colIndex].value || '';
              const bText = b[colIndex] && b[colIndex].value || '';
              if (bText === field || bText === title) return 0;
              if (aText > bText) return -1;
              if (bText > aText) return 1;
              return 0;
            });
          }
        });
      }
      const newChoices = JSON.parse(JSON.stringify(getChoicesFromData(newData)));

      // Ensure newData is all equal row lengths
      const maxColumns = newData[0] && newData[0].length || 0;
      newData.forEach((row, i) => {
        newData[i] = row.slice(0, maxColumns);
        if (row.length < maxColumns) newData[i] = [...row, ...(new Array(maxColumns - row.length)).fill({ value: '' })];
      });

      // Create DerivedData
      const dataDerived = _datanode.dataDerived;
      dataDerived.forEach((_dataDerived) => {
        const dataDerivedText = _dataDerived.children[0];
        if (!dataDerivedText) return;

        // Find all the handlebar matches
        const matchedVariables = findAllHandlebars(dataDerivedText.text);

        // Create all the derived rows
        const matchedColumns = matchedVariables.map((v) => newData[0].findIndex((d) => d.value.trim() === unescape(v.trim())));
        newData.forEach((row, i) => {
          let value = dataDerivedText.text;

          // Create derived title
          if (i === 0) {
            newChoices[_dataDerived.text] = [];
            return row.push({ value: _dataDerived.text });
          }

          let oneVariableFound = false;
          // Replace the matched variables
          matchedVariables.forEach((_var, j) => {
            const matchedIndex = matchedColumns[j];
            if (matchedIndex < 0) value = value.replace(`{{${_var}}}`, '');
            else {
              const replaceValue = row[matchedIndex] && row[matchedIndex].value && row[matchedIndex].value.replaceAll(_datanode.delimiterString, ' · ');
              value = value.replace(`{{${_var}}}`, replaceValue || '');
              if (replaceValue) oneVariableFound = true;
            }
          });
          if (!oneVariableFound) value = '';
          newChoices[_dataDerived.text].push(value);
          return row.push({ value });
        });
      });

      // Establish the child node relationships
      Object.keys(newChoices).forEach((c) => {
        // Get all the special columns which autoCreate from a filter
        const specialColumn = this.__getSpecialColumns(c);
        if (specialColumn) {
          const parentText = c.split(']')[1].trim();
          const parentNodeChoices = newChoices[parentText] || [];
          // eslint-disable-next-line no-nested-ternary
          if (!childNodes[parentText]) childNodes[parentText] = {};
          if (!childNodes[parentText][specialColumn.value]) childNodes[parentText][specialColumn.value] = {};
          parentNodeChoices.forEach((_c, i) => { 
            _c.split(_datanode.delimiterString).forEach((choice) => {
              // Merge special columns if already exists
              if (!childNodes[parentText][specialColumn.value][choice.trim()]) childNodes[parentText][specialColumn.value][choice.trim()] = new Set();
              if (specialColumn.multiple) {
                newChoices[c][i].split(_datanode.delimiterString).forEach((v) => {
                  childNodes[parentText][specialColumn.value][choice.trim()].add(v.trim());
                });
              } else {
                childNodes[parentText][specialColumn.value][choice.trim()].add(newChoices[c][i].trim());
              }
            });
          });
          delete newChoices[c];
        }
      });

      // Remove the child nodes from newData
      const filterOutArray = newData[0].map((v) => {
        const c = v.value;
        if (!c) return true;
        const specialColumn = this.__getSpecialColumns(c);
        return !specialColumn;
      });
      newData = newData.map((r) => r.filter((c, i) => filterOutArray[i]));

      const newChoicesAndData = {
        id: _datanode.spreadsheet._id,
        choices: newChoices,
        data: newData,
        delimiter: _datanode.delimiterString,
        emptyCells: _datanode.emptyCellsOption,
      };
      choicesAndData.push(newChoicesAndData);
      this.__choicesAndDataLoadedAlready[_datanode.spreadsheet._id] = newChoicesAndData;
    });
    if (totalRowsLoading >= staggerMaxRowsLoading) {
      this.update();
    }
    return { childNodes, choicesAndData };
  }
  __getAllData(totalFilters, choicesAndData) {
    // Saves mergedChoicesAndData already calculated
    // For optimizing performance
    if (!this.__mergedChoicesAndDataIds) {
      this.__mergedChoicesAndDataIds = {};
      this.__choicesReferenceCounts = {};
      this.__lastTotalChoicesAndData = {
        totalChoices: {},
        totalData: {},
      };
    }

    // Add new choices and data
    const newIds = new Set();
    let { totalChoices, totalData } = this.__lastTotalChoicesAndData;
    choicesAndData.forEach((d) => {
      const { id, choices, data, delimiter, emptyCells } = d;
      newIds.add(id);
      if (this.__mergedChoicesAndDataIds[id] && this.__mergedChoicesAndDataIds[id].merged) return;
      this.__mergedChoicesAndDataIds[id] = { choices, data, delimiter, merged: true };
      totalChoices = this.__mergeChoices(totalChoices, choices, delimiter, id);
      totalData = this.__mergeData(totalData, data, delimiter, emptyCells);
    });

    // Remove old choices and data
    Object.keys(this.__mergedChoicesAndDataIds).forEach((id) => {
      if (newIds.has(id)) return;
      const { choices, delimiter } = this.__mergedChoicesAndDataIds[id];
      totalChoices = this.__unmergeChoices(totalChoices, choices, delimiter, id);
      this.__mergedChoicesAndDataIds[id].merged = false;
    });

    this.__lastTotalChoicesAndData = { totalChoices, totalData };
    // Return results
    return { totalChoices, totalData };
  }
  __mergeChoices(totalChoices, newChoices, _delimiter, id) {
    if (!newChoices) return totalChoices;
    Object.keys(newChoices).forEach((_key) => {
      // eslint-disable-next-line no-param-reassign
      if (!totalChoices[_key]) totalChoices[_key] = [];
      newChoices[_key].forEach((_vals) => {
        _vals.split(_delimiter).forEach((_val) => {
          if (!_val) return;
          // eslint-disable-next-line no-param-reassign
          _val = _val.trim();
          if ([EXCLUDE_STRING, INCLUDE_STRING, NOT_APPLICABLE_STRING].includes(_val)) return;
          if (!totalChoices[_key].includes(_val)) {
            // eslint-disable-next-line no-param-reassign
            totalChoices[_key].push(_val);
            if (!this.__choicesReferenceCounts[_key]) this.__choicesReferenceCounts[_key] = {};
            if (!this.__choicesReferenceCounts[_key][_val]) this.__choicesReferenceCounts[_key][_val] = new Set();
            this.__choicesReferenceCounts[_key][_val].add(id);
          }
        });
      });
    });
    return totalChoices;
  }
  __unmergeChoices(totalChoices, newChoices, _delimiter, id) {
    if (!newChoices) return totalChoices;
    Object.keys(newChoices).forEach((_key) => {
      // eslint-disable-next-line no-param-reassign
      if (!this.__choicesReferenceCounts[_key]) return;
      newChoices[_key].forEach((_vals) => {
        _vals.split(_delimiter).forEach((_val) => {
          if (!_val) return;
          // eslint-disable-next-line no-param-reassign
          _val = _val.trim();
          if ([EXCLUDE_STRING, INCLUDE_STRING, NOT_APPLICABLE_STRING].includes(_val)) return;
          // Delete the reference
          // and remove the choice if no more indexes
          this.__removeIdFromChoiceAndRemoveChoiceIfNoMoreReferences(totalChoices, id, _key, _val);
        });
      });
      this.__removeIdFromChoiceAndRemoveChoiceIfNoMoreReferences(totalChoices, id, _key, 'Yes');
      this.__removeIdFromChoiceAndRemoveChoiceIfNoMoreReferences(totalChoices, id, _key, 'No');
    });
    return totalChoices;
  }
  __mergeData(totalData, newData, _delimiter, _emptyCells) {
    // Check if data has been loaded
    if (!newData || newData.length === 0) return totalData;

    // Calculate titles
    // Remove Include, Exclude, and Empty cells
    // Also split by delimter
    const titles = newData[0].map((_title) => _title.value);

    // Initialize the formattedData
    const formattedData = [];
    newData.forEach((_row, _rowIndex) => {
      if (_rowIndex === 0) return;
      formattedData.push(new Array(titles.length).fill(''));
    });

    titles.forEach((_title, colIndex) => {
      // eslint-disable-next-line no-param-reassign
      if (!totalData[_title]) totalData[_title] = {};

      // ForEachRow
      newData.slice(1).forEach((_row, rowIndex) => {
        const _cell = _row[colIndex];
        if (!_cell || !_cell.value) {
          if (_emptyCells === EXCLUDE_EMPTY_CELLS_ATTR) formattedData[rowIndex][colIndex] = [EXCLUDE_STRING];
          // else if (_emptyCells === INCLUDE_EMPTY_CELLS_ATTR) formattedData[rowIndex][colIndex] = [INCLUDE_STRING];
          else formattedData[rowIndex][colIndex] = [NOT_APPLICABLE_STRING];
        } else if (_cell.value.trim() === INCLUDE_STRING) {
          formattedData[rowIndex][colIndex] = [INCLUDE_STRING];
        } else if (_cell.value.trim() === EXCLUDE_STRING) {
          formattedData[rowIndex][colIndex] = [EXCLUDE_STRING];
        } else {
          formattedData[rowIndex][colIndex] = _cell.value.split(_delimiter).map((v) => v.trim());
        }
      });
    });

    // Create dictionary
    formattedData.forEach((_row) => _row.forEach((values, j) => {
      const formattedRow = {};
      _row.forEach((_values, k) => {
        formattedRow[titles[k]] = new Set(_values);
      });

      const currentTitle = titles[j];
      values.forEach((_val) => {
        if (!_val) return;
        if (!totalData[currentTitle][_val]) totalData[currentTitle][_val] = [];
        const valueFormattedRow = {};
        Object.keys(formattedRow).forEach((key) => {
          if (key !== currentTitle) valueFormattedRow[key] = formattedRow[key];
        });
        totalData[currentTitle][_val].push(valueFormattedRow);
      });
    }));
    // Return results
    return totalData;
  }
  recursivelyCreateChildrenAndFilter(activeFilters, _topLevelFilters, totalChoices, totalData, childNodes) {
    _topLevelFilters.forEach((_filter) => {
      // Create the children for the filter
      this.createChildrenAndFilter(activeFilters, _filter, totalChoices, totalData, childNodes);

      // Iterate through answer choices to find the filters
      _filter.answers.forEach((_answer) => {
        // Update all filters
        const topLevelFilters = this.getTopLevelFilters(_answer);
        if (!topLevelFilters || topLevelFilters.length === 0) return;
        this.recursivelyCreateChildrenAndFilter(activeFilters, topLevelFilters, totalChoices, totalData, childNodes);
      });
    });
  }
  createChildrenAndFilter(activeFilters, currentFilter, totalChoices, totalData, childNodes) {
    // Get every answer choice for the filter that is in the total choices
    let answerChoices = [];
    if (!totalChoices[currentFilter.text]) totalChoices[currentFilter.text] = [];
    totalChoices[currentFilter.text].forEach((_c) => {
      answerChoices.push(_c.trim());
    });

    const filterData = totalData[currentFilter.text];

    // Handle filtering based upon parent answer choice
    // Does this handle ForEachAnswer
    // Add parents to forceAnswers
    const _forceAnswers = [];
    let startBoundaryFound = false;
    currentFilter.parents.forEach((p, i) => {
      if (p === this) startBoundaryFound = true;
      if (!startBoundaryFound) return;
      if (p.isType(FILTER)) {
        const _answer = currentFilter.parents[i + 1];
        if (_answer.isType(ANSWER)) _forceAnswers.push({ filterText: p.text, answersSelected: [_answer.text], logic: p.filteringLogicOption });
      }
    });
    if (_forceAnswers.length > 0) {
      answerChoices = answerChoices.filter((_choice) => {
        // Check if totalChoices does not contain and then include if so
        if (!totalChoices[currentFilter.text].includes(_choice)) return true;
        // Test that the answer choice passes every forceAnswer
        return this.__testFilterAnswerChoice(totalData, _forceAnswers, currentFilter.text, _choice);
      });
    }

    // Create Filter Question
    this.parents[0].createFilterQuestion(currentFilter, answerChoices, childNodes[currentFilter.text]);

    if (!filterData) {
      currentFilter.hide = true;
      return;
    }
    currentFilter.hide = false;

    // Find filtersDownstream
    const currentFilterIndex = activeFilters.indexOf(currentFilter);
    const filtersUpstream = (currentFilterIndex >= 0) ? activeFilters.slice(0, currentFilterIndex) : [];
    const currentFilterCloned = currentFilter.cloned;
    const filterResults = [];
    const foundParentFilters = [];
    filtersUpstream.forEach((_filter) => {
      // Check if the filter is a parent
      // If the filter is a parent, flag true so that
      // Only the answer that is a parent will be applied
      let filterIsParent = false;
      const foundParentFilterIndex = currentFilter.parents.indexOf(_filter);
      if (foundParentFilterIndex >= 0) {
        foundParentFilters.push({
          filter: currentFilter.parents[foundParentFilterIndex],
          answer: currentFilter.parents[foundParentFilterIndex + 1],
        });
        filterIsParent = true;
      }

      // Check now the filter against foundParentFilters
      const pathDoesNotApply = foundParentFilters.some(({ filter, answer }) => {
        const filterIndex = _filter.parents.indexOf(filter);
        if (filterIndex >= 0) {
          return !_filter.parents.includes(answer);
        }
        return false;
      });
      if (pathDoesNotApply) return;

      // Check if the filter is a forEachAnswer to combine results only if current filter is not a forEachAnswer
      let filterResult;
      if (!filterIsParent && _filter.cloned && currentFilterCloned !== _filter.cloned) {
        filterResult = filterResults.find((r) => r.filterText === _filter.text);
      }
      if (!filterResult) filterResult = { filterText: _filter.text, answersSelected: [], logic: _filter.filteringLogicOption };

      // Parse Answers
      // Dont use the answer if it is the search
      _filter.answers.forEach((_answer) => {
        if (!_answer.stringMatch) {
          if (filterIsParent) {
            if (currentFilter.parents.includes(_answer)) filterResult.answersSelected.push(_answer.text);
          } else if (_answer.hasUserVoted()) filterResult.answersSelected.push(_answer.text);
        }
      });
      if (filterResult.answersSelected.length > 0) filterResults.push(filterResult);
    });

    // Calculate answerChoices based upon selections
    answerChoices = answerChoices.filter((_choice) => this.__testFilterAnswerChoice(totalData, filterResults, currentFilter.text, _choice));

    // Dont apply filtering if the tree is multipleProjects
    if (this.parents[0].multipleProjects) {
      currentFilter.hide = false;
    } else {
      // Update the currentFilter
      let notHidden = 0;
      const currentFilterAnswers = currentFilter.answers;
      const { allowFilteringSelections, selectAllNotFiltered } = currentFilter;
      currentFilterAnswers.forEach((_answer) => {
        if (_answer.filter || _answer.isUserAnswer || (!allowFilteringSelections && !selectAllNotFiltered && _answer.hasUserVoted())) _answer.filteredByFilters = false;
        else if (answerChoices.includes(_answer.text)) _answer.filteredByFilters = false;
        else _answer.filteredByFilters = true;
        if (!_answer.filteredByFilters) notHidden += 1;
      });

      // Convert to null values if none are filtered
      // Null signifies that filtering is not being applied
      if (notHidden === currentFilterAnswers.length) {
        currentFilterAnswers.forEach((_answer) => {
          _answer.filteredByFilters = null;
        });
      }
      // eslint-disable-next-line no-param-reassign
      if (notHidden > 0) {
        // Apply the property to only show if filtered
        if (currentFilter.showOnlyIfFiltered && notHidden === answerChoices.length) {
          currentFilter.hide = true;
        }
        else currentFilter.hide = false;
      }
      // eslint-disable-next-line no-param-reassign
      else {
        currentFilter.hide = true;
      }

      //
      if (selectAllNotFiltered) {
        const answers = currentFilter.answers;
        answers.forEach((_answer) => {
          if (_answer.filteredByFilters) _answer.__removeUserVote();
          else _answer.__addUserVote();
        });
      }
    }
  }
  __testFilterAnswerChoice(totalData, filters, _currentFilter, _choice) {
    // If there are no filters to apply, dont filter
    if (!filters || filters.length === 0) return true;
    const filterData = totalData[_currentFilter];
    if (!filterData) return true;
    const answerData = filterData[_choice];
    if (!answerData) return true;

    const dontFilterRows = [];
    const filterRows = [];
    answerData.forEach((_row) => {
      const filtersFound = [];
      const dontFilter = filters.every(({ filterText, answersSelected, logic }) => {
        const filterOptions = _row[filterText];
        if (!filterOptions) return true;
        filtersFound.push(filterText);
        if (filterOptions.has(NOT_APPLICABLE_STRING) || filterOptions.has(INCLUDE_STRING)) return true;
        if (filterOptions.has(EXCLUDE_STRING)) return false;

        // Handle AND vs OR Logic
        if (logic === OR_ATTR && answersSelected.some((a) => filterOptions.has(a))) return true;
        if (logic === AND_ATTR && answersSelected.every((a) => filterOptions.has(a))) return true;
        return false;
      });
      if (filtersFound.length > 0) {
        if (dontFilter) {
          dontFilterRows.push(filtersFound);
        } else {
          filterRows.push(filtersFound);
        }
      }
    });
    if (filterRows.length === 0) return true;
    return filterRows.every((fr) => dontFilterRows.some((dfr) => fr.every((f) => dfr.includes(f))));
  }
  __removeIdFromChoiceAndRemoveChoiceIfNoMoreReferences(totalChoices, id, _key, _val) {
    if (!this.__choicesReferenceCounts[_key][_val]) return;
    this.__choicesReferenceCounts[_key][_val].delete(id);
    if (this.__choicesReferenceCounts[_key][_val].size === 0) totalChoices[_key] = totalChoices[_key].filter((v) => v !== _val);
  }
  __getSpecialColumns(c) {
    if (!ANSWER_CHILDREN_TYPES.find((at) => c.includes(`[${at}]`) || c.includes(`[${at},`))) return null;
    if (ONLY_ONE_CHILD_ALLOWED.find((at) => c.includes(`[${at}]`) || c.includes(`[${at},`))) return { multiple: false, value: c.split('[')[1].split(']')[0] };
    return { multiple: true, value: c.split('[')[1].split(']')[0] };
  }
  clone() {
    return new FilterLogic(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
}
