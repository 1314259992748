import Service from './Service';

let _blur = false;
let _disableScroll = false;

class AppRoot extends Service {

  get blur() {
    return _blur;
  }
  set blur(value) {
    _blur = value;
    this.emitStateUpdate();
  }
  get disableScroll() {
    return _disableScroll;
  }
  set disableScroll(value) {
    _disableScroll = value;
    if (value) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = null;
    this.emitStateUpdate();
  }
}
const singleton = new AppRoot();
export default singleton;
