import Node from './node';
import { CARD_ATTR, NONE_ATTR } from './nodeattributes';

const displayOptions = [{ name: 'No Border', value: NONE_ATTR }, { name: 'Card', value: CARD_ATTR }];

export default class Category extends Node {
  category = '';
  get card() {
    return this.attributes.includes(CARD_ATTR);
  }
  get displayOptions() {
    return displayOptions;
  }
  updateDisplayOption(_attribute) {
    this.attributes = this.attributes.filter((_attr) => ![CARD_ATTR, NONE_ATTR].includes(_attr));
    if (_attribute !== NONE_ATTR) this.addAttribute(_attribute);
    else this.__attributeChanged();
  }
  clone() {
    return new Category(this.text, this.attributes, this.children.map((c) => c.clone()));
  }
}
