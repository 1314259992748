import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Popup from '../Popup/index';
import Constants from '../../submodules/logictry_config/constants';
import Button from '../Button/index';

const Wrapper = styled.div`
  // display: flex;
  max-width: 600px;
  padding: 40px;

  a {
    color: ${Constants.PrimaryColor};
  }
  p {
    margin: 20px 0px 40px 0px;
  }
`;
const AcceptButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

class DisabledFreePopup extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
  }
  render() {
    return (
      <Popup
        showCloseButton
        allowBackgroundClose
        onClose={this.props.onClick}
      >
        <Wrapper>
          <img alt="logictry" style={{ margin: '0px 0px 10px 0px', height: 40 }} src="https://logictrystatic.s3.amazonaws.com/images/logo062623.png" />
          <p>To access this feature, you must upgrade your account.</p>
          <AcceptButton><Button filledColor onClick={this.props.onClick}><div>OK</div></Button></AcceptButton>
        </Wrapper>
      </Popup>
    );
  }
}
export default DisabledFreePopup;
