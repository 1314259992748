import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import WindowSize from '../../services/WindowSize';
import Connect from '../../services/Connect';
import FixedFullscreenDiv from '../FixedFullscreenDiv';
import CancelAllTouchs from '../../utils/cancelalltouches';
import ReactDOM from 'react-dom';

const Wrapper = styled(FixedFullscreenDiv)`
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  h1, h2 {
    margin: 1rem 0;
  }
`;
const ClearWrapper = styled(FixedFullscreenDiv)`
  z-index: 4;
`;
const Card = styled.div`
  background-color: ${Constants.White};
  border-radius: ${Constants.BoxRadius}px;
  overflow: auto;
  position: relative;
  max-width: calc(100vw - 2rem);
  > i {
    position: absolute;
    top: -3px;
    right: -3px;
  }
`;
const AnchorCard = styled.div`
  max-width: calc(100vw - 2rem);
  background-color: ${Constants.White};
  box-shadow: 0 0 0 1px #8898aa1a, 0 15px 35px #31315d1a, 0 5px 15px #00000014;
  border-radius: 8px;
  overflow: auto;
  max-width: calc(100vw - 40px);
  > i {
    position: absolute;
    top: -3px;
    right: -3px;
  }
`;
const CloseButton = styled.div`
  position: absolute;
  top: 0.25rem;
  right: 1rem;
  font-size: 2rem;
  color: white;
  cursor: pointer;
`;

class Popup extends React.PureComponent {
  componentDidMount() {
    const { preventDeletion, anchorEl, showFullscreen } = this.props;
    if (preventDeletion) document.addEventListener('mousedown', this.preventDeletionHandler);
    if (!anchorEl) {
      if (showFullscreen) Connect.toggleFullscreen();
      else this.centerPopup();
    }
  }
  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMouseMove);
    const { preventDeletion, anchorEl, showFullscreen } = this.props;
    if (preventDeletion) document.removeEventListener('mousedown', this.preventDeletionHandler);
    this.removeInterval();
    if (!anchorEl && showFullscreen) Connect.toggleFullscreen();
  }
  removeInterval = () => {
    if (!this.__boundsInterval) return;
    clearInterval(this.__boundsInterval);
    this.__boundsInterval = null;
  }
  preventDeletionHandler = (e) => {
    const mouseDownInRef = this.ref && this.ref.contains(e.target);
    if (!mouseDownInRef) window.location.reload(true)
  }
  onRef = (ref) => {
    const { allowClickThrough } = this.props;
    const { mobile } = WindowSize;
    if (!ref) return;
    this.ref = ref;
    const { allowMoveClose } = this.props;
    if (!mobile && allowClickThrough) document.addEventListener('mousemove', this.onMouseMove);
    else CancelAllTouchs(ref, { onMove: this.cardRef && (!mobile && allowMoveClose) && this.onMouseMove });
  }
  onCardRef = (ref) => {
    if (!ref) return;
    const { anchorEl } = this.props;
    this.cardRef = ref;
    this.anchorBounds = anchorEl.getBoundingClientRect();
    this.__boundsInterval = setInterval(() => {
      this.__getBounds();
    });
    this.__getBounds();
  }
  onFullscreenCardRef = (e) => {
    this.fullscreenCardRef = e;
    this.centerPopup();
  }
  centerPopup = () => {
    const { anchorEl, showFullscreen } = this.props;
    if (anchorEl || showFullscreen) return;
    if (!this.fullscreenCardRef) return;
    if (this.centerPopupMessageSent) return;
    this.centerPopupMessageSent = true;
    const { top } = this.fullscreenCardRef.getBoundingClientRect();
    Connect.centerContent(top + window.pageYOffset);
  }
  __getBounds = () => {
    const { anchorBottomRight } = this.props;
    const cardBounds = this.cardRef.getBoundingClientRect();
    {
      const left = anchorBottomRight ? this.anchorBounds.right - cardBounds.width : this.anchorBounds.left;
      const right = left + cardBounds.width;
      const top = this.anchorBounds.bottom
      const bottom = this.anchorBounds.bottom + cardBounds.height;
      const offsetFromSide = 20;

      let translateX = 0;
      let translateY = 0;
      if (right > (window.innerWidth - offsetFromSide)) translateX = (window.innerWidth - offsetFromSide) - right;
      else if (left < offsetFromSide) translateX = offsetFromSide - left;
      if (bottom > (window.innerHeight - offsetFromSide)) translateY = (window.innerHeight - offsetFromSide) - bottom;
      else if (top < offsetFromSide) translateY = offsetFromSide - top;
      this.cardRef.style.transform = `translateX(${left + translateX}px) translateY(${top + translateY}px)`;
    }
    {
      const { top, left, right, bottom } = this.cardRef.getBoundingClientRect();
      const newBounds = {
        top: top - this.anchorBounds.height,
        left: left - this.anchorBounds.height,
        right: right + this.anchorBounds.height,
        bottom: bottom + this.anchorBounds.height,
      }
      if (this.bounds && this.bounds.top === newBounds.top && this.bounds.bottom === newBounds.bottom && this.bounds.left === newBounds.left && this.bounds.right === newBounds.right) {
        this.cardRef.style.opacity = 1;
        this.removeInterval();
      }
      this.bounds = newBounds;
    }
  }
  onMouseMove = (e) => {
    if (!this.cardRef || !this.bounds) return;
    const { top, left, right, bottom } = this.bounds;
    const { onClose } = this.props;
    const { pageX, pageY } = e;
    if ((pageX < left) || (pageX > right) || (pageY < top) || (pageY > bottom)) onClose();
  }
  onClickBackground = (e) => {
    const { allowBackgroundClose, onClose } = this.props;
    const { mobile } = WindowSize;
    if (!((mobile || allowBackgroundClose) && onClose)) return;
    e.stopPropagation();
    e.preventDefault();
    onClose();
  }
  render() {
    const { contentBackgroundColor, onClose, children, anchorEl, showCloseButton, allowClickThrough, absolute } = this.props;
    const { mobile } = WindowSize;
    const { innerHeight } = Connect;
    const maxHeight = innerHeight ? `calc(${innerHeight}px - 6rem)` : 'calc(100vh - 6rem)';
    const html = anchorEl ? (
      <ClearWrapper ref={this.onRef} style={{ pointerEvents: (!mobile && allowClickThrough) ? 'none' : 'unset', position: absolute ? 'absolute' : 'fixed' }}
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <AnchorCard
          ref={this.onCardRef}
          style={{ maxHeight, opacity: 0, backgroundColor: contentBackgroundColor || 'white', position: absolute ? 'absolute' : 'fixed' }}
          onClick={(e) => { e.stopPropagation(); }}
          onMouseDown={(e) => { e.stopPropagation(); }}
        >
          {children}
        </AnchorCard>
      </ClearWrapper>
    ) : (
      <Wrapper
        ref={this.onRef}
        style={{ backgroundColor: `rgba(32,33,36,.6)`, position: absolute ? 'absolute' : 'fixed' }}
        onClick={(e) => {
          e.stopPropagation();
          this.onClickBackground(e);
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <Card
          ref={this.onFullscreenCardRef}
          style={{ maxHeight, backgroundColor: contentBackgroundColor || 'white' }}
          onClick={(e) => { e.stopPropagation(); }}
          onMouseDown={(e) => { e.stopPropagation(); }}>
          {children}
        </Card>
        {showCloseButton && onClose && <CloseButton onClick={onClose}><i className="fas fa-times" /></CloseButton>}
      </Wrapper>
    );
    if (absolute) return html;
    return ReactDOM.createPortal(html, document.body);
  }
}
Popup.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
  contentBackgroundColor: PropTypes.string,
  allowBackgroundClose: PropTypes.bool,
  allowClickThrough: PropTypes.bool,
  allowMoveClose: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  anchorEl: PropTypes.any,
  anchorBottomRight: PropTypes.bool,
  preventDeletion: PropTypes.bool,
  showFullscreen: PropTypes.bool,
};
export default Popup;
