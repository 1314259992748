import React from 'react';
import styled from 'styled-components';
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  select {
    padding: 8px 0;
    appearance: none;
    background-color: #fff;
    cursor: pointer;
    margin-right: -20px;
    padding-right: 20px;
  }
  i {
    pointer-events: none;
  }
`;


export default function Select(props) {
  return (
    <Wrapper>
      <select {...props}></select>
      <i className="fas fa-caret-down"></i>
    </Wrapper>
  ) 
}