/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-unresolved
import ObjectCache from './CacheService';
import LogicBaseTopicApi from '../server/LogicBaseTopicApi';
import UserAccount from '../UserAccount';
import LogicBaseTopic from '../../models/logicbasetopic';

class LogicBaseTopicCache extends ObjectCache {
  api = LogicBaseTopicApi;
  createObject = (_results) => {
    const results = JSON.parse(JSON.stringify(_results));
    return new LogicBaseTopic(results._id, results.t, results.d, results.i, results.u);
  }
  revertObject = (_object) => {
    const results = this.objectJson[_object._id];
    this.objects[_object._id] = this.createObject(results);
    this.emitStateUpdate();
  };
  getObjectJson = (_model) => _model.getJson();
}
const singleton = new LogicBaseTopicCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
