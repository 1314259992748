import Node from './node';
import { GOOGLE_DRIVE_ATTR } from './nodeattributes';

export default class AutoCreateLogic extends Node {
  constructor(_text, _attributes, _children, _owner) {
    super(_text, _attributes, _children, _owner);
    this.__defineNodeAttribute('dataSource', [{ name: 'Google Drive', value: GOOGLE_DRIVE_ATTR, default: true }]);
  }
  convertDriveToTree(drive) {
    this.parents[0].__recursivelyRemoveChildren(this);
    if (!drive.files) return this.emitStateUpdate();
    recursivelySortJson(drive.files);
    const treeJson = drive.files.map((c) => convertJsonToTree(c, 0));
    this.parents[0].__recursivelyCreateChildren(this, treeJson);
    return this.emitStateUpdate();
  }
  clone() {
    return new AutoCreateLogic(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
}
function recursivelySortJson(files) {
  files.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (b.name.toLowerCase() < a.name.toLowerCase()) return 1;
    return 0;
  });
  files.sort((a, b) => {
    if (a.mimeType === 'application/vnd.google-apps.folder' && b.mimeType !== 'application/vnd.google-apps.folder') return -1;
    if (a.mimeType !== 'application/vnd.google-apps.folder' && b.mimeType === 'application/vnd.google-apps.folder') return 1;
    return 0;
  });
  files.forEach((file) => recursivelySortJson(file.children));
}
function convertJsonToTree(json) {
  let node = {};
  if (json.mimeType === 'application/vnd.google-apps.folder') node = { attributes: ['Category'], children: json.children && json.children.map((c) => convertJsonToTree(c)) || [], text: json.name };
  else node = { attributes: ['Text'], children: [], text: `<div style="display: flex; align-items: center;"><img src="${json.iconLink}" style="margin-right: 0.5rem; height: 16px; width: 16px;" /><a href="${json.webViewLink}">${json.name}</a></div>` };
  return node;
}
