export function convertLogicSyntaxIntoStructuredText(logicSyntax) {
  let id = 0;
  function convertChildrenToText(node, level) {
    let text = '';
    const type = node.attributes[0];
    if (type === 'Text') {
      text = `<section id=${id}>${node.text}</section>`;
      node.id = id.toString();
      id += 1;
    } else if (type === 'Category') {
      text = `<section id=${id}><h${level}>${node.text}</h${level}></section>`;
      node.id = id.toString();
      id += 1;
    }
    if (type === 'Category' || type === 'Root') {
      text += node.children.map((n) => convertChildrenToText(n, level + 1)).join('');
    } else {
      text += node.children.map((n) => convertChildrenToText(n, level)).join('');
    }
    return text;
  }
  return convertChildrenToText(logicSyntax.children.find((c) => c.attributes[0] === 'Root'), 1);
}
export function convertStructuredTextIntoLogicSyntax(text, initialLogicSyntax) {
  const root = initialLogicSyntax.children.find((c) => c.attributes[0] === 'Root');
  if (root.children.length === 0) {
    const newSyntax = recursivelyImportUnstructuredText(text);
    recursivelyReformatChildren(newSyntax);
    root.children = newSyntax.children;
    function recursivelyReformatChildren(newSyntax) {
      newSyntax.children = newSyntax.children.map(({ text, type, children }) => ({ text, attributes: [type], children }));
      newSyntax.children.forEach(recursivelyReformatChildren);
    }
    return;
  }
  const parser = new DOMParser();
  const dom = parser.parseFromString(text, 'text/html');
  let lastId = 0;
  const sections = Array.from(dom.body.childNodes).map((s) => {
    const newNode = s.cloneNode(true);
    if (newNode.nodeName === 'SECTION') {
      lastId = s.id;
      return newNode;
    } else {
      const section = document.createElement('section');
      section.id = lastId;
      section.appendChild(newNode);
      return section;
    }
  });
  recursivelyTraverseNodesReplacingMatchedKey(root, null);
  function recursivelyTraverseNodesReplacingMatchedKey(node, parent) {
    const type = node.attributes[0];
    if (type === 'Text' || type === 'Category') {
      const foundSection = sections.filter((s) => s.id === node.id);
      if (foundSection.length > 0) {
        const originalFoundHeader = ['<h1>','<h2>','<h3>','<h4>','<h5>','<h6>'].findIndex((c) => node.text.includes(c)) + 1;
        node.text = foundSection[0].innerHTML;
        const foundHeader = ['<h1>','<h2>','<h3>','<h4>','<h5>','<h6>'].findIndex((c) => node.text.includes(c)) + 1;
        if (type === 'Category') {
          if (foundHeader > 0 && originalFoundHeader !== foundHeader) {
            node.text = node.text.replace(`<h${foundHeader}>`,'').replace(`</h${foundHeader}>`,'');
          }
        }
        let currentNodeIndex = parent.children.indexOf(node);
        foundSection.slice(1).forEach((newNode) => {
          currentNodeIndex += 1;
          parent.children.splice(currentNodeIndex, 0, {
            text: newNode.innerHTML,
            attributes: ['Text'],
            children: [],
          });
        });
      } else {
        const currentNodeIndex = parent.children.indexOf(node);
        parent.children.splice(currentNodeIndex, 1);
      }
    }
    [...node.children].forEach((n) => recursivelyTraverseNodesReplacingMatchedKey(n, node));
  }
}
export default function recursivelyImportUnstructuredText(text) {
  const nodes = getNodesFromText(text);
  const categories = {
    children: [],
  };
  nodes.forEach((node) => {
    const foundHeader = ['<h1>','<h2>','<h3>','<h4>','<h5>','<h6>'].findIndex((c) => node.includes(c)) + 1;
    if (foundHeader > 0) {
      const text = node.replace(`<h${foundHeader}>`,'').replace(`</h${foundHeader}>`,'');
      __recursivelyAddCategoryToLastCategory(text, categories, 1, foundHeader);
    } else {
      __recursivelyAddTextToLastCategory(node, categories);
    }
  });
  function __recursivelyAddCategoryToLastCategory(text, categories, currentLevel, targetLevel) {
    if (currentLevel === targetLevel) {
      categories.children.push({
        type: 'Category',
        text,
        children: [],
      });
    } else {
      const lastNode = categories.children[categories.children.length - 1];
      if (!lastNode || lastNode.type !== 'Category') {
        categories.children.push({
          type: 'Category',
          children: [],
        });
      }
      __recursivelyAddCategoryToLastCategory(text, categories.children[categories.children.length - 1], currentLevel + 1, targetLevel);
    }
  }
  function __recursivelyAddTextToLastCategory(text, categories) {
    const lastNode = categories.children[categories.children.length - 1];
    if (lastNode && lastNode.type === 'Category') __recursivelyAddTextToLastCategory(text, lastNode);
    else categories.children.push({
      type: 'Text',
      text,
      children: [],
    });
  }
  return categories;
}
function getNodesFromText(htmlString) {
  const parser = new DOMParser();
  const dom = parser.parseFromString(htmlString, 'text/html');
  let allNodes = [''];
  dom.body.childNodes.forEach(__recursivelyParseHTML);
  allNodes = allNodes.filter((n) => n);
  return allNodes;
  function __recursivelyParseHTML(currentNode) {
    const currentText = currentNode.outerHTML || currentNode.innerHTML || currentNode.textContent;
    if (['H1','H2','H3','H4','H5','H6'].includes(currentNode.tagName)) {
      allNodes.push((currentText).replace(/\r?\n/g, ''));
      allNodes.push('');
    } else if (!['<h1>', '<h2>', '<h3>', '<h4>', '<h5>', '<h6>'].find((t) => (currentText).includes(t))) {
      const lastIndex = allNodes.length - 1;
      const currentString = allNodes[lastIndex];
      allNodes[lastIndex] = `${currentString}${(currentText).replace(/\r?\n/g, '')}`;
    } else {
      currentNode.childNodes.forEach(__recursivelyParseHTML);
    }
  }
}
