import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import UserCache from '../../services/cache/UserCache';
import SpreadsheetCache from '../../services/cache/SpreadsheetCache';
import TreeCache from '../../services/cache/TreeCache';
import Button from '../../components/Button/index';
import Navigation from '../../services/Navigation';
import Tree from '../../models/tree';
import Spreadsheet from '../../models/spreadsheet';
import Constants from '../../submodules/logictry_config/constants';
import { AccountTemplatesOpen, getTeamTemplatesOpen } from '../../services/Pagination/index';
import Popup from '../../components/Popup/index';
import ConfirmDialog from '../../components/ConfirmDialog';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - 6rem);
  width: ${Constants.ListPopupWidth};
  max-width: 90vw;

  input {
    padding: 20px;
    width: 100%;
  }
`;
const TreesWrapper = styled.div`
  border-top: 1px solid ${Constants.DarkGray};
  flex: 1;
  overflow: auto;
  width: 100%;
  ${Constants.MediaMobile} {
    justify-content: center;
  }
`;

const TreeBox = styled.div`
  display: flex;
  align-items: center;
  color: ${Constants.DarkTextColor};
  padding: 10px;
  width: 100%;
  height: 50px;
  overflow: hidden;

  > div:first-child {
    border-radius: 8px;
    min-width: 40px;
    width: 40px;
    min-height: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-right: 10px;
    background-color: ${Constants.PrimaryColor};
    color: ${Constants.White};
    font-size: ${Constants.SmallFontSize};
  }
  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    width: calc(100% - 50px);
    white-space: nowrap;
    > div {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    > div:nth-child(2) {
      font-size: ${Constants.SmallFontSize};
    }
  }
`;
const ShowMoreButton = styled.div`
  margin: 20px;
  display: flex;
  justify-content: center;
`;

export default class CreateProjectPopup extends React.PureComponent {
  static propTypes = {
    treeOrSpreadsheet: PropTypes.object,
    team: PropTypes.string,
    onClose: PropTypes.func,
  }
  state = {
    showCreateNotAllowed: false,
  }
  constructor(props) {
    super(props);
    const { team } = this.props;
    this.paginationService = team ? getTeamTemplatesOpen(team) : AccountTemplatesOpen;
  }
  componentDidMount() {
    UserCache.onStateUpdate(this);
    this.paginationService.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserCache.offStateUpdate(this);
    this.paginationService.offStateUpdate(this);
  }
  searchText = () => {
    this.forceUpdate();
  }
  saveAndClose = () => {
    const { treeOrSpreadsheet, onClose } = this.props;
    if (treeOrSpreadsheet instanceof Tree) {
      TreeCache.updateTree(treeOrSpreadsheet);
    } else if (treeOrSpreadsheet instanceof Spreadsheet) {
      SpreadsheetCache.update(treeOrSpreadsheet);
    }
    onClose();
  }
  treeSelected = (e, _tree, _team) => {
    e.preventDefault();
    e.stopPropagation();
    if (!_tree.isSavingProjectsAllowed) return this.setState({ showCreateNotAllowed: true });
    return Navigation.push(`/apps/${_tree._id}?autocreate=true${_team ? `&team=${_team}` : ''}`);
  }

  render() {
    const { onClose, team } = this.props;
    const { showCreateNotAllowed } = this.state;
    const { currentPage, showMore } = this.paginationService;
    return (
      <Popup
        onClose={onClose}
        allowBackgroundClose
      >
        <Wrapper>
          <div>
            <h2>Select App</h2>
          </div>
          <TreesWrapper>
            {currentPage && currentPage.length > 0 ? (currentPage.map((_tree) => (
              <TreeBox role="button" key={_tree._id} onClick={(e) => this.treeSelected(e, _tree, team)}>
                <div>
                  <i className="fas fa-network-wired"></i>
                </div>
                <div>
                  <div>{(_tree.title && _tree.title.text) || 'Untitled App'}</div>
                  <div>{_tree.formattedUpdateTime} - {(_tree.description && _tree.description.text) || ''}</div>
                </div>
              </TreeBox>
            ))) : ( 
              <div style={{ lineHeight: '50px', textAlign: 'center' }}>No Apps Found</div>
            )}
            {showMore && <ShowMoreButton><Button onClick={() => this.paginationService.getMore()}>Show More</Button></ShowMoreButton>}
          </TreesWrapper>
        </Wrapper>
        {showCreateNotAllowed && <ConfirmDialog
          open={showCreateNotAllowed}
          text="Create Not Allowed"
          description="Creating a project from this App has been disabled. Select a different App."
          onOk={() => this.setState({ showCreateNotAllowed: false })}
        />}
      </Popup>
    );
  }
}
