import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import Company from '../../services/Company';
import determineTextColor from '../../utils/determinetextcolor';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  div {
    padding: 6px 0;
    border-radius: 3px;
    font-size: 13px;
    cursor: pointer;
  }
`;

const NavbarBackground = window.logictry && window.logictry.company && window.logictry.company.theme && window.logictry.company.theme.NavbarBackground;

function LoginButton(props) {
  const { isAuthorizedToLogIn } = Company;
  return (
    <Wrapper>
      <div
        style={{
          color: determineTextColor(!isAuthorizedToLogIn && NavbarBackground || Constants.White)
        }}
        onClick={() => {
          Navigation.push(Navigation.login);
        }}>
        Login
      </div>
      <div
        style={{
          color: determineTextColor(!isAuthorizedToLogIn && NavbarBackground || Constants.White)
        }}
        onClick={() => {
          Navigation.push(Navigation.register);
        }}>
        Sign Up
      </div>
    </Wrapper>
  )
}
LoginButton.propTypes = {
  isApp: PropTypes.bool,
}
export default LoginButton;
