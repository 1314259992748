import React from 'react';
import styled from 'styled-components';
// import { PropTypes } from 'prop-types';
// import Constants from '../../submodules/logictry_config/constants';
import CompanyService from '../../services/Company';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import LoadingIndicator from '../../components/LoadingIndicator';
import Button from '../Button/index';
import SelectMenu from '../SelectMenu';
import Popup from '../Popup/index';
import BoxPopup from '../../containers/TreePage/BoxPopup';
import { AccountCompanies } from '../../services/Pagination/index';

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid gray;
  padding: 2rem;
  h1 {
    margin-bottom: 1rem;
  }
`;
const ButtonsWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`;

export default class CompanyBox extends BoxPopup {
  componentDidMount() {
    AccountCompanies.onStateUpdate(this);
  }
  componentWillUnmount() {
    AccountCompanies.offStateUpdate(this);
  }
  companyChnaged = (newCompany) => {
    const { domainObject } = this.props;
    domainObject.company = newCompany;
    this.forceUpdate();
  }
  render() {
    const { domainObject } = this.props;
    const { currentPage } = AccountCompanies;
    if (!currentPage) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    let options = [{ name: 'Personal Account', value: '' }];
    if (CompanyService.company) options.push({ name: CompanyService.company.longname, value: CompanyService.company._id });
    options = [...options, ...currentPage.map(({ _id, longname }) => ({ name: longname, value: _id }))];
    return (
      <Popup
        allowBackgroundClose
        onClose={this.close}
      >
        <Wrapper key={currentPage.length}>
          <h1>Move to Company</h1>
          <SelectMenu
            options={options}
            value={domainObject.company || 'Personal Account'}
            onChange={this.companyChnaged}
          />
          <ButtonsWrapper>
            <Button onClick={this.close} color="primary" autoFocus>
              Cancel
            </Button>
            <Button onClick={this.saveAndClose} color="primary" autoFocus>
              Save
            </Button>
          </ButtonsWrapper>
        </Wrapper>
      </Popup>
    );
  }
}
