import Node from './node';
import { CATEGORY } from './nodetypes';
import { MAX_DEPTH_ATTR, NONE_ATTR, FORM_ATTR, SHOW_PROGRESS_ATTR, HIDE_NAV_BUTTONS_ATTR, HIDE_PAGE_TITLES_ATTR, SHOW_INFO_PANEL_ATTR, HIDE_SUBMIT_BUTTON_ATTR } from './nodeattributes';

export default class Wiki extends Node {
  constructor(_text, _attributes, _children, _owner) {
    super(_text, _attributes, _children, _owner);
    this.__defineNodeAttribute('contentFormat', [{ name: 'Basic Layout', value: NONE_ATTR, default: true }, { name: 'Form Layout', value: FORM_ATTR }]);
    // this.__defineNodeAttribute('contentFormat', [{ name: NONE_ATTR, value: NONE_ATTR, default: true }, { name: FORM_ATTR, value: FORM_ATTR }, { name: WIKI_LAYOUT_ATTR, value: WIKI_LAYOUT_ATTR }]);
    this.__defineNodeAttribute('showProgress', [{ name: 'Hide Progess', value: NONE_ATTR, default: true }, { name: 'Show Progress', value: SHOW_PROGRESS_ATTR }]);
    this.__defineNodeAttribute('navButtons', [{ name: 'Show Nav Buttons', value: NONE_ATTR, default: true }, { name: 'Hide Nav Buttons', value: HIDE_NAV_BUTTONS_ATTR }]);
    this.__defineNodeAttribute('pageTitles', [{ name: 'Show Page Titles', value: NONE_ATTR, default: true }, { name: 'Hide Page Titles', value: HIDE_PAGE_TITLES_ATTR }]);
    this.__defineNodeAttribute('infoPanel', [{ name: 'Hide Info Panel', value: NONE_ATTR, default: true }, { name: 'Show Info Panel', value: SHOW_INFO_PANEL_ATTR }]);
  }
  get maxDepth() {
    const maxDepth = this.attributes.find((a) => a.includes(MAX_DEPTH_ATTR));
    if (maxDepth) return Math.min(parseInt(maxDepth.split('=')[1], 10), 3);
    return 1;
  }
  get isDynamicHeightElement() {
    return this.contentFormatOption !== FORM_ATTR;
  }
  get showInfoPanel() {
    return this.attributes.includes(SHOW_INFO_PANEL_ATTR);
  }
  get hideSubmitButton() {
    return this.attributes.includes(HIDE_SUBMIT_BUTTON_ATTR);
  }
  updateMaxDepth(_depth) {
    if (_depth < 1 || _depth > 3) return this.__attributeChanged();
    this.attributes = this.attributes.filter((_attr) => !_attr.includes(MAX_DEPTH_ATTR));
    if (parseInt(_depth, 10) === 1) return this.__attributeChanged();
    return this.addAttribute(`${MAX_DEPTH_ATTR}${_depth}`);
  }
  get sections() {
    return this.__recursivelyGetAllCategories(this, this.parents, 0, this.maxDepth);
  }
  clone() {
    return new Wiki(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
  __recursivelyGetAllCategories(node, parents, level, maxDepth) {
    let nodes = [];
    node.children.forEach((child) => {
      if (child.isType(CATEGORY)) {
        nodes.push({ child, parents: [...parents, node], level, dontDisplayCategories: (level + 1 < maxDepth) });
      }
      const childResults = (level + 1 < maxDepth) ? this.__recursivelyGetAllCategories(child, [...parents, node], level + 1, maxDepth) : [];
      nodes = [...nodes, ...childResults];
    });
    return nodes;
  }
}
