/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
import { CHECKLIST, CONDITIONAL, FILTER, QUESTION, CALCULATION_LOGIC, CALCULATION, CALCULATION_OPERATOR, ANSWER, CHECKLIST_ITEM } from './nodetypes';
import { NODE_ATTR, AVERAGE_ATTR, ADD_ATTR, SUBTRACT_ATTR, MULTIPLY_ATTR, DIVIDE_ATTR, CONSTANT_ATTR, ROUND_ATTR, MAX_ATTR, MIN_ATTR, DATE_TIME_ATTR, DATE_ATTR, TIME_ATTR } from './nodeattributes';
import Logic from './logic';

export default class CalculationLogic extends Logic {
  clone() {
    return new CalculationLogic(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
  __update() {
    const calculations = this.__recursivelyGetAllCalculations(this);
    this.__parseAllCalculations(calculations);
    this.__updateAllParentsOnNodeUpdated();
  }
  __parseAllCalculations(calculations) {
    calculations.forEach((calculation, i) => {
      const keyvalpairs = this.__recursivelyGetAnswerKeyValuePairs(this);
      const calculationOperators = this.__recursivelyGetAllCalculationOperators(calculation);
      const firstCalculationOperator = calculationOperators[0];
      if (!firstCalculationOperator) return;
      const result = this.parseCalculation(calculations.slice(0, i), keyvalpairs, firstCalculationOperator);
      calculation.updateCalculation(result);
    });
  }
  parseCalculation(_calculations, _keyvalpairs, _calculation) {
    const { typeOption, text } = _calculation;
    if (typeOption === NODE_ATTR) {
      if (Object.hasOwnProperty.call(_keyvalpairs, text)) return _keyvalpairs[text];
      return null;
    }
    if (typeOption === CONSTANT_ATTR) return parseFloat(text);
    const calculationOperators = this.__recursivelyGetAllCalculationOperators(_calculation);
    const values = calculationOperators.map((operator) => this.parseCalculation(_calculations, _keyvalpairs, operator)).filter((v) => typeof v === 'number' && !isNaN(v));
    if (typeOption === MULTIPLY_ATTR) {
      if (values.length < 2) return null;
      return values.reduce((a, b) => a * b);
    }
    if (typeOption === DIVIDE_ATTR) {
      if (values.length < 2) return null;
      return values.reduce((a, b) => a / b);
    }
    if (typeOption === AVERAGE_ATTR) {
      if (values.length < 1) return null;
      return values.reduce((a, b) => a + b) / values.length;
    }
    if (typeOption === ADD_ATTR) {
      if (values.length < 1) return null;
      return values.reduce((a, b) => a + b);
    }
    if (typeOption === SUBTRACT_ATTR) {
      if (values.length < 1) return null;
      return values.reduce((a, b) => a - b);
    }
    if (typeOption === ROUND_ATTR) {
      if (values.length < 1) return null;
      return Math.round(values[0]);
    }
    if (typeOption === MAX_ATTR) {
      if (values.length < 1) return null;
      return Math.max(...values);
    }
    if (typeOption === MIN_ATTR) {
      if (values.length < 1) return null;
      return Math.min(...values);
    }
    return null;
  }
  __recursivelyGetAnswerKeyValuePairs(node, keyvals = {}) {
    node.children.forEach((_child) => {
      if (_child.isOneOfTypes([ANSWER, CHECKLIST_ITEM])) {
        if (!_child.checked) return;
      }
      if (_child.isOneOfTypes([CHECKLIST, FILTER, QUESTION])) {
        const { answers } = _child;
        if (!answers) return;
        const answerChecked = answers.find((answer) => answer.checked);
        if (!answerChecked) return;
        if ([DATE_TIME_ATTR, DATE_ATTR, TIME_ATTR].includes(_child.userInputType)) keyvals[_child.text] = (new Date(answerChecked.text)).getTime();
        else keyvals[_child.text] = parseFloat(answerChecked.text);
      }
      if (_child.isType(CALCULATION)) {
        if (_child.calculatedValue) keyvals[_child.text] = parseFloat(_child.calculatedValue);
        return;
      }
      if (_child.isOneOfTypes([CONDITIONAL])) {
        if (!_child.conditionalValue) return;
      }
      this.__recursivelyGetAnswerKeyValuePairs(_child, keyvals);
    });
    return keyvals;
  }
  __recursivelyGetAllCalculations(node) {
    let nodes = [];
    node.children.forEach((_child) => {
      if (_child.isType(CALCULATION_LOGIC)) return;
      if (_child.isType(CALCULATION)) {
        nodes.push(_child);
        return;
      }
      const childResults = this.__recursivelyGetAllCalculations(_child);
      nodes = [...nodes, ...childResults];
    });
    return nodes;
  }
  __recursivelyGetAllCalculationOperators(node) {
    let nodes = [];
    node.children.forEach((_child) => {
      if (_child.isType(CALCULATION)) return;
      if (_child.isType(CALCULATION_OPERATOR)) {
        nodes.push(_child);
        return;
      }
      const childResults = this.__recursivelyGetAllCalculationOperators(_child);
      nodes = [...nodes, ...childResults];
    });
    return nodes;
  }
}
