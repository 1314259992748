/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import TreeAnswerPage from '../TreeAnswerPage/index';

const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    position: absolute;
    min-height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
`;

export default class AppLandingPage extends React.PureComponent {
  static = {
    app: PropTypes.string,
    maxWidth: PropTypes.string,
  }
  render() {
    const { app, maxWidth } = this.props;
    return (
      <Wrapper>
        <div style={{ maxWidth }}>
          <TreeAnswerPage fullscreen showAppId={app} />
        </div>
      </Wrapper>
    );
  }
}
