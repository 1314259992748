import Service from './Service';
import LogicBaseFeaturedApi from './server/LogicBaseFeaturedApi';

class Featured extends Service {

}
const singleton = new Featured();
LogicBaseFeaturedApi.query().then((featuredExpert) => {
  singleton.featuredExpert = featuredExpert;
  singleton.emitStateUpdate();
});
export default singleton;

