import Constants from '../submodules/logictry_config/constants';

export default function changeRelativeLinksToAbsoluteForNativeApp(text) {
  if (!Constants.isIphone) return text;
  return text
    .replaceAll(`http://`, `https://`)
    .replaceAll(`href="/links/`, `href="https://logictry.com/links/`)
    .replaceAll(`href='/links/`, `href='https://logictry.com/links/`)
    .replaceAll(`href="/v1/`, `href="https://logictry.com/v1/`)
    .replaceAll(`href='/v1/`, `href='https://logictry.com/v1/`)
}
