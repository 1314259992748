import Model from './model';


export default class ApiKeys extends Model {
  /**
   * Constructor
   */
  constructor(_id, createdTime, updateTime, company, whitelist, state) {
    super();
    this._id = _id;
    this.createdTime = createdTime;
    this.updateTime = updateTime;
    this.company = company;
    this.whitelist = whitelist;
    this.state = state;
  }
  getJson() {
    const { _id, company, whitelist, state } = this;
    return {
      _id,
      company,
      whitelist,
      state,
    };
  }
}
