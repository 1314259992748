import Service from '../Service';
import Server from './index';

class TreeApi extends Service {
  showJson = false;
  async getTree(id, _query = {}) {
    if (this.showJson) return Server.__getRequest(`/apps/${id}.json?${Object.keys(_query).map((_key) => `${_key}=${_query[_key]}`).join('&')}`, null, id);
    return Server.__getRequest(`/apps/${id}?${Object.keys(_query).map((_key) => `${_key}=${_query[_key]}`).join('&')}`, null, id);
  }
  async getCount(_query) {
    return Server.__getRequest(`/apps/count?${Object.keys(_query).map((_key) => `${_key}=${_query[_key]}`).join('&')}`);
  }
  async getAccountTrees(_query) {
    return Server.__getRequest(`/apps?${Object.keys(_query).map((_key) => `${_key}=${_query[_key]}`).join('&')}`);
  }
  async createTree(_tree) {
    return Server.__postRequest('/apps', _tree);
  }
  async updateTree(_tree) {
    return Server.__patchRequest('/apps', _tree);
  }
  async deleteTree(id, permanently) {
    return Server.__deleteRequest(`/apps/${id}`, { permanently });
  }
}

const singleton = new TreeApi();
export default singleton;
