import Node from './node';
import { COMMA_DELIMITED_ATTR, SEMICOLON_DELIMITED_ATTR, PIPE_DELIMITED_ATTR, LOGICTRY_SPREADSHEET_ATTR, GOOGLE_SPREADSHEET_ATTR, RESTFUL_API_ATTR, TABLE_ATTR, READ_ONLY_ATTR, WRITE_ONLY_ATTR, READ_WRITE_ATTR, INCLUDE_EMPTY_CELLS_ATTR, EXCLUDE_EMPTY_CELLS_ATTR, PROJECTS_ATTR } from './nodeattributes';
import { DATA_SCHEMA, DATA_DERIVED } from './nodetypes';

const delimiterOptions = [{ name: 'Comma Delimited', value: COMMA_DELIMITED_ATTR }, { name: 'Semicolon Delimited', value: SEMICOLON_DELIMITED_ATTR }, { name: 'Pipe Delimited', value: PIPE_DELIMITED_ATTR }];
const Delimiters = [COMMA_DELIMITED_ATTR, SEMICOLON_DELIMITED_ATTR, PIPE_DELIMITED_ATTR];

const DataSourceOptions = [{ name: 'Logictry Spreadsheet', value: LOGICTRY_SPREADSHEET_ATTR }, { name: 'Google Spreadsheet', value: GOOGLE_SPREADSHEET_ATTR }, { name: 'Restful API', value: RESTFUL_API_ATTR }, { name: 'Submissions', value: PROJECTS_ATTR }];
const DataSources = [LOGICTRY_SPREADSHEET_ATTR, GOOGLE_SPREADSHEET_ATTR, RESTFUL_API_ATTR, PROJECTS_ATTR];

export default class Data extends Node {
  constructor(_text, _attributes, _children, _owner) {
    super(_text, _attributes, _children, _owner);
    this.__defineNodeAttribute('readWrite', [{ name: 'Read Only', value: READ_ONLY_ATTR, default: true }, { name: 'Write Only', value: WRITE_ONLY_ATTR }, { name: 'Read / Write', value: READ_WRITE_ATTR }]);
    this.__defineNodeAttribute('emptyCells', [{ name: 'Include Empty Cells', value: INCLUDE_EMPTY_CELLS_ATTR, default: true }, { name: 'Exclude Empty Cells', value: EXCLUDE_EMPTY_CELLS_ATTR }]);
  }
  clone() {
    return new Data(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
  get isReadable() {
    return !this.isWritable || this.attributes.includes(READ_WRITE_ATTR);
  }
  get isWritable() {
    return this.attributes.includes(WRITE_ONLY_ATTR) || this.attributes.includes(READ_WRITE_ATTR);
  }
  get dataSource() {
    return this.attributes.find((_attr) => DataSources.includes(_attr)) || LOGICTRY_SPREADSHEET_ATTR;
  }
  get isLogictrySpreadsheet() {
    return this.dataSource === LOGICTRY_SPREADSHEET_ATTR;
  }
  get isGoogleSpreadsheet() {
    return this.attributes.includes(GOOGLE_SPREADSHEET_ATTR);
  }
  get isRestfulApi() {
    return this.attributes.includes(RESTFUL_API_ATTR);
  }
  get isProjects() {
    return this.attributes.includes(PROJECTS_ATTR);
  }
  get isTable() {
    return this.attributes.includes(TABLE_ATTR);
  }
  get dataSchema() {
    return (this.children.find((child) => child.isType(DATA_SCHEMA)) || { schema: {} }).schema;
  }
  get postSchema() {
    return (this.children.find((child) => child.isType(DATA_SCHEMA)) || { schema: {} }).postSchema;
  }
  get dataDerived() {
    return this.children.filter((child) => child.isType(DATA_DERIVED));
  }
  get dataSourceOptions() {
    return DataSourceOptions;
  }
  get delimiter() {
    return this.attributes.find((_attr) => Delimiters.includes(_attr)) || COMMA_DELIMITED_ATTR;
  }
  get delimiterString() {
    if (this.delimiter === SEMICOLON_DELIMITED_ATTR) return ';';
    if (this.delimiter === PIPE_DELIMITED_ATTR) return '|';
    return ',';
  }
  get delimiterOptions() {
    return delimiterOptions;
  }
  updateDataSourceOption(_attribute) {
    this.attributes = this.attributes.filter((_attr) => !DataSources.includes(_attr));
    if (_attribute !== LOGICTRY_SPREADSHEET_ATTR) this.addAttribute(_attribute);
    else this.__attributeChanged();
  }
  updateDelimiterOption(_attribute) {
    this.attributes = this.attributes.filter((_attr) => ![COMMA_DELIMITED_ATTR, SEMICOLON_DELIMITED_ATTR, PIPE_DELIMITED_ATTR].includes(_attr));
    if (_attribute !== COMMA_DELIMITED_ATTR) this.addAttribute(_attribute);
    else this.__attributeChanged();
  }
  updateSpreadsheet(_spreadsheet) {
    if (!(_spreadsheet && this.spreadsheet && this.spreadsheet.key === _spreadsheet.key)) {
      this.spreadsheet = _spreadsheet;
      this.__updateAllParentsOnNodeUpdated();
      this.__updateTreeOnNodeUpdated();
      this.emitStateUpdate();
    }
    this.setBusy(false);
  }
  updateText(_text) {
    this.spreadsheet = null;
    super.updateText(_text);
  }
}
