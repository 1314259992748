import Node from './node';
import { NODE_ATTR, AND_ATTR, OR_ATTR, NOT_ATTR, EQUALS_ATTR, NOT_EQUALS_ATTR, LESS_THAN_ATTR, GREATER_THAN_ATTR, CONSTANT_ATTR } from './nodeattributes';


export default class ConditionalOperator extends Node {
  constructor(_text, _attributes, _children, _owner) {
    super(_text, _attributes, _children, _owner);
    this.__defineNodeAttribute('type', [{ name: NODE_ATTR, value: NODE_ATTR, default: true }, { name: AND_ATTR, value: AND_ATTR }, { name: OR_ATTR, value: OR_ATTR }, { name: NOT_ATTR, value: NOT_ATTR }, { name: EQUALS_ATTR, value: EQUALS_ATTR }, { name: NOT_EQUALS_ATTR, value: NOT_EQUALS_ATTR }, { name: GREATER_THAN_ATTR, value: GREATER_THAN_ATTR }, { name: LESS_THAN_ATTR, value: LESS_THAN_ATTR }, { name: CONSTANT_ATTR, value: CONSTANT_ATTR }]);
  }
  clone() {
    return new ConditionalOperator(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
}
