/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React from 'react';

import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';

export default class LoginToViewPage extends React.PureComponent {
  render() {
    return (
      <FullScreenVerticalCenterContent>
        <h1 style={{ textAlign: 'center' }}>You must login to view</h1>
      </FullScreenVerticalCenterContent>
    );
  }
}
