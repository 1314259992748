/**
 * A link to a certain page, an anchor tag
 */

import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
