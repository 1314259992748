import React from 'react';
import styled from 'styled-components';
import LogicBaseAppList from '../../components/LogicBaseAppList';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import WindowSize from '../../services/WindowSize';
import { getLogicBaseTopicApps, getLogicBaseAppsSearch, getLogicBaseLatestApps, getLogicBaseTopLikesApps, getLogicBaseTopSavesApps } from '../../services/Pagination';
import { PropTypes } from 'prop-types';
import Scrollable from '../Scrollable';
import LoadingIndicator from '../LoadingIndicator';

const Apps = styled.div`
  inset: 0;
  overflow: hidden;
  > div {
    flex: 1;
    width: 100%;
  }
`;

export default class LogicBaseAppLists extends React.PureComponent {
  static propTypes = {
    showUser: PropTypes.bool,
    menuLeft: PropTypes.bool,
  }
  constructor() {
    super();
    const q = Navigation.currentLocation[1] === 'search' && Navigation.currentLocation[2] && unescape(Navigation.currentLocation[2]) || Navigation.currentSearch.q || '';
    const t = Constants.isIphone ? Navigation.currentLocation[3] : Navigation.currentSearch.t;
    if (q && !['topic'].includes(q)) this.appsCache = getLogicBaseAppsSearch(encodeURIComponent(q));
    else if (!t || t === 'most-liked') this.appsCache = getLogicBaseTopLikesApps();
    else if (t === 'most-pinned') this.appsCache = getLogicBaseTopSavesApps();
    else if (t === 'most-recent') this.appsCache = getLogicBaseLatestApps();
    else this.appsCache = getLogicBaseTopicApps(t);
    this.state = { q, t };
  }
  componentDidMount() {
    this.appsCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    this.appsCache.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  onPagination = () => {
    if (this.appsCache.showMore && this.appsCache.allFound && this.appsCache.initialized) {
      this.appsCache.getMore();
    }
  }
  render() {
    const { mobile } = WindowSize;
    const { q, t } = this.state;
    const { showUser } = this.props;
    let apps = this.appsCache.currentPage;
    return (
      <Apps style={{ position: mobile ? 'relative' : 'absolute' }}>
        <Scrollable
          vertical={!mobile}
          paginationHeight={400}
          onPagination={this.onPagination}
          style={{ position: mobile ? 'relative' : 'absolute', inset: 0, padding: '0 0 2rem' }}
          rememberScrollPosition={`logicbaseapplists_${q}_${t}`}
        >
          <div style={(q && !mobile) && { maxWidth: '68rem', margin: 'auto', padding: '0 2rem 4rem 2rem', position: 'relative' } || { position: 'relative', padding: mobile ? '1rem 0' : '1rem 2rem 1rem 2rem' }}>
            <LogicBaseAppList showUser={showUser} apps={apps} listView={!!(q && !mobile)} />
          </div>
          <div style={{ margin: '4rem auto', display: 'flex', justifyContent: 'center' }}>
            {apps && apps.length > 0 && !this.appsCache.allFound && <LoadingIndicator />}
          </div>
        </Scrollable>
      </Apps>
    );
  }
}
