import Service from './Service';

class SalesForce extends Service {
  loadScript() {
    const script = document.createElement('script');
    script.src = 'https://na112.salesforce.com/canvas/sdk/js/46.0/canvas-all.js';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      window.Sfdc.canvas.onReady(() => {
        window.Sfdc.canvas.client.refreshSignedRequest((signedRequest) => {
          if (signedRequest.status === 200) {
            setTimeout(() => {
              this.loadSuccess = true;
              this.emitStateUpdate();
            });
            const encodedSignedRequest = signedRequest.payload.response;
            this.sr = JSON.parse(window.Sfdc.canvas.decode(encodedSignedRequest.split('.')[1]));
            // window.Sfdc.canvas.client.autogrow(sr.client, true);

            /**
             * Read posts
             */
            const chatterUrl = `${this.sr.client.instanceUrl}${this.sr.context.links.chatterFeedsUrl}/news/me/feed-elements`;
            const idpPosts = [];
            window.Sfdc.canvas.client.ajax(
              chatterUrl, {
                client: this.sr.client,
                success: (data) => {
                  if (data.status === 200) {
                    for (let i = 0; i < data.payload.elements.length; i += 1) {
                      if (!window.Sfdc.canvas.isNil(data.payload.elements[i].body)) {
                        idpPosts.push({
                          from: data.payload.elements[i].actor.name,
                          when: data.payload.elements[i].relativeCreatedDate,
                          text: data.payload.elements[i].body.text,
                          org: this.sr.context.organization.name,
                          date: data.payload.elements[i].createdDate,
                        });
                      }
                    }
                  }
                },
              }
            );
          }
        });
      });
    };
    document.body.appendChild(script);
  }
  postUrl = (text) => {
    const url = `${this.sr.context.links.chatterFeedsUrl}/news/${this.sr.context.user.userId}/feed-items`;
    const body = { body: { messageSegments: [{ type: 'Text', text }] } };
    window.Sfdc.canvas.client.ajax(url,
      {
        client: this.sr.client,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(body),
        success: () => {},
      }
    );
  }
}

const singleton = new SalesForce();
export default singleton;
