import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';

const Wrapper = styled.div`
  margin-right: 12px;
  text-align: center;
  border-radius: 4px;
  background-color: ${Constants.PrimaryColor};
  color: ${Constants.White};
  font-family: monospace !Important;
  font-size: ${Constants.SemiSmallFontSize};
`;

export default class AnswerAnalytics extends React.PureComponent {
  static propTypes = {
    answer: PropTypes.object,
  }
  componentDidMount() {
    const { answer } = this.props;
    if (!answer) return;
    answer.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { answer } = this.props;
    answer.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
  }
  render() {
    const { tree } = GrayOut;
    const { projectsAddedCount } = tree;
    const { answer } = this.props;
    const votes = answer.votes.length;
    const percentage = Math.round((votes / projectsAddedCount) * 100);
    const wider = percentage === 100;
    const width = wider ? '40px' : '34px';
    return (
      <Wrapper style={{ width, minWidth: width }}>
        {percentage}%
      </Wrapper>
    );
  }
}
