/* eslint-disable import/no-cycle */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import TreeDisplay from '../../services/TreeDisplay';
import SidebarService from '../../services/SidebarService';
import SelectMenu from '../../components/SelectMenu/index';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  > div:last-child {
    width: 100%;
    height: 100%;
  }
`;
const TypeSelect = styled.div`
  padding-top: 0.75rem;
  padding-left: 1.5rem;
  color: ${Constants.MediumTextGray};
`;

export default class ShowNodeTypeWrapper extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
    node: PropTypes.object,
  }
  componentDidMount() {
    SidebarService.onStateUpdate(this);
  }
  componentWillUnmount() {
    SidebarService.offStateUpdate(this);
  }
  onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }
  render() {
    const { children, node } = this.props;
    const { type, attributes } = node;
    const { editing } = TreeDisplay;
    const { showNodeTypes } = SidebarService;
    const selectOptions = [{ value: type, name: type }];
    return (
      <Wrapper>
        {editing && showNodeTypes && <TypeSelect onClick={this.onClick}>
          <SelectMenu mini noPadding hideCaret value={`${type} - ${attributes.slice(1).join(', ')}`} onChange={this.updateState} options={selectOptions} />
        </TypeSelect>}
        <div>
          {children}
        </div>
      </Wrapper>
    );
  }
}
