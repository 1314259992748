import ObjectCache from './CacheService';
import UserAccount from '../UserAccount';
import TeamApi from '../server/TeamApi';
import Team from '../../models/team';

class TeamCache extends ObjectCache {
  api = TeamApi;
  createObject = (_results) => {
    const { isDevAccount } = UserAccount;
    const results = JSON.parse(JSON.stringify(_results));
    return new Team(results.owner, results.company, results.users, UserAccount.account._id, results._id, results.longname, results.description, results.createdTime, results.updateTime, isDevAccount, results.shareSetting);
  }
  revertObject = (_object) => {
    const results = this.objectJson[_object._id];
    this.objects[_object._id] = this.createObject(results);
    this.emitStateUpdate();
  }
  getObjectJson = (_model) => _model.getJson();
}

const singleton = new TeamCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
