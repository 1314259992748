import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';

export default styled.div`
  width: calc(100% - 6rem);
  max-width: 56rem;
  ${Constants.MediaMobile} {
    width: calc(100% - 4rem);
  }
`;
