

import React from 'react';
import styled from 'styled-components';
// import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import LoadingIndicator from '../../components/LoadingIndicator';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import UserAccount from '../../services/UserAccount';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb';
import ContentBoxesAndLists from '../../components/ContentBoxesAndLists';
import DatabaseCollectionsBox from '../TreePage/DatabaseCollectionsBox';
import ListViewGridViewSelect from '../TreePage/ListViewGridViewSelect';
import HeaderSection from '../TreePage/HeaderSection';
import SelectMenu from '../../components/SelectMenu/index';
import Scrollable from '../../components/Scrollable/index';

const Wrapper = styled.div`
  display: flex;
  width: calc(100% - 4rem);
  justify-content: center;
  margin: 0 2rem;
  ${Constants.MediaMobile} {
    width: 100%;
    margin: 0;
  }
`

export default class DatabasePage extends React.PureComponent {
  render() {
    if (!UserAccount.isLoggedIn) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    return (
      <Scrollable
        vertical
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <FullScreenVerticalCenterContent alignTop>
          <DrawerBreadcrumb></DrawerBreadcrumb>
          <Wrapper>
            <ContentBoxesAndLists>
              <HeaderSection>
                <SelectMenu value="Database Collections" onChange={this.updateState} options={[{ name: 'Database Collections', value: 'Database Collections' }]} />
                <ListViewGridViewSelect />
              </HeaderSection>
              <DatabaseCollectionsBox />
            </ContentBoxesAndLists>
          </Wrapper>
        </FullScreenVerticalCenterContent>
      </Scrollable>
    );
  }
}
