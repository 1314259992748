/* eslint-disable no-param-reassign */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import UserAccount from '../../services/UserAccount';
import Company from '../../services/Company';
import Popup from '../Popup/index';
import Button from '../Button/index';
import ClickableIcon from '../ClickableIcon/index';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 90vw;
  > div:nth-child(2) {
    border-top: 1px solid ${Constants.DarkGray};
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    > * {
      margin: 20px;
    }
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const WebsiteInput = styled.div`
  > div:first-child {
    display: flex;
    > div {
      line-height: 40px;
    }
    input:nth-child(1) {
      width: 100px;
    }
    input:nth-child(2) {
      width: 300px;
    }
    * {
      margin: 10px;
    }
  }
  > div:last-child {
    margin-left: 20px;
  }
`;

export default class EditHomePopup extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
  }
  constructor() {
    super();
    const { account } = UserAccount;
    this.state = {
      home: account.home || {}
    }
  }
  componentDidMount() {
    UserAccount.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserAccount.offStateUpdate(this);
  }
  changeProperty = (website, value) => {
    this.changed = true;
    if (Company.company && Company.company._id) website[Company.company._id] = value;
    else website._id = value;
  }
  close = () => {
    this.props.onClose();
  }
  saveAndClose = () => {
    UserAccount.account.home = JSON.parse(JSON.stringify(this.state.home));
    UserAccount.updateUserProfile(UserAccount.account).then(() => {
      if (this.changed) window.location.reload();
    });
    if (!this.changed) this.close();
  }
  createWebsiteInputs = (level, parentWebsite, hideDelete) => {
    const website = parentWebsite[level];
    return (
      <WebsiteInput key={level || 'new'}>
        <div key={level || 'new'}>
          <input disabled={hideDelete} tabIndex={0} onBlur={(e) => this.changePath(parentWebsite, level, e.target.value)} placeholder="Path" defaultValue={level || ''} />
          <input tabIndex={0} onChange={(e) => this.changeProperty(website, e.target.value)} placeholder="App ID" defaultValue={(Company.company && Company.company._id ? website[Company.company._id] : website._id) || ''} />
          <ClickableIcon onClick={() => { this.changeProperty(website, ''); this.forceUpdate(); }} icon="fas fa-trash" />
        </div>
      </WebsiteInput>
    )
  }
  render() {
    const { home } = this.state;
    return (
      <Popup
        onClose={this.close}
        allowBackgroundClose
      >
        <Wrapper>
          <h1>Edit Home</h1>
          <div key={JSON.stringify(home)}>
            {this.createWebsiteInputs('home', { home }, true)}
          </div>
          <ButtonsWrapper>
            <Button onClick={this.close} color="primary" autoFocus>
              Cancel
            </Button>
            <Button onClick={this.saveAndClose} color="primary" autoFocus>
              Save
            </Button>
          </ButtonsWrapper>
        </Wrapper>
      </Popup>
    );
  }
}
