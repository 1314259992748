import ObjectModel from './objectmodel';

export default class DatabaseCollectionDocument extends ObjectModel {
  constructor(_document) {
    super();
    this.document = _document;
  }
  getJson = () => {
    const { document } = this;
    return document;
  }
}
