import ObjectModel from './objectmodel';
import Sheet from './sheet';
import Meta from './meta';
import { DESCRIPTION, TITLE } from './nodetypes';
import EstimateObjectSize from '../utils/estimateobjectsize';
import { diff, reverse, patch } from 'jsondiffpatch';

export default class Spreadsheet extends ObjectModel {
  /**
   * 1-d array of sheets
   */
  sheets = [];
  sheetVersions = {};
  /**
   * Constructor
   */
  constructor(_editor, _owner, _users, _teams, _userLoggedIn, _sheets, _id, _title, _description, _createdTime, _updateTime, _state, _sheetDiffs, _version, _shareSetting, _company, _background, _isDevAccount) {
    super();
    this._id = _id;
    this.title = new Meta(_title || '', [TITLE], [], null, this.userLoggedIn);
    this.description = new Meta(_description || '', [DESCRIPTION], [], null, this.userLoggedIn);
    if (_createdTime) this.createdTime = new Date(_createdTime);
    if (_updateTime) this.updateTime = new Date(_updateTime);
    this.userLoggedIn = _userLoggedIn;
    this.editor = _editor;
    this.owner = _owner;
    this.users = _users || [];
    this.teams = _teams || [];
    this.state = _state;
    this.sheetDiffs = _sheetDiffs || [[]];
    const sheetDiffVersion = (this.sheetDiffs && this.sheetDiffs[0] && this.sheetDiffs[0].length) ? this.sheetDiffs[0].length + 1 : 1;
    this.version = _version || sheetDiffVersion;
    this.shareSetting = _shareSetting || 'LinkView';
    this.company = _company;
    this.background = _background;
    this.isDevAccount = _isDevAccount;

    // Apply Parents
    this.__recursivelyApplyParents(this.title, [this]);
    this.__recursivelyApplyParents(this.description, [this]);
    /**
     * Parse the names and values to remove empty columns and rows
     */
    this.__rawSheets = JSON.parse(JSON.stringify(_sheets));
    this.sheets = _sheets.map((_sheet) => new Sheet(_sheet));
    this.sheets.forEach((s) => s.onStateUpdate(this.onTextChanged));
  }
  get formattedUpdateTime() {
    if (this.updateTime) return this.updateTime.toLocaleString();
    return '';
  }
  get isShowingAPreviousVersion() {
    if (this.currentVersionDisplayed && this.currentVersionDisplayed !== this.version) return true;
    return false;
  }
  onTextChanged = () => {
    this.emitStateUpdate();
  }
  getJson() {
    return SpreadsheetModelToSpreadsheetJson(this);
  }
  
  updateOrAddRows(updates) {
    const { data } = this.sheets[0];
    updates.forEach((update) => {
      let rowIndexToUpdate;
      const row = data[0].map(() => ({ value: '' }));
      let changed = false;

      // Create new
      update.forEach(({ question, answer, project, uniqueId }, i) => {
        // Find the LogictryId column and create if doesn't exist
        let logictryIdColumn = data[0].findIndex((v) => v.value === 'logictry_id');
        if (logictryIdColumn < 0) {
          data[0].push({ value: 'logictry_id' });
          row.push({ value: '' });
          logictryIdColumn = data[0].length - 1;
        }

        // Find the question column
        let questionColumn = data[0].findIndex((v) => v.value === question);
        if (questionColumn < 0) {
          data[0].push({ value: question });
          row.push({ value: '' });
          questionColumn = data[0].length - 1;
        }
        if (i === 0) {
          // If autofill, there will be a uniqueId
          // Check for existing answer and dont change if so
          const existingRow = uniqueId && data.findIndex((r, j) => (j > 0 && r[questionColumn] && (r[questionColumn].value === answer)));
          const logictryId = uniqueId && `${project}_${uniqueId}` || project;
          rowIndexToUpdate = data.findIndex((r, j) => (j > 0 && r[logictryIdColumn] && (r[logictryIdColumn].value === logictryId)));
          if (existingRow >= 0 && existingRow !== rowIndexToUpdate) return;
          changed = true;
          row[questionColumn] = { value: answer };
          row[logictryIdColumn] = { value: logictryId };
        } else {
          row[questionColumn] = { value: answer };
        }
      });

      if (!changed) return;
      if (rowIndexToUpdate >= 0) data[rowIndexToUpdate] = row;
      else data.push(row);
    });
  }
  showVersion(_version, _diffs) {
    let sheetFromVersion = JSON.parse(JSON.stringify(this.__rawSheets[0]));
    _diffs.forEach((_diff, i) => {
      if (this.sheetVersions[_diff.version]) {
        sheetFromVersion = JSON.parse(JSON.stringify(this.sheetVersions[_diff.version]));
      } else {
        const { delta, sheet } = _diff;
        if (delta) {
          const reverseDelta = reverse(delta);
          sheetFromVersion = JSON.parse(JSON.stringify(patch(sheetFromVersion, reverseDelta)));
        } else if (sheet) {
          sheetFromVersion = JSON.parse(JSON.stringify(sheet));
        }
        this.sheetVersions[_diff.version] = sheetFromVersion;
      }
    });
    if (_version !== this.version) {
      this.currentVersionDisplayed = _version;
    } else {
      this.currentVersionDisplayed = null;
    }
    this.sheets[0].updateData(sheetFromVersion);
  }
  createVersion() {
    const newSpreadsheet = this.getJson();
    const oldSheet = this.__rawSheets[0];
    if (JSON.stringify(newSpreadsheet.sheets[0]) !== JSON.stringify(oldSheet)) {
      let delta;
      let reverseWorks;
      try {
        delta = diff(oldSheet, newSpreadsheet.sheets[0]);

        // Test diff in reverse to ensure it works
        const reverseDelta = reverse(delta);
        const reverseSheet = patch(JSON.parse(JSON.stringify(newSpreadsheet.sheets[0])), reverseDelta);
        reverseWorks = JSON.stringify(reverseSheet) === JSON.stringify(oldSheet);
      } catch (e) {
        // Ensure delta is null on failure
        delta = null;
        reverseWorks = false;
      }

      // Dont use the delta if
      // 1. The delta is bigger than the text
      // 2. The reverse diff doesn't work
      let newDiff;
      if (!delta || !reverseWorks || EstimateObjectSize(oldSheet) < EstimateObjectSize(delta)) {
        newDiff = { version: this.version, sheet: oldSheet, owner: this.userLoggedIn, createdTime: (new Date()) };
      } else {
        newDiff = { version: this.version, delta, owner: this.userLoggedIn, createdTime: (new Date()) };
      }

      // eslint-disable-next-line no-param-reassign
      if (this.sheetDiffs && this.sheetDiffs[0]) this.sheetDiffs[0].unshift(newDiff);
      // eslint-disable-next-line no-param-reassign
      else this.sheetDiffs = [[newDiff]];
      this.version += 1;
      // eslint-disable-next-line no-param-reassign
      this.__rawSheets = JSON.parse(JSON.stringify(newSpreadsheet.sheets));
      // eslint-disable-next-line no-param-reassign
      this.currentVersionDisplayed = null;
    }
  }
  __recursivelyApplyParents(node, parents) {
    // eslint-disable-next-line no-param-reassign
    node.parents = parents;
    node.children.forEach((_child) => this.__recursivelyApplyParents(_child, [...parents, node]));
  }
  __recursivelyRemoveParents(node) {
    // eslint-disable-next-line no-param-reassign
    node.parents = null;
    node.children.forEach((_child) => this.__recursivelyRemoveParents(_child));
  }
}
export function SpreadsheetModelToSpreadsheetJson(_spreadsheet) {
  const spreadsheetJson = {
    version: _spreadsheet.version,
    owner: _spreadsheet.owner,
    users: _spreadsheet.users,
    sheets: [],
    sheetDiffs: _spreadsheet.sheetDiffs,
  };
  spreadsheetJson.title = _spreadsheet.title && _spreadsheet.title.text || '';
  spreadsheetJson.description = _spreadsheet.description && _spreadsheet.description.text || '';
  spreadsheetJson.sheets.push(_spreadsheet.sheets[0].data || []);
  if (_spreadsheet.background) spreadsheetJson.background = _spreadsheet.background;
  if (Object.hasOwnProperty.call(_spreadsheet, 'company')) spreadsheetJson.company = _spreadsheet.company;
  if (_spreadsheet.state) spreadsheetJson.state = _spreadsheet.state;
  if (_spreadsheet.teams) spreadsheetJson.teams = _spreadsheet.teams;
  if (_spreadsheet.shareSetting) spreadsheetJson.shareSetting = _spreadsheet.shareSetting;
  if (_spreadsheet._id) spreadsheetJson._id = _spreadsheet._id;
  return spreadsheetJson;
}
