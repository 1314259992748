import React from 'react';
import styled from 'styled-components';
// import { PropTypes } from 'prop-types';
import Popup from '../Popup/index';
import MenuList from '../Popup/MenuList';
import MenuItem from '../Popup/MenuItem';
import Tree from '../../models/tree';
import Button from '../Button/index';
import StatefulTextField from '../StatefulTextField/index';
import ColorPicker from '../ColorPicker';
import UploadFolder from '../UploadFolder';
import DatabaseCollection from '../../models/databasecollection';
import BoxPopup from '../../containers/TreePage/BoxPopup';
import UserAccount from '../../services/UserAccount';
import Company from '../../services/Company';
import Database from '../../models/database';
import Link from '../../models/link';

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid gray;
  padding: 2rem;
  max-width: 540px;
`;
const SettingWrapper = styled.div`
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * {
    padding: 6px;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ColorLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  input {
    padding: 4px 0;
  }
`;
const UploadLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  input {
    padding: 4px 0;
  }
  i {
    font-size: 1.4rem;
    width: 37px;
  }
`;

const PrivateText = 'Private - only users invited can see or edit';
const CompanyViewText = 'Company View - anyone in the company can view';
const LoggedInLinkViewText = 'Logged In Link View - anyone logged in with the unique URL can view';
const LinkViewText = 'Link View - anyone with the unique URL can view';
const LinkEditText = 'Link Edit - anyone with the unique URL can edit';
const textMapping =  {
  Private: {
    text: PrivateText,
    icon: 'fas fa-eye-slash',
  },
  CompanyView: {
    text: CompanyViewText,
    icon: 'fas fa-building',
  },
  LoggedInLinkView: {
    text: LoggedInLinkViewText,
    icon: 'fas fa-eye',
  },
  LinkView: {
    text: LinkViewText,
    icon: 'fas fa-eye',
  },
  LinkEdit: {
    text: LinkEditText,
    icon: 'fas fa-edit',
  },
};
const AllowText = 'Enabled - embed will work regardless of other settings';
const DenyText = 'Disabled - embed is now disabled';
const EmbedMapping =  {
  Enabled: {
    text: AllowText,
    icon: 'fas fa-eye-slash',
  },
  Disabled: {
    text: DenyText,
    icon: 'fas fa-eye',
  },
};

export default class SettingsBox extends BoxPopup {
  state = {
    selectSettingsOpen: false,
    embedSettingsOpen: false,
  }
  onViewChange = (setting) => {
    const { domainObject } = this.props;
    domainObject.updateShareSetting(setting);
    this.setState({ selectSettingsOpen: false });
  }
  onEmbedChange = (setting) => {
    const { domainObject } = this.props;
    domainObject.updateEmbedSetting(setting);
    this.setState({ embedSettingsOpen: false });
  }
  onPasswordChange = (password) => {
    const { domainObject } = this.props;
    domainObject.updatePassword(password);
    this.forceUpdate();
  }
  onBackgroundImageChange = (_image) => {
    const { domainObject } = this.props;
    domainObject.updateBackgroundImage(_image);
    this.forceUpdate();
  }
  onBackgroundColorChange = (_color) => {
    const { domainObject } = this.props;
    domainObject.updateBackgroundColor(_color);
    this.forceUpdate();
  }
  onCustomSourceChange = (_url) => {
    const { domainObject } = this.props;
    domainObject.updateCustomSource(_url);
    this.forceUpdate();
  }
  onCustomSourceConfigChange = (_config) => {
    const { domainObject } = this.props;
    domainObject.updateCustomSourceConfig(_config);
    this.forceUpdate();
  }
  onLinkChange = (_link) => {
    const { domainObject } = this.props;
    domainObject.updateLink(_link);
    this.forceUpdate();
  }
  onSchemaChange = (_schema) => {
    const { domainObject } = this.props;
    domainObject.updateSchema(_schema);
    this.forceUpdate();
  }
  onIndicesChange = (_schema) => {
    const { domainObject } = this.props;
    domainObject.updateIndices(_schema);
    this.forceUpdate();
  }
  onFunctionsChange = (_schema) => {
    const { domainObject } = this.props;
    domainObject.updateFunctions(_schema);
    this.forceUpdate();
  }
  uploadComplete = (url) => {
    const { domainObject } = this.props;
    domainObject.updateCustomSource(url);
    this.saveAndClose();
  }
  render() {
    const { company } = Company;
    const { isDevAccount } = UserAccount;
    const { domainObject } = this.props;
    const { selectSettingsOpen, embedSettingsOpen } = this.state;
    const { _id, shareSetting, embedSetting, password, background, customSource, customSourceConfigString, schemaString, indicesString, functionsString, link } = domainObject;
    return (
      <div>
        <Popup
          onClose={this.close}
          allowBackgroundClose
        >
          <Wrapper>
            <h1>Settings</h1>
            <SettingWrapper role="button" onClick={() => this.setState({ selectSettingsOpen: true })} ref={(e) => { if (e) this.anchorEl = e; }}>
              <i className={textMapping[shareSetting].icon} />
              <div>{textMapping[shareSetting].text}</div>
              <i className="fas fa-angle-down" />
            </SettingWrapper>
            {domainObject instanceof Tree && <SettingWrapper role="button" onClick={() => this.setState({ embedSettingsOpen: true })} ref={(e) => { if (e) this.embedEl = e; }}>
              <i className={EmbedMapping[embedSetting].icon} />
              <div>{EmbedMapping[embedSetting].text}</div>
              <i className="fas fa-angle-down" />
            </SettingWrapper>}
            {domainObject instanceof Tree && <StatefulTextField
              value={password || ''}
              onChange={this.onPasswordChange}
              label="Password"
              maxLength={256}
            />}
            <StatefulTextField
              value={background && background.image || ''}
              onChange={this.onBackgroundImageChange}
              label="Background Image"
              maxLength={500}
            />
            <ColorLine>
              <StatefulTextField
                value={background && background.color || ''}
                onChange={this.onBackgroundColorChange}
                label="Background Color"
                maxLength={20}
                width={260}
              />
              <ColorPicker onChange={this.onBackgroundColorChange} color={background && background.color} />
            </ColorLine>
            {domainObject instanceof Tree && <UploadLine>
              <StatefulTextField
                value={customSource && customSource.url || ''}
                onChange={this.onCustomSourceChange}
                label="Custom App (html)"
                maxLength={1000}
                width={260}
              />
              <UploadFolder
                onComplete={this.uploadComplete}
              />
            </UploadLine>}
            {domainObject instanceof Tree && <StatefulTextField
              value={customSourceConfigString || ''}
              onChange={this.onCustomSourceConfigChange}
              label="Custom App (config)"
              maxLength={40000}
            />}
            {domainObject instanceof Link && <StatefulTextField
              value={link || ''}
              onChange={this.onLinkChange}
              label="Redirect to Url"
              maxLength={1000}
            />}
            {isDevAccount && domainObject instanceof DatabaseCollection && <StatefulTextField
              value={schemaString}
              onChange={this.onSchemaChange}
              label="Schema"
              maxLength={4000}
            />}
            {isDevAccount && domainObject instanceof DatabaseCollection && <StatefulTextField
              value={indicesString}
              onChange={this.onIndicesChange}
              label="Indices"
              maxLength={4000}
            />}
            {isDevAccount && domainObject instanceof DatabaseCollection && <StatefulTextField
              value={functionsString}
              onChange={this.onFunctionsChange}
              label="Functions"
              maxLength={4000}
            />}
            <ButtonsWrapper>
              <Button onClick={this.close} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={this.saveAndClose} color="primary" autoFocus>
                Save
              </Button>
            </ButtonsWrapper>
          </Wrapper>
        </Popup>
        {selectSettingsOpen && <Popup
          allowBackgroundClose
          onClose={() => this.setState({ selectSettingsOpen: false })}
          anchorEl={this.anchorEl}
          anchorBottomRight
        >
          <MenuList>
            <MenuItem onClick={() => this.onViewChange('Private')}>
              <i className="fas fa-eye-slash"></i>
              <div style={{ marginLeft: 10 }}>{PrivateText}</div>
            </MenuItem>
            {company && company._id && <MenuItem onClick={() => this.onViewChange('CompanyView')}>
              <i className="fas fa-building"></i>
              <div style={{ marginLeft: 10 }}>{CompanyViewText}</div>
            </MenuItem>}
            <MenuItem onClick={() => this.onViewChange('LoggedInLinkView')}>
              <i className="fas fa-eye"></i>
              <div style={{ marginLeft: 10 }}>{LoggedInLinkViewText}</div>
            </MenuItem>
            <MenuItem onClick={() => this.onViewChange('LinkView')}>
              <i className="fas fa-eye"></i>
              <div style={{ marginLeft: 10 }}>{LinkViewText}</div>
            </MenuItem>
          </MenuList>
        </Popup>}
        {embedSettingsOpen && <Popup
          allowBackgroundClose
          onClose={() => this.setState({ embedSettingsOpen: false })}
          anchorEl={this.embedEl}
          anchorBottomRight
        >
          <MenuList>
            <MenuItem onClick={() => this.onEmbedChange('Enabled')}>
              <i className="fas fa-eye-slash"></i>
              <div style={{ marginLeft: 10 }}>{AllowText}</div>
            </MenuItem>
            <MenuItem onClick={() => this.onEmbedChange('Disabled')}>
              <i className="fas fa-eye"></i>
              <div style={{ marginLeft: 10 }}>{DenyText}</div>
            </MenuItem>
          </MenuList>
        </Popup>}
      </div>
    );
  }
}
