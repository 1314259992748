import Model from './model';

export default class LogicBaseBlock extends Model {
  constructor(_id, blocker, blockee) {
    super();
    this._id = _id;
    this.blocker = blocker;
    this.blockee = blockee;
  }
  getJson = () => {
    const { _id, blocker, blockee } = this;
    return {
      _id,
      br: blocker,
      be: blockee,
    }
  }
}
