import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import TreeCache from '../../services/cache/TreeCache';
import GrayOut from '../../services/TreeDisplay';
import Tree from '../../models/tree';
import LoadingIndicator from '../../components/LoadingIndicator';
import { subtreeUpdate } from '../../services/Connect';
import Constants from '../../submodules/logictry_config/constants';
import { SUB_TREE, WIKI, PANE } from '../../models/nodetypes';
import RenderedHtmlToTextArea from '../../components/RenderedHtmlToTextArea/index';
import Dropdown from '../../components/Dropdown/index';
import { AccountTemplatesOpen } from '../../services/Pagination/index';
import Navigation from '../../services/Navigation';
import ClickableDiv from '../../components/ClickableDiv/index';

const Wrapper = styled.div`
  margin: 0px 0px 0px ${Constants.TreeContentIconIndent};
  > div:first-child {
    > div {
      margin: ${Constants.defaultLinePadding} 0px;
    }
    display: flex;
    align-items: center;
    &:hover {
      opacity: 1 !Important;
    }
    > i {
      cursor: pointer;
    }
  }
`;
const SubtreeBoxWrapper = styled.div`
  display: block;
  margin-right: -28px;
`;
const TreeList = styled.div`
  display: flex;
  align-items: center;
  color: ${Constants.DarkTextColor};
  width: 100%;
  > div:first-child {
    margin-right: 12px;
    border-radius: 8px;
    min-width: 40px;
    width: 40px;
    min-height: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: ${Constants.White};
    font-size: ${Constants.SmallFontSize};
  }
  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;
const SpreadsheetLink = styled.div`

`;
const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 2.4rem;
`;

export default class SubtreeBox extends React.PureComponent {
  static propTypes = {
    subtree: PropTypes.object,
  }
  state = {
    error: '',
  }
  componentDidMount() {
    const { subtree } = this.props;
    const { apikey } = Constants;
    subtree.onStateUpdate(this);
    TreeCache.onStateUpdate(this.getTree);
    GrayOut.onStateUpdate(this.subtreeUpdate);
    this.getTree();
    this.__iframe = document.createElement('iframe');
    this.__iframe.style.width = '100%';
    this.__iframe.style.border = '0px';
    this.__iframe.src = `${window.location.origin}/apps/${subtree.text}${apikey ? `?apikey=${apikey}` : ''}`;
    subtreeUpdate.onStateUpdate(this.onHeightUpdate);
    this.onSubTreeRef(this.subtreeBoxRef);
    window.addEventListener('resize', this.windowSizeUpdate);
  }
  componentWillUnmount() {
    const { subtree } = this.props;
    subtree.offStateUpdate(this);
    TreeCache.offStateUpdate(this.getTree);
    GrayOut.offStateUpdate(this.subtreeUpdate);
    if (this.subtreeUpdateTimeout) clearTimeout(this.subtreeUpdateTimeout);
    this.__iframe = null;
    subtreeUpdate.offStateUpdate(this.onHeightUpdate);
    window.removeEventListener('resize', this.windowSizeUpdate);
  }
  onHeightUpdate = (_, { src, height, toggleFullscreen }) => {
    this.setHeightToFullscreen = false;
    if (this.__iframe.src !== src) return;
    if (!this.__iframe || !this.__iframe.parentNode) return;
    const isFullscreen = this.__iframe.parentNode.style.position === 'fixed';
    if (toggleFullscreen) {
      if (isFullscreen) {
        this.__iframe.parentNode.style.position = 'unset';
        this.__iframe.parentNode.style.top = 'unset';
        this.__iframe.parentNode.style.left = 'unset';
        this.__iframe.parentNode.style.right = 'unset';
        this.__iframe.parentNode.style.bottom = 'unset';
        this.__iframe.parentNode.style.height = this.__previousHeight;
        this.__iframe.parentNode.style.margin = this.__previousMargin;
        this.__iframe.parentNode.style.zIndex = 'unset';
        this.__iframe.style.height = this.__previousHeight;
        this.__iframe.parentNode.parentNode.style.height = null;
      } else {
        this.__iframe.parentNode.parentNode.style.height = `${this.__iframe.parentNode.parentNode.getBoundingClientRect().height}px`;
        this.__previousHeight = this.__iframe.parentNode.style.height;
        this.__previousMargin = this.__iframe.parentNode.style.margin;
        this.__iframe.parentNode.style.position = 'fixed';
        this.__iframe.parentNode.style.top = '0px';
        this.__iframe.parentNode.style.left = '0px';
        this.__iframe.parentNode.style.right = '0px';
        this.__iframe.parentNode.style.bottom = '0px';
        this.__iframe.parentNode.style.height = 'unset';
        this.__iframe.parentNode.style.margin = '0px';
        this.__iframe.parentNode.style.zIndex = 5;
        this.__iframe.style.height = `100vh`;
      }
    }
    if (isFullscreen) return;
    if (height === 'fullscreen') {
      this.setHeightToFullscreen = true;
      this.__iframe.parentNode.style.height = `${this.fullscreenHeight}px`;
      this.__iframe.style.height = `${this.fullscreenHeight}px`;
    } else {
      this.__iframe.parentNode.style.height = `${height}px`;
      this.__iframe.style.height = `${height}px`;
    }
  }
  windowSizeUpdate = () => {
    if (!this.subtreeBoxRef) return;
    this.calculateSubtreeHeight();
  }
  calculateSubtreeHeight = () => {
    if (!this.subtreeBoxRef) return;
    const parent = GrayOut.findPaneRef(this.subtreeBoxRef);
    if (!parent) return;
    const { paddingTop, paddingBottom } = getComputedStyle(parent);
    this.fullscreenHeight = Math.min(parent.offsetHeight - parseFloat(paddingTop) - parseFloat(paddingBottom), window.outerHeight - 177);
    if (!this.setHeightToFullscreen) return;
    this.__iframe.parentNode.style.height = `${this.fullscreenHeight}px`;
    this.__iframe.style.height = `${this.fullscreenHeight}px`;
  }
  onChange = (val) => {
    const { subtree } = this.props;
    subtree.updateText(val);
    this.getTree();
  }
  subtreeUpdate = () => {
    const { subtree } = this.props;
    const { text } = subtree;
    if (this.gettingTree === text) return this.forceUpdate();
    this.gettingTree = text;
    if (this.subtreeUpdateTimeout) clearTimeout(this.subtreeUpdateTimeout);
    this.subtreeUpdateTimeout = setTimeout(() => {
      this.getTree();
    }, 500);
    return this.forceUpdate();
  }
  isValidObjectId = (id) => {
    return /^[a-fA-F0-9]{24}$/.test(id);
  }
  getTree = () => {
    const { editing, tree } = GrayOut;
    const { subtree } = this.props;
    const { text, objectRef } = subtree;
    if (subtree.tree) return this.forceUpdate();
    if (!text) return subtree.updateTree(null);
    if (tree._id === text || subtree.parents.find((_p) => _p instanceof Tree && _p._id === text)) return subtree.updateTree({ error: 'InvalidRecursiveTreeCall' });
    if (objectRef && objectRef.text) {
      const answerTree = TreeCache.getTree(objectRef.text, editing);
      // if (!answerTree || answerTree.loading) return subtree.updateTree(null);
      if (answerTree._id) return subtree.updateTree(answerTree);
      if (answerTree.error) {
        const emptyTree = TreeCache.getTree(text, editing);
        // if (!emptyTree || emptyTree.loading) return subtree.updateTree(null);
        if (emptyTree._id) {
          // TreeCache.createTree(emptyTree, { _id: objectRef.text, parentTree: subtree.parents[0]._id });
        }
      }
    // } else if (!editing && subtree.tree && subtree.tree.template && !objectRef) {
    //   subtree.addChild(new ObjectRef('', [OBJECT_REF], [], subtree.userLoggedIn, subtree.userLoggedIn));
    //   const immediateTreeParent = parents.reverse().find((_child) => _child instanceof Tree) || tree;
    //   if (immediateTreeParent && immediateTreeParent.project && immediateTreeParent._id) {
    //     return TreeCache.updateTree(immediateTreeParent);
    //   }
    }
    if (!this.isValidObjectId(text)) return this.setState({ error: 'Invalid ID' })
    subtree.updateTree(TreeCache.getTree(text, editing));
  }
  checkFocus = () => {
    const { subtree } = this.props;
    if (this.inputRef && GrayOut.isActive(subtree)) this.inputRef.focus({ preventScroll: true });
  }
  onSubTreeRef = (e) => {
    this.subtreeBoxRef = e;
    if (!this.subtreeBoxRef) return;
    if (!this.__iframe) return;
    this.calculateSubtreeHeight();
    this.subtreeBoxRef.appendChild(this.__iframe);
  }

  render() {
    const { editing } = GrayOut;
    const isDisabled = GrayOut.tree.isDisabled;
    const { subtree } = this.props;
    const { error } = this.state;
    const { tree, text } = subtree;

    if (!editing) {
      if (error) return null;
      if (!text) return null;
      if (!tree) return <LoadingWrapper><LoadingIndicator /></LoadingWrapper>;
      if (tree.error) return null;
      let style = {};
      if (subtree.parents.reverse().find((n) => n.isOneOfTypes([WIKI, PANE]))) style = { display: 'flex', flexDirection: 'column', flex: 1 };
      return (
        <div style={{ position: 'relative' }}>
          <div
            style={{ fontSize: Constants.VerySmallFontSize, position: 'absolute', top: 0, left: '1.75rem', cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => Navigation.push(`/apps/${tree._id}`)}
          >{`${tree.title.text} - click to open fullscreen`}</div>
          <SubtreeBoxWrapper style={style} ref={this.onSubTreeRef} />
        </div>
      );
    }

    const isActive = GrayOut.isActive(subtree);
    const { currentPage } = AccountTemplatesOpen;
    const project =  tree && tree.project;
    const { primaryColor } = GrayOut.tree.root;
    return (
      <Wrapper>
        <div>
          {(!subtree.text || GrayOut.isActive(subtree)) ? (
            <TreeList>
              <div style={{ backgroundColor: primaryColor }}>
                <i className={project ? 'fas fa-project-diagram' : 'fas fa-network-wired'}></i>
              </div>
              <div>
                <RenderedHtmlToTextArea node={subtree} placeholderText={SUB_TREE} />
              </div>
            </TreeList>
          ) : (
            <TreeList key={subtree.text}>
              <div style={{ backgroundColor: primaryColor }}>
                <i className={project ? 'fas fa-project-diagram' : 'fas fa-network-wired'}></i>
              </div>
              <div>
                {error && (
                  <SpreadsheetLink style={{ color: 'red' }}>{`Invalid App ID`}</SpreadsheetLink>
                ) || !tree && (
                  <LoadingIndicator />
                ) || tree && tree.error && (
                  <SpreadsheetLink style={{ color: 'red' }}>{`App Error: ${tree.error}`}</SpreadsheetLink>
                ) || (
                  <SpreadsheetLink style={{ color: primaryColor }} role="button" tabIndex={-1} onClick={() => Navigation.push(`/apps/${subtree.text}/edit`)}>{`${tree && ((tree.title.text) && tree.title.text || `App: ${tree._id}`) || ''}`}</SpreadsheetLink>
                )}
                {(error || tree) && <ClickableDiv style={{ pointerEvents: isDisabled ? 'none' : null }} onClick={() => GrayOut.addActiveObject(subtree)}>Change Subtree</ClickableDiv>}
              </div>
            </TreeList>
          )}
        </div>
        {isActive && <Dropdown values={currentPage.map((s) => ({ name: s.title && s.title.text || s._id, value: s._id }))} onChange={this.onChange} />}
      </Wrapper>
    );
  }
}
