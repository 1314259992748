import ObjectCache from './CacheService';
import UserAccount from '../UserAccount';
import ApiKeyCallsApi from '../server/ApiKeyCallsApi';
import ApiKeyCalls from '../../models/apikeycalls';

class ApiKeyCallsCache extends ObjectCache {
  api = ApiKeyCallsApi;
  createNew = () => new ApiKeyCalls();
  createObject = (_results) => {
    const results = JSON.parse(JSON.stringify(_results));
    return new ApiKeyCalls(results._id, results.createdTime, results.updateTime, results.calls);
  }
  revertObject = (_object) => {
    const results = this.objectJson[_object._id];
    this.objects[_object._id] = this.createObject(results);
    this.emitStateUpdate();
  }
  getObjectJson = (_model) => _model.getJson();
}

const singleton = new ApiKeyCallsCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
