import React from 'react';
// import styled from 'styled-components';

import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import { AccountProjectsOpen, AccountProjectsInvitedOpen, AccountProjectsDeleted } from '../../services/Pagination/index';
import Box from './Box';
import CreateProjectPopup from './CreateProjectPopup';
import ProjectTreeSettings from '../../components/ObjectSettings/ProjectTreeSettings';
import { getAccountProjectsSearch } from '../../services/Pagination/index';


export default class ProjectTreesBox extends Box {
  name = 'Submissions';
  missingTitleText = 'New Submission';
  paginationService = this.props.paginationService || this.props.showDeleted && AccountProjectsDeleted || (this.props.searchText || this.props.filterText) && getAccountProjectsSearch(this.props.searchText, null, this.props.filterText) || this.props.invitedTo && AccountProjectsInvitedOpen || AccountProjectsOpen;
  backgroundColor = Constants.PrimaryColor;
  boxIcon = 'fas fa-project-diagram';
  open = (_tree) => Navigation.push(`/apps/${_tree._id}`);
  createText = 'New Submission';
  createNewPopup = () => <CreateProjectPopup team={this.props.team} onClose={this.hideCreatePopup} />;
  objectSettings = ProjectTreeSettings;
}
