// import React from 'react';
// import styled from 'styled-components';
// import { PropTypes } from 'prop-types';
// eslint-disable-next-line import/no-cycle
import QuestionBox from './QuestionBox';
import GrayOut from '../../services/TreeDisplay';
import { FILTER } from '../../models/nodetypes';

export default class FilterBox extends QuestionBox {
  placeholder = FILTER;
  render() {
    const { question } = this.props;
    const { editing } = GrayOut;
    if (!editing && question && (question.hide || question.answers.length === 0)) return null;
    return super.render();
  }
}
