import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';

const iconWrapper = styled.i`
  text-align: center;
`;
const Disabled = styled(iconWrapper)`
  opacity: 0.5;

`;
const Enabled = styled(iconWrapper)`
  
`;

export default class ClickableIcon extends React.PureComponent {
  static propTypes = {
    small: PropTypes.bool,
    grayedOut: PropTypes.bool,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onMouseDown: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    style: PropTypes.object,
    innerRef: PropTypes.func,
    fontsize: PropTypes.string,
  }
  render() {
    const { innerRef, disabled, icon, onClick, grayedOut, onFocus, onBlur, onMouseDown, style, small, onMouseEnter, onMouseLeave, fontsize } = this.props;
    const role = ((onMouseDown || onClick) ? 'button' : '');
    const fontSize = fontsize || Constants.SemiSmallFontSize;
    // eslint-disable-next-line no-nested-ternary
    const length = (small ? '28px' : '40px');
    if (disabled) return <Disabled style={{ fontSize, minWidth: length, width: length, minHeight: length, height: length, lineHeight: length }} className={icon} />;
    return <Enabled onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={innerRef} style={{ fontSize, minWidth: length, width: length, minHeight: length, height: length, lineHeight: length, opacity: grayedOut ? 0.5 : null, ...style }} role={role} tabIndex={-1} onMouseDown={onMouseDown} onClick={onClick} onFocus={onFocus} onBlur={onBlur} className={icon} />;
  }
}
