/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
import Category from './category';
import Question from './question';
import Answer from './answer';
import Answerable from './answerable';
import ForEachAnswer from './foreachanswer';
import ObjectModel from './objectmodel';
import Info from './info';
import DisplayText from './displaytext';
import PlaceholderText from './placeholdertext';
import ShowAllText from './showalltext';
import ShowAllFilteredText from './showallfilteredtext';
import Root from './root';
import Autofill from './autofill';
import Branch from './branch';
import Branches from './branches';
import Subtree from './subtree';
import Text from './text';
import Goto from './goto';
import File from './file';
import Checklist from './checklist';
import ObjectRef from './objectref';
import Filter from './filter';
import Data from './data';
import DataSchema from './dataschema';
import Note from './note';
import SplitPane from './splitpane';
import SearchLogic from './searchlogic';
import Search from './search';
import Keywords from './keywords';
import Meta from './meta';
import CalendarLogic from './calendarlogic';
import Calendar from './calendar';
import CalendarEvent from './calendarevent';
import Pane from './pane';
import ChecklistItem from './checklistitem';
import FileGroup from './filegroup';
import FilterLogic from './filterlogic';
import { AUTO_CREATE_LOGIC, TOP_LEVEL_NODES, CONDITIONAL_OPERATOR, ACTION, AUTOFILL, ANSWER, BRANCH, BRANCHES, CALCULATION, CALCULATION_LOGIC, CALENDAR, CALENDAR_EVENT, CALENDAR_LOGIC, CATEGORY, CHART_LOGIC, CHATBOT, CHECKLIST, CHECKLIST_ITEM, CONDITIONAL, CONDITIONAL_LOGIC, DATA, DATA_SCHEMA, DATA_TABLE, DESCRIPTION, DISPLAY_TEXT, EMAIL, FILE_GROUP, FILE, FILTER_LOGIC, FILTER, FOR_EACH_ANSWER, GOTO, INFO, KEYWORDS, NOTE, PANE, PLACEHOLDER_TEXT, OBJECT_REF, QUESTION, ROOT, SCHEMA, SEARCH, SEARCH_LOGIC, SEARCH_TEXT, SPLIT_PANE, SUB_TREE, TITLE, TEXT, CALENDAR_EVENT_INFO, CALENDAR_MAP, EXPORT, WIKI, SHOW_ALL_TEXT, SHOW_ALL_FILTERED_TEXT, DATA_SCHEMA_FIELD, DATA_SCHEMA_FIELD_TITLE, DATA_DERIVED, DATA_DERIVED_TEXT, MODAL, REPORT, PRESENTATION, CALCULATION_OPERATOR, TEXT_LOGIC, ALLOW_HASH_KEY_INSTEAD_OF_TEXT_IN_PROJECT, SHOW_IN_PROJECT_REPORTS, CHART, VIDEO, DATA_SCHEMA_KEY, DATA_SCHEMA_VALUE, ACTIONS } from './nodetypes';
import { DATE_ATTR, IGNORE_FOR_EACH_ANSWER_ATTR, LOGICTRY_SPREADSHEET_ATTR, PREVENT_SAVING_PROJECTS_ATTR, TIME_ATTR, DATE_TIME_ATTR, HIDE_DISPLAY_TEXT_ATTR, EMAIL_ATTR } from './nodeattributes';
import TreeV0toV1 from './migrations/treev0tov1';
import treeAutoDetectSchema from './migrations/treeautodetectschema';
import CalendarEventInfo from './calendareventinfo';
import SearchText from './searchtext';
import Questionable from './questionable';
import CalendarMap from './calendarmap';
import Wiki from './wiki';
import Export from './export';
import EstimateObjectSize from '../utils/estimateobjectsize';
import Spreadsheet from './spreadsheet';
import Node from './node';
import Action from './action';
import DataSchemaField from './dataschemafield';
import DataSchemaKey from './dataschemakey';
import DataSchemaValue from './dataschemavalue';
import Model from './model';
import Modal from './modal';
import ConditionalLogic from './conditionallogic';
import Conditional from './conditional';
import CalculationLogic from './calculationlogic';
import Calculation from './calculation';
import ConditionalOperator from './conditionaloperator';
import Presentation from './presentation';
import CalculationOperator from './calculationoperator';
import TextLogic from './textlogic';
import findAllHandlebars from '../utils/parsevariables';
import Report from './report';
import Chart from './chart';
import ChartLogic from './chartlogic';
import Video from './video';
import AutoCreateLogic from './autocreatelogic';
import DataTable from './datatable';
import { __recursivelyGetAllQuestionableNodes } from './findnode';
import Actions from './actions';
import Chatbot from './chatbot';
import { COMMENT } from './nodetypes';
import Comment from './comment';
import Email from './email';
import { patch, reverse, diff } from 'jsondiffpatch';
import SparkMD5 from 'spark-md5';
import { validateEmail } from '../utils/regex';

const CONSTRUCTORS = {};
CONSTRUCTORS[ACTION] = Action;
CONSTRUCTORS[ANSWER] = Answer;
CONSTRUCTORS[AUTO_CREATE_LOGIC] = AutoCreateLogic;
CONSTRUCTORS[AUTOFILL] = Autofill;
CONSTRUCTORS[BRANCH] = Branch;
CONSTRUCTORS[CALCULATION] = Calculation;
CONSTRUCTORS[CALCULATION_LOGIC] = CalculationLogic;
CONSTRUCTORS[CALCULATION_OPERATOR] = CalculationOperator;
CONSTRUCTORS[CALENDAR] = Calendar;
CONSTRUCTORS[CALENDAR_EVENT] = CalendarEvent;
CONSTRUCTORS[CALENDAR_EVENT_INFO] = CalendarEventInfo;
CONSTRUCTORS[CALENDAR_LOGIC] = CalendarLogic;
CONSTRUCTORS[CALENDAR_MAP] = CalendarMap;
CONSTRUCTORS[CATEGORY] = Category;
CONSTRUCTORS[CHART] = Chart;
CONSTRUCTORS[CHART_LOGIC] = ChartLogic;
CONSTRUCTORS[CHATBOT] = Chatbot;
CONSTRUCTORS[CHECKLIST] = Checklist;
CONSTRUCTORS[CHECKLIST_ITEM] = ChecklistItem;
CONSTRUCTORS[COMMENT] = Comment;
CONSTRUCTORS[CONDITIONAL] = Conditional;
CONSTRUCTORS[CONDITIONAL_LOGIC] = ConditionalLogic;
CONSTRUCTORS[CONDITIONAL_OPERATOR] = ConditionalOperator;
CONSTRUCTORS[DATA] = Data;
CONSTRUCTORS[DATA_DERIVED] = Node;
CONSTRUCTORS[DATA_DERIVED_TEXT] = Node;
CONSTRUCTORS[DATA_SCHEMA] = DataSchema;
CONSTRUCTORS[DATA_SCHEMA_FIELD] = DataSchemaField;
CONSTRUCTORS[DATA_SCHEMA_FIELD_TITLE] = Node;
CONSTRUCTORS[DATA_SCHEMA_KEY] = DataSchemaKey;
CONSTRUCTORS[DATA_SCHEMA_VALUE] = DataSchemaValue;
CONSTRUCTORS[DATA_TABLE] = DataTable;
CONSTRUCTORS[DISPLAY_TEXT] = DisplayText;
CONSTRUCTORS[EMAIL] = Email;
CONSTRUCTORS[EXPORT] = Export;
CONSTRUCTORS[FILE] = File;
CONSTRUCTORS[FILE_GROUP] = FileGroup;
CONSTRUCTORS[FILTER] = Filter;
CONSTRUCTORS[FILTER_LOGIC] = FilterLogic;
CONSTRUCTORS[FOR_EACH_ANSWER] = ForEachAnswer;
CONSTRUCTORS[GOTO] = Goto;
CONSTRUCTORS[INFO] = Info;
CONSTRUCTORS[KEYWORDS] = Keywords;
CONSTRUCTORS[MODAL] = Modal;
CONSTRUCTORS[NOTE] = Note;
CONSTRUCTORS[PANE] = Pane;
CONSTRUCTORS[PLACEHOLDER_TEXT] = PlaceholderText;
CONSTRUCTORS[PRESENTATION] = Presentation;
CONSTRUCTORS[QUESTION] = Question;
CONSTRUCTORS[SEARCH] = Search;
CONSTRUCTORS[SEARCH_LOGIC] = SearchLogic;
CONSTRUCTORS[SEARCH_TEXT] = SearchText;
CONSTRUCTORS[SHOW_ALL_FILTERED_TEXT] = ShowAllFilteredText;
CONSTRUCTORS[SHOW_ALL_TEXT] = ShowAllText;
CONSTRUCTORS[SPLIT_PANE] = SplitPane;
CONSTRUCTORS[SUB_TREE] = Subtree;
CONSTRUCTORS[TEXT] = Text;
CONSTRUCTORS[TEXT_LOGIC] = TextLogic;
CONSTRUCTORS[VIDEO] = Video;
CONSTRUCTORS[WIKI] = Wiki;

const CURRENT_TREE_SCHEMA_VERSION = 1;

export default class Tree extends ObjectModel {
  busyNodes = new Set();
  attributes = ['Tree'];
  children = [];
  constructor(_editing, _type, _editor, _owner, _users, _teams, _userLoggedIn, _childrenJsonOrText, _id, _referenceTree, _createdTime, _updateTime, _parentTree, _state, _diffs, _version, _shareSetting, _embedSetting, _password, _company, _background, _customSource, _isDevAccount, _progress) {
    super();

    // Set initial properties
    this.diffs = _childrenJsonOrText.diffs || _diffs;
    this.splitPanes = [];
    this.subtrees = [];
    this.editing = _editing;
    this._id = _id;
    this.referenceTree = _referenceTree;
    this.parentTree = _parentTree;
    this.state = _state;
    this.editor = _editor;
    this.userLoggedIn = _userLoggedIn;
    if (_createdTime) this.createdTime = new Date(_createdTime);
    if (_updateTime) this.updateTime = new Date(_updateTime);
    this.owner = _owner;
    this.users = _users || [];
    this.teams = _teams || [];
    this.type = _type || 'Template';
    this.lastCreatedQuestion = null;
    const diffVersion = (this.diffs && this.diffs.length) ? this.diffs.length + 1 : 1;
    this.version = _version || diffVersion;
    this.shareSetting = _shareSetting || 'LinkView';
    this.embedSetting = _embedSetting || 'Enabled';
    this.password = _password || '';
    this.company = _company;
    this.background = _background;
    this.customSource = _customSource;
    this.customSourceConfigString = _customSource && _customSource.config && JSON.stringify(_customSource.config);
    this.isDevAccount = _isDevAccount;
    this.progress = _progress;

    // Set the initial string
    // This will be used in the diff
    if (_childrenJsonOrText.text) {
      this.__initialTemplateString = _childrenJsonOrText.text;
    }

    // Parse children
    this.__createChildrenFromJsonOrText(_childrenJsonOrText);

    // If not editing, run all logic calculations
    if (!this.editing) {
      __recursivelyRunOnTreeInitialized(this.root);
      recursivelyExpandChildrenAnswered(this.root);
    }
  }
  get spreadsheets() {
    return this.getAllSpreadsheets(this.root);
  }
  get isBusy() {
    return this.busyNodes.size > 0;
  }
  get isCustomApp() {
    return this.customSource && this.customSource.url;
  }
  get isSavingProjectsAllowed() {
    if (!this.template) return false;
    if (this.isCustomApp) return false;
    const subTrees = __recursivelyGetAllNodesByType(this.root, SUB_TREE);
    if (subTrees.length > 0) return false;
    if (this.root.attributes.includes(PREVENT_SAVING_PROJECTS_ATTR)) return false;
    return true;
  }
  getTreeTemplateJson() {
    return TreeModelToTreeJson(this);
  }
  getTreeProjectJson(_subtree, _showInfo, _allowHashKey, _showDisplayText) {
    return TreeModelToTreeResultJson(this, _subtree, _showInfo, _allowHashKey, _showDisplayText);
  }
  getTreeTemplateText() {
    return TreeJsonToTreeText(this.getTreeTemplateJson());
  }
  getReport(answersOnly, includeLink, showInfo, openAIResponse) {
    const showDisplayTextValues = this.report.displayTextOption !== HIDE_DISPLAY_TEXT_ATTR;
    const isCustomReport = this.report.children.length > 0;
    if (isCustomReport) {
      const customReport = createCustomReport(this, showDisplayTextValues, openAIResponse);
      if (customReport) return customReport;
    }
    const treeResults = this.getTreeProjectJson(null, showInfo, null, showDisplayTextValues);
    return createText(this, treeResults, answersOnly, includeLink);
  }
  getOpenAIPrompt() {
    return createOpenAIText(this);
  }
  getReportSaveOnSubmitEmail() {
    const { saveOnSubmit } = this.root;
    if (!saveOnSubmit) return null;
    const afterIntroBracket = saveOnSubmit.split('{{')[1];
    if (!afterIntroBracket) return null;
    const email = afterIntroBracket.split('}}')[0];
    return getCustomVariableValue(this, email);
  }
  clearReportHasBeenViewed() {
    this.progress = this.__percentageComplete;
    this.emitStateUpdate();
  }
  reportHasBeenViewed() {
    this.progress = this.__percentageComplete;
    this.progress.report = true;
    this.emitStateUpdate();
  }
  destroy() {
    this.__recursivelyRemoveParents(this.title);
    this.__recursivelyRemoveParents(this.description);
    this.__recursivelyRemoveParents(this.root);
    this.__recursivelyRemoveParents(this.branches);
    this.__recursivelyRemoveParents(this.report);
    if (this.email) this.__recursivelyRemoveParents(this.email);
    if (this.actions) this.__recursivelyRemoveParents(this.actions);
    this.__recursivelyRemoveChildren(this.title);
    this.__recursivelyRemoveChildren(this.description);
    this.__recursivelyRemoveChildren(this.root);
    this.__recursivelyRemoveChildren(this.branches);
    this.__recursivelyRemoveChildren(this.report);
    if (this.email) this.__recursivelyRemoveChildren(this.email);
    if (this.actions) this.__recursivelyRemoveChildren(this.actions);
  }
  __recursivelyApplyParents(node, parents) {
    // eslint-disable-next-line no-param-reassign
    node.parents = parents;
    node.children.forEach((_child) => this.__recursivelyApplyParents(_child, [...parents, node]));
  }
  __recursivelyRemoveParents(node) {
    if (node.onRemovedParents) node.onRemovedParents();
    // eslint-disable-next-line no-param-reassign
    node.parents = null;
    node.children.forEach((_child) => this.__recursivelyRemoveParents(_child));
  }
  __recursivelyRemoveChildren(node) {
    // eslint-disable-next-line no-param-reassign
    node.children.forEach((_child) => this.__recursivelyRemoveChildren(_child));
    node.children = [];
  }
  
  get __percentageComplete() {
    let complete = 0;
    let total = 0;
    parseNode(this.root);
    function parseNode(node) {
      if (node instanceof ForEachAnswer) return;
      if (node instanceof Questionable) {
        if (!node.isNodeHidden) total += 1;
        if (node.answers.some((_answer) => _answer.hasUserVoted() && !_answer.isPreselected)) complete += 1;
      }
      if (node instanceof Answerable && !node.checked) return;
      node.children.forEach(parseNode);
    }
    const percentage = !total ? 0 : Math.floor(((complete / total) || 0) * 100);
    return { complete, total, percentage };
  }
  get projectAnalytics() {
    let startTime;
    let endTime;
    let totalAnswerCount = 0;
    parseNode(this.root);
    function parseNode(node) {
      if (node instanceof ForEachAnswer) return;
      if (node.checked && !node.isPreselected) {
        const timestamp = node.votes[0].ts;
        startTime = Math.min(startTime || Infinity, timestamp);
        endTime = Math.max(endTime || 0, timestamp);
        totalAnswerCount += 1;
      }
      node.children.forEach(parseNode);
    }
    return { startTime, endTime, totalAnswerCount };
  }
  get template() {
    return this.type === 'Template';
  }
  get project() {
    return this.type === 'Project';
  }
  get multipleProjects() {
    return this.type === 'MultipleProjects';
  }
  get projectsAddedCount() {
    if (!this.__projectsAdded) return 0;
    return this.__projectsAdded.size;
  }
  formattedProgress(showCount) {
    if (!this.project) return '';
    if (!this.progress) return '';
    return (this.progress.percentage === 100 ? ` (Complete)` : ` (${this.progress.percentage}% complete${showCount && `: ${this.progress.total - this.progress.complete} remaining)` || ')'}`) || '';
  }
  get formattedUpdateTime() {
    if (this.updateTime) return this.updateTime.toLocaleString();
    return '';
  }
  get isDisabled() {
    if (this.multipleProjects) return true;
    if (this.project) return !this.isOwner;
    if (!this.editing) return false;
    if (this.isTemplateAndShowingAPreviousVersion) return true;
    return super.isDisabled;
  }
  get isTemplateAndShowingAPreviousVersion() {
    if (this.template && this.currentVersionDisplayed && this.currentVersionDisplayed !== this.version) return true;
    return false;
  }

  get isCheckoutAllowed() {
    if (this.project) return false;
    return super.isCheckoutAllowed;
  }
  get isCheckedOut() {
    if (this.project) return false;
    return super.isCheckedOut;
  }
  
  onNodeAdded(node, parents) {
    this.__recursivelyApplyParents(node, parents);
    this.emitStateUpdate();
  }
  onNodeMoved() {
    this.emitStateUpdate();
  }
  onNodeDeleted(node) {
    if (this.onRemoveActiveObject) this.onRemoveActiveObject(node);
    this.__recursivelyRemoveParents(node);
    this.emitStateUpdate();
  }
  onNodeUpdated() {
    if (this.type === 'Project') {
      this.progress = { ...(this.progress || {}), ...this.__percentageComplete };
    }
    this.emitStateUpdate();
  }
  onAttributeChanged() {
    this.emitStateUpdate();
  }
  onBusy(_node, _busy) {
    const previouslyBusy = this.isBusy;
    if (_busy) this.busyNodes.add(_node);
    else this.busyNodes.delete(_node);
    if (previouslyBusy !== this.isBusy) this.emitStateUpdate();
  }
  updateChildrenJsonOrText(_childrenJsonOrText) {
    if (_childrenJsonOrText.text && this.getTreeTemplateText() === _childrenJsonOrText.text) return;
    const oldRoot = this.root;
    const oldBranches = this.branches;
    this.root = null;
    this.branches = null;
    this.busyNodes = new Set();
    this.__createChildrenFromJsonOrText(_childrenJsonOrText);
    this.__mergeExpandedOldToNewTree(oldRoot, this.root);
    this.__recursivelyRemoveParents(oldRoot);
    this.__recursivelyRemoveParents(oldBranches);
  }
  addAnswers(_answerTree) {
    if (!this._id) return;
    if (this.type !== 'Template' || _answerTree.type !== 'Project') return;
    if (_answerTree.referenceTree && _answerTree.referenceTree !== this._id) return;

    // Show template version that the project was saved against
    if (_answerTree.reference) {
      const referenceTreeVersion = _answerTree.reference.trees.find(({ _id }) => _id === _answerTree.referenceTree).version;
      if (referenceTreeVersion < this.version) {
        const foundIndex = this.diffs.findIndex(({ version }) => version === referenceTreeVersion);
        if (foundIndex >= 0) {
          this.showVersion(referenceTreeVersion, this.diffs.slice(0, foundIndex + 1));
        }
      }
    }

    // Override with the project properties
    this.answersAdded = true;
    this.type = _answerTree.type;
    this.state = _answerTree.state;
    this.editor = _answerTree.editor;
    if (_answerTree.createdTime) this.createdTime = new Date(_answerTree.createdTime);
    if (_answerTree.updateTime) this.updateTime = new Date(_answerTree.updateTime);
    this.parentTree = _answerTree.parentTree;
    this.owner = _answerTree.owner;
    this.company = _answerTree.company || '';
    this.background = _answerTree.background;
    this.users = _answerTree.users || [];
    this.teams = _answerTree.teams || [];
    this.password = _answerTree.password || '';
    this.progress = _answerTree.progress;
    this.diffs = null;

    // Save reference tree id and version and set new id and version for project
    this.referenceTree = this._id;
    this._id = _answerTree._id;
    this.referenceTreeVersion = this.currentVersionDisplayed || this.version;
    this.version = _answerTree.version || 1;

    // Create settings, title and description
    this.shareSetting = _answerTree.shareSetting || 'LinkView';
    const titleJson = _answerTree.children.find((_child) => _child.attributes[0] === TITLE);
    if (titleJson) {
      this.title = new Meta(titleJson.text, titleJson.attributes, (this.title && this.title.children) || []);
    } else this.title = new Meta('', [TITLE], (this.title && this.title.children) || []);
    const descriptionJson = _answerTree.children.find((_child) => _child.attributes[0] === DESCRIPTION);
    if (descriptionJson) {
      this.description = new Meta(descriptionJson.text, descriptionJson.attributes, (this.description && this.description.children) || []);
    } else this.description = new Meta('', [DESCRIPTION], (this.description && this.description.children) || []);

    // Detect answer schema, migrate, and then parse answers
    this.__detectSchemaVersionAndMigrate(_answerTree.children);
    this.__parseChildrenAnswers(_answerTree.children.find((_child) => _child.attributes[0] === ROOT));

    // Recursively apply parent chains
    this.__recursivelyApplyParents(this.title, [this]);
    this.__recursivelyApplyParents(this.description, [this]);
    this.__recursivelyApplyParents(this.report, [this]);
    this.__recursivelyApplyParents(this.root, [this]);
    if (this.email) this.__recursivelyApplyParents(this.email, [this]);
    if (this.actions) this.__recursivelyApplyParents(this.actions, [this]);
  }
  addMultipleProjects(_project) {
    if (!this._id) return;
    if (!this.multipleProjects) return;
    if (_project.referenceTree && _project.referenceTree !== this._id) return;
    if (!this.__projectsAdded) this.__projectsAdded = new Set();
    if (this.__projectsAdded.has(_project._id)) return;
    this.__projectsAdded.add(_project._id);

    this.answersAdded = true;
    this.state = null;
    this.editor = null;
    this.createdTime = null;
    this.updateTime = null;
    this.parentTree = null;
    this.owner = null;
    this.users = null;
    this.teams = null;
    this.diffs = null;
    this.shareSetting = null;

    // Detect answer schema, migrate, and then parse answers
    this.__detectSchemaVersionAndMigrate(_project.children);
    this.__parseChildrenAnswers(_project.children.find((_child) => _child.attributes[0] === ROOT), _project._id);

    // Recursively apply parent chains
    this.__recursivelyApplyParents(this.root, [this]);
  }
  updatePassword(_password) {
    this.password = _password;
  }
  updateQueryParameters(_queryParameters) {
    const foundParameters = new Set();
    this.__applyQueryParameters(this.root, _queryParameters, foundParameters);
    return foundParameters;
  }
  isMatchingNode() {
    return false;
  }
  __parseChildren(_parents, _node) {
    const _children = _node.children;
    const children = [];
    const forEachAnswerToPush = [];
    let forEachAnswerQuestions = [];
    /**
     * Parse ForEachAnswer first
     */
    _children.forEach((_child) => {
      if (_child.attributes[0] === FOR_EACH_ANSWER) {
        const newForEachAnswer = new ForEachAnswer(_child.text, _child.attributes, this.__parseChildren([..._parents, _node], _child));
        forEachAnswerToPush.push(newForEachAnswer);
        if (!this.editing && !(_node instanceof Model)) forEachAnswerQuestions = newForEachAnswer.children;
      }
    });

    /**
      * Now create the rest
      */
    _children.forEach((_child) => {
      // eslint-disable-next-line default-case
      const type = _child.attributes[0];
      switch (type) {
        case ANSWER:
          const answerChildren = _child.attributes && _child.attributes.includes(IGNORE_FOR_EACH_ANSWER_ATTR) ? this.__parseChildren([..._parents, _node], _child) : [...forEachAnswerQuestions.map((_q) => _q.clone()), ...this.__parseChildren([..._parents, _node], _child)];
          children.push(new Answer(_child.text, _child.attributes, answerChildren, _child.owner, _child.votes));
          break;
        case BRANCH:
          const foundInParent = _parents.find((p) => p.attributes[0] === BRANCH && p.text === _child.text);
          if (!foundInParent) {
            const branchObject = this.branches && this.branches.children.find((child) => child.text && child.isMatchingNode(_child));
            // eslint-disable-next-line no-nested-ternary
            const branchChildren = (_node.attributes[0] === BRANCHES || !this.editing) ? (
              branchObject ? this.__parseChildren([..._parents, _node], branchObject) : this.__parseChildren([..._parents, _node], _child)
            ) : [];
            children.push(new Branch(_child.text, _child.attributes, branchChildren, _child.owner));
          }
          break;
        case CATEGORY:
          children.push(new Category(_child.text, _child.attributes, this.__parseChildren([..._parents, _node], _child)));
          break;
        case CHECKLIST_ITEM:
          children.push(new ChecklistItem(_child.text, _child.attributes, this.__parseChildren([..._parents, _node], _child), _child.owner, _child.votes));
          break;
        case FILTER:
          const filter = new Filter(_child.text, _child.attributes, this.__parseChildren([..._parents, _node], _child), _child.owner);
          filter.parents = [this];
          this.createType(ANSWER, filter);
          children.push(filter);
          break;
        case FILTER_LOGIC:
          const filters = new FilterLogic(_child.text, _child.attributes, this.__parseChildren([..._parents, _node], _child), _child.owner);
          children.push(filters);
          break;
        case FOR_EACH_ANSWER:
          children.push(forEachAnswerToPush.shift());
          break;
        case KEYWORDS:
          children.push(new Keywords(_child.text, _child.attributes, this.__parseChildren([..._parents, _node], _child)));
          break;
        case NOTE:
          children.push(new Note(_child.text, _child.attributes, this.__parseChildren([..._parents, _node], _child), _child.owner, _child.votes));
          break;
        case QUESTION:
          const question = new Question(_child.text, _child.attributes, this.__parseChildren([..._parents, _node], _child), _child.owner);
          question.parents = [this];
          this.createType(ANSWER, question);
          children.push(question);
          break;
        case SEARCH:
          const searchChildren = this.__parseChildren([..._parents, _node], _child);
          const newSearch = new Search(_child.text, _child.attributes, searchChildren, _child.owner);
          children.push(newSearch);
          if (searchChildren.length === 0) this.createType(SEARCH_TEXT, newSearch);
          break;
        case SEARCH_TEXT:
          children.push(new SearchText(_child.text, _child.attributes, this.__parseChildren([..._parents, _node], _child), _child.owner, _child.votes));
          break;
        case SPLIT_PANE:
          const splitPane = new SplitPane(_child.text, _child.attributes, this.__parseChildren([..._parents, _node], _child), _child.owner);
          this.splitPanes.push(splitPane);
          children.push(splitPane);
          break;
        case SUB_TREE:
          const subtree = new Subtree(_child.text, _child.attributes, []);
          this.subtrees.push(subtree);
          children.push(subtree);
          break;
        default:
          if (CONSTRUCTORS[type]) children.push(new CONSTRUCTORS[type](_child.text, _child.attributes, this.__parseChildren([..._parents, _node], _child), _child.owner));
          else children.push(new Node(_child.text, _child.attributes, this.__parseChildren([..._parents, _node], _child), _child.owner));
          break;
      }
      if (_child.expanded && children.length > 0) {
        children[children.length - 1].setExpanded(true);
      }
    });
    return children;
  }
  setExpanded() {
    // Placeholder if called on tree
  }
  __parseChildrenAnswers(_node, _project) {
    // Parsing childrenAnswers needs to match Answerable items
    // Matching uses specificity of matched parent nodes to
    // determine if it is replacing or adding at location
    // Order of operation:
    //  1. Attempt exact match using version saved with
    //  2. Use specificity algorithm adding a point if isMatchingNode

    // The answer to parents mapping should contain
    // { answer, parentChain, potentialParents: [{ parentsMatched, answerMatched }] }
    const answerToParentsMappings = [];
    // const parentToAnswersMappings = {};

    // Apply answerMappings
    const __applyMapping = (_mapping) => {
      if (_mapping.potentialParents.length === 0 && !_mapping.answerMatched) return;

      const _answer = _mapping.answer;
      const isInput = _answer.attributes[0] === SEARCH_TEXT;
      const isAnswer = _answer.attributes[0] === ANSWER;
      const isItem = _answer.attributes[0] === CHECKLIST_ITEM;
      const isSubtree = _answer.attributes[0] === SUB_TREE;
      const isFile = _answer.attributes[0] === FILE;
      // const isObjectRef = _answer.attributes[0] === OBJECT_REF;
      const isNote = _answer.attributes[0] === NOTE;
      /**
       * Find matching children by type and text, but if Answer match with empty answers
       */
      // Get the last potentialParent match

      // Find the immediate parent and if it doesn't exist, create the chain
      let _parent;
      _mapping.potentialParents.forEach((p) => {
        if (_parent || !p.answerMatched) return;
        _parent = p.answerMatched.parents[p.answerMatched.parents.length - 1];
      });
      const lastParentToMatch = _mapping.parents[_mapping.parents.length - 1];
      if (!_parent) {
        _mapping.potentialParents.forEach((p) => {
          if (_parent) return;
          if (p.parentsMatched.length === 0) return;
          const lastParentMatched = p.parentsMatched[p.parentsMatched.length - 1];
          if (lastParentMatched.isMatchingNode(lastParentToMatch)) _parent = lastParentMatched;
        });
      }
      if (!_parent) {
        const secondToLastParentToMatch = _mapping.parents[_mapping.parents.length - 2];
        _mapping.potentialParents.forEach((p) => {
          if (_parent) return;
          if (p.parentsMatched.length === 0) return;
          const lastParentMatched = p.parentsMatched[p.parentsMatched.length - 1];
          if (lastParentMatched.isMatchingNode(secondToLastParentToMatch)) {
            const lastParentMatchedParent = p.parentsMatched[p.parentsMatched.length - 2];
            const lastParentMatchedParents = p.parentsMatched.slice(0, -2);
            _parent = this.__parseChildren(lastParentMatchedParents, { text: lastParentMatchedParent.text, attributes: lastParentMatchedParent.attributes, children: [lastParentToMatch] })[0];
            lastParentMatched.__addChild(_parent);
            this.__recursivelyApplyParents(_parent, [...lastParentMatched.parents, lastParentMatched]);
          }
        });
      }
      if (!_parent) return;

      // Create the answer
      const matchingTreeChildIndex = _parent.children.findIndex((_child, j) => {
        if (_child.isMatchingNode(_answer)) return true;

        // Should match Answerable only if it doesn't exist later
        if (_child instanceof Answerable && !_child.text && !_child.owner && _child.isMatchingAttributes(_answer)) {
          if (!(_parent.children.slice(j + 1).some((_c) => _c.isMatchingNode(_answer)))) return true;
        }

        // Special case for multipleProjects and SearchText
        if (this.multipleProjects && _child instanceof SearchText && _child.isMatchingAttributes(_answer)) return true;

        return false;
      });
      let matchingTreeChild = _parent.children[matchingTreeChildIndex];
      if (isAnswer) {
        if (!matchingTreeChild) {
          const answerChildren = _answer.attributes && _answer.attributes.includes(IGNORE_FOR_EACH_ANSWER_ATTR) ? [] : [..._parent.forEachAnswerQuestions];
          matchingTreeChild = new Answer(_answer.text, _answer.attributes, answerChildren, _answer.owner, _answer.votes, null, _project);
          this.__recursivelyApplyParents(matchingTreeChild, [..._parent.parents, _parent]);
          _parent.addExistingAnswer(matchingTreeChild);
        } else {
          matchingTreeChild.addAnswer(_answer.text, _answer.votes, _answer.owner, _answer.attributes, _project);
        }
      } else if (isInput) {
        if (!matchingTreeChild) {
          matchingTreeChild = new SearchText(_answer.text, _answer.attributes, [], _answer.owner, _answer.votes, null, _project);
          _parent.__addChild(matchingTreeChild);
          this.__recursivelyApplyParents(matchingTreeChild, [..._parent.parents, _parent]);
        } else {
          let newText = _answer.text;
          if (this.multipleProjects) newText = `${matchingTreeChild.text}\r\n\r\n${_answer.text}`;
          matchingTreeChild.addAnswer(newText, _answer.votes, _answer.owner, _answer.attributes, _project);
        }
      } else if (isItem) {
        if (!matchingTreeChild) {
          matchingTreeChild = new ChecklistItem(_answer.text, _answer.attributes, [], _answer.owner, _answer.votes, null, _project);
          this.__recursivelyApplyParents(matchingTreeChild, [..._parent.parents, _parent]);
          _parent.addExistingAnswer(matchingTreeChild);
        } else {
          matchingTreeChild.addAnswer(_answer.text, _answer.votes, _answer.owner, _answer.attributes, _project);
        }
      } else if (isSubtree && matchingTreeChild) {
        const newResultSubtree = new Subtree(_answer.text, _answer.attributes, this.__parseChildren([..._parent.parents, _parent], _answer), _answer.owner);
        this.__recursivelyApplyParents(newResultSubtree, [..._parent.parents, _parent]);
        this.subtrees = this.subtrees.filter((_subtree) => _subtree.key !== matchingTreeChild.key);
        this.subtrees.push(newResultSubtree);
        _parent.children[matchingTreeChildIndex] = newResultSubtree; // eslint-disable-line
      } else if (isNote) {
        matchingTreeChild = new Note(_answer.text, _answer.attributes, [], _answer.owner, _answer.votes);
        _parent.__addChild(matchingTreeChild);
        this.__recursivelyApplyParents(matchingTreeChild, [..._parent.parents, _parent]);
      } else if (isFile) {
        const matchingFileNode = _parent.children.find((_child) => !_child.text && !_child.owner && this.arraysEqual(_child.attributes, _answer.attributes));
        if (matchingFileNode) matchingFileNode.addAnswer(_answer.text, _answer.votes, _answer.owner, _answer.attributes, _project);
      }
    };

    /**
     * Get the Answer Mappings
     *
     * This section iterates through all the possible nodes to find all of the matches
     * It creates a mapping of the best possible choices
     */
    const __getAnswerMappings = (answer, parents) => {
      if ([SEARCH_TEXT, ANSWER, CHECKLIST_ITEM, SUB_TREE, OBJECT_REF, NOTE, FILE].includes(answer.attributes[0])) {
        // Find match either based on exact matchingNode or matching an openEnded node with an answer
        const __findMatches = (_parentToTest, _nodeToMatch) => {
          let exactMatchFounds = _parentToTest.children.filter((_child) => _child.isMatchingNode(_nodeToMatch));
          // Allow matching and replacing empty userInput nodes
          if (exactMatchFounds.length === 0 && _nodeToMatch.owner) {
            exactMatchFounds = _parentToTest.children.filter((_child) => !_child.text && !_child.owner && this.arraysEqual(_nodeToMatch.attributes, _child.attributes));
          }
          return exactMatchFounds;
        };

        // Search parent chain
        const potentialParents = new Set();
        const __recursivelyMatchParentsAndAnswer = (_potentialParent, currentParentToTest) => {
          // If all parents have been matched, test for answer match
          // Otherwise continue testing for parents match
          const lastParentMatched = _potentialParent.parentsMatched[_potentialParent.parentsMatched.length - 1];
          if ([ANSWER, CHECKLIST_ITEM, NOTE].includes(answer.attributes[0]) && lastParentMatched && parents.findIndex((p) => lastParentMatched.isMatchingNode(p)) === parents.length - 1) {
            // Test for exact answer match
            const answerMatchesFound = __findMatches(currentParentToTest, answer);
            answerMatchesFound.forEach((answerMatchFound, i) => {
              if (i === 0) {
                _potentialParent.answerMatched = answerMatchFound;
                potentialParents.add(_potentialParent);
              } else {
                const newPotentialParent = { parentsMatched: [..._potentialParent.parentsMatched] };
                newPotentialParent.answerMatched = answerMatchFound;
                potentialParents.add(newPotentialParent);
              }
            });
            return;
          }
          // Test for exact parent match
          if (parents.length > _potentialParent.parentsMatched.length) {
            const currentParentToMatch = parents[_potentialParent.parentsMatched.length];
            const parentMatchesFound = __findMatches(currentParentToTest, currentParentToMatch);
            if (parentMatchesFound.length > 0) {
              parentMatchesFound.forEach((parentMatchFound) => {
                const newPotentialParent = { parentsMatched: [..._potentialParent.parentsMatched] };
                newPotentialParent.parentsMatched.push(parentMatchFound);
                __recursivelyMatchParentsAndAnswer(newPotentialParent, parentMatchFound);
                potentialParents.add(newPotentialParent);
              });
              return;
            }
          }

          // Test for answer match
          if ([SEARCH_TEXT, SUB_TREE, OBJECT_REF].includes(answer.attributes[0])) {
            const answerMatchesFound = __findMatches(currentParentToTest, answer);
            if (answerMatchesFound.length > 0) {
              answerMatchesFound.forEach((answerMatchFound, i) => {
                if (i === 0) {
                  _potentialParent.answerMatched = answerMatchFound;
                  potentialParents.add(_potentialParent);
                } else {
                  const newPotentialParent = { parentsMatched: [..._potentialParent.parentsMatched] };
                  newPotentialParent.answerMatched = answerMatchFound;
                  potentialParents.add(newPotentialParent);
                }
              });
              return;
            }
          }

          // TODO: Test for other parents matching

          // If no matches, open up recurse to all children
          currentParentToTest.children.forEach((_child) => {
            if (_child.isOneOfTypes([FOR_EACH_ANSWER])) return;
            const newPotentialParent = { parentsMatched: [..._potentialParent.parentsMatched] };
            __recursivelyMatchParentsAndAnswer(newPotentialParent, _child);
          });
        };
        __recursivelyMatchParentsAndAnswer({ parentsMatched: [this.root] }, this.root);
        const matchResults = { answer, parents, potentialParents: [...potentialParents] };
        answerToParentsMappings.push(matchResults);
        __applyMapping(matchResults);
      }
      answer.children.forEach((_child) => __getAnswerMappings(_child, [...parents, answer]));
    };

    // Get Mappings
    __getAnswerMappings(_node, []);
  }
  createType(type, node, index, attributes, text, userCreated, dontAddEmptyNodeChild) {
    let children = [];
    if (node.canAddChild && !node.canAddChild(type, userCreated)) return null;
    if (TOP_LEVEL_NODES.includes(type)) return null;
    if (type === ANSWER) {
      children = attributes && attributes.includes(IGNORE_FOR_EACH_ANSWER_ATTR) ? [] : node.forEachAnswerQuestions;
    }
    const newChild = (CONSTRUCTORS[type] && new CONSTRUCTORS[type](text || '', attributes || [type], children)) || (new Node(text || '', attributes || [type], children));
    node.addChild(newChild, index);

    if (!dontAddEmptyNodeChild) {
      if (type === CHECKLIST) {
        this.createType(CHECKLIST_ITEM, newChild);
      } else if (type === FILE_GROUP) {
        this.createType(FILE, newChild);
      } else if (type === QUESTION) {
        this.createType(ANSWER, newChild, null, null, null, userCreated);
      } else if (type === SEARCH) {
        this.createType(SEARCH_TEXT, newChild);
      }
    }
    return newChild;
  }

  createFilterQuestion(_filter, choices, childNodes) {
    // First set every answer to be filtered by default
    const filterAnswers = _filter.filters;
    _filter.children.forEach((_c) => {
      if (!(_c instanceof Answer)) return;
      // Dont filter the filterAnswer
      if (filterAnswers.includes(_c)) _c.filteredByFilters = false;
      // Dont filter user answers
      else if (_c.isUserAnswer) _c.filteredByFilters = false;
      // Filter all other answers
      else _c.filteredByFilters = true;
    });
    Array.from(new Set(choices)).forEach((_c) => {
      if (!_c) return;
      const existingAnswerIndex = _filter.children.findIndex((_a) => _a.attributes[0] === ANSWER && !filterAnswers.includes(_a) && _a.text === _c);
      let existingAnswer;
      if (existingAnswerIndex >= 0) {
        existingAnswer = _filter.children[existingAnswerIndex];
        existingAnswer.filteredByFilters = false;
        _filter.children.splice(existingAnswerIndex, 1);
        _filter.children.push(existingAnswer);
        this.__recursivelyApplyParents(existingAnswer, [..._filter.parents, _filter]);
      } else {
        existingAnswer = new Answer(_c, [ANSWER], _filter.forEachAnswerQuestions);
        _filter.__addChild(existingAnswer);
        this.__recursivelyApplyParents(existingAnswer, [..._filter.parents, _filter]);
      }
      // Add the childNodes
      if (!childNodes) return;
      Object.keys(childNodes).forEach((child) => {
        if (!childNodes[child][_c]) return;
        const attributes = child.split(',').map((a) => a.trim());
        const type = attributes[0];
        Array.from(childNodes[child][_c]).forEach((text) => {
          this.createType(type, existingAnswer, null, attributes, text);
        });
      });
    });
    _filter.__internalComputeQuestionFilter();
    _filter.emitStateUpdate();
  }
  backward() {
    if (this.currentVersionDisplayed > 1) {
      this.showVersion(this.currentVersionDisplayed - 1);
    } else if (!this.currentVersionDisplayed && this.version > 1) {
      this.showVersion(this.version - 1);
    }
  }
  forward() {
    if (this.version > this.currentVersionDisplayed) {
      this.showVersion(this.currentVersionDisplayed + 1);
    }
  }
  showVersion(_version, _diffs) {
    let versionText = this.__initialTemplateString;
    _diffs.forEach((_diff) => {
      const { delta, text } = _diff;
      if (delta) {
        const reverseDelta = reverse(delta);
        versionText = patch(versionText, reverseDelta)
      } else if (text) {
        versionText = text;
      }
    });
    const convertedToJson = TreeTextToTreeJson(versionText);

    // Remove root and branches
    const oldRoot = this.root;
    const oldBranches = this.branches;
    const oldReport = this.report;
    const oldEmail = this.email;
    const oldActions = this.actions;
    this.root = null;
    this.branches = null;
    this.report = null;
    this.email = null;
    this.actions = null;
    this.busyNodes = new Set();
    const _children = convertedToJson.children;
    this.__recursivelyRemoveParents(oldRoot);
    this.__recursivelyRemoveParents(oldBranches);
    this.__recursivelyRemoveParents(oldReport);
    if (oldEmail) this.__recursivelyRemoveParents(oldEmail);
    if (oldActions) this.__recursivelyRemoveParents(oldActions);

    // Parse the new root and branches and merge expanded
    this.__parseChildrenJson(_children);
    this.__mergeExpandedOldToNewTree(oldRoot, this.root);
    this.text = versionText;
    if (_version !== this.version) {
      this.currentVersionDisplayed = _version;
    } else {
      this.currentVersionDisplayed = null;
    }
    recursivelyExpandToCategories(this.root);
    this.emitStateUpdate();
  }
  createVersion() {
    if (this.type === 'Template') {
      const newText = this.getTreeTemplateText();
      const oldText = this.__initialTemplateString;
      if (newText !== oldText) {
        let delta;
        let reverseWorks;
        try {
          // This is in try catch because of emoji bug
          delta = diff(oldText, newText);

          // Test diff in reverse to ensure it works
          const reverseDelta = reverse(delta);
          const reverseText = patch(newText, reverseDelta);
          reverseWorks = reverseText === oldText;
        } catch(e) {
          // Ensure delta is null on failure
          delta = null;
          reverseWorks = false;
        }

        // Dont use the delta if
        // 1. The delta is bigger than the text
        // 2. The reverse diff doesn't work
        let newDiff;
        if (!delta || !reverseWorks || (EstimateObjectSize(oldText) < EstimateObjectSize(delta))) {
          newDiff = { version: this.version, text: oldText, owner: this.userLoggedIn, createdTime: (new Date()) };
        } else {
          newDiff = { version: this.version, delta, owner: this.userLoggedIn, createdTime: (new Date()) };
        }
        this.diffs = [newDiff, ...(this.diffs || [])];
        this.__initialTemplateString = newText;
        this.text = newText;
        this.currentVersionDisplayed = null;
        this.version += 1;
      }
    } else {
      this.version += 1;
    }
  }
  __applyQueryParameters(node, queryParameters, foundParameters) {
    if (node instanceof Questionable) {
      const { defaultValue, filter } = node;
      const queryParameter = findAllHandlebars(defaultValue || '')[0];
      const queryValue = queryParameters[queryParameter];
      if (queryValue) {
        const queryValues = queryValue.split(',');
        let found = false;
        if (filter) {
          node.children.forEach((child) => {
            if (child.isUserAnswer && !child.checked) {
              child.updateText(queryValues.shift());
              found = true;
            }
          });
        } else {
          // First prioritize matching exact children
          node.children.forEach((child) => {
            if (queryValues.includes(child.text) && !child.checked) {
              child.addUserVote();
              queryValues.splice(queryValues.indexOf(child.text), 1);
              found = true;
            }
          });
          // Then match empty user answers
          node.children.forEach((child) => {
            if (child.isUserAnswer && !child.checked) {
              child.updateText(queryValues.shift());
              found = true;
            }
          });
        }
        if (found) foundParameters.add(queryParameter);
      }
    }
    node.children.forEach((child) => this.__applyQueryParameters(child, queryParameters, foundParameters));
  }
  __mergeExpandedOldToNewTree(_oldNode, _newNode) {
    // eslint-disable-next-line no-param-reassign
    _newNode.setExpanded(_oldNode.expanded);
    _newNode.children.forEach((_child) => {
      const oldNodeFound = _oldNode.children.find((child) => child.isMatchingNode(_child));
      if (oldNodeFound) this.__mergeExpandedOldToNewTree(oldNodeFound, _child);
    });
  }
  __createChildrenFromJsonOrText(_childrenJsonOrText) {
    let _children;
    if (Array.isArray(_childrenJsonOrText)) {
      _children = _childrenJsonOrText;
    } else {
      const convertedToJson = TreeTextToTreeJson(_childrenJsonOrText.text);
      _children = convertedToJson.children;
    }
    this.__parseChildrenJson(_children);
    this.text = this.getTreeTemplateText();
  }
  __parseChildrenJson(_children) {
    /**
     * Handle schema migrations
     */
    this.__detectSchemaVersionAndMigrate(_children);
    /**
     * Parse title and description
     */
    const titleJson = _children.find((_child) => _child.attributes[0] === TITLE);
    if (titleJson) {
      this.title = new Meta(titleJson.text, titleJson.attributes, this.__parseChildren([this], titleJson));
    } else this.title = new Meta('', [TITLE], []);
    const descriptionJson = _children.find((_child) => _child.attributes[0] === DESCRIPTION);
    if (descriptionJson) {
      this.description = new Meta(descriptionJson.text, descriptionJson.attributes, this.__parseChildren([this], descriptionJson));
    } else this.description = new Meta('', [DESCRIPTION], []);
    const reportJson = _children.find((_child) => _child.attributes[0] === REPORT);
    if (reportJson) {
      this.report = new Report(reportJson.text, reportJson.attributes, this.__parseChildren([this], reportJson));
    } else this.report = new Report('', [REPORT], []);
    const emailJson = _children.find((_child) => _child.attributes[0] === EMAIL);
    if (emailJson) {
      this.email = new Email(emailJson.text, emailJson.attributes, this.__parseChildren([this], emailJson));
    }
    const actionsJson = _children.find((_child) => _child.attributes[0] === ACTIONS);
    if (actionsJson) {
      this.actions = new Actions(actionsJson.text, actionsJson.attributes, this.__parseChildren([this], actionsJson));
    }
    this.__recursivelyApplyParents(this.title, [this]);
    this.__recursivelyApplyParents(this.description, [this]);
    this.__recursivelyApplyParents(this.report, [this]);
    if (this.email) this.__recursivelyApplyParents(this.email, [this]);
    if (this.actions) this.__recursivelyApplyParents(this.actions, [this]);
    /**
     * Parse Branches
     * This happens before root to allow injection during parse root
     */
    const branchesJson = _children.find((_child) => _child.attributes[0] === BRANCHES);
    if (branchesJson) {
      this.branches = new Branches(branchesJson.text, branchesJson.attributes, this.__parseChildren([this], branchesJson));
      this.__recursivelyApplyParents(this.branches, [this]);
    } else {
      this.branches = new Branches('', [BRANCHES], []);
      this.__recursivelyApplyParents(this.branches, [this]);
    }
    /**
     * Parse Root
     */
    const rootJson = _children.find((_child) => _child.attributes[0] === ROOT);
    if (rootJson) {
      this.root = new Root(rootJson.text, rootJson.attributes, this.__parseChildren([this], rootJson));
      this.__recursivelyApplyParents(this.root, [this]);
    } else {
      this.root = new Root('', [ROOT], []);
      this.__recursivelyApplyParents(this.root, [this]);
    }
  }
  __recursivelyCreateChildren(node, children) {
    children.forEach((child) => {
      const newNode = this.createType(child.attributes[0], node, null, null, child.text);
      if (child.children) this.__recursivelyCreateChildren(newNode, child.children);
    });
  }
  __detectSchemaVersionAndMigrate(_children) {
    const schemaJson = _children.find((_child) => _child.attributes[0] === SCHEMA);
    if (schemaJson) {
      this.schema = new Meta(schemaJson.text, schemaJson.attributes, []);
    } else {
      const autoDetectedSchema = treeAutoDetectSchema(_children);
      this.schema = new Meta(autoDetectedSchema >= 0 ? autoDetectedSchema : CURRENT_TREE_SCHEMA_VERSION, [SCHEMA], []);
    }
    if (this.schema.text <= 0) TreeV0toV1(this.type, _children);
  }
  getAllSpreadsheets(node) {
    let totalSpreadsheets = new Set();
    node.children.forEach((_child) => {
      if (_child.isType(DATA)) {
        totalSpreadsheets.add(_child.text);
      }
      const results = this.getAllSpreadsheets(_child);
      totalSpreadsheets = new Set([...totalSpreadsheets, ...results]);
    });
    return totalSpreadsheets;
  }
  getFormattedActionSteps() {
    if (!this.actions) return [];
    let steps = [];
    this.actions.children.forEach((action) => {
      steps = [...steps, ...action.steps];
    });
    const rootNodes = __recursivelyGetAllQuestionableNodes(this.root);
    steps.forEach((step) => {
      step.body = __replaceHandlebarsWithValues(rootNodes, step.body, true, null, step.delimiter);
    });
    return steps;
  }
  isType() {
    return false;
  }
  isOneOfTypes() {
    return false;
  }
}

export function TreeJsonToTreeText(_tree) {
  if (!_tree) return null;
  let treeText = '';
  /**
   * parse the tree
   */
  _tree.children.forEach((__child) => parseChild(__child, 0));
  /**
   * Parse children
   */
  function parseChild(_child, _indent) {
    treeText = `${treeText}${' '.repeat(_indent)}[${_child.attributes.join(', ')}] ${_child.text}\n`;
    _child.children.forEach((__child) => parseChild(__child, _indent + 2));
  }
  /**
   * Return final string
   */
  return treeText;
}
export function TreeTextToTreeJson(_tree) {
  const lines = _tree.split(/\r?\n/);
  const structure = {
    children: [],
  };
  const indents = [0];
  lines.forEach((line) => {
    const splitIndex = line.indexOf('[');
    const secondSplitIndex = line.indexOf(']');
    if (splitIndex < 0) return;
    const tabbedPart = line.substring(0, splitIndex);
    let level = indents.findIndex((el) => el === tabbedPart.length);
    if (level < 0) {
      indents.push(tabbedPart.length);
      level = indents.length - 1;
    }
    getLevel(structure, 0, level);
    function getLevel(_structure, i, end) {
      // This signifies an error in the structure
      if (!_structure) return;

      if (i === level) {
        _structure.children.push({
          text: line.substring(secondSplitIndex + 1).trim(),
          attributes: line.substring(splitIndex + 1, secondSplitIndex).split(',').map((a) => a.trim()),
          children: [],
        });
      } else {
        getLevel(_structure.children[_structure.children.length - 1], i + 1, end);
      }
    }
  });
  return structure;
}
export function TreeModelToTreeJson(_tree, _export) {
  /**
   * Check if template or project
   */
  if (_tree.type !== 'Template') return null;
  const treeJson = {
    type: _tree.type,
    version: _tree.version,
    owner: _tree.owner,
    users: [],
    teams: [],
    children: [],
    shareSetting: _tree.shareSetting,
    password: _tree.password,
  };
  if (_tree.background) treeJson.background = _tree.background;
  if (Object.hasOwnProperty.call(_tree, 'company')) treeJson.company = _tree.company;
  if (_tree.state) treeJson.state = _tree.state;
  if (!_export && _tree.users) treeJson.users = _tree.users;
  if (!_export && _tree.teams) treeJson.teams = _tree.teams;
  if (_tree._id) treeJson._id = _tree._id;
  if (_tree.title) treeJson.children.push(parseNodeTreeModelToTreeJson(_tree.title, true));
  if (_tree.description) treeJson.children.push(parseNodeTreeModelToTreeJson(_tree.description, true));
  if (_tree.root) treeJson.children.push(parseNodeTreeModelToTreeJson(_tree.root, true));
  if (_tree.branches) treeJson.children.push(parseNodeTreeModelToTreeJson(_tree.branches));
  if (_tree.report) treeJson.children.push(parseNodeTreeModelToTreeJson(_tree.report, true));
  if (_tree.email) treeJson.children.push(parseNodeTreeModelToTreeJson(_tree.email, true));
  if (_tree.actions) treeJson.children.push(parseNodeTreeModelToTreeJson(_tree.actions, true));
  if (!_export && _tree.diffs) {
    treeJson.diffs = _tree.diffs;
  }
  if (_tree.customSource) treeJson.customSource = _tree.customSource;
  return treeJson;
}
function parseNodeTreeModelToTreeJson(_node, _hideBranch, _parent) {
  // Remove the answers with an owner
  if (_node.owner) return null;
  // Remove the answers cloned
  if (_node.cloned) return null;
  // Remove empty answers which have no children
  if (_parent instanceof Questionable && _node instanceof Answerable && !_node.text && !_node.filter && (!_node.children || _node.children.length === 0 || _node.children.every((_c) => _c instanceof Questionable && _c.cloned))) {
    // If SingleAnswer, just remove all empty answers
    if (!_parent.multipleAnswers) return null;
    // If MultipleAnswers and OpenEnded, only remove if just one empty answer
    if ((_parent.multipleAnswers && _parent.openEnded) && _parent.answers.filter((_a) => (!_a.text && (!_a.children || _a.children.length === 0 || _a.children.every((_c) => _c instanceof Questionable && _c.cloned)))).length === 1) return null;
  }
  /**
   * Create the node
   */
  const node = {
    text: _node.text,
    attributes: _node.attributes,
    children: [],
  };
  if (!(_hideBranch && _node instanceof Branch)) node.children = _node.children.map((_child) => parseNodeTreeModelToTreeJson(_child, (_hideBranch || _node instanceof Branch), _node)).filter((_child) => _child !== null);
  if (_node.owner) node.owner = _node.owner;
  return node;
}
export function TreeModelToTreeResultJson(_tree, _subtree, _showInfo, _allowHashKey, _showDisplayText) {
  /**
   * Owner of the results is the userLoggedIn
   */
  const treeJson = {
    type: 'Project',
    users: [],
    teams: [],
    children: [],
    progress: _tree.progress || _tree.__percentageComplete,
  };
  /**
   * Reference tree comes from template
   */
  if (_tree.referenceTree) treeJson.referenceTree = _tree.referenceTree;
  else if (_tree.type === 'Template') treeJson.referenceTree = _tree._id;

  if (_tree.company) treeJson.company = _tree.company;
  if (_tree.background) treeJson.background = _tree.background;

  // Detect if existing project or new project
  if (_tree.type === 'Project' && _tree._id) {

    // Existing project
    treeJson.version = _tree.version;
    treeJson._id = _tree._id;
    treeJson.owner = _tree.owner;
    if (_tree.title) {
      treeJson.children.push({
        text: _tree.title.text,
        attributes: _tree.title.attributes,
        children: [],
      });
    }
    if (_tree.description) {
      treeJson.children.push({
        text: _tree.description.text,
        attributes: _tree.description.attributes,
        children: [],
      });
    }
    if (_tree.state) treeJson.state = _tree.state;
    if (_tree.teams) treeJson.teams = _tree.teams;
    if (_tree.users) treeJson.users = _tree.users;
    if (_tree.shareSetting) treeJson.shareSetting = _tree.shareSetting;
    treeJson.password = _tree.password;
  } else {
    // New Project
    treeJson.version = 1;
    if (_subtree) {
      if (_subtree._id) treeJson._id = _subtree._id;
      if (_subtree.parentTree) treeJson.parentTree = _subtree.parentTree;
    }
    treeJson.owner = _tree.userLoggedIn;
    if (_tree.teams) treeJson.teams = _tree.teams;
    treeJson.children.push({ text: _tree.title && _tree.title.text || '', attributes: [TITLE], children: [] });
    treeJson.children.push({ text: _tree.description && _tree.description.text || '', attributes: [DESCRIPTION], children: [] });
    if (_tree.email) {
      const customEmail = createCustomEmail(_tree, true);
      if (customEmail) treeJson.email = {
        body: customEmail
      };
    }
  }
  if (_tree.root) treeJson.children.push(parseNodeTreeModelToTreeResultJson(_tree.root, _tree, _showInfo, _allowHashKey, _showDisplayText));

  // Get All References and Versions
  if (treeJson.referenceTree) {
    treeJson.reference = {
      trees: [{
        _id: treeJson.referenceTree,
        version: _tree.referenceTreeVersion || _tree.version,
      }],
      spreadsheets: [],
    }
    const references = __recursivelyGetAllReferenceVersions(_tree.root);
    if (references && references.length > 0) {
      references.forEach((ref) => {
        if (ref instanceof Tree) {
          if (treeJson.reference.trees.find((_ref) => _ref._id === ref._id)) return;
          treeJson.reference.trees.push({
            _id: ref._id,
            version: ref.version,
          })
        } else if (ref instanceof Spreadsheet) {
          if (treeJson.reference.spreadsheets.find((_ref) => _ref._id === ref._id)) return;
          treeJson.reference.spreadsheets.push({
            _id: ref._id,
            version: ref.version,
          })
        }
      });
    }
  }

  return treeJson;
}
function parseNodeTreeModelToTreeResultJson(_node, _parent, _showInfo, _allowHashKey, _showDisplayText) {
  if (_node instanceof Answerable && !_node.checked) return null;
  if (_node instanceof File && !_node.owner) return null;
  /**
   * Only save nodes that user has voted for
   */
  const children = _node.children.map((_child) => parseNodeTreeModelToTreeResultJson(_child, _node, _showInfo, _allowHashKey, _showDisplayText)).filter((_child) => _child !== null);
  if (!(_node instanceof Answerable || _node instanceof Root || _node instanceof ObjectRef || _node instanceof File) && children.length <= 0) return null;
  const { filter } = _parent;
  if (filter && (filter.greaterThan || filter.lessThan) && !_node.filter) return null;

  const node = {};
  if (_allowHashKey && ALLOW_HASH_KEY_INSTEAD_OF_TEXT_IN_PROJECT.includes(_node.attributes[0]) && _node.text.length > 32) {
    node.text = SparkMD5.hash(_node.text);
  } else if (_showDisplayText) {
    node.text = _node.formattedProjectText;
  } else {
    node.text = _node.text;
  }
  if (_node.attributes) node.attributes = _node.attributes;
  if (_node.owner) node.owner = _node.owner;
  if (_node.votes) node.votes = Array.from(_node.votes);
  node.children = children;
  if (_showInfo) {
    const info = _node.children.find((c) => c instanceof Info);
    if (info && info.text) {
      node.children.push({
        text: info.text,
        attributes: info.attributes,
        children: [],
      });
    }
  }
  return node;
}
function __recursivelyGetAllReferenceVersions(node) {
  let references = [];
  node.children.forEach((_child) => {
    if (_child instanceof Subtree && _child.tree) {
      references.push(_child.tree);
      return;
    }
    if (_child instanceof Data && _child.dataSource === LOGICTRY_SPREADSHEET_ATTR && _child.spreadsheet && _child.spreadsheet._id) {
      references.push(_child.spreadsheet);
      return;
    }
    if (_child.isType(ANSWER) && !_child.hasUserVoted()) return;
    if ([FOR_EACH_ANSWER].includes(_child.attributes[0])) return;
    const childResults = __recursivelyGetAllReferenceVersions(_child);
    references = [...references, ...childResults];
  });
  return references;
}
function __recursivelyGetAllNodesByType(node, type) {
  let nodes = [];
  node.children.forEach((_child) => {
    if (_child.isType(type)) {
      nodes.push(_child);
    }
    const childResults = __recursivelyGetAllNodesByType(_child, type);
    nodes = [...nodes, ...childResults];
  });
  return nodes;
}

function createText(_tree, _treeResults, answersOnly, includeLink) {
  let text = includeLink && `<p><a target="_blank" href="${window.location.origin}/apps/${_tree._id}">Link to project</a></p>` || '';
  if (_tree.title && _tree.title.text) {
    text = `${text}<h1 style="text-align: center;">${_tree.title.text}</h1>`;
  }
  if (_tree.description && _tree.description.text) {
    text = `${text}<div style="text-align: center;">${_tree.description.text}</div>`;
  }
  const root = answersOnly ? _treeResults.children.find((_child) => _child.attributes[0] === ROOT) : _tree.root;
  if (root.text) parseObject(root, 0);
  else root.children.forEach((_child) => parseObject(_child, 0, root));

  function parseObject(node, level, parent) {
    let textToAdd = node.text;
    const type = node.attributes[0];
    if (!textToAdd || !SHOW_IN_PROJECT_REPORTS.includes(type)) {
      node.children.forEach((_child) => parseObject(_child, level, node));
      return;
    }
    if (textToAdd) {
      const indent = level * 16;
      if (type === CATEGORY || type === ROOT) {
        text = `${text}<h2 style="margin-left: ${indent}px;">${textToAdd}</h2>`;
      } else if (node.votes && node.votes.length > 0) {
        text = `${text}<div style="margin-left: ${indent}px; border-left: 1px solid; padding-left: 15px; margin-top: 8px;">${textToAdd}</div>`;
      } else if ([CHECKLIST, FILE, FILE_GROUP, FILTER, QUESTION].includes(type)) {
        text = `${text}<div style="margin-left: ${indent}px; margin-top: 8px;">${textToAdd}</div>`;
      } else if (!answersOnly && [TEXT].includes(type)) {
        text = `${text}<div style="margin-left: ${indent}px; margin-top: 8px;">${textToAdd}</div>`;
      }
      node.children.forEach((_child) => parseObject(_child, level + 1, node));
      return;
    }
    node.children.forEach((_child) => parseObject(_child, level, node));
  }
  return text;
}
function createOpenAIText(_tree) {
  const questions = {};
  const root = _tree.root;
  if (root.text) parseObject(root, 0);
  else root.children.forEach((_child) => parseObject(_child, 0, root));

  function parseObject(node, level, parent) {
    let textToAdd = node.formattedProjectText;
    const type = node.attributes[0];
    if (!textToAdd || !SHOW_IN_PROJECT_REPORTS.includes(type)) {
      node.children.forEach((_child) => parseObject(_child, level, node));
      return;
    }
    if (textToAdd) {
      if (node.votes && node.votes.length > 0 || node.isPreselected) {
        if (!questions[parent.formattedProjectText]) questions[parent.formattedProjectText] = [];
        questions[parent.formattedProjectText].push(textToAdd);
      }
      node.children.forEach((_child) => parseObject(_child, level + 1, node));
      return;
    }
    node.children.forEach((_child) => parseObject(_child, level, node));
  }
  return questions;
}
function createCustomEmail(tree, showDisplayTextValues) {
  const email = tree.email.children.map((rc) => rc.text).join('');
  const rootNodes = __recursivelyGetAllQuestionableNodes(tree.root);
  return __replaceHandlebarsWithValues(rootNodes, email, showDisplayTextValues, ['CTA_LINK']);
}
function createCustomReport(tree, showDisplayTextValues, openAIResponse) {
  let report = tree.report.children.map((rc) => rc.text).join('');
  const rootNodes = __recursivelyGetAllQuestionableNodes(tree.root);
  if (openAIResponse) report = report.replace(`{{OPEN_AI_RESULT}}`, openAIResponse);
  return __replaceHandlebarsWithValues(rootNodes, report, showDisplayTextValues);
}
function __replaceHandlebarsWithValues(nodes, textToReplace, showDisplayTextValues, serverSideHandlebars, delimiter) {
  // Find all the handlebar matches
  let matchedVariables = findAllHandlebars(textToReplace);
  if (serverSideHandlebars) matchedVariables = matchedVariables.filter((v) => !serverSideHandlebars.includes(v));

  // Create all the derived rows
  const matchedNodes = matchedVariables.map((v) => nodes.filter((d) => d.text.trim() === unescape(v.trim())));

  // Replace the matched variables
  matchedVariables.forEach((_var, j) => {
    const matchedNodeNodes = matchedNodes[j];
    let variableText = '';
    matchedNodeNodes.forEach((matchedNodeNode) => {
      if (matchedNodeNode.answersChecked) {
        const { answersChecked } = matchedNodeNode;
        if (answersChecked && answersChecked.length > 0) {
          variableText = answersChecked.map((a) => {
            return showDisplayTextValues && a.formattedProjectText || a.formattedText;;
          }).join(delimiter || '');
        }
      } else if (matchedNodeNode.calculatedValueFormatted) {
        variableText = matchedNodeNode.calculatedValueFormatted;
      }
    });
    textToReplace = textToReplace.replace(`{{${_var}}}`, variableText);
  });

  return textToReplace;
}
function getCustomVariableValue(tree, varname) {
  const rootNodes = __recursivelyGetAllQuestionableNodes(tree.root);
  const foundNode = rootNodes.find((d) => d.text.trim() === unescape(varname.trim()) && d.answersChecked && d.answersChecked.length > 0);
  if (!foundNode) return null;
  const { answersChecked } = foundNode;
  return answersChecked[0].text;
}
function __recursivelyRunOnTreeInitialized(node) {
  node.children.forEach(__recursivelyRunOnTreeInitialized);
  if (node.onTreeInitialized) node.onTreeInitialized();
}
export function recursivelyExpandChildrenAnswered(node) {
  if (node instanceof Answerable && node.hasUserVoted() && !node.isPreselected) {
    // eslint-disable-next-line no-param-reassign
    node.setExpanded(true);
    // eslint-disable-next-line no-param-reassign
    node.parents.forEach((_parent) => { _parent.setExpanded(true); });
  } else if (node.isDefaultExpanded) {
    // eslint-disable-next-line no-param-reassign
    node.setExpanded(true);
  }
  node.children.forEach((_child) => recursivelyExpandChildrenAnswered(_child));
}
export function recursivelyExpandToCategories(node) {
  if (!node.isOneOfTypes([CATEGORY])) node.setExpanded(true);
  node.children.forEach(recursivelyExpandToCategories);
}
export function recursivelyCheckIfRequiredNodesEmpty(node) {
  let emptyRequired = false;
  if (node.isNodeHidden) return emptyRequired;
  if (node.isRequired) {
    if (node.answers.length > 0) {
      const emailRegex = node.userInputType === EMAIL_ATTR
      emptyRequired = !(node.answers.some((_answer) => _answer.checked && (!emailRegex || !validateEmail(_answer.text))));
      if (emptyRequired) node.updateShowRequiredText(true);
    } else if (node.files) {
      emptyRequired = !(node.files.some((_answer) => _answer.checked));
      if (emptyRequired) node.updateShowRequiredText(true);
    }
  }
  node.children.forEach((_child) => {
    if (_child.isType(CONDITIONAL) && !_child.conditionalValue) return;
    if (_child.isType(ANSWER) && !_child.hasUserVoted()) return;
    if ([FOR_EACH_ANSWER].includes(_child.attributes[0])) return;
    emptyRequired = recursivelyCheckIfRequiredNodesEmpty(_child) || emptyRequired;
  });
  return emptyRequired;
}
