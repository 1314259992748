import Service from './Service';
import Storage from './Storage';

class SidebarService extends Service {
  showVersions = false;
  showNodeTypes = Storage.state.showNodeTypes;
  selectType = 'Tree';
  showWysiwyg = Storage.state.showWysiwyg;

  updateSelectType(_selectType, _save) {
    this.selectType = _selectType;
    this.saveOnShowReport = _save;
    this.emitStateUpdate();
  }
  updateShowVersions(_showVersions) {
    this.showVersions = _showVersions;
    this.emitStateUpdate();
  }
  updateShowWysiwyg(_showWysiwyg) {
    Storage.setShowWysiwyg(_showWysiwyg);
    this.showWysiwyg = _showWysiwyg;
    this.emitStateUpdate();
  }
  updateShowNodeTypes(_val) {
    Storage.setShowNodeTypes(_val);
    this.showNodeTypes = _val;
    this.emitStateUpdate();
  }
}

const singleton = new SidebarService();
export default singleton;
