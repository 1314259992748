import Service from '../Service';
import UserApi from '../server/UserApi';
import User from '../../models/user';
import UserAccount, { LOGGED_IN, LOGGED_OUT } from '../UserAccount';

class UserCache extends Service {
  initialize() {
    /**
     * Object keys are the treeId
     * Object values are the treeJSON
     */
    this.usersJson = {};
    /**
     * Object keys are the page
     * Object values are ids of trees
     */
    this.searchUsers = {};
    /**
     * Object keys are the treeId
     * Object values are the treeModel
     */
    this.users = {};
  }
  /**
   * Method to get a tree
   */
  getUsersByIds(_ids) {
    if (![LOGGED_IN, LOGGED_OUT].includes(UserAccount.state)) return null;
    const userIdsToFetch = [];
    const users = [];
    _ids.forEach((_id) => {
      const user = this.users[_id];
      if (user) users.push(user);
      else userIdsToFetch.push(_id);
    });
    if (userIdsToFetch.length === 0) return users;
    /**
     * Get users
     */
    UserApi.getUsers(userIdsToFetch).then((results) => {
      if (!results) return;
      if (!results.forEach) return;

      userIdsToFetch.forEach((_id) => {
        const user = results.find((u) => u._id === _id);
        if (!user) {
          this.usersJson[_id] = { error: 'NotFound' };
          this.users[_id] = { error: 'NotFound' };
          return;
        }
        if (UserAccount.account._id === user._id) return;
        this.usersJson[_id] = user;
        this.users[_id] = new User(user);
      });
      this.emitStateUpdate();
    });
    return null;
  }
  /**
   * Search users by username and fullname
   */
  getUsers(_query) {
    if (![LOGGED_IN, LOGGED_OUT].includes(UserAccount.state)) return null;
    const _queryString = JSON.stringify(_query);
    if (this.searchUsers[_queryString]) {
      /**
       * Return the trees only if all have been found
       */
      const users = this.searchUsers[_queryString].map((_id) => this.users[_id]);
      if (users.every((_user) => _user)) return users;
    }
    /**
     * Get the account trees if not found
     */
    UserApi.searchUsers(_query).then((results) => {
      /**
       * Need to handle error cases
       */
      if (!results) return;
      if (!results.forEach) return;
      /**
       * Parse the account trees
       */
      this.searchUsers[_queryString] = [];
      results.forEach((_user) => {
        this.searchUsers[_queryString].push(_user._id);
        if (UserAccount.account._id === _user._id) return;
        this.usersJson[_user._id] = _user;
        this.users[_user._id] = new User(_user);
      });
      /**
       * Emit state update
       */
      this.emitStateUpdate();
    });
    return null;
  }
}

const singleton = new UserCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
    singleton.emitStateUpdate();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
    singleton.emitStateUpdate();
    singleton.users[UserAccount.account._id] = UserAccount.account;
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
