import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';
import WindowSize from '../../services/WindowSize';
import TreeDisplay from '../../services/TreeDisplay';
import Scrollable from '../../components/Scrollable';

export default class PaneBox extends React.PureComponent {
  static propTypes = {
    pane: PropTypes.object,
  }
  componentDidMount() {
    const { pane } = this.props;
    pane.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    window.addEventListener('resize', this.updateWidth);
  }
  componentWillUnmount() {
    const { pane } = this.props;
    pane.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    window.removeEventListener('resize', this.updateWidth);
  }
  createPaneRef = (e) => {
    TreeDisplay.addScrollableParent(e);
  }
  updateWidth = () => {
    this.forceUpdate();
  }
  render() {
    const mobile = window.innerWidth < 900;
    const { pane } = this.props;
    const { contentHeight, parent } = pane;
    const { vertical } = parent;
    const style = (mobile || (contentHeight && !vertical)) && {} || (contentHeight && vertical && { display: 'flex', flexDirection: 'column', maxHeight: '100%' }) || { position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', flexDirection: 'column' };
    return (
      <Scrollable
        scrollRef={this.createPaneRef}
        style={style}
        vertical
      >
        <CreateChildren node={pane} />
      </Scrollable>
    );
  }
}
