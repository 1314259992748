import TreeCache from '../../services/cache/TreeCache';
import SpreadsheetCache from '../../services/cache/SpreadsheetCache';
import GoogleSpreadsheetCache from '../../services/cache/GoogleSpreadsheetCache';
import UserAccount from '../../services/UserAccount';
import { AUTOFILL, ANSWER, CHECKLIST_ITEM, FILTER_LOGIC, FOR_EACH_ANSWER } from '../../models/nodetypes';
// import { ANSWER, AUTOFILL, CHECKLIST_ITEM, FILTER_LOGIC, FOR_EACH_ANSWER } from '../../models/nodetypes';

function SaveTreeAndSubtrees(tree) {
  // This is to prevent saving when logged out and viewing a project
  if (!UserAccount.isLoggedIn) return;
  // Update Spreadsheets
  const autofillNodes = getAutofillNodes(tree.root);
  autofillNodes.forEach(applyUpdates);
  const spreadsheets = {};
  autofillNodes.forEach(({ spreadsheet, isGoogleSpreadsheet }) => {
    spreadsheets[spreadsheet._id] = { spreadsheet, isGoogleSpreadsheet };
  });
  Object.values(spreadsheets).forEach(saveSpreadsheet);

  // Update Trees
  TreeCache.updateTree(tree);
  tree.subtrees.forEach((_subtree) => {
    if (_subtree.tree) SaveTreeAndSubtrees(_subtree.tree);
  });
}

function getAutofillNodes(_node) {
  let totalAutofillNodes = [];
  _node.children.forEach((_child) => {
    if (_child.isType(AUTOFILL) && _child.isWritable) totalAutofillNodes.push(_child);
    if (_child.isOneOfTypes([ANSWER, CHECKLIST_ITEM]) && !_child.checked) return;
    if ([FILTER_LOGIC, FOR_EACH_ANSWER].includes(_child.attributes[0])) return;
    totalAutofillNodes = [...totalAutofillNodes, ...getAutofillNodes(_child)];
  });
  return totalAutofillNodes;
}
function applyUpdates(_autofill) {
  _autofill.updateOrAddRows();
}
function saveSpreadsheet({ spreadsheet, isGoogleSpreadsheet }) {
  if (isGoogleSpreadsheet) GoogleSpreadsheetCache.update(spreadsheet);
  else SpreadsheetCache.update(spreadsheet);
}

export default SaveTreeAndSubtrees;
