import Service from './Service';
import Storage from './Storage';
import Company from './Company';

class Drawer extends Service {
  __children = null;
  __open = !Storage.state.drawerClosed;
  toggle() {
    this.__open = !this.__open;
    Storage.setDrawerClosed(!this.__open);
    this.emitStateUpdate();
  }
  get open() {
    const { isAuthorizedToLogIn } = Company;
    return this.__open && (isAuthorizedToLogIn);
  }
  get children() {
    return this.__children;
  }
  updateChildren(_children) {
    this.__children = _children;
    this.emitStateUpdate();
  }
}
const singleton = new Drawer();
export default singleton;
