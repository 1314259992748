/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import GrayOut from '../../services/TreeDisplay';
import TreeCreateNode from '../../services/TreeCreateNode';
import ClickableIcon from '../../components/ClickableIcon/index';

const Wrapper = styled.div`

`;

export default class AddNote extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
  }
  createNote = (e) => {
    const { node } = this.props;
    e.preventDefault();
    e.stopPropagation();
    GrayOut.addActiveObject(node);
    TreeCreateNode.createNote(node);
  }
  render() {
    const { primaryColor } = GrayOut.tree.root;
    return (
      <Wrapper style={{ color: primaryColor }}>
        <ClickableIcon onClick={this.createNote} icon="far fa-sticky-note" />
      </Wrapper>
    );
  }
}
