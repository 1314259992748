import Node from './node';

export default class Chart extends Node {
  clone() {
    return new Chart(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
  updateChartData(_data) {
    this.chartData = _data;
    this.emitStateUpdate();
  }
}
