import Node from './node';
import { NONE_ATTR, PRESELECT_ATTR } from './nodeattributes';

export default class Answerable extends Node {
  constructor(_text, _attributes, _children, _owner) {
    super(_text, _attributes, _children, _owner);
    this.__defineNodeAttribute('preselect', [{ name: 'No Preselect', value: NONE_ATTR, default: true }, { name: 'Preselect', value: PRESELECT_ATTR }]);
  }
  addAnswer(_text, _votes, _owner, _attributes, _project) {
    if (_owner && this.text !== _text) this.owner = _owner;
    this.text = _text;
    this.votes = this.votes || [];
    if (_votes) {
      _votes.forEach((_vote) => {
        if (_project) {
          if (!this.votes.find((vote) => vote._id === _vote._id && vote.project === _project)) this.votes.push({ ..._vote, project: _project }); 
        } else if (!this.votes.find((vote) => vote._id && _vote._id)) this.votes.push(_vote);
      });
    }
    this.attributes = JSON.parse(JSON.stringify(_attributes));
    this.checkedChanged(this);
    this.__updateDirectParentOnNodeUpdated();
    return this.emitStateUpdate();
  }
  hasUserVoted() {
    return this.checked;
  }
  addUserVote() {
    this.__addUserVote();
    this.checkedChanged(this);
    this.emitStateUpdate();
  }
  removeUserVote() {
    this.__removeUserVote();
    this.checkedChanged(this);
    this.emitStateUpdate();
  }
  toggleUserVote() {
    const { isDisabled } = this.parents[0];
    if (isDisabled) return;
    if (!this.hasUserVoted()) this.addUserVote();
    else this.removeUserVote();
  }
  updateUserVote() {
    if (this.text) this.addUserVote();
    else this.removeUserVote();
  }
  updateText(_text, _autofill) {
    const { editing, isDisabled } = this.parents[0];
    if (isDisabled) return this.emitStateUpdate();
    if (editing) {
      this.text = _text;
      this.__updateDirectParentOnNodeUpdated();
      this.__updateTreeOnNodeUpdated();
      return this.emitStateUpdate();
    }
    const oldChecked = this.checked;
    this.text = _text;
    this.owner = this.parents[0].userLoggedIn;
    this.updateUserVote();
    if (_autofill) this.__autofillChildren(this.children, _autofill);
    if (oldChecked !== this.checked) this.checkedChanged(this);
    this.__updateDirectParentOnNodeUpdated();
    this.__updateTreeOnNodeUpdated();
    return this.emitStateUpdate();
  }
  get checked() {
    return this.votes.length > 0 || this.preselectOption === PRESELECT_ATTR;
  }
  get isPreselected() {
    return this.attributes.includes(PRESELECT_ATTR);
  }
  __addUserVote() {
    if (this.votes && this.votes[0] && this.votes[0]._id === this.parents[0].userLoggedIn) return;
    this.votes = [{ _id: this.parents[0].userLoggedIn, ts: Date.now() }];
  }
  __removeUserVote() {
    this.attributes = this.attributes.filter((a) => a !== PRESELECT_ATTR);
    this.votes = [];
  }
}
