import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import GrayOut from '../../services/TreeDisplay';
import ClickableDiv from '../../components/ClickableDiv/index';

const Wrapper = styled.div`

`;

export default class GotoBox extends React.PureComponent {
  static propTypes = {
    goto: PropTypes.object,
  };
  componentDidMount() {
    const { goto } = this.props;
    goto.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { goto } = this.props;
    goto.offStateUpdate(this);
  }
  onClick = () => {
    const { goto } = this.props;
    const { text } = goto;
    const targetParent = goto.parents.reverse().find((_parent) => _parent.text === text);
    if (targetParent) GrayOut.goToActiveObject();
  }
  render() {
    const { goto } = this.props;
    const { text } = goto;
    return (
      <Wrapper>
        <ClickableDiv onClick={this.onClick}>{text}</ClickableDiv>
      </Wrapper>
    );
  }
}
