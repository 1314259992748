import Model from './model';

export default class LogicBaseNotification extends Model {
  constructor(_id, createdTime, owner, type, read, objectId) {
    super();
    this._id = _id;
    this.createdTime = createdTime;
    this.owner = owner;
    this.type = type;
    this.read = read;
    this.objectId = objectId;
  }
  getJson = () => {
    const { _id, owner, type, read, objectId } = this;
    return {
      _id,
      o: owner,
      t: type,
      r: read,
      oid: objectId,
    }
  }
}
