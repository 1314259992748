import Answerable from './answerable';

export default class Note extends Answerable {
  constructor(_text, _attributes, _children, _owner, _votes) {
    super(_text, _attributes, _children, _owner);
    this.votes = JSON.parse(JSON.stringify(_votes || []));
  }
  checkedChanged = () => {
    this.__updateAllParentsOnNodeUpdated();
  }
  clone() {
    return new Note(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner, this.votes);
  }
}
