import { ANSWER, CHECKLIST_ITEM, CHECKLIST, FILTER, QUESTION, CALCULATION, CONDITIONAL, FILE_GROUP, FILE } from './nodetypes';

export function __recursivelyGetAllQuestionableNodes(node) {
  let nodes = [];
  node.children.forEach((_child) => {
    if (_child.isOneOfTypes([ANSWER, CHECKLIST_ITEM])) {
      if (!_child.checked) return;
    }
    if (_child.isOneOfTypes([CHECKLIST, FILTER, QUESTION])) {
      const { answers } = _child;
      if (!answers) return;
      const answersChecked = answers.filter((a) => a.checked);
      if (answersChecked.length === 0) return;
      nodes.push(_child);
    }
    if (_child.isOneOfTypes([CALCULATION])) {
      if (!_child.calculatedValue) return;
      nodes.push(_child);
    }
    if (_child.isOneOfTypes([FILE_GROUP])) {
      const { children } = _child;
      if (!children) return;
      const answersChecked = children.filter((a) => a.checked);
      if (answersChecked.length === 0) return;
      nodes.push(_child);
    }
    if (_child.isOneOfTypes([CONDITIONAL])) {
      if (!_child.conditionalValue) return;
    }
    const childResults = __recursivelyGetAllQuestionableNodes(_child);
    nodes = [...nodes, ...childResults];
  });
  return nodes;
}
