import Model from './model';


export default class ApiKeyCalls extends Model {
  /**
   * Constructor
   */
  constructor(_id, createdTime, updateTime, calls) {
    super();
    this._id = _id;
    this.createdTime = createdTime;
    this.updateTime = updateTime;
    this.calls = calls || [];
  }
  getJson() {
    const { _id, calls } = this;
    return {
      _id,
      calls,
    };
  }
}
