/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
/* eslint-disable no-useless-escape */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';

const Wrapper = styled.div`
  margin: 0px 0px 0px ${Constants.TreeContentIconIndent};
  > div:first-child {
    > div:first-child {
      margin: ${Constants.defaultLinePadding} 0px;
    }
    display: flex;
    align-items: center;
  }
`;

export default class VideoBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  };
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
  }
  renderVideo = () => {
    const { node } = this.props;
    if (!this.mount) return;
    Array.from(this.mount.children).forEach((c) => this.mount.removeChild(c));
    const video = document.createElement('video');
    video.controls = true;
    video.src = node.text;
    const segments = [];
    let start = Infinity;
    let currentSegment;
    node.children.forEach((child) => {
      const isStart = child.attributes[1] === 'Start';
      const isEnd = child.attributes[1] === 'End';
      const value = parseInt(child.text, 10);
      if (isStart) start = Math.min(start, value);
      if (isEnd) currentSegment = { end: value };
      else if (isStart && currentSegment) currentSegment.start = value;
      if (currentSegment && currentSegment.start && currentSegment.end) {
        segments.push(currentSegment);
        currentSegment = null;
      }
    });
    if (start === Infinity) start = 0;
    video.currentTime = start;
    video.ontimeupdate = () => {
      segments.forEach((segment) => {
        checkSeek(segment.end, segment.start);
      });
      function checkSeek(min, seek) {
        if (video.currentTime > min && video.currentTime < seek) {
          video.currentTime = seek;
        }
      }
    };
    this.mount.appendChild(video);
  }

  render() {
    return (
      <Wrapper ref={(e) => { this.mount = e; this.renderVideo(); }} />
    );
  }
}
