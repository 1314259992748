import Service from './Service';
// import Constants from '../../submodules/logictry_config/constants';
import Navigation from './Navigation';
import Storage from './Storage';
import UserAccount from './UserAccount';
import Version from '../version.json';

let _notifications = [];
let userLoggedIn = false;

class Notifications extends Service {
  versionUpdated() {
    // if (Update.updateNeeded) {
    //   const findIndex = _notifications.findIndex((v) => v._id === 'NewVersionAvailable');
    //   if (findIndex < 0) {
    //     _notifications.unshift({
    //       _id: 'NewVersionAvailable',
    //       action: 'NewVersionAvailable',
    //       html: `
    //         <div style="color: red;"><b>New Version v${Update.updateNeeded} is available.</b></div>
    //         <br/>
    //         <div><b>Save your work before updating.</b></div>
    //       `,
    //       seeMoreText: 'Update',
    //       onClick: () => window.location.reload(true),
    //     });
    //     this.emitStateUpdate();
    //   }
    // }
  }
  accountUpdate() {
    if (UserAccount.isLoggedIn && !userLoggedIn) {
      userLoggedIn = true;
      // Notification if user has not set their fullname
      if (UserAccount.account.email && !UserAccount.account.fullname) {
        const findIndex = _notifications.findIndex((v) => v._id === 'SetFullname');
        if (findIndex < 0) {
          _notifications.push({
            _id: 'SetFullname',
            action: 'SetFullname',
            description: 'Your account is missing a fullname.  Click here to set your fullname.',
            onClick: () => Navigation.push(Navigation.accountSettings),
          });
        }
      }
      if (Storage.state.version === '0') {
        Storage.setVersion(Version.version);
      }
      // Account notifications
      // UserAccount.account.notifications.open.forEach((n) => {
      //   _notifications.push({
      //     _id: n._id,
      //     action: n.notification,
      //     questionSet: n.questionSet,
      //     description: this.getDescription(n.notification),
      //   });
      // });
      this.emitStateUpdate();
    } else if (!UserAccount.isLoggedIn) {
      userLoggedIn = false;
      this.emitStateUpdate();
    }
  }

  getDescription(_n) {
    if (_n === 'AnswerQuestion') return 'You have been asked a question!';
    return '';
  }

  get count() {
    return _notifications.length;
  }
  get current() {
    return _notifications;
  }
  get priority() {
    return _notifications.find((v) => v._id === 'NewVersionAvailable');
  }

  clear() {
    _notifications = [];
  }

  notificationClicked(n) {
    n.onClick();
    this.clearNotification(n);
  }
  clearNotification(n) {
    if (n.onClear) n.onClear();
    const findIndex = _notifications.findIndex((v) => v._id === n._id);
    _notifications.splice(findIndex, 1);
  }
}


const singleton = new Notifications();
UserAccount.onStateUpdate(() => singleton.accountUpdate());
export default singleton;
