/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Navigation from '../../services/Navigation';
import { DESCRIPTION, TITLE } from '../../models/nodetypes';

const Wrapper = styled.div`

`;
const InputsWrapper = styled.div`
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  > input {
    padding: 0px;
  }
  > input:nth-child(1) {
    width: 100%;
  }
  > input:nth-child(2) {
    width: 100%;
  }
`;

export default class MetaBox extends React.PureComponent {
  static propTypes = {
    editing: PropTypes.bool,
    spreadsheet: PropTypes.object,
  }
  componentDidMount() {
    const { spreadsheet } = this.props;
    const { title, description } = spreadsheet;
    title.onStateUpdate(this);
    description.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { spreadsheet } = this.props;
    const { title, description } = spreadsheet;
    title.offStateUpdate(this);
    description.offStateUpdate(this);
  }
  changeTitle = (value) => {
    const { spreadsheet } = this.props;
    spreadsheet.title.updateText(value);
  }
  changeDescription = (value) => {
    const { spreadsheet } = this.props;
    spreadsheet.description.updateText(value);
  }

  render() {
    const { spreadsheet } = this.props;
    const { editing } = this.props;
    const { title, description } = spreadsheet;
    if (!editing) {
      const titleParam = Navigation.getQueryParameter('title');
      const descriptionParam = Navigation.getQueryParameter('description');
      if (titleParam) title.text = titleParam;
      if (descriptionParam) description.text = descriptionParam;
    }
    return (
      <Wrapper>
        <InputsWrapper>
          <input tabIndex={0} ref={(e) => { this.inputRef = e; }} onChange={(e) => { this.changeTitle(e.target.value); }} onBlur={(e) => { this.changeTitle(e.target.value); }} placeholder={TITLE} defaultValue={(title && title.text) || ''} />
          <input tabIndex={0} ref={(e) => { this.inputRef = e; }} onChange={(e) => { this.changeDescription(e.target.value); }} onBlur={(e) => { this.changeDescription(e.target.value); }} placeholder={DESCRIPTION} defaultValue={(description && description.text) || ''} />
        </InputsWrapper>
      </Wrapper>
    );
  }
}

