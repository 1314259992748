/**
 *
 * Button.js
 *
 * A common button, if you pass it a prop "route" it'll render a link to a react-router route
 * otherwise it'll render a link with an onclick
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Constants from '../../submodules/logictry_config/constants';
import Button from '../Button/index';

const WrapperNoColor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Wrapper = styled(WrapperNoColor)`
  * {
    color: ${Constants.White};
  }
`;

class StatefulButton extends React.PureComponent {
  getContent = () => [
    <Button
      key="submit"
      filledColor={!this.props.noColor}
      onClick={this.props.onClick}
      // eslint-disable-next-line
      buttonRef={(ref) => { if (ref) ref.tabIndex = this.props.tabIndex || 0; }}
    >
      {this.props.state === 'busy' ? (
        <i className="fas fa-spinner fa-spin"></i>
      ) : (
        this.props.text
      )}
      {this.props.children}
    </Button>,
    (this.props.warning || this.props.success || this.props.status) && <div key="warning" style={{ marginTop: 12, textAlign: 'center', color: this.props.warning && 'red' || this.props.success && 'green' || 'black', fontSize: Constants.SmallFontSize }}>{this.props.warning || this.props.success || this.props.status}</div>,
  ];

  render() {
    if (this.props.state === 'complete') return (<div style={{ height: 54, width: 230, textAlign: 'center', lineHeight: '50px' }}>Success!</div>);

    if (this.props.noColor) {
      return (
        <WrapperNoColor>
          {this.getContent()}
        </WrapperNoColor>
      );
    }

    return (
      <Wrapper>
        {this.getContent()}
      </Wrapper>
    );
  }
}

StatefulButton.propTypes = {
  children: PropTypes.array,
  onClick: PropTypes.func,
  warning: PropTypes.string,
  success: PropTypes.string,
  status: PropTypes.string,
  state: PropTypes.string,
  text: PropTypes.string,
  noColor: PropTypes.bool,
  tabIndex: PropTypes.number,
};

export default StatefulButton;
