import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid gray;
`;


export default class HeaderSection extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
  }
  render() {
    const { children } = this.props;
    return (
      <Wrapper>
        {children}
      </Wrapper>
    );
  }
}
