/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';

export default class LogicBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
  }
  render() {
    const { node } = this.props;
    return (
      <CreateChildren node={node} />
    );
  }
}
