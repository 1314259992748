import Node from './node';
import { ATTRIBUTE_ATTR, NONE_ATTR } from './nodeattributes';

export default class DataSchemaKey extends Node {
  constructor(_text, _attributes, _children, _owner) {
    super(_text, _attributes, _children, _owner);
    this.__defineNodeAttribute('layout', [{ name: 'Child', value: NONE_ATTR, default: true }, { name: 'Attribute', value: ATTRIBUTE_ATTR }]);
  }
  clone() {
    return new DataSchemaKey(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
}
