/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import InfoPopup from './InfoPopup';
import GrayOut from '../../services/TreeDisplay';
import RawHtml from '../../components/RawHtml/index';
// eslint-disable-next-line import/no-cycle
import CreateChild from './CreateChild';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';
import AddNote from './AddNote';

const Wrapper = styled.div`
  margin: 0px 0px 0px ${Constants.TreeContentIconIndent};

  > div:first-child {
    > div:first-child {
      margin: ${Constants.defaultLinePadding} 0px;
    }
    .question-box-hide-till-hover {
      opacity: 0;
    }
    &:hover {
      .question-box-hide-till-hover {
        opacity: 1 !Important;
      }
    }
    display: flex;
    align-items: center;
    &:hover {
      opacity: 1 !Important;
    }
    > i {
      cursor: pointer;
    }
  }
`;

export default class ChecklistBox extends React.PureComponent {
  static propTypes = {
    checklist: PropTypes.object,
  }
  componentDidMount() {
    const { checklist } = this.props;
    checklist.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { checklist } = this.props;
    checklist.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
  }
  render() {
    const { checklist } = this.props;
    const { tree } = GrayOut;
    const { root, isDisabled } = tree;
    const { showInlineNotes } = root;
    const { info, items, isTextHidden, note } = checklist;
    if (isTextHidden) return items.map((_child) => <CreateChild key={_child.key} node={_child} />);
    return (
      <Wrapper>
        <div>
          <RawHtml html={checklist.formattedProjectText} />
          {info && <InfoPopup text={info.text} />}
          <div className="question-box-hide-till-hover">
            {showInlineNotes && !isDisabled && !note && <AddNote node={checklist} />}
          </div>
        </div>
        <CreateChildren node={checklist} />
      </Wrapper>
    );
  }
}
