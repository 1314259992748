/* eslint-disable no-param-reassign */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ScriptLoader from '../../services/ScriptLoader';

const Wrapper = styled.div`
  flex: 1;
  overflow: auto;
  width: 100%;
  height: 100%;
`;

const width = 10000;

export default class TreeGraphBox extends React.PureComponent {
  static propTypes = {
    tree: PropTypes.object,
  }
  componentDidMount() {
    ScriptLoader.load('https://logictrystatic.s3.amazonaws.com/js/d3.min.js');
    ScriptLoader.onStateUpdate(this.renderGraph);
  }
  componentWillUnmount() {
    ScriptLoader.offStateUpdate(this.renderGraph);
  }
  renderGraph = () => {
    if (!this.mount) return;
    if (!ScriptLoader.isLoaded('https://logictrystatic.s3.amazonaws.com/js/d3.min.js')) return;
    Array.from(this.mount.children).forEach((c) => this.mount.removeChild(c));
    const { tree } = this.props;
    const data = { name: 'Root', children: tree.getTreeTemplateJson().children.find((child) => child.attributes[0] === 'Root').children };
    this.renderCollapsibleTree(data);
  }

  renderCollapsibleTree = (data) => {
    const dx = 60
    const dy = 200
    const margin = ({top: 40, right: 120, bottom: 10, left: 60})
    const diagonal = window.d3.linkHorizontal().x(d => d.y).y(d => d.x)
    const treeParser = window.d3.tree().nodeSize([dx, dy])

    function recursivelyAddName(node) {
      if (!node.name) node.name = node.text || node.attributes[0];
      node.children.forEach(recursivelyAddName);
    }
    recursivelyAddName(data);

    const root = window.d3.hierarchy(data);

    root.x0 = dy / 2;
    root.y0 = 0;
    root.descendants().forEach((d, i) => {
      d.id = i;
      d._children = d.children;
      if (d.depth > 0) d.children = null;
    });

    const svg = window.d3.create("svg")
      .attr("viewBox", [-margin.left, -margin.top, width, dx])
      .style("font", "10px sans-serif")
      .style("user-select", "none");

    const gLink = svg.append("g")
      .attr("fill", "none")
      .attr("stroke", "#555")
      .attr("stroke-opacity", 0.4)
      .attr("stroke-width", 1.5);

    const gNode = svg.append("g")
      .attr("cursor", "pointer")
      .attr("pointer-events", "all");

    function update(source) {
      const duration = window.d3.event && window.d3.event.altKey ? 2500 : 250;
      const nodes = root.descendants().reverse();
      const links = root.links();

      // Compute the new tree layout.
      treeParser(root);

      let left = root;
      let right = root;
      root.eachBefore(node => {
        if (node.x < left.x) left = node;
        if (node.x > right.x) right = node;
      });

      svg.node().style.width = `${width}px`;
      const transition = svg.transition()
        .duration(duration)
        .attr("viewBox", [-margin.left, left.x - margin.top, width, width])
        .tween("resize", window.ResizeObserver ? null : () => () => svg.dispatch("toggle"));

      // Update the nodes…
      const node = gNode.selectAll("g")
        .data(nodes, d => d.id);

      // Enter any new nodes at the parent's previous position.
      const nodeEnter = node.enter().append("g")
        .attr("transform", () => `translate(${source.y0},${source.x0})`)
        .attr("fill-opacity", 0)
        .attr("stroke-opacity", 0)
        .on("click", (event, d) => {
          d.children = d.children ? null : d._children;
          update(d);
        });

      nodeEnter.append("circle")
        .attr("r", 2.5)
        .attr("fill", d => d._children ? "#555" : "#999")
        .attr("stroke-width", 10);

      nodeEnter.append("text")
        .attr("dy", "-1em")
        .attr("x", d => d._children ? -6 : 6)
        .attr("text-anchor", d => d._children ? "middle" : "middle")
        .text(d => d.data.name.length > 40 ? `${d.data.name.slice(0,40)}...` : d.data.name)
        .clone(true).lower()
        .attr("stroke-linejoin", "round")
        .attr("stroke-width", 3)
        .attr("stroke", "white");

      // Transition nodes to their new position.
      node.merge(nodeEnter).transition(transition)
        .attr("transform", d => `translate(${d.y},${d.x})`)
        .attr("fill-opacity", 1)
        .attr("stroke-opacity", 1);

      // Transition exiting nodes to the parent's new position.
      node.exit().transition(transition).remove()
        .attr("transform", () => `translate(${source.y},${source.x})`)
        .attr("fill-opacity", 0)
        .attr("stroke-opacity", 0);

      // Update the links…
      const link = gLink.selectAll("path")
        .data(links, d => d.target.id);

      // Enter any new links at the parent's previous position.
      const linkEnter = link.enter().append("path")
        .attr("d", () => {
          const o = {x: source.x0, y: source.y0};
          return diagonal({source: o, target: o});
        });

      // Transition links to their new position.
      link.merge(linkEnter).transition(transition)
        .attr("d", diagonal);

      // Transition exiting nodes to the parent's new position.
      link.exit().transition(transition).remove()
        .attr("d", () => {
          const o = {x: source.x, y: source.y};
          return diagonal({source: o, target: o});
        });

      // Stash the old positions for transition.
      root.eachBefore(d => {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    }
    update(root);
    this.mount.appendChild(svg.node());
  }
  render() {
    return (
      <Wrapper ref={(e) => { this.mount = e; this.renderGraph(); }} />
    );
  }
}
