import Service from '../Service';
import { reportError } from '../Errors';
import UserAccount, { LOGGED_IN, LOGGED_OUT } from '../UserAccount';

export default class CacheService extends Service {
  initialize() {
    /**
     * Object keys are the id
     * Object values are the json
     */
    this.objectJson = {};
    /**
     * Object keys are the page
     * Object values are ids of objects
     */
    this.accountObjects = {};
    /**
     * Object keys are the id
     * Object values are the objects
     */
    this.objects = {};
    /**
     * Updates Active
     */
    this.updatesActive = new Set();
    /**
     * Updates Queued
     */
    this.updatesQueued = {};
  }
  checkNewerObject = (_object) => {
    if (!this.objectJson[_object._id]) return true;
    if (!this.objects[_object._id]) return true;
    if (!this.objectJson[_object._id].version) return true;
    return _object.version > this.objectJson[_object._id].version;
  }
  createFromJson(json) {
    this.objectJson[json._id] = json;
    this.objects[json._id] = this.createObject(json);
    return this.objects[json._id];
  }
  // checkVersion(id) {
  //   this.api.get(id, null, { version: true }).then(async (results) => {
  //     console.log('version', id, results);
  //   });
  // }
  get(id, _checkout, _checkin) {
    // Dont run if in transition
    if (![LOGGED_IN, LOGGED_OUT].includes(UserAccount.state)) return null;
    if (!id) return null;
    if (id && this.isIdInvalid && this.isIdInvalid(id)) return { error: 'IdInvalid' };
    /**
     * Get Object
     */
    if (!_checkout && !_checkin) {
      if (this.objects[id]) {
        return this.objects[id];
      }
    }
    const query = {};
    if (_checkout) query.checkout = true;
    if (_checkin) query.checkin = true;
    this.api.get(id, query).then(async (results) => {
      if (!results) return;
      if (results.error) {
        if ((results.error === 'CheckoutFailed' || results.error === 'CheckinFailed') && this.objects[id] && this.objects[id]._id) {
          this.revertObject(this.objects[id]);
          if (this.onUpdateFail) this.onUpdateFail(results);
        } else {
          this.objects[id] = results;
        }
      } else if ((_checkin || _checkout) && results.success) {
        this.objectJson[id].editor = results.editor;
        this.objects[id].editor = results.editor;
      } else {
        if (id) this.objectJson[id] = results;
        if (results._id) {
          if (!id) id = results._id;
          const newObject = this.createObject(results);
          if (id) this.objects[id] = newObject;
          // Check the Diff Paging
          if (this.__checkDiffPaging) this.__checkDiffPaging(newObject);
        }
      }
      this.emitStateUpdate();
    });
    return null;
  }
  /**
   * Get multiple ids
   */
  getByIds(_ids) {
    const idsToFetch = [];
    const objects = [];
    _ids.forEach((_id) => {
      const object = this.objects[_id];
      if (object) objects.push(object);
      else idsToFetch.push(_id);
    });
    if (idsToFetch.length === 0) return objects;
    /**
     * Get users
     */
    this.api.query({ _ids: idsToFetch }).then((results) => {
      if (!results) return;
      if (!results.forEach) return;

      idsToFetch.forEach((_id) => {
        const result = results.find((result) => result._id === _id);
        this.objectJson[_id] = result || { error: 'NotFound' };
        this.objects[_id] = result && this.createObject(result) || { error: 'NotFound' };
      });
      this.emitStateUpdate();
    });
    return null;
  }
  // /**
  //  * Get all the objects associated with account
  //  */
  clearAccountObjects() {
    this.accountObjects = {};
    this.emitStateUpdate();
  }
  __deleteQueryFromCache(_query) {
    const _queryString = JSON.stringify({ ..._query });
    delete this.accountObjects[_queryString];
  }
  query(_query, _params, _exactMatch) {
    // Dont run if in transition
    if (![LOGGED_IN, LOGGED_OUT].includes(UserAccount.state)) return null;
    if (_exactMatch) {
      let existing = Object.values(this.objectJson).find((obj) => Object.keys(_query).every((key) => obj[key] === _query[key]));
      if (existing && this.objects[existing._id]) return [this.objects[existing._id]];
    }
    const _queryString = JSON.stringify({ ..._query, ...(_params || {}) });
    if (this.accountObjects[_queryString]) {
      /**
       * Return the objects only if all have been found
       */
      const objects = this.accountObjects[_queryString].map((_id) => this.objects[_id]);
      if (objects.every((_object) => _object)) return objects;
    }
    /**
     * Get the account objects if not found
     */
    this.api.query(_query, _params).then((results) => {
      /**
       * Need to handle error cases
       */
      if (!results) return;
      if (!results.forEach) return;
      /**
       * Parse the account objects
       */
      this.accountObjects[_queryString] = [];
      results.forEach((_object) => {
        this.accountObjects[_queryString].push(_object._id);
        if (this.checkNewerObject(_object)) {
          this.objectJson[_object._id] = _object;        
          this.objects[_object._id] = this.createObject(_object);
        }
      });
      /**
       * Emit state update
       */
      this.emitStateUpdate();
    });
    return null;
  }
  /**
   * Create object
   */
  create(_objectModel, _dontClearAccountObjects) {
    const _object = this.getObjectJson(_objectModel);
    if (this.onCreate) this.onCreate(_objectModel);
    this.api.create(_object).then((results) => {
      if (!results || !results._id) {
        if (this.onCreateFail) this.onCreateFail(results);
        return this.emitStateUpdate();
      }
      if (!_dontClearAccountObjects) this.accountObjects = {};
      this.objectJson[results._id] = results;
      this.objects[results._id] = this.createObject(results);
      if (this.onCreateSuccess) this.onCreateSuccess(results);
      return this.emitStateUpdate();
    });
  }
  /**
   * Save spreadsheet
   */
  async update(_objectModel, newState) {
    // Check for active update
    if (this.updatesActive.has(_objectModel._id)) {
      // eslint-disable-next-line prefer-rest-params
      this.updatesQueued[_objectModel._id] = [_objectModel, newState];
      return false;
    }
    this.updatesActive.add(_objectModel._id);

    // Check for new state
    const updatesToSend = this.getObjectJson(_objectModel);
    if (!updatesToSend) {
      this.updatesActive.delete(_objectModel._id);
      return false;
    }
    if (newState) {
      const userLoggedIn = UserAccount.account._id;
      // eslint-disable-next-line no-param-reassign
      if (updatesToSend.owner === userLoggedIn) {
        // eslint-disable-next-line no-param-reassign
        updatesToSend.state = newState;
        // eslint-disable-next-line no-param-reassign
        _objectModel.state = newState;
      }
    }

    // Filter out all the keys that have not changed
    Object.keys(updatesToSend).forEach((_key) => {
      if (['_id', 'version'].includes(_key)) return;
      if (JSON.stringify(updatesToSend[_key]) !== JSON.stringify(this.objectJson[updatesToSend._id][_key])) return;
      delete updatesToSend[_key];
    });

    // Handle cache specific updates
    let updatesToSyncLocally;
    if (this.updateObject) {
      // eslint-disable-next-line no-param-reassign
      updatesToSyncLocally = await this.updateObject(updatesToSend, _objectModel);
    }
    if (!updatesToSend) {
      this.updatesActive.delete(_objectModel._id);
      return false;
    }

    // Dont update if no changes
    if (Object.keys(updatesToSend).every((k) => ['_id', 'version'].includes(k))) {
      this.updatesActive.delete(_objectModel._id);
      return false;
    }

    // Send the server update
    if (this.onUpdate) this.onUpdate(_objectModel);
    this.api.update(updatesToSend).then((results) => {
      // Remove active and check queued
      this.updatesActive.delete(_objectModel._id);

      // Check for a queuedUpdate
      const queuedUpdate = this.updatesQueued[_objectModel._id];
      if (queuedUpdate) {        
        setTimeout(() => this.update(...queuedUpdate));
        delete this.updatesQueued[_objectModel._id];
      }

      // Handle failed update
      if (!results || !results.success || results.error) {
        if (results && results.error === 'IncorrectVersion') {
          this.objectJson[_objectModel._id] = results.latest;
        }
        this.revertObject(_objectModel);
        if (this.onUpdateFail) this.onUpdateFail(results);
        return this.emitStateUpdate();
      }

      // Update if changed
      // First check if updatesToSyncLocally were provided since updatesSent may be different
      const _object = updatesToSyncLocally || updatesToSend;
      Object.keys(_object).forEach((_key) => {
        this.objectJson[_object._id][_key] = JSON.parse(JSON.stringify(_object[_key]));
      });

      // Check the Diff Paging
      if (this.__checkDiffPaging) this.__checkDiffPaging(this.objects[_object._id]);

      // Update state
      if (newState) this.accountObjects = {};
      if (this.onUpdateSuccess) this.onUpdateSuccess(_objectModel);
      return this.emitStateUpdate();
    });
    return true;
  }
  /**
   * Delete spreadsheet
   */
  delete(_object, _permanently, _dontClearAccountObjects) {
    if (this.onDelete) this.onDelete(_object);
    this.api.delete({ _id: _object._id }, _permanently, _object.getJson()).then((results) => {
      delete this.objects[_object._id];
      delete this.objectJson[_object._id];
      if (!results || !results.success) {
        if (this.onDeleteFail) this.onDeleteFail(results);
        return this.emitStateUpdate();
      }
      if (!_dontClearAccountObjects) this.accountObjects = {};
      if (this.onDeleteSuccess) this.onDeleteSuccess(_object);
      return this.emitStateUpdate();
    });
  }
}
