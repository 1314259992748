import Node from './node';
import { NODE_ATTR, AVERAGE_ATTR, ADD_ATTR, SUBTRACT_ATTR, MULTIPLY_ATTR, DIVIDE_ATTR, CONSTANT_ATTR, MAX_ATTR, MIN_ATTR, ROUND_ATTR } from './nodeattributes';


export default class CalculationOperator extends Node {
  constructor(_text, _attributes, _children, _owner) {
    super(_text, _attributes, _children, _owner);
    this.__defineNodeAttribute('type', [{ name: NODE_ATTR, value: NODE_ATTR, default: true }, { name: AVERAGE_ATTR, value: AVERAGE_ATTR }, { name: ADD_ATTR, value: ADD_ATTR }, { name: SUBTRACT_ATTR, value: SUBTRACT_ATTR }, { name: MULTIPLY_ATTR, value: MULTIPLY_ATTR }, { name: DIVIDE_ATTR, value: DIVIDE_ATTR }, { name: ROUND_ATTR, value: ROUND_ATTR }, { name: MAX_ATTR, value: MAX_ATTR }, { name: MIN_ATTR, value: MIN_ATTR }, { name: CONSTANT_ATTR, value: CONSTANT_ATTR }]);
  }
  clone() {
    return new CalculationOperator(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
}
