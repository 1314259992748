// eslint-disable-next-line import/no-cycle
import NodeBox from './NodeBox';
import GoogleDriveCache from '../../services/cache/GoogleDriveCache';


export default class AutoCreateLogicBox extends NodeBox {
  componentDidMount() {
    GoogleDriveCache.onStateUpdate(this);
    super.componentDidMount();
  }
  componentWillUnmount() {
    GoogleDriveCache.offStateUpdate(this);
    super.componentWillUnmount();
  }
}
