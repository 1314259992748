import LinkCache from '../../services/cache/LinkCache';
import CreatePopup from './CreatePopup';



export default class CreateLinkPopup extends CreatePopup {
  createTitle = "Create Link"
  saveAndClose = () => {
    const { title, description, link } = this.state;
    if (!(link)) return this.close();
    const database = LinkCache.createNew(title, description, link);
    // if (this.props.team) database.addTeam(this.props.team);
    LinkCache.create(database);
    return this.close();
  }
  showLink = true;
}
