/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import UserCache from '../../services/cache/UserCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import UserProfileHeader from '../../components/UserProfileHeader';
import LogicBaseAppList from '../../components/LogicBaseAppList/index';
import { getUserAppSaves, getUserPublicApps, getUserPublicSubmissions, getAccountCollections } from '../../services/Pagination';
import LoadingIndicator from '../../components/LoadingIndicator';
import LogicBaseAppCache from '../../services/cache/LogicBaseAppCache';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import LogicBaseRelationshipCache from '../../services/cache/LogicBaseRelationshipCache';
import Scrollable from '../../components/Scrollable';
import WindowSize from '../../services/WindowSize';
import LogicBaseCollectionRow from '../../components/LogicBaseCollectionRow';

const Wrapper = styled.div`
  margin: auto;
  padding: 0 1rem;
  > div:last-child {
    margin: 2rem auto;
    inset: 0;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding-bottom: 4rem;
    h2 {
      font-size: ${Constants.LargeFontSize};
      ${Constants.MediaMobile} {
        font-size: ${Constants.SemiLargeFontSize};
      }
      font-weight: 500;
    }
    > div {
      height: 270px;
      ${Constants.MediaMobile} {
        height: 250px;
      }
    }
  }
`;
export default class LogicBaseUserPage extends React.PureComponent {
  state = {
    username: ((Navigation.currentLocation[1] === 'users' ? Navigation.currentLocation[2] : Navigation.currentLocation[1].slice(1)) || '').toLowerCase(),
    isAccount: UserAccount.isLoggedIn && Navigation.currentLocation[1] === 'account',
  }
  componentDidMount() {
    UserCache.onStateUpdate(this);
    LogicBaseAppCache.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    LogicBaseRelationshipCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserCache.offStateUpdate(this);
    if (this.userPublicApps) this.userPublicApps.offStateUpdate(this);
    if (this.userPublicSubmissions) this.userPublicSubmissions.offStateUpdate(this);
    if (this.userAppSaves) this.userAppSaves.offStateUpdate(this);
    LogicBaseAppCache.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    LogicBaseRelationshipCache.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    if (this.collections) this.collections.offStateUpdate(this);
  }
  onPaginationVertical = () => {
    if (this.collections && this.collections.showMore && this.collections.allFound && this.collections.initialized) {
      this.collections.getMore();
    }
  }
  onPaginationSubmissions = () => {
    if (this.userPublicSubmissions && this.userPublicSubmissions.showMore && this.userPublicSubmissions.allFound && this.userPublicSubmissions.initialized) {
      this.userPublicSubmissions.getMore();
    }
  }
  onPaginationPosts = () => {
    if (this.userPublicApps && this.userPublicApps.showMore && this.userPublicApps.allFound && this.userPublicApps.initialized) {
      this.userPublicApps.getMore();
    }
  }
  onPaginationPins = () => {
    if (this.userAppSaves && this.userAppSaves.showMore && this.userAppSaves.allFound && this.userAppSaves.initialized) {
      this.userAppSaves.getMore();
    }
  }
  render() {
    const { mobile } = WindowSize;
    const { username, isAccount } = this.state;
    if (isAccount) {
      this.user = UserAccount.account;
    } else if (username) {
      const users = UserCache.getUsers({ username });
      this.user = users && (users.length === 0 ? { error: 'NotFound' } : users[0]);
    }
    if (!this.user) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    if (!this.collections) {
      this.collections = getAccountCollections(this.user._id);
      this.collections.onStateUpdate(this);
    }
    if (this.user && !this.user.error && !this.userPublicApps) {
      this.userPublicApps = getUserPublicApps(this.user._id);
      this.userPublicApps.onStateUpdate(this);
      this.userPublicSubmissions = getUserPublicSubmissions(this.user._id);
      this.userPublicSubmissions.onStateUpdate(this);
      this.userAppSaves = getUserAppSaves(this.user._id);
      this.userAppSaves.onStateUpdate(this);
    }
    if (this.user && this.user.error) return <FullScreenVerticalCenterContent><h1>User Not Found</h1></FullScreenVerticalCenterContent>;
    if (!this.userPublicApps || !this.user) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const { isLoggedIn, account } = UserAccount;
    const publicUser = LogicBaseUserCache.get(this.user._id);
    const relationship = (isLoggedIn && LogicBaseRelationshipCache.query({ fr: UserAccount.account._id, fe: this.user._id }) || [])[0];
    if (!publicUser) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const answersArePrivate = !publicUser.socialSettings || !publicUser.socialSettings.a || publicUser.socialSettings.a === 'private';
    const answersAreSubscriberOnly = publicUser.socialSettings && publicUser.socialSettings.a === 'followers';
    const answersArePublic = !answersArePrivate && !answersAreSubscriberOnly;
    const isUserAccount = isLoggedIn && account._id === this.user._id;
    const showAnswers = isUserAccount || (answersAreSubscriberOnly && (relationship && !relationship.requested)) || answersArePublic;
    let userSaves = null;
    if (this.userAppSaves.currentPage && this.userAppSaves.allFound) {
      userSaves = LogicBaseAppCache.getByIds(this.userAppSaves.currentPage.map(({ app }) => app));
    }
    return (
      <Scrollable
        vertical={!mobile}
        paginationHeight={400}
        onPagination={this.onPaginationVertical}
        style={{ position: mobile ? 'relative' : 'absolute', inset: 0, padding: mobile ? '1rem 0 2rem' : '0 1rem 2rem' }}
        rememberScrollPosition={`logicbaseuser_${this.user._id}`}
      >
        <Wrapper>
          <UserProfileHeader
            user={this.user}
          />
          <div>
            {showAnswers && <div>
              <h2>All Submissions</h2>
              {this.userPublicSubmissions.currentPage && this.userPublicSubmissions.showMore && <Scrollable
                horizontal
                paginationHeight={400}
                onPagination={this.onPaginationSubmissions}
                rememberScrollPosition={`logicbaseuser_submissions`}
              >
                <LogicBaseAppList apps={this.userPublicSubmissions.currentPage} showUser horizontal medium />
              </Scrollable>}
            </div>}
            <div>
              <h2>All Posts</h2>
              {this.userPublicApps.currentPage && this.userPublicApps.allFound && <Scrollable
                horizontal
                paginationHeight={400}
                onPagination={this.onPaginationPosts}
                rememberScrollPosition={`logicbaseuser_posts`}
              >
                <LogicBaseAppList apps={this.userPublicApps.currentPage} showUser horizontal medium />
              </Scrollable>}
            </div>
            <div>
              <h2>All Pins</h2>
              {userSaves && <Scrollable
                horizontal
                paginationHeight={400}
                onPagination={this.onPaginationPins}
                rememberScrollPosition={`logicbaseuser_pins`}
              >
                <LogicBaseAppList apps={userSaves} showUser horizontal medium />
              </Scrollable>}
            </div>
            {this.collections.currentPage.map((collection) => <LogicBaseCollectionRow key={collection._id} collection={collection} />)}
          </div>
        </Wrapper>
      </Scrollable>
    );
  }
}
