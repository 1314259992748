import Service from '../Service';
import Server from '../server/index';
import { reportError } from '../Errors';

export default class DiffCache extends Service {
  /**
   * Diffs
   */
  diffs = {};
  /**
   * Constructor
   */
  constructor(_url, _bucket) {
    super();
    this.url = _url;
    this.bucket = _bucket;
  }
  async checkDiffPaging(_id, _version, _diffs, _dontUpdate) {
    // Adding a check for intermittent bug
    if (!_diffs || !_diffs.sort) {
      const object = this.__treeCacheReferenceForDebugging && JSON.stringify(this.__treeCacheReferenceForDebugging.treesJson[_id] || {}) || this.__spreadsheetCacheReferenceForDebugging && JSON.stringify(this.__spreadsheetCacheReferenceForDebugging.objectJson[_id] || {});
      return reportError(`NO DIFFS for ${_id} and ${_version}. ${object}.`);
    }
    const { keep, page } = calculatePaging(_diffs);
    if (page.length > 0) {
      const { version, uploadUrl } = await this.getDiffUploadUrl(_id, _version - keep.length);
      await this.uploadDiff(uploadUrl, page);
      const diffs = await this.__getDiffs(_id, version);
      if (diffs && JSON.stringify(diffs) === JSON.stringify(page)) {
        if (!_dontUpdate) {
          this.onUpdateDiffs(_id, keep);
          this.emitStateUpdate();
        }
        return { keep, page };
      }
      return null;
    }
    return { keep, page };
  }
  async getDiffsSync(_id, _version) {
    const diffKey = `${_id}_${_version}`;
    if (this.diffs[diffKey]) return this.diffs[diffKey];
    return this.__getDiffs(_id, _version);
  }
  getDiffs(_id, _version) {
    const diffKey = `${_id}_${_version}`;
    if (this.diffs[diffKey]) return this.diffs[diffKey];
    this.__getDiffs(_id, _version);
    return [];
  }
  async __getDiffs(_id, _version) {
    const diffKey = `${_id}_${_version}`;
    const response = await Server.__request({
      uri: `https://${this.bucket}.s3.amazonaws.com/${_id}/${_version}`,
      method: 'GET',
      json: true,
    });
    if (!response) return null;
    const diffs = response.body;
    this.diffs[diffKey] = diffs || [];
    this.emitStateUpdate();
    return this.diffs[diffKey];
  }
  async getDiffUploadUrl(_id, _diffVersion) {
    const results = await Server.__getRequest(`${this.url}?X-Amz-Expires=0&path=${_id}/&objectName=${_diffVersion}&contentType=application%2Fjson`);
    if (results && results.signedUrl) return { version: _diffVersion, uploadUrl: results.signedUrl };
    return null;
  }
  async uploadDiff(_uploadUrl, _diff) {
    return Server.__request({
      uri: _uploadUrl,
      method: 'PUT',
      json: true,
      body: _diff,
    });
  }
}
function calculatePaging(diffs) {
  diffs.sort((a, b) => {
    if (a.version < b.version) return 1;
    if (b.version < a.version) return -1;
    return 0;
  });

  const totalDiffs = diffs.length;
  let totalSize = 0;
  diffs.forEach((diff) => { totalSize += EstimateObjectSize(diff) });

  let diffsToKeep = [];
  let diffsToPage = [];
  if (totalSize > 20000) {
    // First page based on size
    let sizeAccumulated = 0;
    diffs.forEach((diff) => {
      sizeAccumulated += EstimateObjectSize(diff);
      if (sizeAccumulated < 10000) diffsToKeep.push(diff);
      else diffsToPage.push(diff);
    });
  } else if (totalDiffs > 50) {
    // Then based on count
    diffsToKeep = diffs.slice(0, 25);
    diffsToPage = diffs.slice(25);
  } else {
    diffsToKeep = diffs;
  }

  return { keep: diffsToKeep, page: diffsToPage };
}
/* eslint-disable */
function EstimateObjectSize(object) {
  const objectList = [];
  const stack = [object];
  let bytes = 0;
  while (stack.length) {
    const value = stack.pop();
    if (typeof value === 'boolean') {
      bytes += 4;
    }
    else if (typeof value === 'string') {
      bytes += value.length * 2;
    }
    else if (typeof value === 'number') {
      bytes += 8;
    }
    else if
    (
      typeof value === 'object'
      && objectList.indexOf(value) === -1
    )
    {
      objectList.push(value);

      for (let i in value) {
        stack.push( value[ i ] );
      }
    }
  }
  return bytes;
}
