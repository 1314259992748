/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import styled from 'styled-components';
import WindowSize from '../../services/WindowSize';
import Navigation from '../../services/Navigation';
import Storage from '../../services/Storage';
import DrawerService from '../../services/Drawer';
import Company from '../../services/Company';
import AssetCache from '../../services/cache/AssetCache';
import SpreadsheetCache from '../../services/cache/SpreadsheetCache';
import TreeCache from '../../services/cache/TreeCache';
import TeamCache from '../../services/cache/TeamCache';
import UserAccount from '../../services/UserAccount';
import Constants from '../../submodules/logictry_config/constants';
import Link from '../../components/Link/index';
import UsersBox from '../../components/UsersBox';
import CompanyModel from '../../models/company';
import Team from '../../models/team';
import CreateTeamPopup from '../../containers/TeamPage/CreateTeamPopup';
import ConfirmDialog from '../../components/ConfirmDialog/index';
import Popup from '../../components/Popup/index';
import MenuList from '../../components/Popup/MenuList';
import MenuItem from '../../components/Popup/MenuItem';
import ClickableIcon from '../../components/ClickableIcon/index';
import FixedFullscreenDiv from '../../components/FixedFullscreenDiv';
import CancelAllTouchs from '../../utils/cancelalltouches';
import Tooltip from '../../components/Tooltip';
import CompanyThemePopup from '../../components/CompanyThemePopup/index';
import CompanyWebsitePopup from '../../components/CompanyWebsitePopup/index';
import EditHomePopup from '../../components/EditHomePopup/index';
import EditCompanyHomePopup from '../../components/EditHomePopup/EditCompanyHomePopup';
import Scrollable from '../../components/Scrollable/index';
import Version from '../../version.json';

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.4);
  white-space: nowrap;
  transition: all 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  background-color: ${Constants.White};
  width: 240px;
  a {
    text-decoration: none;
  }
`;
const Content = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  div {
    word-wrap: break-word;
  }
  > i {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
    cursor: pointer;
  }
  > div:nth-child(1) {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
  }
`
const ClickToClose = styled(FixedFullscreenDiv)`
  z-index: 3;
`;
const Links = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  > * {
    margin-left: 1rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: ${Constants.SmallFontSize};
  }
`;
const Section = styled.div`
  padding: 0 6px;
  display: flex;
  > div:first-child {
    flex: 1;
    flex-direction: column;
    width: 100%;
    i {
      font-size: ${Constants.SmallFontSize};
      height: 2rem;
      width: 28px;
      line-height: 2rem;
      text-align: center;
      margin-right: 0.25rem;
    }
    b {
      text-overflow: ellipsis;
      overflow: hidden;
      flex: 1;
    }
    > div {
      font-size: ${Constants.SemiSmallFontSize};
      display: flex;
      align-items: center;
      width: 100%;
      > div {
        font-size: ${Constants.SmallFontSize};
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
      }
    }
    > div:first-child {
      padding: 0 0.5rem;
      height: 2rem;
    }
    > div:nth-child(n+2) {
      padding: 0.25rem 1.5rem;
      * {
        font-size: ${Constants.SmallFontSize};
        height: 1.5rem;
        line-height: 1.5rem;
      }
    }
  }
`;
const HideLastTillHover = styled.div`
  ${Constants.MediaTabletAndDesktop} {
    > *:last-child {
      display: none;
    }
    &:hover {
      > *:last-child {
        display: block;
      }
    }
  }
`;
const Header = styled.div`
  margin-right: 1rem;
  * {
    font-size: ${Constants.SemiSmallFontSize};
  }
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 1rem 0;
  background-color: #dadce0;
  padding: 0px;
  flex-shrink: 0;
`;
const MenuWrapper = styled.div`
  i, img {
    margin-right: 20px;
    justify-content: center;
    display: flex;
    width: 14px;
  }
`;
// const Logo = styled.div`
//   height: 5rem;
//   display: flex;
//   align-items: center;
// `;
// const Logictry = styled.div`
//   ${Constants.MediaMobile} {
//     font-size: ${Constants.SmallFontSize};
//   }
// `;

export default class Drawer extends React.PureComponent {
  state = {
    showTemplates: false,
    showUsersbox: null,
    ...Storage.state.drawerState,
  }
  componentDidMount() {
    UserAccount.onStateUpdate(this);
    DrawerService.onStateUpdate(this);
    AssetCache.onStateUpdate(this);
    SpreadsheetCache.onStateUpdate(this);
    TreeCache.onStateUpdate(this);
    Company.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserAccount.offStateUpdate(this);
    DrawerService.offStateUpdate(this);
    AssetCache.offStateUpdate(this);
    SpreadsheetCache.offStateUpdate(this);
    TreeCache.offStateUpdate(this);
    Company.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  delete = () => {
    TeamCache.delete(this.currentTeam);
    this.setState({ confirmDelete: false });
  }
  teamPaginations = {};
  changeHome = (e, _team) => {
    e.preventDefault();
    e.stopPropagation();
    this.currentTeam = _team;
    this.anchorEl = e.target;
    const { menuOpen } = this.state;
    this.setState({ menuOpen: !menuOpen });
  }
  handleToggle = (e, _team) => {
    e.preventDefault();
    e.stopPropagation();
    this.currentTeam = _team;
    this.anchorEl = e.target;
    const { menuOpen } = this.state;
    this.setState({ menuOpen: !menuOpen });
  }
  handleClose = () => {
    this.setState({ menuOpen: false });
  };
  clearUsersBox = () => this.setState({ showUsersbox: null });
  hideCreateTeam = () => this.setState({ showCreateTeam: false });
  hideEditHome = () => this.setState({ showEditHome: false });
  hideEditTheme = () => this.setState({ showEditTheme: false });
  hideEditWebsite = () => this.setState({ showEditWebsite: false });
  cancelDelete = () => this.setState({ confirmDelete: false });
  showEditHome = () => this.setState({ menuOpen: false, showEditHome: true });
  showCreateTeam = () => this.setState({ menuOpen: false, showCreateTeam: this.currentTeam });
  showEditTheme = () => this.setState({ menuOpen: false, showEditTheme: this.currentTeam });
  showEditWebsite = () => this.setState({ menuOpen: false, showEditWebsite: this.currentTeam });
  showUsersBox = () => this.setState({ menuOpen: false, showUsersbox: this.currentTeam });
  confirmDelete = () => this.setState({ menuOpen: false, confirmDelete: true });
  getCompanySection = () => {
    const { isExpected, subscriptionVerified, company, companyTeams } = Company;
    if (!isExpected) return null;
    if (!subscriptionVerified) return null;
    return (
      <>
        <Divider key="company_divider" />
        {this.getSection(company, company && `Company Home`, 'fas fa-building', '', '/company', false)}
        {companyTeams && companyTeams.map((child) => this.getSection(child, child.longname, '', '', `/teams/${child._id}/`, true))}
      </>
    );
  }
  toggleSection = (sectionId) => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state[sectionId] = !this.state[sectionId];
    Storage.setDrawerState(sectionId, this.state[sectionId]);
    this.forceUpdate();
  }
  getSection = (child, name, icon, tooltip, _url, showSubsections, showDatabases, showCompanyWebsite, state) => {
    const { isDevAccount } = UserAccount;
    const sectionId = child && child._id || showCompanyWebsite && 'company' || 'personal';
    const subsectionsOpen = this.state[sectionId];
    const url = _url;
    let websiteSubsections;
    if (!showSubsections && showCompanyWebsite) {
      const website = window.logictry && window.logictry.company && window.logictry.company.website;
      if (website) {
        websiteSubsections = Object.keys(flattenObj(website)).filter((u) => u).map((url) => this.getSubsection(url, url, 'fas fa-network-wired', ''));
      }
    }
    return (
      <Section key={name}>
        <div>
          <HideLastTillHover>
            {icon && <i className={icon} />}
            {showSubsections && <ClickableIcon small onClick={() => { this.toggleSection(sectionId) }} icon={subsectionsOpen ? 'fas fa-caret-down' : 'fas fa-caret-right'} />}
            <Header role="button" onClick={() => { Navigation.push(url, state) }}><Tooltip text={tooltip}>{name}</Tooltip></Header>
            {(child && <ClickableIcon small onClick={(e) => this.handleToggle(e, child)} icon="fas fa-ellipsis-h" />) || <i></i>}
          </HideLastTillHover>
          {showSubsections && subsectionsOpen && <React.Fragment>
            {[
              ['Apps', `${url}apps`, 'fas fa-network-wired', 'Logic Apps™ are no-code or full-code apps'],
              ['Assets', `${url}assets`, 'fas fa-images', 'Assets are files uploaded'],
              showDatabases && ['Databases', `${url}databases`, 'fas fa-database', 'Databases are No-SQL databases'],
              isDevAccount && ['Links', `${url}links`, 'fas fa-link', 'Links are short url redirects'],
              ['Spreadsheets', `${url}spreadsheets`, 'fas fa-table', 'Spreadsheets are tables of data'],
            ].filter((c) => c).map((props) => this.getSubsection(...props))}
          </React.Fragment>}
          {websiteSubsections}
        </div>
      </Section>
    );
  }
  getSubsection = (name, url, icon, tooltip) => {
    return (
      <Section key={name}>
        <div>
          <div>
            <i className={icon} />
            <Header role="button" onClick={() => Navigation.push(url)}><Tooltip text={tooltip}>{name}</Tooltip></Header>
          </div>
        </div>
      </Section>
    );
  }

  render() {
    const { isDevAccount } = UserAccount;
    const { mobile } = WindowSize;
    const { showUsersbox, showCreateTeam, showEditHome, showEditTheme, showEditWebsite, confirmDelete } = this.state;
    const mobileStyle = mobile && { position: 'fixed', zIndex: 3, top: 0, left: 0, bottom: 0} || {};
    return [
      (mobile) && (<ClickToClose ref={(e) => CancelAllTouchs(e, { onClick: () => DrawerService.toggle() })} key="drawer_click_to_close" />),
      <Wrapper key="drawer_content" style={mobileStyle}>
        <Scrollable
          vertical
          style={{ position: 'absolute', inset: 0 }}
        >
          <Content>
            <div>
              <Section key="Home">
                <div>
                  <HideLastTillHover>
                    <i className="fas fa-home" />
                    <Header role="button" onClick={() => { Navigation.push('/', { home: 'welcome' }); }}><Tooltip text="">Home</Tooltip></Header>
                    <ClickableIcon small onClick={(e) => this.changeHome(e)} icon="fas fa-ellipsis-h" />
                  </HideLastTillHover>
                </div>
              </Section>
              {this.getSection(null, 'Your Content', null, 'Your content or content shared with you', '/', true, true, null, { home: 'yourContent' })}
              {this.getCompanySection()}
            </div>
            <Divider key="company_divider" />
            <Links>
              <Link url={Navigation.welcome}>Welcome</Link>
              <Link url={Navigation.tutorials}>Tutorials</Link>
              <Link url={Navigation.templates}>Templates</Link>
              <Link url={Navigation.videos}>Videos</Link>
              <Link url={Navigation.support}>Support</Link>
              <Link url={Navigation.feedback}>Feedback</Link>
              <Link url={Navigation.legal}>Legal</Link>
              {isDevAccount && <div>v{Version.version}</div>}
            </Links>
          </Content>
        </Scrollable>
        {showUsersbox && <UsersBox domainObject={showUsersbox} onClose={this.clearUsersBox} />}
        {showCreateTeam && <CreateTeamPopup open={showCreateTeam} onClose={this.hideCreateTeam} />}
        {showEditHome && (this.currentTeam ? (
          <EditCompanyHomePopup open={showEditHome} onClose={this.hideEditHome} />
        ) : (
          <EditHomePopup open={showEditHome} onClose={this.hideEditHome} />
        ))}
        {showEditTheme && <CompanyThemePopup open={showEditTheme} onClose={this.hideEditTheme} />}
        {showEditWebsite && <CompanyWebsitePopup open={showEditWebsite} onClose={this.hideEditWebsite} />}
        <ConfirmDialog text="Are you sure you wish to delete?" description="This is permanent and cannot be undone." open={!!confirmDelete} onNo={this.cancelDelete} onYes={this.delete} />
        {this.state.menuOpen && <Popup
          onClose={this.handleClose}
          allowBackgroundClose
          anchorEl={this.anchorEl}
        >
          <MenuWrapper>
            <MenuList>
              {(!this.currentTeam || (this.currentTeam instanceof CompanyModel && this.currentTeam.isOwnerOrAdmin)) && <MenuItem onClick={this.showEditHome}>
                <i className="fas fa-project-diagram"></i>
                <div>Edit Home</div>
              </MenuItem>}
              {this.currentTeam instanceof CompanyModel && <MenuItem onClick={this.showCreateTeam}>
                <i className="fas fa-plus"></i>
                <div>Create Team</div>
              </MenuItem>}
              {this.currentTeam instanceof CompanyModel && this.currentTeam.isOwnerOrAdmin && <MenuItem onClick={this.showEditTheme}>
                <i className="fas fa-palette"></i>
                <div>Edit Theme</div>
              </MenuItem>}
              {this.currentTeam instanceof CompanyModel && this.currentTeam.isOwnerOrAdmin && <MenuItem onClick={this.showEditWebsite}>
                <img alt="website" src="https://logictrystatic.s3.amazonaws.com/images/website.svg"></img>
                <div>Edit Website</div>
              </MenuItem>}
              {this.currentTeam instanceof Team && this.currentTeam.isOwnerOrAdmin && <MenuItem onClick={this.showCreateTeam}>
                <i className="fas fa-edit"></i>
                <div>Edit Team</div>
              </MenuItem>}
              {this.currentTeam instanceof Team && <MenuItem onClick={this.showUsersBox}>
                <i className="fas fa-users"></i>
                <div>View Members</div>
              </MenuItem>}
              {this.currentTeam instanceof Team && this.currentTeam.isOwner && <MenuItem onClick={this.confirmDelete}>
                <i className="fas fa-trash"></i>
                <div>Delete Team</div>
              </MenuItem>}
            </MenuList>
          </MenuWrapper>
        </Popup>}
      </Wrapper>
    ];
  }
}
const flattenObj = (obj, parent, res = {}) => {
  for (const key of Object.keys(obj)) {
    const propName = (parent ? parent + '/' + key : '/' + key).replace('/_id', '');
    if (typeof obj[key] === 'object') {
      flattenObj(obj[key], propName, res);
    } else {
      if (propName) res[propName] = obj[key];
    }
  }
  return res;
}
