import Service from './Service';

export default class LocalStorage extends Service {
  constructor(key) {
    super();
    this.stateKey = key;
    this.loadState();
  }
  loadState() {
    try {
      this.state = JSON.parse(localStorage.getItem(this.stateKey)) || {};
    } catch (e) {
      this.state = {};
    }
  }
  saveState() {
    try {
      localStorage.setItem(this.stateKey, JSON.stringify(this.state));
    } catch (e) {
      // Wrapping incase is blocked
    }
  }
  removeState() {
    try {
      localStorage.removeItem(this.stateKey);
    } catch (e) {
      // Wrapping incase is blocked
    }
  }
}
