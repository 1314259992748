import Model from './model';

export default class LogicBaseTopic extends Model {
  constructor(_id, title, description, image, url) {
    super();
    this._id = _id;
    this.title = title;
    this.description = description;
    this.image = image;
    this.url = url;
  }
  getJson = () => {
    const { _id, title, description, image, url } = this;
    return {
      _id,
      t: title,
      d: description,
      i: image,
      u: url,
    }
  }
}
