import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import File from '../../models/file';
import FileBox from './FileBox';
import RenderedHtmlToTextArea from '../../components/RenderedHtmlToTextArea/index';
import { FILE, FILE_GROUP } from '../../models/nodetypes';

const Wrapper = styled.div`
  margin: 0px 0px 0px ${Constants.TreeContentIconIndent};
  > div:first-child {
    display: flex;
    align-items: center;
    > div:first-child {
      margin: ${Constants.defaultLinePadding} 0px;
    }
    flex: 1;
  }
`;
const FileBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const CreateNewBox = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${Constants.BoxSize}px;
  height: ${Constants.BoxSize}px;
  margin: 1rem 0px 1rem 1.75rem;
  border-radius: ${Constants.BoxRadius}px;
  border: 1px dashed;
  cursor: pointer;
`;
const RequiredText = styled.div`
  margin-left: ${Constants.TreeContentIconIndent};
  color: red;
`;

export default class FilesBox extends React.PureComponent {
  static propTypes = {
    files: PropTypes.object,
  };
  componentDidMount() {
    const { files } = this.props;
    files.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { files } = this.props;
    files.offStateUpdate(this);
  }
  getFilesBoxes = () => {
    const { files } = this.props;
    const { openEnded } = files;
    return (
      <FileBoxes>
        {files.children.map((_child) => {
          if (_child instanceof File) return <FileBox key={_child.key} file={_child} />;
          return null;
        })}
        {openEnded && <CreateNewBox onClick={this.createNew}>+ Add New</CreateNewBox>}
      </FileBoxes>
    );
  }
  createNew = () => {
    const { editing } = GrayOut;
    const { files } = this.props;
    files.addChild(new File('', [FILE], [], (!editing && files.userLoggedIn) || null, files.userLoggedIn));
  }
  render() {
    const { editing } = GrayOut;
    const { files } = this.props;
    const { text, showRequiredText } = files;
    if (!editing && !text) return this.getFilesBoxes();
    return (
      <Wrapper>
        <div>
          {editing ? (
            <RenderedHtmlToTextArea node={files} placeholderText={FILE_GROUP} />
          ) : (
            <div>{files && files.text}</div>
          )}
        </div>
        {showRequiredText && <RequiredText>* Required</RequiredText>}
        {this.getFilesBoxes()}
      </Wrapper>
    );
  }
}
