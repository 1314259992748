import Service from './Service';

class Scroll extends Service {
  __allowScroll = true;
  set allowScroll(_allowScroll) {
    this.__allowScroll = _allowScroll;
    this.emitStateUpdate();
  }
  get allowScroll() {
    return this.__allowScroll;
  }
}
const singleton = new Scroll();
export default singleton;
