import React from 'react';
import styled from 'styled-components';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb';
import Navigation from '../../services/Navigation';
import Company from '../../services/Company';
import ProjectTreesBox from '../TreePage/ProjectTreesBox';
import TemplateTreesBox from '../TreePage/TemplateTreesBox';
import SpreadsheetsBox from '../TreePage/SpreadsheetsBox';
import AssetsBox from '../TreePage/AssetsBox';
import { AccountTemplatesOpen, AccountProjectsOpen, AccountAssetsOpen, AccountSpreadsheetsOpen } from '../../services/Pagination/index';
import Scrollable from '../../components/Scrollable/index';

const TextContent = styled.div`
  width: 100%;
  h2 {
    cursor: pointer;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  h1, h2, p {
    margin-left: 1rem;
    padding: 0 2rem;
  }
`;

export default class HomePage extends React.PureComponent {
  componentDidMount() {
    AccountTemplatesOpen.onStateUpdate(this);
    AccountProjectsOpen.onStateUpdate(this);
    AccountAssetsOpen.onStateUpdate(this);
    AccountSpreadsheetsOpen.onStateUpdate(this);
  }
  componentWillUnmount() {
    AccountTemplatesOpen.offStateUpdate(this);
    AccountProjectsOpen.offStateUpdate(this);
    AccountAssetsOpen.offStateUpdate(this);
    AccountSpreadsheetsOpen.offStateUpdate(this);
  }
  render() {
    const { isAuthorizedToLogIn } = Company;
    return (
      <Scrollable
        vertical
        showVertical
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <FullScreenVerticalCenterContent alignTop>
          <TextContent>
            <DrawerBreadcrumb key="Home"></DrawerBreadcrumb>
            <h1>Your Content</h1>
            {(!isAuthorizedToLogIn) && (
              <>
                <h2 onClick={() => Navigation.push('submissions')}>Submissions</h2>
                <ProjectTreesBox row />
              </>
            ) || (
              <>
                <h2 onClick={() => Navigation.push('apps')}>Apps</h2>
                <TemplateTreesBox row />
                {AccountTemplatesOpen.allFound && <h2 onClick={() => Navigation.push('assets')}>Assets</h2>}
                {AccountTemplatesOpen.allFound && <AssetsBox row />}
                {AccountAssetsOpen.allFound && <h2 onClick={() => Navigation.push('spreadsheets')}>Spreadsheets</h2>}
                {AccountAssetsOpen.allFound && <SpreadsheetsBox row />}
              </>
            )}
          </TextContent>
        </FullScreenVerticalCenterContent>
      </Scrollable>
    )
  }
}
