/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import WysiwygWidget from '../js';

export default class Wysiwyg extends React.PureComponent {
  static propTypes = {
    onBlur: PropTypes.func,
    node: PropTypes.object,
    height: PropTypes.string,
    width: PropTypes.string,
    focusOnInit: PropTypes.bool,
    fullscreen: PropTypes.bool,
    disabled: PropTypes.bool,
  }
  componentDidMount() {
    this.mounted = true;
    this.mountWysiwyg();
  }
  componentWillUnmount() {
    this.mounted = false;
    if (!this.wysiwygWidget) return;
    const { node } = this.props;
    node.offStateUpdate(this.nodeUpdate);
    this.wysiwygWidget.unmount();
    this.wysiwygWidget = null;
  }
  onBlur = () => {
    const { onBlur } = this.props;
    if (!onBlur) return;
    setTimeout(() => {
      if (!this.mounted) return;
      onBlur();
    });
  }
  mountRef = (e) => {
    if (!e) return;
    this.mountNode = e;
    this.mountWysiwyg();
  }
  nodeUpdate = () => {
    const { node } = this.props;
    this.wysiwygWidget.changeText(node.text);
  }
  mountWysiwyg = () => {
    if (!this.mountNode || !this.mounted) return;
    const { node, height, width, focusOnInit, fullscreen, disabled } = this.props;
    const { onBlur } = this;
    this.wysiwygWidget = new WysiwygWidget(this.mountNode, {
      onBlur,
      height,
      width,
      focusOnInit,
      fullscreen,
      disabled,
      initialText: node.text,
      onTextChanged: node.updateText.bind(node),
    });
    node.onStateUpdate(this.nodeUpdate);
  }
  render() {
    return (
      <div style={{ width: '100%', height: '100%' }} ref={this.mountRef} />
    );
  }
}
