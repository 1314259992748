import Service from '../Service';
import Server from './index';

class UserApi extends Service {
  getUsers(_ids) {
    return Server.__getRequest(`/users?ids=${_ids.join(',')}`);
  }
  searchUsers(_query) {
    if (_query.username) return Server.__getRequest(`/users?username=${_query.username}`);
    return Server.__getRequest(`/users?page=${_query.page}&search=${_query.search}`);
  }
  async getCount(_query) {
    return Server.__getRequest(`/users/count`);
  }
  async query(_query) {
    return Server.__getRequest(`/users?${Object.keys(_query).map((_key) => `${_key}=${_query[_key]}`).join('&')}`);
  }
}

const singleton = new UserApi();
export default singleton;
