export default class User {
  constructor(_user) {
    Object.keys(_user).forEach((_key) => {
      this[_key] = _user[_key];
    });
  }

  getImage() {
    let backgroundImage;
    if (this.image && this.image.link) backgroundImage = this.image.link;
    if (!backgroundImage && this.facebook && this.facebook.id) backgroundImage = `https://graph.facebook.com/${this.facebook.id}/picture?type=large`;
    return backgroundImage;
  }
}
