/* eslint-disable no-param-reassign */
export default function CancelAllTouchs(ref, handlers) {
  if (!ref) return;

  const ontouchstart = (e) => {
    if (!handlers.onClick) return;
    if (e.target !== ref) return;
    e.preventDefault();
    e.stopPropagation();
    handlers.onClick(e);
  };
  const ontouchmove = (e) => {
    if (!handlers.onMove) return;
    if (e.target !== ref) return;
    e.preventDefault();
    e.stopPropagation();
    handlers.onMove(e);
  };

  ref.ontouchstart = ontouchstart;
  ref.onmousedown = ontouchstart;
  ref.ontouchmove = ontouchmove;
  ref.onmousemove = ontouchmove;
}
