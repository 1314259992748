import React from 'react';
import PropTypes from 'prop-types';
import ClickableIcon from '../ClickableIcon/index';
import ClickableDiv from '../ClickableDiv/index';

export default class CopyHtmlToClipboardButton extends React.PureComponent {
  static propTypes = {
    target: PropTypes.object,
    text: PropTypes.string,
  }
  state = {
    copied: false,
  }
  copyToClipboard = () => {
    const { target } = this.props;
    if (document.body && document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(target);
      range.select().createTextRange();
      document.execCommand('copy');
      this.setState({ copied: true });
    } else if (window.getSelection) {
      const originalReadOnly = target.readOnly;
      target.readOnly = true;
      const range = document.createRange();
      range.selectNodeContents(target);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      if (target.setSelectionRange) target.setSelectionRange(0, 999999);
      document.execCommand('copy');
      this.setState({ copied: true });
      target.blur();
      target.readOnly = originalReadOnly;
    }
  }
  render() {
    const { text, copiedText } = this.props;
    const { copied } = this.state;
    return (
      <ClickableDiv onClick={this.copyToClipboard} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ClickableIcon small icon="far fa-copy" />
        {copied && copiedText && <div style={{ textDecoration: 'underline' }}>{copiedText}</div>
          || text && <div>{text}</div>}
      </ClickableDiv>
    )
  }
}
