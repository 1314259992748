import Node from './node';
import { SAVE_ON_SUBMIT_ATTR, ALLOW_SAVING_PROGRESS_ATTR, MAX_WIDTH_ATTR, PADDING_ATTR, FONT_FAMILY_ATTR, FONT_SIZE_ATTR, FONT_SIZE_H1_ATTR, FONT_SIZE_H2_ATTR, FONT_SIZE_H3_ATTR } from './nodeattributes';

export default class Root extends Node {
  constructor(_text, _attributes, _children, _owner) {
    super(_text, _attributes, _children, _owner);
    this.__defineNodeProperty('fontFamily', FONT_FAMILY_ATTR);
    this.__defineNodeProperty('fontSize', FONT_SIZE_ATTR);
    this.__defineNodeProperty('fontSizeH1', FONT_SIZE_H1_ATTR);
    this.__defineNodeProperty('fontSizeH2', FONT_SIZE_H2_ATTR);
    this.__defineNodeProperty('fontSizeH3', FONT_SIZE_H3_ATTR);
  }
  expanded = true;
  get allowSavingProgress() {
    return this.saveOnSubmit && this.attributes.includes(ALLOW_SAVING_PROGRESS_ATTR);
  }
  get saveOnSubmit() {
    const saveOnSubmit = this.attributes.find((a) => a.includes(SAVE_ON_SUBMIT_ATTR));
    if (saveOnSubmit) return saveOnSubmit.split('=')[1];
    return null;
  }
  get maxWidth() {
    return (this.attributes.find((a) => a.includes(MAX_WIDTH_ATTR)) || '').split('=')[1];
  }
  get padding() {
    return (this.attributes.find((a) => a.includes(PADDING_ATTR)) || '').split('=')[1];
  }
  updateSaveOnSubmit(_email) {
    this.attributes = this.attributes.filter((_attr) => !_attr.includes(SAVE_ON_SUBMIT_ATTR));
    if (!_email) return this.__attributeChanged();
    return this.addAttribute(`${SAVE_ON_SUBMIT_ATTR}${_email}`);
  }
  updateMaxWidth(_maxWidth) {
    this.attributes = this.attributes.filter((_attr) => !_attr.includes(MAX_WIDTH_ATTR));
    if (!_maxWidth) return this.__attributeChanged();
    return this.addAttribute(`${MAX_WIDTH_ATTR}${_maxWidth}`);
  }
  clone() {
    return new Root(this.text, this.attributes, this.children.map((c) => c.clone()));
  }
}
