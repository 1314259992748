import Model from './model';

export default class LogicBaseComment extends Model {
  constructor(_id, createdTime, owner, app, comment, text, likes) {
    super();
    this._id = _id;
    this.createdTime = createdTime;
    this.owner = owner;
    this.app = app;
    this.comment = comment;
    this.text = text;
    this.likes = likes || 0;
  }
  getJson = () => {
    const { _id, owner, app, comment, text } = this;
    return {
      _id,
      o: owner,
      a: app,
      c: comment,
      t: text
    }
  }
}
