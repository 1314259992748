import ObjectCache from './CacheService';
import UserAccount from '../UserAccount';
import CompanyApi from '../server/CompanyApi';
import Company from '../../models/company';

class CompanyCache extends ObjectCache {
  api = CompanyApi;
  createObject = (_results) => {
    const { isDevAccount } = UserAccount;
    const results = JSON.parse(JSON.stringify(_results));
    return new Company(results.owner, results.users, UserAccount.account._id, results._id, results.shortname, results.longname, results.createdTime, results.updateTime, results.theme, results.website, results.home, results.subscriptions, isDevAccount, results.publicHome, results.websiteSettings);
  }
  revertObject = (_object) => {
    const results = this.objectJson[_object._id];
    this.objects[_object._id] = this.createObject(results);
    this.emitStateUpdate();
  }
  getObjectJson = (_model) => _model.getJson();
}

const singleton = new CompanyCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
