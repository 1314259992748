import Node from './node';
import { PANE } from './nodetypes';
import { HORIZONTAL_ATTR } from './nodeattributes';

export default class SplitPane extends Node {
  get panes() {
    return this.children.filter((_child) => _child.isType(PANE));
  }
  get isDynamicHeightElement() {
    return true;
  }
  clone() {
    return new SplitPane(this.text, this.attributes, this.children.map((c) => c.clone()));
  }
  isMatchingNode(_node) {
    if (this.attributes.includes(HORIZONTAL_ATTR) !== _node.attributes.includes(HORIZONTAL_ATTR)) return false;
    return super.isMatchingNode(_node);
  }
}
