/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */

import Constants from '../submodules/logictry_config/constants';
import { CALENDAR, CALENDAR_MAP } from './nodetypes';
import Logic from './logic';

export default class CalendarLogic extends Logic {
  get calendarView() {
    return this.__recursivelyGetCalendarView(this);
  }
  get calendarMapView() {
    return this.__recursivelyGetCalendarMapView(this);
  }
  clone() {
    return new CalendarLogic(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
  __update() {
    const { calendarView, calendarMapView } = this;
    if (!calendarView) return;
    const { calendarEvents } = calendarView;
    const calendarEventsFound = [];
    const events = {};
    calendarEvents.forEach((ce) => {
      const subCalEvents = ce.calendarEventInfos;
      const title = (subCalEvents.find((e) => e.isTitle) || {}).text;
      const start = (subCalEvents.find((e) => e.isStart) || {}).text;
      const end = (subCalEvents.find((e) => e.isEnd) || {}).text;
      const color = (subCalEvents.find((e) => e.isColor) || {}).text;
      const description = (subCalEvents.find((e) => e.isDescription) || {}).text;
      const location = (subCalEvents.find((e) => e.isLocation) || {}).text;
      const longitude = (subCalEvents.find((e) => e.isLongitude) || {}).text;
      const latitude = (subCalEvents.find((e) => e.isLatitude) || {}).text;
      events[ce.text] = {
        title,
        start,
        end,
        color,
        description,
        location,
        longitude,
        latitude,
      };
    });

    // Get all filters
    const filters = this.__recursivelyGetAllFilters(this);

    // Get all spreadsheets
    const dataNodes = this.__recursivelyGetAllDataNodes(this);
    const data = [];
    dataNodes.forEach((_datanode) => {
      if (!_datanode.spreadsheet || _datanode.spreadsheet.error || this.parents[0].editing) return;
      data.push(_datanode.spreadsheet.sheets[0].data);
    });

    // Iterate through filter and check if:
    // 1. Filter matches a title
    // 2. What answers have been chosen
    // 3. Get the title, start, end for the answers
    filters.forEach((_filter) => {
      if (!events[_filter.text]) return;
      const { title, start, end, color, description, location, latitude, longitude } = events[_filter.text];
      const filterData = { filters: [], titles: [], start: [], end: [], color: [], description: [], location: [], longitude: [], latitude: [] };
      data.forEach((d) => {
        let filterIndex;
        let titleIndex;
        let startIndex;
        let endIndex;
        let colorIndex;
        let descriptionIndex;
        let locationIndex;
        let longitudeIndex;
        let latitudeIndex;
        d[0].forEach((_cell, i) => {
          if (!_cell || !_cell.value) return;
          if (_cell.value === _filter.text) filterIndex = i;
          if (_cell.value === title) titleIndex = i;
          if (_cell.value === start) startIndex = i;
          if (_cell.value === end) endIndex = i;
          if (_cell.value === color) colorIndex = i;
          if (_cell.value === description) descriptionIndex = i;
          if (_cell.value === location) locationIndex = i;
          if (_cell.value === longitude) longitudeIndex = i;
          if (_cell.value === latitude) latitudeIndex = i;
        });
        if (!(filterIndex >= 0)) return;
        d.slice(1).forEach((_row) => {
          filterData.filters.push(_row[filterIndex] && _row[filterIndex].value.trim());
          filterData.titles.push((_row[titleIndex] && _row[titleIndex].value.trim()) || (_row[filterIndex] && _row[filterIndex].value.trim()));
          filterData.start.push(startIndex && _row[startIndex] && _row[startIndex].value.trim());
          filterData.end.push(endIndex && _row[endIndex] && _row[endIndex].value.trim());
          filterData.color.push(colorIndex && _row[colorIndex] && _row[colorIndex].value.trim());
          filterData.description.push(descriptionIndex && _row[descriptionIndex] && _row[descriptionIndex].value.trim());
          filterData.location.push(locationIndex && _row[locationIndex] && _row[locationIndex].value.trim());
          filterData.longitude.push(longitudeIndex && _row[longitudeIndex] && _row[longitudeIndex].value.trim());
          filterData.latitude.push(latitudeIndex && _row[latitudeIndex] && _row[latitudeIndex].value.trim());
        });
      });
      const { answers } = _filter;
      answers.forEach((_answer) => {
        if (!_answer.filter && !_answer.isUserAnswer && _answer.hasUserVoted()) {
          const answerRowIndex = filterData.filters.indexOf(_answer.text);
          if (!(answerRowIndex >= 0)) return;
          calendarEventsFound.push({
            title: filterData.titles[answerRowIndex],
            start: filterData.start[answerRowIndex] || '',
            end: filterData.end[answerRowIndex] || '',
            description: filterData.description[answerRowIndex] || '',
            location: filterData.location[answerRowIndex] || '',
            longitude: filterData.longitude[answerRowIndex] || '',
            latitude: filterData.latitude[answerRowIndex] || '',
            backgroundColor: filterData.color[answerRowIndex] || Constants.PrimaryColor,
            borderColor: filterData.color[answerRowIndex] || Constants.PrimaryColor,
          });
        }
      });
    });

    // Update CalendarView with calendarEvents
    calendarView.updateCalendarEventsSelected(calendarEventsFound);
    if (calendarMapView) calendarMapView.updateCalendarEventsSelected(calendarEventsFound);
  }
  __recursivelyGetCalendarView(node) {
    let calendarView = node.children.find((_child) => _child.attributes[0] === CALENDAR && _child.text === this.text);
    if (calendarView) return calendarView;
    node.children.find((_child) => {
      const foundCalendarView = this.__recursivelyGetCalendarView(_child);
      if (foundCalendarView) calendarView = foundCalendarView;
      return foundCalendarView;
    });
    if (calendarView) return calendarView;
    return null;
  }
  __recursivelyGetCalendarMapView(node) {
    let calendarMapView = node.children.find((_child) => _child.attributes[0] === CALENDAR_MAP && _child.text === this.text);
    if (calendarMapView) return calendarMapView;
    node.children.find((_child) => {
      const foundCalendarView = this.__recursivelyGetCalendarMapView(_child);
      if (foundCalendarView) calendarMapView = foundCalendarView;
      return foundCalendarView;
    });
    if (calendarMapView) return calendarMapView;
    return null;
  }
}
