import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Popup from '../../components/Popup/index';
import StatefulTextField from '../../components/StatefulTextField/index';
import Button from '../../components/Button/index';

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid gray;
  padding: 2rem 3rem;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;


export default class CreatePopup extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
  }
  state = {
    url: '',
    title: '',
    description: '',
    name: '',
    link: '',
  }
  close = () => {
    const { onClose } = this.props;
    onClose(null);
  }
  onChange = (name, value) => {
    this.setState({ [name]: value });
  }
  render() {
    const { url, title, description, name, link } = this.state;
    return (
      <div>
        <Popup
          onClose={this.close}
          allowBackgroundClose
        >
          <Wrapper>
            <h1>{this.createTitle}</h1>
            <StatefulTextField
              value={title || ''}
              onChange={(val) => this.onChange('title', val)}
              label="Title"
              maxLength={256}
            />
            <StatefulTextField
              value={description || ''}
              onChange={(val) => this.onChange('description', val)}
              label="Description"
              maxLength={256}
            />
            {this.showName && <StatefulTextField
              value={name || ''}
              onChange={(val) => this.onChange('name', val)}
              label={this.showName}
              maxLength={256}
            />}
            {this.showUrl && <StatefulTextField
              value={url || ''}
              onChange={(val) => this.onChange('url', val)}
              label="Connection Url"
              maxLength={256}
            />}
            {this.showLink && <StatefulTextField
              value={link || ''}
              onChange={(val) => this.onChange('link', val)}
              label="Redirect to Url"
              maxLength={256}
            />}
            <ButtonsWrapper>
              <Button onClick={this.close} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={this.saveAndClose} color="primary" autoFocus>
                Save
              </Button>
            </ButtonsWrapper>
          </Wrapper>
        </Popup>
      </div>
    );
  }
}
