import Api from './Api';
import Server from './index';

class AssetApi extends Api {
  url = 'assets';
  async getFolderSignedUrls(path, body) {
    return Server.__postRequest(path, body);
  }
}

const singleton = new AssetApi();
export default singleton;
