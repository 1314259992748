import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Navigation from '../../services/Navigation';
import Constants from '../../submodules/logictry_config/constants';

const ButtonWrapper = styled.a`
  display: inline-flex;
  justify-content: center;
  * {
    font-size: ${Constants.NormalFontSize};
  }
  ${Constants.MediaMobile} {
    font-size: ${Constants.SemiSmallFontSize};
  }
  > button {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
  }
`;
const Unfilled = styled(ButtonWrapper)`
  > button {
    box-shadow: unset;
    color: ${Constants.DarkGray};
    ${Constants.MediaTabletAndDesktop} {
      &:hover {
        background-color: unset;
        color: ${Constants.PrimaryColor};
      }
    }
  }

  ${Constants.MediaMobile} {
    * {
      min-width: 0px !Important;
    }
  }
`;
const Filled = styled(ButtonWrapper)`
  > button {
    background-color: ${Constants.PrimaryColor};
    span {
      color: ${Constants.White};
    }
    * {
      color: ${Constants.White};
    }

    &:hover, &:focus, &:active {
      * {
        color: ${Constants.White};
      }
    }
  }

  ${Constants.MediaMobile} {
    * {
      min-width: 0px !Important;
    }
  }
`;

function Button(props) {
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.url) Navigation.push(props.url);
    if (props.absoluteUrl) Navigation.site(props.absoluteUrl);
    if (props.onClick) props.onClick();
  };

  const button = (
    <button
      type="button"
      style={{ textTransform: props.upperCase ? 'uppercase' : 'none' }}
      onClick={onClick}
      ref={props.buttonRef}
      tabIndex={(Object.hasOwnProperty.call(props, 'tabIndex') ? props.tabIndex : -1)}
    >
      {props.children}
    </button>
  );

  if (props.filledColor) {
    return (<Filled
      tabIndex={-1}
      onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
      href={props.url ? `${window.location.origin}${props.url}` : props.absoluteUrl}
    >{button}</Filled>);
  }
  return (<Unfilled
    tabIndex={-1}
    onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
    href={props.url ? `${window.location.origin}${props.url}` : props.absoluteUrl}
  >{button}</Unfilled>);
}

Button.propTypes = {
  children: PropTypes.any,
  url: PropTypes.string,
  absoluteUrl: PropTypes.string,
  onClick: PropTypes.func,
  buttonRef: PropTypes.func,
  filledColor: PropTypes.bool,
  tabIndex: PropTypes.number,
  upperCase: PropTypes.bool,
};

export default Button;
