import Node from './node';
import { COMMA_DELIMITED_ATTR, SEMICOLON_DELIMITED_ATTR, PIPE_DELIMITED_ATTR, EXCLUDE_ATTR } from './nodeattributes';

const Delimiters = [COMMA_DELIMITED_ATTR, SEMICOLON_DELIMITED_ATTR, PIPE_DELIMITED_ATTR];
// const Direction = ['Exclude', 'Include'];

export default class Keywords extends Node {
  get exclude() {
    return this.attributes.includes(EXCLUDE_ATTR);
  }
  get delimiter() {
    return this.attributes.find((_attr) => Delimiters.includes(_attr)) || COMMA_DELIMITED_ATTR;
  }
  get delimiterString() {
    if (this.delimiter === SEMICOLON_DELIMITED_ATTR) return ';';
    if (this.delimiter === PIPE_DELIMITED_ATTR) return '|';
    return ',';
  }
  get keywordArray() {
    return this.text.split(this.delimiterString);
  }
  addKeywords(keywords) {
    const currentKeywords = this.keywordArray;
    keywords.forEach((_keyword) => {
      if (!currentKeywords.includes(_keyword)) currentKeywords.push(_keyword);
    });
    this.text = currentKeywords.join(this.delimiterString);
  }

  clone() {
    return new Keywords(this.text, this.attributes, this.children.map((c) => c.clone()));
  }
}
