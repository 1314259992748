import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import Company from '../../services/Company';
import Popup from '../Popup/index';
import Button from '../Button/index';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 90vw;
  > div:nth-child(2) {
    border-top: 1px solid ${Constants.DarkGray};
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    > * {
      width: 300px;
      margin: 20px;
      ${Constants.InputBorderBottom}
    }
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const COMPANY_THEME_PROPERTIES = ['PrimaryColor', 'Logo', 'Favicon', 'NavbarBackground'];

export default class CompanyThemePopup extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
  }
  componentDidMount() {
    Company.onStateUpdate(this);
  }
  componentWillUnmount() {
    Company.offStateUpdate(this);
  }
  changeProperty = (name, value) => {
    const { company } = Company;
    company.theme[name] = value;
  }
  close = () => {
    const { company } = Company;
    const { onClose } = this.props;
    Company.revertObject(company);
    onClose();
  }
  saveAndClose = () => {
    const { company } = Company;
    const { onClose } = this.props;
    Company.update(company);
    onClose();
  }
  render() {
    const { company } = Company;
    company.theme = company.theme || {};
    return (
      <Popup
        onClose={this.close}
        allowBackgroundClose
      >
        <Wrapper>
          <h1>Company Theme</h1>
          <div>
            {COMPANY_THEME_PROPERTIES.map((prop) => (
              <input key={prop} tabIndex={0} onChange={(e) => this.changeProperty(prop, e.target.value)} placeholder={prop} defaultValue={company.theme[prop] || ''} />
            ))}            
          </div>
          <ButtonsWrapper>
            <Button onClick={this.close} color="primary" autoFocus>
              Cancel
            </Button>
            <Button onClick={this.saveAndClose} color="primary" autoFocus>
              Save
            </Button>
          </ButtonsWrapper>
        </Wrapper>
      </Popup>
    );
  }
}
