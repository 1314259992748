import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';

export default styled.div`
  padding: 0px 4px;
  margin: 0px 12px;
  border-radius: 4px;
  color: ${Constants.White};
  font-family: monospace !Important;
  font-size: ${Constants.SemiSmallFontSize};
`;
