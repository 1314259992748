import ObjectModel from './objectmodel';
import Meta from './meta';
import { DESCRIPTION, TITLE } from './nodetypes';

export default class DatabaseCollection extends ObjectModel {
  constructor(_id, _owner, _users, _teams, _userLoggedIn, _title, _description, _name, _database, _schema, _indices, _createdTime, _updateTime, _state, _shareSetting, _version, _company, _background, _functions) {
    super();
    this._id = _id;
    this.title = new Meta(_title || '', [TITLE], [], null, this.userLoggedIn);
    this.description = new Meta(_description || '', [DESCRIPTION], [], null, this.userLoggedIn);
    this.name = _name;
    if (_createdTime) this.createdTime = new Date(_createdTime);
    if (_updateTime) this.updateTime = new Date(_updateTime);
    this.userLoggedIn = _userLoggedIn;
    this.owner = _owner;
    this.users = _users || [];
    this.teams = _teams || [];
    this.state = _state;
    this.database = _database;
    this.schema = _schema || {};
    this.schemaString = JSON.stringify(_schema);
    this.indices = _indices;
    this.indicesString = JSON.stringify(_indices);
    this.shareSetting = _shareSetting || 'LinkView';
    this.version = _version || 1;
    this.company = _company || '';
    this.background = _background;
    this.functions = _functions;
    this.functionsString = JSON.stringify(_functions);
  }
  getJson = () => {
    const { _id, owner, title, description, name, version, state, database, indices, shareSetting, users, teams, company, background, schema, functions } = this;
    const json = {
      _id,
      owner,
      title: title.text,
      description: description.text,
      name,
      version,
      state,
      database,
      indices,
      shareSetting,
      users,
      teams,
      background,
      schema,
      functions,
    };
    if (company) json.company = company;
    return json;
  }
  updateSchema(schemaString) {
    this.schemaString = schemaString
    try {
      this.schema = JSON.parse(schemaString);
    } catch(e) {
      this.schema = {};
    }
  }
  updateIndices(indicesString) {
    this.indicesString = indicesString
    try {
      this.indices = JSON.parse(indicesString);
    } catch(e) {
      this.indices = [];
    }
  }
  updateFunctions(functionsString) {
    this.functionsString = functionsString
    try {
      this.functions = JSON.parse(functionsString);
    } catch(e) {
      this.functions = {};
    }
  }
}
