/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-unresolved
import ObjectCache from './CacheService';
import LogicBaseSubmissionApi from '../server/LogicBaseSubmissionApi';
import UserAccount from '../UserAccount';
import LogicBaseApp from '../../models/logicbaseapp';

class LogicBaseSubmissionCache extends ObjectCache {
  api = LogicBaseSubmissionApi;
  createObject = (_results) => {
    const results = JSON.parse(JSON.stringify(_results));
    return new LogicBaseApp(results._id, results.ct, results.o, results.t, results.d, results.i, results.ts, results.u, results.l, results.v, results.a, results.g);
  }
  revertObject = (_object) => {
    const results = this.objectJson[_object._id];
    this.objects[_object._id] = this.createObject(results);
    this.emitStateUpdate();
  };
  getObjectJson = (_model) => _model.getJson();
}
const singleton = new LogicBaseSubmissionCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
