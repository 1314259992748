import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Button from '../../components/Button/index';
import Team from '../../models/team';
import Company from '../../models/company';
import CompanyService from '../../services/Company';
import { DESCRIPTION } from '../../models/nodetypes';
import Popup from '../../components/Popup/index';
import MenuList from '../../components/Popup/MenuList';
import MenuItem from '../../components/Popup/MenuItem';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 90vw;
  > div:nth-child(2) {
    border-top: 1px solid ${Constants.DarkGray};
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    > * {
      width: 300px;
      margin: 20px;
      ${Constants.InputBorderBottom}
    }
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SettingWrapper = styled.div`
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * {
    padding: 6px;
  }
`;
const PrivateText = 'Private - only users invited';
const PublicText = 'Public - anyone in the company';
const shareSettingMapping =  {
  Private: {
    text: PrivateText,
    icon: 'fas fa-eye-slash',
  },
  Public: {
    text: PublicText,
    icon: 'fas fa-eye',
  },
};

export default class CreateTeamPopup extends React.PureComponent {
  static propTypes = {
    open: PropTypes.object,
    onClose: PropTypes.func,
  }
  constructor(props) {
    super(props);
    const { open } = props;
    if (open instanceof Team) {
      this.state = {
        name: open.longname,
        description: open.description,
        shareSetting: open.shareSetting,
      };
    } else {
      this.state = {
        name: '',
        description: '',
        shareSetting: 'Private',
      };
    }
  }
  changeTitle = (name) => {
    this.state.name = name;
  }
  changeDescription = (description) => {
    this.state.description = description;
  }
  saveAndClose = () => {
    const { onClose, open } = this.props;
    const { name, description, shareSetting } = this.state;
    if (open instanceof Company) {
      CompanyService.createCompanyTeam(name, description, shareSetting);
    } else if (open instanceof Team) {
      open.longname = name;
      open.description = description;
      open.shareSetting = shareSetting;
      CompanyService.updateCompany(open);
    }
    onClose();
  }
  onViewChange = (shareSetting) => {
    this.setState({ shareSettingsOpen: false, shareSetting });
  }
  render() {
    const { open, onClose } = this.props;
    const { name, description, shareSetting, shareSettingsOpen } = this.state;
    if (!open) return null;
    return (
      <>
        <Popup
          onClose={onClose}
          allowBackgroundClose
        >
          <Wrapper>
            <h1>{open instanceof Team ? 'Edit Team' : 'Create Team'}</h1>
            <div>
              <input tabIndex={0} ref={(e) => { this.inputRef = e; }} onChange={(e) => { this.changeTitle(e.target.value); }} placeholder="Team Name" defaultValue={name || ''} />
              <input tabIndex={0} ref={(e) => { this.inputRef = e; }} onChange={(e) => { this.changeDescription(e.target.value); }} placeholder={DESCRIPTION} defaultValue={description || ''} />
              <SettingWrapper role="button" onClick={() => this.setState({ shareSettingsOpen: true })} ref={(e) => { if (e) this.embedEl = e; }}>
                <i className={shareSettingMapping[shareSetting].icon} />
                <div>{shareSettingMapping[shareSetting].text}</div>
                <i className="fas fa-angle-down" />
              </SettingWrapper>
            </div>
            <ButtonsWrapper>
              <Button onClick={onClose} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={this.saveAndClose} color="primary" autoFocus>
                Save
              </Button>
            </ButtonsWrapper>
          </Wrapper>
        </Popup>
        {shareSettingsOpen && <Popup
          allowBackgroundClose
          onClose={() => this.setState({ selectSettingsOpen: false })}
          anchorEl={this.embedEl}
          anchorBottomLeft
        >
          <MenuList>
            <MenuItem onClick={() => this.onViewChange('Private')}>
              <i className="fas fa-eye-slash"></i>
              <div style={{ marginLeft: 10 }}>{PrivateText}</div>
            </MenuItem>
            <MenuItem onClick={() => this.onViewChange('Public')}>
              <i className="fas fa-eye"></i>
              <div style={{ marginLeft: 10 }}>{PublicText}</div>
            </MenuItem>
          </MenuList>
        </Popup>}
      </>
    );
  }
}
