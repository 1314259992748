/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
// import styled from 'styled-components';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
// import { PropTypes } from 'prop-types';

import Navigation from '../../services/Navigation';
import NotFoundPage from '../../containers/NotFoundPage';
import UserAccount from '../../services/UserAccount';
import PopoutPage from '../../containers/PopoutPage';


function PopoutRoutes() {

  
  const alwaysPaths = [
  ]
  const loggedOutPaths = [
    <Route key="loggingInPaths/apps/:id" path="/popout" element={<PopoutPage />} />,
  ]
  const loggedInPaths = [
    <Route key="loggingInPaths/apps/:id" path="/popout" element={<PopoutPage />} />,
  ]
  const getPaths = () => {
    if (UserAccount.state === 'loggedIn') return this.loggedInPaths;
    return this.loggedOutPaths;
  }

  Navigation.useNavigate = useNavigate();
  Navigation.location = useLocation();
  return (
    <Routes key={`${window.location.pathname}${window.location.search}${JSON.stringify(Navigation.location.state)}`}>
      {alwaysPaths}
      {getPaths()}
      <Route key="notfound" path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default PopoutRoutes;
