import React from 'react';
import Navigation from '../../services/Navigation';
import Server from '../../services/server';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import LoadingIndicator from '../../components/LoadingIndicator';

export default class LandingPage extends React.PureComponent {
  render() {
    if (window.location.origin === 'https://logictry.com' || window.location.origin === 'https://localhost') {
      Server.__getRequest(`/website?url=${window.location.pathname}`).then((r) => {
        if (r && r.exists) window.location.reload();
        else window.location = window.location.origin;
      });
      return null;
    }
    setTimeout(() => Navigation.push(Navigation.login), 250);
    return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
  }
}
