import React from 'react';
import styled from 'styled-components';
import UserAccount from '../../services/UserAccount';
import Navigation from '../../services/Navigation';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import LoadingIndicator from '../../components/LoadingIndicator';
import HorizontalCenterDiv from '../../styledhtml/HorizontalCenterDiv';
import StatefulButton from '../../components/StatefulButton/index';
import Button from '../../components/Button/index';

const Wrapper = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default class EmailPage extends React.PureComponent {
  state = {
    submitting: true,
    requireLogin: false,
    text: '',
  }

  componentDidMount() {
    const params = Navigation.currentSearch;
    if (params.email && params.token) {
      UserAccount.unsubscribe(params.email, false, params.token).then((r) => {
        if (r.success || r.error === 'UserNotUpdated') this.setState({ submitting: false, requireLogin: false, text: 'Unsubscribed!' });
        if (r.error === 'UserLoginRequired') this.setState({ submitting: false, requireLogin: true });
        if (r.error === 'UserNotFound') this.setState({ submitting: false, requireLogin: false, text: 'User not found' });
        if (r.error === 'UnsubscribeFailed') this.setState({ submitting: false, requireLogin: true });
      });
    }
  }

  render() {
    if (this.state.submitting) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;

    if (this.state.requireLogin) {
      return (
        <FullScreenVerticalCenterContent>
          <HorizontalCenterDiv>
            <h2>You must login to unsubscribe</h2>
            <StatefulButton text="Log In" onClick={() => Navigation.push(Navigation.login)} />
          </HorizontalCenterDiv>
        </FullScreenVerticalCenterContent>
      );
    }

    return (
      <FullScreenVerticalCenterContent>
        <Wrapper>{this.state.text}</Wrapper>
      </FullScreenVerticalCenterContent>
    );
  }
}
