import React from 'react';
// import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';

const ShareIcons = styled.div`
  text-align: center;
  color: ${Constants.PrimaryColor};
  font-weight: bold;
  margin-bottom: 4rem;
  > div {
    display: flex;
    justify-content: center;
    gap: 1rem;
    h2 {
      font-size: Constants.LargeFontSize;
    }
    i {
      color: ${Constants.PrimaryColor};
      font-size: ${Constants.LargeFontSize};
      height: 32px;
    }

    svg {
      height: 26px;
      font-size: ${Constants.LargeFontSize};
      fill: ${Constants.PrimaryColor};
    }
  }
`;

export default function ShareOnSocial({ title, text, facebook, twitter, linkedIn }) {
  return (
    <ShareIcons>
      <h2>{title}</h2>
      <div>
        {/* <a target="_blank" onClick={this.openInstagram}><i className="fab fa-instagram"></i></a> */}
        <a target="_blank" href={`https://www.facebook.com/sharer.php?u=${facebook}`}><i className="fab fa-facebook-f"></i></a>
        <a target="_blank" href={`https://twitter.com/intent/tweet?url=${twitter}&text=${text}&via=logictry&hashtags=logictry`}><svg viewBox="0 0 24 24" aria-hidden="true"><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg></a>
        <a target="_blank" href={`https://www.linkedin.com/shareArticle?url=${linkedIn}`}><i className="fab fa-linkedin"></i></a>
      </div>
    </ShareIcons>
  )
}
