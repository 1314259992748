import ObjectCache from './CacheService';
import UserAccount from '../UserAccount';
import ApiKeyApi from '../server/ApiKeyApi';
import ApiKeys from '../../models/apikeys';
import Company from '../Company';

class ApiKeyCache extends ObjectCache {
  api = ApiKeyApi;
  createNew = () => new ApiKeys(null, null, null, Company.company && Company.company._id || '', [], 'Live');
  createObject = (_results) => {
    const results = JSON.parse(JSON.stringify(_results));
    return new ApiKeys(results._id, results.createdTime, results.updateTime, results.company, results.whitelist, results.state);
  }
  revertObject = (_object) => {
    const results = this.objectJson[_object._id];
    this.objects[_object._id] = this.createObject(results);
    this.emitStateUpdate();
  }
  getObjectJson = (_model) => _model.getJson();
}

const singleton = new ApiKeyCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
