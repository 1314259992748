import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import LogicBaseAppCache from '../../services/cache/LogicBaseAppCache';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import UserCache from '../../services/cache/UserCache';
import TreeCache from '../../services/cache/TreeCache';
import WindowSize from '../../services/WindowSize';
import UserAccount from '../../services/UserAccount';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import LoadingIndicator from '../../components/LoadingIndicator';
import { getCollectionMostFollowers, getCollectionMostRecentlyUpdated } from '../../services/Pagination';
import Scrollable from '../../components/Scrollable';
import Navigation from '../../services/Navigation';
import LogicBaseCollectionRow from '../../components/LogicBaseCollectionRow';
import ScrollIt from '../../utils/Scroll';

const TOPICS = ['latest', 'trending', 'politics', 'shopping', 'startups'];

const UserProfile = styled.div`
  margin-top: 0;
  margin-bottom: 0rem;
  @media (max-width: 959px) {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  h1 {
    font-weight: 600;
    font-size: ${Constants.VeryLargeFontSize};
    margin: 0;
  }
  h1:first-letter {
    text-transform: capitalize;
  }
  > div:first-child {
    margin-bottom: 4rem;
    // text-align: center;
  }
  > div:last-child {
    // max-width: 38rem;
    margin: auto;
    margin-bottom: 1rem;
  }
`;
const Search = styled.input`
  border: 1px solid #C1C1C1;
  border-radius: 0.5rem;
  overflow: hidden;
  line-height: 18px;
  padding: 1rem 3rem 1rem 1rem;
  margin: 1.5rem 0 1rem;
  width: 100%;
  max-width: 42rem;
  display: block;
`;
const Topic = styled.div`
  cursor: pointer;
  min-width: 4rem;
  text-align: center;
  border-radius: 1.5rem;
  font-size: 0.75rem;
  flex-shrink: 0;
  padding: 0.5rem 1rem;
`;
const Arrows = styled.div`
  > div {
    position: absolute;
    height: 34px;
    width: 34px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    > div {
      border-radius: 50%;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      box-shadow: 0px 0px 0px 1px transparent, 0px 0px 0px 4px transparent, 0px 6px 16px rgb(0 0 0 / 12%) !important;
    }
  }

  > div:first-child {
    left: 0;
  }
  > div:last-child {
    right: 0;
  }
`;

export default class LogicBaseCollectionsPage extends React.PureComponent {
  constructor() {
    super();
    this.collectionMostFollowers = getCollectionMostFollowers('');
    this.collectionMostRecentlyUpdate = getCollectionMostRecentlyUpdated('');
  }
  state = {
    t: Navigation.currentSearch.t || 'latest',
    showLeft: false,
    showRight: false,
  }
  componentDidMount() {
    this.collectionMostFollowers.onStateUpdate(this);
    this.collectionMostRecentlyUpdate.onStateUpdate(this);
    LogicBaseAppCache.onStateUpdate(this);
    LogicBaseCollectionCache.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    TreeCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    this.collectionMostFollowers.offStateUpdate(this);
    this.collectionMostRecentlyUpdate.offStateUpdate(this);
    LogicBaseAppCache.offStateUpdate(this);
    LogicBaseCollectionCache.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    TreeCache.offStateUpdate(this);
    clearTimeout(this.__searchTimeout);
  }
  onPagination = () => {
    if (this.collectionsCache && this.collectionsCache.showMore && this.collectionsCache.allFound && this.collectionsCache.initialized) {
      this.collectionsCache.getMore();
      this.forceUpdate();
    }
  }
  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.runSearch();
    } else {
      clearTimeout(this.__searchTimeout);
      this.__searchTimeout = setTimeout(() => {
        this.runSearch();
      }, 500);
    }
  }
  runSearch = () => {
    this.searchText();
  }
  searchText = () => {
    const { searchInput } = this;
    this.textToSearch = searchInput.value.toLowerCase();
    this.forceUpdate();
  }
  scroll = (forward) => {
    if (!this.topicsRef) return;
    const destination = Math.max(this.topicsRef.scrollLeft + (forward ? this.topicsRef.offsetWidth : -this.topicsRef.offsetWidth), 0);
    ScrollIt(destination, this.topicsRef, undefined, undefined, undefined, true);
  }
  onScroll = () => {
    if (!this.topicsRef) return;
    const showLeft = this.topicsRef.scrollLeft > 0;
    const showRight = Math.ceil(this.topicsRef.scrollLeft + this.topicsRef.offsetWidth) < this.topicsRef.scrollWidth;
    this.setState({ showLeft, showRight });
  }
  navigateTag = (tag) => {
    if (Constants.isIphone) {
      this.setState({ t: tag });
    } else {
      if (!tag || tag === 'latest') return Navigation.push(`collections`);
      return Navigation.push(`collections?t=${tag}`, { 'logicbasecollectiontopics_horizontal': this.topicsRef.scrollLeft });
    }
  }
  render() {
    const { t, showLeft, showRight } = this.state;
    const { mobile } = WindowSize;
    const loading = <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    this.collectionsCache = null;
    if (t === 'latest' || !t) {
      this.collectionsCache = this.collectionMostRecentlyUpdate;
      this.collections = this.collectionMostRecentlyUpdate.currentPage;
    } else if (t === 'trending') {
      this.collectionsCache = this.collectionMostFollowers;
      this.collections = this.collectionMostFollowers.currentPage;
    } else if (t === 'politics') this.collections = LogicBaseCollectionCache.getByIds(['64bd1698b20bfa2b228ad089', '657db498a73f67a5614832db']);
    else if (t === 'shopping') this.collections = LogicBaseCollectionCache.getByIds(['6582fc663591dc84594585ed']);
    else if (t === 'startups') this.collections = LogicBaseCollectionCache.getByIds(['64bc3170fd1d5c1bdba61eb6', '658850efdaa6a95c57fb67e9', '66e973f02277c6df8dcbc932']);
    if (!this.collections || this.collections.length === 0) return loading;
    const search = this.textToSearch || '';
    const searchResults = search && LogicBaseCollectionCache.query({ search });
    const showCollections = search ? searchResults : this.collections || this.previousCollections;
    this.previousCollections = showCollections || this.previousCollections;
    const tagArray = t && t.split(',') || [];
    return (
      <Scrollable
        vertical
        paginationHeight={400}
        onPagination={this.onPagination}
        style={{ position: mobile ? 'relative' : 'absolute', top: 0, left: 0, right: 0, bottom: 0, padding: mobile ? '1rem 1rem 2rem 1rem' : '2rem 2rem 2rem 2rem' }}
        rememberScrollPosition={`logicbasecollections`}
      >
        <UserProfile>
          <div>
            <h1>{t} Collections</h1>
            <p>Community-curated collections for the best resources, decision-making guides, and more…</p>
          </div>
        </UserProfile>
        <Search
          key="collectionssearch"
          onKeyDown={this.onKeyDown}
          ref={(e) => { this.searchInput = e; }}
          placeholder={"Search collections"}
          defaultValue={search}
        ></Search>
        {!search && <div style={{ maxWidth: '42rem' }}>
          <div style={{ position: 'relative', overflow: 'hidden', height: 34 }}>
            <Scrollable
              horizontal
              onScroll={this.onScroll}
              scrollRef={(e) => { this.topicsRef = e; this.onScroll(); }}
              style={{ position: 'absolute', inset: 0 }}
              rememberScrollPosition={`logicbasecollectiontopics`}
            >
              <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                {TOPICS.map((url) => {
                  const isSelected = tagArray.includes(url);
                  return (
                    <Topic key={url} style={{ backgroundColor: isSelected ? Constants.DarkGray : 'rgba(242, 242, 242, 1)', color: isSelected ? 'white' : 'unset', }} onClick={() => this.navigateTag(url)}>{url}</Topic>
                  );
                })}
              </div>
            </Scrollable>
            <Arrows>
              <div style={{ display: showLeft && 'flex' || 'none' }}>
                <div onClick={() => this.scroll(false)}>
                  <i className="fas fa-angle-left"></i>
                </div>
              </div>
              <div style={{ display: showRight && 'flex' || 'none' }}>
                <div onClick={() => this.scroll(true)}>
                  <i className="fas fa-angle-right"></i>
                </div>
              </div>
            </Arrows>
          </div>
        </div>}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', marginTop: '4rem' }}>
          {showCollections && showCollections.map((collection) => <LogicBaseCollectionRow key={collection._id} collection={collection} />)}
        </div>
      </Scrollable>
    );
  }
}
