import Node from './node';
import { IF_ATTR, ELSE_IF_ATTR, ELSE_ATTR } from './nodeattributes';


export default class Conditional extends Node {
  constructor(_text, _attributes, _children, _owner) {
    super(_text, _attributes, _children, _owner);
    this.__defineNodeAttribute('type', [{ name: IF_ATTR, value: IF_ATTR, default: true }, { name: ELSE_IF_ATTR, value: ELSE_IF_ATTR }, { name: ELSE_ATTR, value: ELSE_ATTR }]);
  }
  clone() {
    return new Conditional(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
  updateConditionalValue(_show) {
    if (_show === this.conditionalValue) return;
    this.conditionalValue = _show;
    this.__updateAllParentsOnNodeUpdated();
    this.emitStateUpdate();
  }
}
