/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */

import Node from './node';
import { TITLE_ATTR, START_ATTR, END_ATTR, COLOR_ATTR, DESCRIPTION_ATTR, LOCATION_ATTR, LATITUDE_ATTR, LONGITUDE_ATTR } from './nodeattributes';

export default class CalendarEventInfo extends Node {
  clone() {
    return new CalendarEventInfo(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
  get isTitle() { return this.attributes.includes(TITLE_ATTR); }
  get isColor() { return this.attributes.includes(COLOR_ATTR); }
  get isDescription() { return this.attributes.includes(DESCRIPTION_ATTR); }
  get isEnd() { return this.attributes.includes(END_ATTR); }
  get isLocation() { return this.attributes.includes(LOCATION_ATTR); }
  get isStart() { return this.attributes.includes(START_ATTR); }
  get isLatitude() { return this.attributes.includes(LATITUDE_ATTR); }
  get isLongitude() { return this.attributes.includes(LONGITUDE_ATTR); }
}
