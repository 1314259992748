import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import StatefulTextField from '../../components/StatefulTextField';
import Company from '../../services/Company';
import CompanyCache from '../../services/cache/CompanyCache';
import StatefulButton from '../../components/StatefulButton/index';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import UserCache from '../../services/cache/UserCache';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-bottom: 2rem;
  }
`;

export default class RegisterCompany extends React.PureComponent {
  static propTypes = {
    onComplete: PropTypes.func,
  };
  state = {
    longname: '',
    shortname: '',
    owner: '',
    warning: '',
    success: '',
  };
  componentDidMount() {
    CompanyCache.onCreate = () => {

    };
    CompanyCache.onCreateSuccess = () => {
      // const { onComplete } = this.props;
      // return onComplete();
      this.setState({ success: 'success' });
    };
    CompanyCache.onCreateFail = (error) => {
      if (error && error.error === 'UserNotAuthorized') return this.setState({ warning: 'User not authorized' });
      if (error && error.error === 'AlreadyExists') return this.setState({ warning: 'Company already exists' });
      return this.setState({ warning: 'Something went wrong' });
    };
    // UserCache.onStateUpdate(this.getCurrentSubscriptions);
  }
  componentWillUnmount = () => {
    CompanyCache.onCreate = null;
    CompanyCache.onCreateSuccess = null;
    CompanyCache.onCreateFail = null;
    // UserCache.offStateUpdate(this.getCurrentSubscriptions);
  };

  createCompany = () => {
    const { longname, shortname, owner } = this.state;
    if (longname === '') return this.setState({ warning: 'Company Full Name Blank' });
    if (shortname === '') return this.setState({ warning: 'Company Handle Blank' });
    if (owner === '') return this.setState({ warning: 'Owner Blank' });

    const foundUsers = UserCache.getUsers({
      page: 1,
      search: owner,
    });

    if (!(foundUsers && foundUsers[0] && foundUsers[0].email === owner.toLowerCase())) return this.setState({ warning: 'Something Went Wrong' });

    Company.createCompany(longname, shortname, foundUsers[0]._id);
    return this.setState({ warning: '' });
  };
  ownerBlurred = () => {
    const { owner } = this.state;
    UserCache.getUsers({
      page: 1,
      search: owner,
    });
  }

  render() {
    const { onComplete } = this.props;
    const { longname, shortname, owner, warning, success } = this.state;
    return (
      <FullScreenVerticalCenterContent>
        <Form>
          <h1>Create Company</h1>
          <StatefulTextField
            value={longname}
            onChange={(longname) => { this.setState({ longname }) }}
            type="Longname"
            label="Company Full Name"
            style={{ minWidth: 120, maxWidth: '80%', width: 400 }}
          />
          <StatefulTextField
            value={shortname}
            onChange={(shortname) => { this.setState({ shortname }) }}
            type="Shortname"
            label="Company Handle"
            style={{ minWidth: 120, maxWidth: '80%', width: 400 }}
          />
          <StatefulTextField
            value={owner}
            onChange={(owner) => { this.setState({ owner }) }}
            onComplete={this.ownerBlurred}
            type="Owner"
            label="Owner"
            style={{ minWidth: 120, maxWidth: '80%', width: 400 }}
          />
          <div style={{ margin: '2rem 0 1rem' }}>
            <StatefulButton
              onClick={this.createCompany}
              text="Create Company"
              warning={warning}
              success={success}
            />
          </div>
          {/* <Button onClick={onComplete} tabIndex={-1}>
            Skip Company?
          </Button> */}
        </Form>
      </FullScreenVerticalCenterContent>
    );
  }
}
