/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import GrayOut from '../../services/TreeDisplay';
import SpreadsheetCache from '../../services/cache/SpreadsheetCache';
import GoogleSpreadsheetCache from '../../services/cache/GoogleSpreadsheetCache';
import RenderedHtmlToTextArea from '../RenderedHtmlToTextArea/index';
import Dropdown from '../Dropdown';

const Wrapper = styled.div`
  flex: 1;
`;

export default class AutofillInput extends React.PureComponent {
  static propTypes = {
    question: PropTypes.object,
    answer: PropTypes.object,
    autofill: PropTypes.object,
    placeholder: PropTypes.string,
  }
  componentDidMount() {
    const { answer, autofill } = this.props;
    answer.onStateUpdate(this);
    autofill.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
    SpreadsheetCache.onStateUpdate(this.getSpreadsheet);
    GoogleSpreadsheetCache.onStateUpdate(this.getSpreadsheet);
    this.getSpreadsheet();
  }
  componentWillUnmount() {
    const { answer, autofill } = this.props;
    answer.offStateUpdate(this);
    autofill.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
    SpreadsheetCache.offStateUpdate(this.getSpreadsheet);
    GoogleSpreadsheetCache.offStateUpdate(this.getSpreadsheet);
  }
  getSuggestions = (value) => {
    const { autofill, question } = this.props;
    const { spreadsheet } = autofill;
    if (!spreadsheet || !spreadsheet.sheets || !spreadsheet.sheets[0]) return [];
    const choices = (spreadsheet.sheets[0].choices[question.text] || []).filter((v) => v);
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return (inputLength === 0 ? choices : choices
      .filter((lang) => lang.toLowerCase().includes(inputValue)))
      .map((c) => ({ name: c, value: c }));
  };

  getSpreadsheet = () => {
    const { editing } = GrayOut;
    if (editing) return;
    const { autofill } = this.props;
    const { isGoogleSpreadsheet, text } = autofill;
    const spreadsheetModel = isGoogleSpreadsheet ? GoogleSpreadsheetCache.get(text) : SpreadsheetCache.get(text);
    autofill.updateSpreadsheet(spreadsheetModel);
  }
  updateAnswer = (value) => {
    const { autofill, question, answer } = this.props;
    const { spreadsheet } = autofill;

    if (!spreadsheet || !spreadsheet.sheets || !spreadsheet.sheets[0]) {
      return answer.updateText(value);
    }
    const autofillUpdates = spreadsheet.sheets[0].findUpdatesFromChoice(question.text, value);
    GrayOut.setExpanded(answer, true);
    return answer.updateText(value, autofillUpdates);
  }

  render() {
    const { editing } = GrayOut;
    const { answer, placeholder } = this.props;
    const isActive = GrayOut.isActive(answer);

    return (
      <Wrapper ref={(e) => { this.autofillRef = e; }}>
        <RenderedHtmlToTextArea node={answer} placeholderText={placeholder} inputType="text" />
        {!editing && isActive && <Dropdown values={this.getSuggestions(answer.text)} onChange={this.updateAnswer} />}
      </Wrapper>
    );
  }
}
