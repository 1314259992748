/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React from 'react';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';

import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import WindowSize from '../../services/WindowSize';
import RenderedHtmlToTextArea from '../../components/RenderedHtmlToTextArea/index';
import { CALENDAR } from '../../models/nodetypes';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';
import ClickableIcon from '../../components/ClickableIcon/index';
import smoothscrollfix from '../../utils/smoothscrollfix';
import '../../utils/ics.deps.min.jsx';

const CalendarWrapper = styled.div`
  margin: 12px 0px 0px ${Constants.TreeContentIconIndent};
  background-color: white;
  > *:first-child {
    z-index: 0;
  }
  > div:first-child {
    position: relative;
    flex: 1;
  }
  position: relative;
  .fc-button, .fc-button .fc-icon {
    background-color: ${Constants.PrimaryColor} !Important;
    border: ${Constants.PrimaryColor} !Important;
    &:focus {
      box-shadow: rgba(20, 149, 15, 0.7) 0px 1px 6px !Important;
    }
    line-height: 1;
  }
  .fc-button {
    font-size: ${Constants.SmallFontSize};
  }
  .fc-button .fc-icon {
    font-size: ${Constants.SemiSmallFontSize};
  }
  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 10px;
    height: 22px;
    h2 {
      font-size: ${Constants.NormalFontSize};
    }
  }
  .fc-event, .fc-event * {
    font-size: ${Constants.SmallFontSize};
    line-height: 1;
  }
  .fc-list-table td {
    ${Constants.MediaMobile} {
      padding: 6px;
    }
    word-break: break-word;
  }
`;
const Wrapper = styled.div`
  position: relative;
  > * {
    width: 100%;
  }
`;
const FullscreenWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  z-index: 4;
  .leaflet-control-attribution {
    display: none;
  }
  background-color: ${Constants.White};
`;
const FullscreenButton = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  background-color: ${Constants.White};
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
`;
const ExportButton = styled.div`
  position: absolute;
  top: 6px;
  right: 40px;
  background-color: ${Constants.White};
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
`;

export default class CalendarViewBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  }
  state = {
    fullscreen: false,
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
  }
  calendarRef = (ref) => {
    const { fullscreen } = this.state;
    if (!fullscreen) return;
    if (!ref) return;
    const scroller = document.getElementsByClassName('fc-scroller')[0];
    if (!scroller) return;
    smoothscrollfix(scroller);
  }
  exportCalendar = () => {
    const { node } = this.props;
    const { calendarEventsSelected } = node;
    if (!calendarEventsSelected || calendarEventsSelected.length === 0) return;
    const cal = window.ics();
    calendarEventsSelected.forEach((ce) => {
      cal.addEvent(ce.title, ce.description, ce.location, ce.start, ce.end);
    });
    cal.download('logictry_calendar');
  }
  getCalendar = (height, calendarEventsSelected, calendarTimeZone) => {
    const defaultDate = calendarEventsSelected.length > 1 && calendarEventsSelected.reduce((a, b) => Math.min((new Date(a.start)).getTime(), (new Date(b.start)).getTime())) || calendarEventsSelected.length > 0 && (new Date(calendarEventsSelected[0].start)).getTime() || Date.now();
    const defaultDateYear = (new Date(defaultDate)).getUTCFullYear().toString();
    return (
      <FullCalendar
        key={defaultDateYear}
        initialDate={defaultDateYear}
        views={{
          listAllYears: {
            type: 'listYear',
            duration: { years: 10 },
          },
        }}
        timeZone={calendarTimeZone || 'local'}
        headerToolbar={false}
        height={height}
        ref={this.calendarRef}
        initialView="listAllYears"
        plugins={[listPlugin]}
        events={calendarEventsSelected}
        listDayFormat={{
          month: 'long',
          year: 'numeric',
          day: 'numeric',
          weekday: 'long'
        }}
      />
    );
  }
  render() {
    const { mobile, isSafari } = WindowSize;
    const { node } = this.props;
    const { fullscreen } = this.state;
    const { calendarEventsSelected, calendarTimeZone } = node;
    const { editing } = GrayOut;
    const height = 200;

    if (!editing) {
      if (fullscreen) {
        return (
          <FullscreenWrapper ref={smoothscrollfix}>
            {this.getCalendar(window.innerHeight + (mobile && isSafari ? 2 : 0), calendarEventsSelected, calendarTimeZone)}
            <FullscreenButton style={{ zIndex: 1000 }}>
              <ClickableIcon small onClick={() => this.setState({ fullscreen: false })} icon="fas fa-expand" />
            </FullscreenButton>
          </FullscreenWrapper>
        );
      }
      return (
        <CalendarWrapper style={{ height }}>
          {this.getCalendar(height, calendarEventsSelected, calendarTimeZone)}
          <FullscreenButton>
            <ClickableIcon small onClick={() => this.setState({ fullscreen: true })} icon="fas fa-expand" />
          </FullscreenButton>
          <ExportButton>
            <ClickableIcon small onClick={this.exportCalendar} icon="fas fa-calendar-alt" />
          </ExportButton>
        </CalendarWrapper>
      );
    }

    return (
      <Wrapper>
        <RenderedHtmlToTextArea node={node} placeholderText={CALENDAR} />
        <CreateChildren node={node} />
      </Wrapper>
    );
  }
}
