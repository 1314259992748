import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';

const Wrapper = styled.div`
  margin-right: 12px;
  text-align: center;
  border-radius: 4px;
  background-color: ${Constants.PrimaryColor};
  color: ${Constants.White};
  font-family: monospace !Important;
  font-size: ${Constants.SemiSmallFontSize};
`;

export default class CategoryAnalytics extends React.PureComponent {
  static propTypes = {
    category: PropTypes.object,
  }
  componentDidMount() {
    const { category } = this.props;
    if (!category) return;
    category.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { category } = this.props;
    category.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
  }
  render() {
    const { tree } = GrayOut;
    const { projectsAddedCount } = tree;
    const { category } = this.props;
    const answers = __recursivelyFindAnswers(category);
    if (answers.size === 0) return null;

    const percentage = Math.round((answers.size / projectsAddedCount) * 100);
    const wider = percentage === 100;
    const width = wider ? '40px' : '34px';
    return (
      <Wrapper style={{ width }}>
        {percentage}%
      </Wrapper>
    );
  }
}

function __recursivelyFindAnswers(child) {
  if (child.checked) return new Set(child.votes.map((v) => v.project));
  const answers = new Set();
  child.children.forEach((answerChild) => {
    const childAnswers = __recursivelyFindAnswers(answerChild);
    if (childAnswers) childAnswers.forEach((val) => { answers.add(val); });
  });
  return answers;
}
