/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import GrayOut from '../../services/TreeDisplay';
import WindowSize from '../../services/WindowSize';
import TreeCreateNode from '../../services/TreeCreateNode';
import Constants from '../../submodules/logictry_config/constants';
import Popup from '../../components/Popup/index';
import { ANSWER } from '../../models/nodetypes';
import NodeSettings from '../../components/NodeSettings';
import MenuItemTemplate from '../../components/Popup/MenuItem';

const Wrapper = styled.div`
  position: relative;
  border-radius: 8px;
  &:hover {
    > div:first-child {
      opacity: 1;
    }
  }
`;
const MoreIcon = styled.div`
  display: flex;
  position: absolute;
  margin: 0px 2px;
  height: 100%;
  fill: #b3b3b3;
  align-items: flex-start;
  justify-content: center;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 40px;
    height: 100%;
  }
  svg {
    height: 14px;
    width: 12px;
  }
`;
const DragIcon = styled.div`
  cursor: pointer;
`;
const HideTillHoverMoreIcon = styled(MoreIcon)`
  opacity: 0;
`;
const MoveTo = styled.div`
  position: absolute;
  background-color: rgba(0,0,0,0.2);
  pointer-events: none;
  border-radius: 4px;
`;
const MenuItem = styled(MenuItemTemplate)`
  > i {
    margin-right: 10px;
    font-size: ${Constants.SmallFontSize};
  }
`
const SectionOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 320px;
`;
const HoverOverlay = styled.div`
  border-radius: 8px;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  pointer-events: none;
`;

const moreIcon = (
  <svg viewBox="0 0 10 10"><path d="M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z"></path></svg>
);

export default class EditableDivWrapper extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    children: PropTypes.any,
    showAddNewAnswer: PropTypes.bool,
    onAddNewAnswer: PropTypes.func,
    onDeleteAnswer: PropTypes.func,
  }
  state = {
    open: false,
  }
  componentDidMount() {
    WindowSize.onStateUpdate(this);
    const { showAddNewAnswer } = this.props;
    if (showAddNewAnswer) document.addEventListener('keydown', this.keyDown);
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
    const { showAddNewAnswer } = this.props;
    if (showAddNewAnswer) document.removeEventListener('keydown', this.keyDown);
  }
  keyDown = (e) => {
    const { node } = this.props;
    if (e.key === 'Enter' && GrayOut.isActive(node)) this.onAddNew();
  }
  onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ open: true });
  }
  onClose = () => this.setState({ open: false });
  onDragStart = (ev) => {
    const { tree } = GrayOut;
    const { isDisabled } = tree;
    if (isDisabled || !this.dragEl) {
      ev.preventDefault();
      return false;
    }
    const { node } = this.props;
    const anchorRect = this.dragEl.getBoundingClientRect();
    if (ev.clientX > anchorRect.right || ev.clientX < anchorRect.left || ev.clientY < anchorRect.top || ev.clientY > anchorRect.bottom) {
      if (!TreeCreateNode.nodeToMove) ev.preventDefault();
      return false;
    }
    if (!TreeCreateNode.isNodeAllowedToMove(node)) {
      ev.preventDefault();
      return false;
    }
    if (this.wrapperEl) this.wrapperEl.style.backgroundColor = 'rgba(0,0,0,0.05)';
    var img = document.createElement("img");
    img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
    ev.dataTransfer.setDragImage(img, 0, 0);
    TreeCreateNode.initializeMove(node);
    return true;
  }
  onDrag = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  }
  onDragEnter = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  }
  onDragOver = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.__setActionPosition(ev, this.__getCurrentPosition(ev));
  }
  onDragLeave = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.__setActionPosition(ev);
  }
  onDragEnd = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    TreeCreateNode.cancelMove();
    if (this.wrapperEl) this.wrapperEl.style.backgroundColor = null;
  }
  onDrop = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.__setActionPosition(ev);
    const { node } = this.props;
    const { parents, parent } = node;
    const position = this.__getCurrentPosition(ev);
    if (position === 'middle') {
      TreeCreateNode.finishMove(node, parents);
    } else if (position === 'top') {
      const newIndex = parent.children.indexOf(node);
      TreeCreateNode.finishMove(parent, parents.slice(0, -1), newIndex);
    } else if (position === 'bottom') {
      const newIndex = parent.children.indexOf(node) + 1;
      TreeCreateNode.finishMove(parent, parents.slice(0, -1), newIndex);
    }
    if (this.wrapperEl) this.wrapperEl.style.backgroundColor = null;
  }
  onAddNew = () => {
    const { node, onAddNewAnswer } = this.props;
    const { parents, parent } = node;
    if (onAddNewAnswer) onAddNewAnswer(node);
    else {
      const question = parent;
      const currentIndex = question.children.indexOf(node);
      TreeCreateNode.createNode(ANSWER, question, parents, currentIndex + 1);
    }
    return this.setState({ open: false });
  }
  deleteChild = () => {
    const { node, onDeleteAnswer } = this.props;
    const { parents } = node;
    if (onDeleteAnswer) onDeleteAnswer(node);
    else  {
      const parent = parents[parents.length - 1];
      parent.deleteChild(node);
    }
    this.setState({ open: false });
  }
  __setActionPosition(ev, position) {
    const { node } = this.props;
    if (!this.moveToRef) return;
    if (TreeCreateNode.nodeToMove === node) return;
    this.moveToRef.style.top = ['top'].includes(position) ? '0px' : null;
    this.moveToRef.style.left = ['top', 'bottom'].includes(position) ? '28px' : '12px';
    this.moveToRef.style.right = ['top', 'bottom'].includes(position) ? '0px' : null;
    this.moveToRef.style.bottom = ['bottom'].includes(position) ? '0px' : null;
    this.moveToRef.style.height = ['top', 'bottom'].includes(position) ? '8px' : null
    this.wrapperEl.style.backgroundColor = ['middle'].includes(position) ? 'rgba(0,0,0,0.1)' : null;
  }
  __getCurrentPosition(ev) {
    const { node } = this.props;
    const { parents, parent } = node;
    if (TreeCreateNode.nodeToMove === node) return null;
    const childMoveAllowed = TreeCreateNode.isMoveAllowed(node, parents);
    const siblingMoveAllowed = TreeCreateNode.isMoveAllowed(parent, parents.slice(0, -1));
    if (!childMoveAllowed && !siblingMoveAllowed) return null;

    const { top, height } = ev.currentTarget.getBoundingClientRect();
    if (!childMoveAllowed) {
      const sectionHeight = height / 2;
      if (siblingMoveAllowed && ev.clientY > top + sectionHeight) return 'bottom';
      if (siblingMoveAllowed && ev.clientY > top) return 'top';
    }
    if (!siblingMoveAllowed) return 'middle';

    const sectionHeight = height / 3;
    if (siblingMoveAllowed && ev.clientY > top + sectionHeight * 2) return 'bottom';
    if (childMoveAllowed && ev.clientY > top + sectionHeight) return 'middle';
    if (siblingMoveAllowed && ev.clientY > top) return 'top';
    return null;
  }
  render() {
    const { mobile } = WindowSize;
    const { editing, tree } = GrayOut;
    const { isDisabled } = tree;
    const { children, showAddNewAnswer, node } = this.props;
    const expanded = GrayOut.isExpanded(node);
    const { parent } = node;
    const { open } = this.state;
    const { answers } = parent;
    const showDeleteChild = answers && answers.length > 1;
    const showChildrenHidden = editing && node.children.length > 0 && !expanded;
    return (
      <Wrapper
        ref={(e) => { this.wrapperEl = e; }}
        draggable={editing}
        onDragStart={this.onDragStart}
        onDragEnter={this.onDragEnter}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDragEnd={this.onDragEnd}
        onDrop={this.onDrop}
      >
        {(!isDisabled && (!editing && showAddNewAnswer) && (mobile ? <MoreIcon
          ref={(e) => { this.dragEl = e; }}
          onClick={this.onClick}
        >
          <div>{moreIcon}</div>
        </MoreIcon> : <HideTillHoverMoreIcon
          ref={(e) => { this.dragEl = e; }}
          onClick={this.onClick}
        >
          <div>{moreIcon}</div>
        </HideTillHoverMoreIcon>)) || (editing && <MoreIcon><DragIcon
          ref={(e) => { this.dragEl = e; }}
          onClick={this.onClick}
        >
          <div style={{ transform: showChildrenHidden && 'rotate(90deg)' || null, fill: showChildrenHidden && Constants.DarkTextColor || null }}>{moreIcon}</div>
        </DragIcon></MoreIcon>) || <div />}
        <div style={{ width: 0, height: 0 }} ref={(e) => { this.anchorEl = e; }} />
        {children}
        {editing && <HoverOverlay ref={(e) => { this.hoverOverlayEl = e; }} />}
        {editing && open && <Popup
          allowBackgroundClose
          onClose={this.onClose}
          anchorEl={this.anchorEl}
        >
          <div style={{ width: 320 }}>
            <NodeSettings node={node} onClose={this.onClose} />
          </div>
        </Popup>}
        {!editing && open && <Popup
          allowBackgroundClose
          onClose={this.onClose}
          anchorEl={this.anchorEl}
        >
          <SectionOptions>
            {showAddNewAnswer && <MenuItem onClick={this.onAddNew}>
              <div>New Answer</div>
            </MenuItem>}
            {showDeleteChild && <MenuItem onClick={this.deleteChild}>
              <div>Delete Answer</div>
            </MenuItem>}
          </SectionOptions>
        </Popup>}
        <MoveTo ref={(e) => { this.moveToRef = e; }} />
      </Wrapper>
    );
  }
}
