
import React from 'react';
import styled from 'styled-components';
import ContentDisplay from '../../services/ContentDisplay';
import Popup from '../../components/Popup/index';
import MenuList from '../../components/Popup/MenuList';
import MenuItem from '../../components/Popup/MenuItem';

const Wrapper = styled.div`
  display: flex;
  > i {
    padding: 6px;
  }
`;

export default class ListViewGridViewSelect extends React.PureComponent {
  state = {
    menuOpen: false,
  }
  componentDidMount() {
    ContentDisplay.onStateUpdate(this);
  }
  componentWillUnmount() {
    ContentDisplay.offStateUpdate(this);
  }
  onViewChange = (_viewAs) => {
    this.setState({ menuOpen: false });
    ContentDisplay.updateGridOrList(_viewAs);
  }
  handleClose = () => this.setState({ menuOpen: false });
  render() {
    const { gridOrList } = ContentDisplay;
    return (
      <div>
        <Wrapper role="button" onClick={() => this.setState({ menuOpen: true })} ref={(e) => { if (e) this.anchorEl = e; }}>
          {gridOrList === 'list' && <i className="fas fa-list" />}
          {gridOrList === 'grid' && <i className="fas fa-th" />}
          {gridOrList === 'grid-large' && <i className="fas fa-th-large" />}
          <i className="fas fa-angle-down" />
        </Wrapper>
        {this.state.menuOpen && <Popup
          allowBackgroundClose
          onClose={this.handleClose}
          anchorEl={this.anchorEl}
          anchorBottomRight
        >
          <MenuList>
            <MenuItem onClick={() => this.onViewChange('list')}>
              <i className="fas fa-list"></i>
              <div style={{ marginLeft: 10 }}>List</div>
            </MenuItem>
            <MenuItem onClick={() => this.onViewChange('grid')}>
              <i className="fas fa-th"></i>
              <div style={{ marginLeft: 10 }}>Grid - small</div>
            </MenuItem>
            <MenuItem onClick={() => this.onViewChange('grid-large')}>
              <i className="fas fa-th-large"></i>
              <div style={{ marginLeft: 10 }}>Grid - large</div>
            </MenuItem>
          </MenuList>
        </Popup>}
      </div>
    );
  }
}
