import React from 'react';
// import styled from 'styled-components';

import { AccountAssetsOpen, AccountAssetsInvitedOpen, getAccountAssetsSearch, AccountAssetsDeleted } from '../../services/Pagination/index';
import Box from './Box';
import ChangeableImage from '../../components/ChangeableImage/index';
import AssetCache from '../../services/cache/AssetCache';
import UserAccount from '../../services/UserAccount';
import Company from '../../services/Company';
import AssetSettings from '../../components/ObjectSettings/AssetSettings';
import AppConstants from '../../submodules/logictry_config/s3_config.json';

export default class AssetsBox extends Box {
  name = 'Assets';
  missingTitleText = '';
  paginationService = this.props.paginationService || this.props.showDeleted && AccountAssetsDeleted || (this.props.searchText || this.props.filterText) && getAccountAssetsSearch(this.props.searchText, null, this.props.filterText) || this.props.invitedTo && AccountAssetsInvitedOpen || AccountAssetsOpen;
  boxIcon = 'fas fa-table';
  open = (_tree) => {}
  createDiv = (width, height, borderRadius) => {
    let s3Path;
    let signingUrl;
    if (Company.company && Company.company._id) {
      s3Path = `${Company.company._id}/${UserAccount.account._id}/`;
      signingUrl = `/v1${AppConstants.S3.signingUrls.companyfiles}`;
    } else {
      s3Path = `${UserAccount.account._id}/`;
      signingUrl = `/v1${AppConstants.S3.signingUrls.userfiles}`;
    }
    return (
      <ChangeableImage
        s3Path={s3Path}
        signingUrl={signingUrl}
        additionalQueryParams={{
          team: this.props.team || '',
        }}
        style={{
          width,
          height,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          borderRadius,
          border: '1px dashed'
        }}
        onUploadFinish={() => {
          // const filename = _file.signedUrl.split('?')[0];
          // const asset = AssetCache.createNew(filename);
          // if (this.props.team) asset.addTeam(this.props.team);
          // AssetCache.create(asset);
          AssetCache.clearAccountObjects();
        }}
      />
    );
  }
  backgroundDiv = (_asset, width, height, borderRadius) => (
    <ChangeableImage
      style={{
        width,
        height,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius,
        border: '1px dashed'
      }}
      backgroundImage={_asset.link}
      backgroundColor={_asset.background && _asset.background.color}
      fileType={_asset.type}
      disableEditing
      showUrl
    />
  );
  objectSettings = AssetSettings;
}
