import Model from './model';
import { ADMIN_ROLE, VIEWER_ROLE } from './permissions';

export default class Team extends Model {
  /**
   * Constructor
   */
  constructor(_owner, _company, _users, _userLoggedIn, _id, _longname, _description, _createdTime, _updateTime, _isDevAccount, _shareSetting) {
    super();
    this._id = _id;
    if (_createdTime) this.createdTime = new Date(_createdTime);
    if (_updateTime) this.updateTime = new Date(_updateTime);
    this.userLoggedIn = _userLoggedIn;
    this.longname = _longname;
    this.description = _description
    this.owner = _owner;
    this.company = _company;
    this.users = _users;
    this.isDevAccount = _isDevAccount;
    this.shareSetting = _shareSetting || 'Private';
  }
  roles = [ADMIN_ROLE, VIEWER_ROLE];
  get isOwner() {
    return !!(this.userLoggedIn && (this.userLoggedIn === this.owner));
  }
  get isOwnerOrAdmin() {
    return !!(this.isDevAccount || this.userLoggedIn && (this.userLoggedIn === this.owner || this.users.find((_user) => (_user._id === this.userLoggedIn && _user.role === ADMIN_ROLE))));
  }
  get formattedUpdateTime() {
    if (this.updateTime) return this.updateTime.toLocaleString();
    return '';
  }
  addUser(_user, role) {
    if (this.users.find((user) => user._id === _user._id)) return;
    this.users.push({
      _id: _user._id,
      role,
    });
  }
  removeUser(_user) {
    const index = this.users.findIndex((user) => user._id === _user._id);
    if (index < 0) return;
    this.users.splice(index, 1);
  }
  getJson() {
    const { _id, longname, description, company, users, teams, shareSetting } = this;
    return {
      _id,
      longname,
      description,
      company,
      users,
      teams,
      shareSetting,
    };
  }
}
