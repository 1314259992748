/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import TreeDisplay from '../../services/TreeDisplay';

const Wrapper = styled.div`

`;

export default class FocusableDiv extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    children: PropTypes.any,
  }
  componentDidMount() {
    TreeDisplay.onStateUpdate(this.checkFocus);
  }
  componentWillUnmount() {
    TreeDisplay.offStateUpdate(this.checkFocus);
  }
  checkFocus = () => {
    const { node } = this.props;
    const { editing } = TreeDisplay;
    if (!editing && TreeDisplay.isActive(node) && this.ref) {
      this.ref.focus({ preventScroll: true });
    }
  }

  render() {
    const { children } = this.props;
    return (
      <Wrapper tabIndex={0} ref={(ref) => { this.ref = ref; this.checkFocus(); }}>
        {children}
      </Wrapper>
    );
  }
}
