/**
 * A link to a certain page, an anchor tag
 */

import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';

export default styled.div`
  max-width: ${Constants.MaxWidth};
  margin: auto;
  padding: 10px;
`;
