/* eslint-disable import/no-cycle */
import Service from '../Service';
import UserAccount from '../UserAccount';
import AssetCache from '../cache/AssetCache';
import SpreadsheetCache from '../cache/SpreadsheetCache';
import DatabaseCache from '../cache/DatabaseCache';
import DatabaseCollectionCache from '../cache/DatabaseCollectionCache';
import DatabaseCollectionDocumentCache from '../cache/DatabaseCollectionDocumentCache';
import TreeCache from '../cache/TreeCache';
import TeamCache from '../cache/TeamCache';
import CompanyCache from '../cache/CompanyCache';
import LogicBaseAppCache from '../cache/LogicBaseAppCache';
import LogicBaseCollectionCache from '../cache/LogicBaseCollectionCache';
import LogicBaseRelationshipCache from '../cache/LogicBaseRelationshipCache';
import LogicBaseSubmissionCache from '../cache/LogicBaseSubmissionCache';
import LogicBaseTopicCache from '../cache/LogicBaseTopicCache';
import LogicBaseUserCache from '../cache/LogicBaseUserCache';
import LogicBaseAppSaveCache from '../cache/LogicBaseAppSaveCache';
import LogicBaseCommentLikeCache from '../cache/LogicBaseCommentLikeCache';
import LogicBaseAppLikeCache from '../cache/LogicBaseAppLikeCache';
import LogicBaseCommentsCache from '../cache/LogicBaseCommentsCache';
import LogicBaseCollectionSubscriptionCache from '../cache/LogicBaseCollectionSubscriptionCache';
import LogicBaseNotificationCache from '../cache/LogicBaseNotificationCache';
import LinkCache from '../cache/LinkCache';

const company = window.logictry && window.logictry.company && window.logictry.company._id || '';

class Pagination extends Service {
  constructor(cacheCB, onStateUpdate, pageSize) {
    super();
    this.initialize();
    this.__cacheCB = cacheCB;
    onStateUpdate(this.__updatePage);
    this.pageSize = pageSize || 12;
  }
  initialize() {
    this.__currentPageTotal = 0;
    this.page = 0;
  }
  get currentPage() {
    if (!this.initialized) this.page = 1;
    return this.__getCurrentPage();
  }
  get initialized() {
    return this.page > 0;
  }
  getMore() {
    if (this.initialized && !this.showMore) return;
    this.page += 1;
    this.__getCurrentPage();
  }
  get showMore() {
    return this.page * this.pageSize === this.__currentPageTotal;
  }
  __updatePage = () => {
    if (!this.initialized) return;
    this.__getCurrentPage();
    this.emitStateUpdate();
  }
  __getCurrentPage() {
    let _currentPage = [];
    this.allFound = true;
    for (let i = 1; i <= this.page; i += 1) {
      if (this.allFound) {
        const _trees = this.__cacheCB(i);
        if (!_trees) this.allFound = false;
        else _currentPage = [..._currentPage, ..._trees];
      }
    }
    this.__currentPageTotal = _currentPage.length;
    return _currentPage;
  }
}



let logicBaseAppsSearch = {};
export function getLogicBaseAppsSearch(search) {
  const key = search;
  if (logicBaseAppsSearch[key]) return logicBaseAppsSearch[key];
  logicBaseAppsSearch[key] = LogicBaseAppsSearch(search);
  return logicBaseAppsSearch[key];
}
const LogicBaseAppsSearch = (search) => new Pagination((i) => LogicBaseAppCache.query({
  search,
  limit: 20,
  offset: (i - 1) * 20,
}), (cb) => LogicBaseAppCache.onStateUpdate(cb), 20);


export const AccountLinksOpen = new Pagination((i) => LinkCache.query({
  state: 'Live',
  company,
  page: i,
}), (cb) => LinkCache.onStateUpdate(cb));

export const AccountTemplatesOpen = new Pagination((i) => TreeCache.query({
  state: 'Live',
  company,
  type: 'Template',
  page: i,
}), (cb) => TreeCache.onStateUpdate(cb));
export const AccountTemplatesInvitedOpen = new Pagination((i) => TreeCache.query({
  state: 'Live',
  company,
  role: 'Invited',
  type: 'Template',
  page: i,
}), (cb) => TreeCache.onStateUpdate(cb));
export const AccountTemplatesDeleted = new Pagination((i) => TreeCache.query({
  state: 'Deleted',
  company,
  type: 'Template',
  page: i,
}), (cb) => TreeCache.onStateUpdate(cb));
export const AccountProjectsOpen = new Pagination((i) => TreeCache.query({
  state: 'Live',
  company,
  type: 'Project',
  page: i,
}), (cb) => TreeCache.onStateUpdate(cb));
export const AccountProjectsInvitedOpen = new Pagination((i) => TreeCache.query({
  state: 'Live',
  company,
  role: 'Invited',
  type: 'Project',
  page: i,
}), (cb) => TreeCache.onStateUpdate(cb));
export const AccountProjectsDeleted = new Pagination((i) => TreeCache.query({
  state: 'Deleted',
  company,
  type: 'Project',
  page: i,
}), (cb) => TreeCache.onStateUpdate(cb));
export const AccountSpreadsheetsOpen = new Pagination((i) => SpreadsheetCache.query({
  state: 'Live',
  company,
  page: i,
}), (cb) => SpreadsheetCache.onStateUpdate(cb));
export const AccountSpreadsheetsInvitedOpen = new Pagination((i) => SpreadsheetCache.query({
  state: 'Live',
  role: 'Invited',
  company,
  page: i,
}), (cb) => SpreadsheetCache.onStateUpdate(cb));
export const AccountSpreadsheetsDeleted = new Pagination((i) => SpreadsheetCache.query({
  state: 'Deleted',
  company,
  page: i,
}), (cb) => SpreadsheetCache.onStateUpdate(cb));
export const AccountDatabasesOpen = new Pagination((i) => DatabaseCache.query({
  state: 'Live',
  company,
  page: i,
}), (cb) => DatabaseCache.onStateUpdate(cb));
export const AccountDatabasesInvitedOpen = new Pagination((i) => DatabaseCache.query({
  state: 'Live',
  company,
  role: 'Invited',
  page: i,
}), (cb) => DatabaseCache.onStateUpdate(cb));
export const AccountDatabasesDeleted = new Pagination((i) => DatabaseCache.query({
  state: 'Deleted',
  company,
  page: i,
}), (cb) => DatabaseCache.onStateUpdate(cb));
export const AccountAssetsOpen = new Pagination((i) => AssetCache.query({
  state: 'Live',
  company,
  page: i,
}), (cb) => AssetCache.onStateUpdate(cb));
export const AccountAssetsInvitedOpen = new Pagination((i) => AssetCache.query({
  state: 'Live',
  company,
  role: 'Invited',
  page: i,
}), (cb) => AssetCache.onStateUpdate(cb));
export const AccountAssetsDeleted = new Pagination((i) => AssetCache.query({
  state: 'Deleted',
  company,
  page: i,
}), (cb) => AssetCache.onStateUpdate(cb));
export const AccountCompanies = new Pagination((i) => CompanyCache.query({
  company,
  page: i,
}), (cb) => CompanyCache.onStateUpdate(cb));



/**
 * Paginations that are cached
 */
let accountTeams;
export function getAccountTeams() {
  if (accountTeams) return accountTeams;
  accountTeams = AccountTeams();
  return accountTeams;
}
const AccountTeams = () => new Pagination((i) => TeamCache.query({
  user: UserAccount.account._id,
  page: i,
}), (cb) => TeamCache.onStateUpdate(cb));

let teamProjectsOpen = {};
export function getTeamProjectsOpen(team) {
  if (teamProjectsOpen[team]) return teamProjectsOpen[team];
  teamProjectsOpen[team] = TeamProjectsOpen(team);
  return teamProjectsOpen[team];
}
const TeamProjectsOpen = (team) => new Pagination((i) => TreeCache.query({
  team,
  state: 'Live',
  type: 'Project',
  page: i,
}), (cb) => TreeCache.onStateUpdate(cb));

let teamTemplatesOpen = {};
export function getTeamTemplatesOpen(team) {
  if (teamTemplatesOpen[team]) return teamTemplatesOpen[team];
  teamTemplatesOpen[team] = TeamTemplatesOpen(team);
  return teamTemplatesOpen[team];
}
const TeamTemplatesOpen = (team) => new Pagination((i) => TreeCache.query({
  team,
  state: 'Live',
  type: 'Template',
  page: i,
}), (cb) => TreeCache.onStateUpdate(cb));

let teamSpreadsheetsOpen = {};
export function getTeamSpreadsheetsOpen(team) {
  if (teamSpreadsheetsOpen[team]) return teamSpreadsheetsOpen[team];
  teamSpreadsheetsOpen[team] = TeamSpreadsheetsOpen(team);
  return teamSpreadsheetsOpen[team];
}
const TeamSpreadsheetsOpen = (team) => new Pagination((i) => SpreadsheetCache.query({
  team,
  state: 'Live',
  page: i,
}), (cb) => SpreadsheetCache.onStateUpdate(cb));

let teamDatabasesOpen = {};
export function getTeamDatabasesOpen(team) {
  if (teamDatabasesOpen[team]) return teamDatabasesOpen[team];
  teamDatabasesOpen[team] = TeamDatabasesOpen(team);
  return teamDatabasesOpen[team];
}
const TeamDatabasesOpen = (team) => new Pagination((i) => DatabaseCache.query({
  team,
  state: 'Live',
  page: i,
}), (cb) => DatabaseCache.onStateUpdate(cb));

let databaseCollections = {};
export function getDatabaseCollections(database) {
  if (databaseCollections[database]) return databaseCollections[database];
  databaseCollections[database] = DatabaseCollections(database);
  return databaseCollections[database];
}
const DatabaseCollections = (database) => new Pagination((i) => DatabaseCollectionCache.query({
  database,
  page: i,
}), (cb) => DatabaseCollectionCache.onStateUpdate(cb));

let databaseCollectionDocuments = {};
export function getDatabaseCollectionDocuments(databaseCollection) {
  if (databaseCollectionDocuments[databaseCollection]) return databaseCollectionDocuments[databaseCollection];
  databaseCollectionDocuments[databaseCollection] = DatabaseCollectionDocuments(databaseCollection);
  return databaseCollectionDocuments[databaseCollection];
}
const DatabaseCollectionDocuments = (collection) => new Pagination((i) => DatabaseCollectionDocumentCache.query({
  limit: 20,
  offset: (i - 1) * 20,
}, {
  collection,
}), (cb) => DatabaseCollectionDocumentCache.onStateUpdate(cb), 20);


let teamAssetsOpen = {};
export function getTeamAssetsOpen(team) {
  if (teamAssetsOpen[team]) return teamAssetsOpen[team];
  teamAssetsOpen[team] = TeamAssetsOpen(team);
  return teamAssetsOpen[team];
}
const TeamAssetsOpen = (team) => new Pagination((i) => AssetCache.query({
  team,
  state: 'Live',
  page: i,
}), (cb) => AssetCache.onStateUpdate(cb));

let templateProjects = {};
export function getTemplateProjects(referenceTree) {
  if (templateProjects[referenceTree]) return templateProjects[referenceTree];
  templateProjects[referenceTree] = TemplateProjects(referenceTree);
  return templateProjects[referenceTree];
}
const TemplateProjects = (referenceTree) => new Pagination((i) => TreeCache.query({
  referenceTree,
  page: i,
  count: 1000,
}), (cb) => TreeCache.onStateUpdate(cb), 1000);

let appComments = {};
export function getAppComments(app, comment) {
  const id = `${app}_${comment}`;
  if (appComments[id]) return appComments[id];
  appComments[id] = AppComments(app, comment);
  return appComments[id];
}
const AppComments = (app, comment) => new Pagination((i) => LogicBaseCommentsCache.query({
  a: app,
  c: comment,
  limit: 12,
  offset: (i - 1) * 12,
  sort: '-_id'
}), (cb) => LogicBaseCommentsCache.onStateUpdate(cb));


export const { getCache: getAccountAssetsSearch, initialize: initializeAccountAssetsSearch } = getSearchCache(AssetCache);
export const { getCache: getAccountSpreadsheetsSearch, initialize: initializeAccountSpreadsheetsSearch } = getSearchCache(SpreadsheetCache);
export const { getCache: getAccountTemplatesSearch, initialize: initializeAccountTemplatesSearch } = getSearchCache(TreeCache, { type: 'Template' });
export const { getCache: getAccountProjectsSearch, initialize: initializeAccountProjectsSearch } = getSearchCache(TreeCache, { type: 'Project' });
export const { getCache: getAccountDatabasesSearch, initialize: initializeAccountDatabasesSearch } = getSearchCache(DatabaseCache);
export const { getCache: getAccountDatabasecollectionsSearch, initialize: initializeAccountDatabasecollectionsSearch } = getSearchCache(DatabaseCollectionCache);
export const { getCache: getLogicBaseTopSavesApps, initialize: initializeLogicBaseTopSavesApps } = getCacheByKey(LogicBaseAppCache, '', {
  sort: '-s',
});
export const { getCache: getLogicBaseTopViewsApps, initialize: initializeLogicBaseTopViewsApps } = getCacheByKey(LogicBaseAppCache, '', {
  sort: '-v',
});
export const { getCache: getLogicBaseTopLikesApps, initialize: initializeLogicBaseTopLikesApps } = getCacheByKey(LogicBaseAppCache, '', {
  sort: '-l',
});
export const { getCache: getLogicBaseLatestApps, initialize: initializeLogicBaseLatestApps } = getCacheByKey(LogicBaseAppCache, '', {
  sort: '-_id',
});
export const { getCache: getUserPublicApps, initialize: initializeUserPublicApps } = getCacheByKey(LogicBaseAppCache, 'o', {
  sort: '-_id',
});
export const { getCache: getLatestSubmissions, initialize: initializeLatestSubmissions } = getCacheByKey(LogicBaseSubmissionCache, '', {
  sort: '-_id',
});
export const { getCache: getUserPublicSubmissions, initialize: initializeUserPublicSubmissions } = getCacheByKey(LogicBaseSubmissionCache, 'o', {
  sort: '-_id',
});
export const { getCache: getLogicBaseTopicApps, initialize: initializeLogicBaseTopicApps } = getCacheByKey(LogicBaseAppCache, 'ts', {
  sort: '-l',
});
export const { getCache: getLogicBaseGeoApps, initialize: initializeLogicBaseGeoApps } = getCacheByKey(LogicBaseAppCache, ['g', 'ts'], {
  sort: '-l',
});
export const { getCache: getTrendingTopics, initialize: initializeTrendingTopics } = getCacheByKey(LogicBaseTopicCache, '', {
  sort: '-ut',
});
export const { getCache: getTopLikedTopics, initialize: initializeTopLikedTopics } = getCacheByKey(LogicBaseTopicCache, '', {
  sort: '-a',
});
export const { getCache: getUserAppSaves, initialize: initializeUserAppSaves } = getCacheByKey(LogicBaseAppSaveCache, 'fr', {
  sort: '-_id',
});
export const { getCache: getAppLikes, initialize: initializeAppLikes } = getCacheByKey(LogicBaseAppLikeCache, 'a', {
  sort: '-_id',
});
export const { getCache: getCommentLikes, initialize: initializeCommentLikes } = getCacheByKey(LogicBaseCommentLikeCache, 'c', {
  sort: '-_id',
});
export const { getCache: getTopPostedUsers, initialize: initializeTopPostedUsers } = getCacheByKey(LogicBaseUserCache, '', {
  sort: '-a',
});
export const { getCache: getTopPinnedUsers, initialize: initializeTopPinnedUsers } = getCacheByKey(LogicBaseUserCache, '', {
  sort: '-p',
});
export const { getCache: getTopLikedUsers, initialize: initializeTopLikedUsers } = getCacheByKey(LogicBaseUserCache, '', {
  sort: '-fr',
});
export const { getCache: getTopDecisionsUsers, initialize: initializeTopDecisionsUsers } = getCacheByKey(LogicBaseUserCache, '', {
  sort: '-s',
});
export const { getCache: getUserFollowRequests, initialize: initializeUserFollowRequests } = getCacheByKey(LogicBaseRelationshipCache, 'fe', {
  r: true,
  sort: '-_id',
});
export const { getCache: getUserFollowers, initialize: initializeUserFollowers } = getCacheByKey(LogicBaseRelationshipCache, 'fe', {
  r: false,
  sort: '-_id',
});
export const { getCache: getUserFollowees, initialize: initializeUserFollowees } = getCacheByKey(LogicBaseRelationshipCache, 'fr', {
  r: false,
  sort: '-_id',
});
export const { getCache: getNotifications, initialize: initializeNotifications } = getCacheByKey(LogicBaseNotificationCache, 'o', {
  sort: '-_id',
});
export const { getCache: getUnreadNotifications, initialize: initializeUnreadNotifications } = getCacheByKey(LogicBaseNotificationCache, 'o', {
  r: false,
  sort: '-_id',
});
export const { getCache: getCollectionSubscribers, initialize: initializeCollectionSubscribers } = getCacheByKey(LogicBaseCollectionSubscriptionCache, 'c', {
  sort: '-_id',
});
export const { getCache: getCollectionsSubscribed, initialize: initializeCollectionsSubscribed } = getCacheByKey(LogicBaseCollectionSubscriptionCache, 'fr', {
  sort: '-_id',
});
export const { getCache: getCollectionMostFollowers, initialize: initializeCollectionMostFollowers } = getCacheByKey(LogicBaseCollectionCache, '', {
  ss: true,
  sort: '-fr',
});
export const { getCache: getCollectionMostRecentlyUpdated, initialize: initializeCollectionMostRecentlyUpdated } = getCacheByKey(LogicBaseCollectionCache, '', {
  ss: true,
  sort: '-ut',
});
export const { getCache: getAccountCollections, initialize: initializeAccountCollections } = getCacheByKey(LogicBaseCollectionCache, ['o', 'e'], {
  condition: 'or',
  sort: '-ut',
});

let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if ((UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') || (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn')) {
    AccountTemplatesOpen.initialize();
    AccountTemplatesDeleted.initialize();
    AccountProjectsOpen.initialize();
    AccountProjectsDeleted.initialize();
    AccountSpreadsheetsOpen.initialize();
    AccountSpreadsheetsDeleted.initialize();
    AccountAssetsOpen.initialize();
    AccountAssetsDeleted.initialize();
    AccountCompanies.initialize();
    initializeUserPublicApps();
    initializeLogicBaseTopicApps();
    initializeLogicBaseGeoApps();
    initializeTopLikedTopics();
    initializeUserAppSaves();
    initializeAppLikes();
    initializeCommentLikes();
    initializeTopLikedUsers();
    initializeTopDecisionsUsers();
    initializeTopPostedUsers();
    initializeTopPinnedUsers();
    initializeLogicBaseLatestApps();
    initializeLogicBaseTopLikesApps();
    initializeLogicBaseTopSavesApps();
    initializeLogicBaseTopViewsApps();
    initializeAccountAssetsSearch();
    initializeAccountSpreadsheetsSearch();
    initializeAccountDatabasecollectionsSearch();
    initializeAccountDatabasesSearch();
    initializeAccountProjectsSearch();
    initializeAccountTemplatesSearch();
    initializeUserFollowRequests();
    initializeUserFollowers();
    initializeUserFollowees();
    initializeNotifications();
    initializeUnreadNotifications();
    initializeCollectionSubscribers();
    initializeCollectionsSubscribed();
    initializeLatestSubmissions();
    initializeUserPublicApps();
    initializeCollectionMostFollowers();
    initializeCollectionMostRecentlyUpdated();
    initializeAccountCollections();

    accountTeams = null;
    teamProjectsOpen = {};
    teamTemplatesOpen = {};
    teamSpreadsheetsOpen = {};
    teamDatabasesOpen = {};
    databaseCollections = {};
    databaseCollectionDocuments = {};
    teamAssetsOpen = {};
    templateProjects = {};
    logicBaseAppsSearch = {};
    appComments = {}
  }
  _lastAccountState = UserAccount.state;
});

function getSearchCache(cache, keys) {
  let cacheByKey = {};

  const getCache = (search, team, filters) => {
    const key = `${search}_${team}_${JSON.stringify(filters)}`;
    if (cacheByKey[key]) return cacheByKey[key];
    cacheByKey[key] = getPagination(search, team, filters);
    return cacheByKey[key];
  }

  function getPagination(search, team, filters) {
    return new Pagination((i) => cache.query({
      search,
      team: team || '',
      ...(keys || {}),
      ...(filters || {}),
      company,
      state: 'Live',
      page: i,
    }), (cb) => cache.onStateUpdate(cb));
  }

  const initialize = () => {
    cacheByKey = {};
  };

  return { getCache, initialize };
}
function getCacheByKey(cache, keyKey, query) {
  let cacheByKey = {};

  const queryByKey = (key, filter) => new Pagination((i) => {
    const cacheQuery = {
      limit: 20,
      offset: (i - 1) * 20,
      ...query
    }
    if (Array.isArray(keyKey)) {
      keyKey.forEach((_keyKey, i) => {
        cacheQuery[filter ? `${_keyKey}[${filter}]` : _keyKey] = Array.isArray(key) ? key[i] : key;
      });
    } else {
      cacheQuery[filter ? `${keyKey}[${filter}]` : keyKey] = key;
    }
    return cache.query(cacheQuery)
  }, (cb) => cache.onStateUpdate(cb), 20);

  const getCache = (key, filter) => {
    const keyIndex = `${key}[${filter}]`;
    if (cacheByKey[keyIndex]) return cacheByKey[keyIndex];
    cacheByKey[keyIndex] = queryByKey(key, filter);
    return cacheByKey[keyIndex];
  }

  const initialize = () => {
    cacheByKey = {};
  };

  return { getCache, initialize };
}
