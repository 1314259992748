import Answerable from './answerable';
import { QUESTION } from './nodetypes';
import { STRING_MATCH_ATTR, GREATER_THAN_ATTR, LESS_THAN_ATTR, IGNORE_FOR_EACH_ANSWER_ATTR, NONE_ATTR, NUMBER_ATTR } from './nodeattributes';

export default class Answer extends Answerable {
  constructor(_text, _attributes, _children, _owner, _votes, _cloned, _project) {
    /**
     * Children can be answers and categories
     */
    super(_text, _attributes, _children, _owner);
    this.cloned = _cloned;
    /**
     * The answer if new has an owner
     * Votes are the users that have upvoted the answer
     */
    this.votes = JSON.parse(JSON.stringify(_votes || []));
    if (_project) this.votes.forEach((vote) => {
      // eslint-disable-next-line no-param-reassign
      vote.project = _project; 
    });

    this.__defineNodeAttribute('inputType', [{ name: 'No Compare', value: NONE_ATTR, default: true }, { name: 'Greater Than', value: GREATER_THAN_ATTR }, { name: 'Less Than', value: LESS_THAN_ATTR }])
  }
  get formattedText() {
    if (this.isNumber) return __addCommasToNumber(this.text);
    return this.text;
  }
  get questions() {
    return this.children.filter((_child) => _child.isType(QUESTION));
  }
  get stringMatch() {
    return this.attributes.includes(STRING_MATCH_ATTR);
  }
  get isNumber() {
    const { userInputType } = this.parent;
    return (userInputType === NUMBER_ATTR || this.greaterThan || this.lessThan);
  }
  get greaterThan() {
    return this.attributes.includes(GREATER_THAN_ATTR);
  }
  get lessThan() {
    return this.attributes.includes(LESS_THAN_ATTR);
  }
  get filter() {
    return this.stringMatch || this.greaterThan || this.lessThan;
  }
  get ignoreForEachAnswer() {
    return this.attributes.includes(IGNORE_FOR_EACH_ANSWER_ATTR);
  }
  get isUserAnswer() {
    return !this.text || this.owner;
  }
  checkedChanged = () => {
    this.__updateDirectParentOnNodeUpdated('AnswerChanged');
  }
  clone() {
    return new Answer(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner, this.votes, this);
  }
  __autofillChildren(_children, _autofill) {
    if (!_autofill) return;
    _children.forEach((_child) => {
      if (_child.isType(QUESTION) && _child.openEnded && !_child.multipleAnswers && _child.answers.length === 1) {
        // eslint-disable-next-line no-prototype-builtins
        if (_autofill.hasOwnProperty(_child.text)) {
          const answerText = _autofill[_child.text];
          if (answerText) _child.answers[0].updateText(answerText);
          else _child.answers[0].updateText('');
        }
      }
    });
  }
  isMatchingAttributes(_node) {
    if (this.attributes.includes(STRING_MATCH_ATTR) !== _node.attributes.includes(STRING_MATCH_ATTR)) return false;
    if (this.attributes.includes(GREATER_THAN_ATTR) !== _node.attributes.includes(GREATER_THAN_ATTR)) return false;
    if (this.attributes.includes(LESS_THAN_ATTR) !== _node.attributes.includes(LESS_THAN_ATTR)) return false;
    return true;
  }
  isMatchingNode(_node) {
    if (!this.isMatchingAttributes(_node)) return false;
    return super.isMatchingNode(_node);
  }
}
function __addCommasToNumber(numb) {
  const str = numb.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
}
