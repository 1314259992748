import ObjectModel from './objectmodel';
import Meta from './meta';
import { DESCRIPTION, TITLE } from './nodetypes';

export default class Link extends ObjectModel {
  constructor(_id, _owner, _users, _teams, _userLoggedIn, _title, _description, _link, _createdTime, _updateTime, _state, _shareSetting, _version, _company, _background) {
    super();
    this._id = _id;
    this.title = new Meta(_title || '', [TITLE], [], null, this.userLoggedIn);
    this.description = new Meta(_description || '', [DESCRIPTION], [], null, this.userLoggedIn);
    if (_createdTime) this.createdTime = new Date(_createdTime);
    if (_updateTime) this.updateTime = new Date(_updateTime);
    this.userLoggedIn = _userLoggedIn;
    this.owner = _owner;
    this.users = _users || [];
    this.teams = _teams || [];
    this.state = _state;
    this.shareSetting = _shareSetting || 'LinkView';
    this.version = _version || 1;
    this.company = _company || '';
    this.background = _background;
    this.link = _link;
  }
  updateLink = (_link) => {
    this.link = _link;
  }
  getJson = () => {
    const { _id, owner, title, description, version, state, shareSetting, users, teams, company, background, link } = this;
    const json = {
      _id,
      owner,
      title: title.text,
      description: description.text,
      version,
      state,
      shareSetting,
      users,
      teams,
      background,
      link,
      company
    };
    return json;
  }
}
