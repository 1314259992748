/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import styled from 'styled-components';
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Constants from '../../submodules/logictry_config/constants';
import UserAccount from '../../services/UserAccount';
import Navigation from '../../services/Navigation';
import SidebarService from '../../services/SidebarService';
import TreeCache from '../../services/cache/TreeCache';
import AssetCache from '../../services/cache/AssetCache';
import DatabaseCache from '../../services/cache/DatabaseCache';
import DatabaseCollectionCache from '../../services/cache/DatabaseCollectionCache';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import Connect from '../../services/Connect';
import SpreadsheetCache from '../../services/cache/SpreadsheetCache';
import GoogleSpreadsheetCache from '../../services/cache/GoogleSpreadsheetCache';
import TeamCache from '../../services/cache/TeamCache';
import CompanyCache from '../../services/cache/CompanyCache';
import { PropTypes } from 'prop-types';

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  // text-decoration: underline;
  > * {
    margin: 4px;
    font-size: ${Constants.SmallFontSize};
  }
`;

export default class Status extends React.PureComponent {
  static propTypes = {
    hideStatusUpdates: PropTypes.bool,
  }
  state = {
    status: '',
    success: null,
    error: null,
  }
  componentDidMount() {
    Navigation.onStateUpdate(this.navigationChanged);
    // Handlers
    const onCreate = () => this.setState({ status: 'Creating' });
    const onCreateFail = () => this.showError('Create failed');
    const onUpdate = () => this.setState({ status: 'Saving' });
    const onUpdateSuccess = () => this.showSuccess(`Saved ${(new Date()).toLocaleTimeString({ hour: true, minutes: true })}`);
    const onUpdateFail = (result) => {
      if (!result) return this.showError('Save failed');
      if (result.error === 'IncorrectVersion') {
        this.showError('Save failed - updated to newer version');
        return toast.error('Save failed');
      }
      if (result.error === 'Locked') return this.showError('Locked - unlock to save');
      if (result.error === 'CheckoutFailed') return this.showError('Failed to start editing');
      if (result.error === 'CheckinFailed') return this.showError('Failed to finish editing');
      if (result.error === 'UserNotAuthorized') return this.showError('You are not authorized to edit');
      if (result.error === 'CheckOutRequired') return this.showError('Click start editing to edit');
      return this.showError('Save failed');
    };
    const onDelete = () => this.setState({ status: 'Deleting' });
    const onDeleteSuccess = () => this.showSuccess('Deleted');
    const onDeleteFail = (result) => {
      if (!result) return this.showError('Delete failed');
      if (result.error === 'Locked') return this.showError('Delete failed - unlock first to delete');
      if (result.error === 'UnpublishAppFirst') {
        this.showError('Delete failed - unpublish first to delete');
        return toast.error('Delete failed - you must unpublish first to delete');
      }
      return this.showError('Delete failed');
    }
    const onTreeCreateSuccess = (_tree) => {
      if (!_tree._id) return this.showError('Failed to create');
      if (_tree.type === 'Project') {
        if (SidebarService.selectType === 'Tree') Navigation.replace(`${Navigation.trees}/${_tree._id}${window.location.search}`);
        else if (SidebarService.selectType === 'Text') Navigation.push(`${Navigation.trees}/${_tree._id}${window.location.search}`);
        return this.showSuccess(`Created!`);
      }
      if (_tree.type === 'Template') {
        Navigation.push(`${Navigation.trees}/${_tree._id}/edit`);
        return this.showSuccess(`Created!`);
      }
      return this.clearAll();
    };

    // UserAccount
    UserAccount.onPasswordChanged = () => this.showSuccess('Password changed');

    // TreeCache
    TreeCache.onCreate = onCreate;
    TreeCache.onCreateSuccess = onTreeCreateSuccess;
    TreeCache.onCreateFail = onCreateFail;
    TreeCache.onUpdate = onUpdate;
    TreeCache.onUpdateSuccess = onUpdateSuccess;
    TreeCache.onUpdateFail = onUpdateFail;
    TreeCache.onDelete = onDelete;
    TreeCache.onDeleteSuccess = onDeleteSuccess;
    TreeCache.onDeleteFail = onDeleteFail;

    Connect.onTreeCacheUpdate = (status, data) => {
      if (status === 'onCreate') onCreate();
      if (status === 'onCreateSuccess') onTreeCreateSuccess(data);
      if (status === 'onCreateFail') onCreateFail();
      if (status === 'onUpdate') onUpdate();
      if (status === 'onUpdateSuccess') onUpdateSuccess();
      if (status === 'onUpdateFail') onUpdateFail(data);
      if (status === 'onDelete') onDelete();
      if (status === 'onDeleteSuccess') onDeleteSuccess();
      if (status === 'onDeleteFail') onDeleteFail(data);
    };

    // AssetCache
    AssetCache.onCreate = onCreate;
    AssetCache.onCreateSuccess = () => this.showSuccess(`Created!`);
    AssetCache.onCreateFail = onCreateFail;
    AssetCache.onUpdate = onUpdate;
    AssetCache.onUpdateSuccess = onUpdateSuccess;
    AssetCache.onUpdateFail = onUpdateFail;
    AssetCache.onDelete = onDelete;
    AssetCache.onDeleteSuccess = onDeleteSuccess;
    AssetCache.onDeleteFail = onDeleteFail;

    // SpreadsheetCache
    SpreadsheetCache.onCreate = onCreate;
    SpreadsheetCache.onCreateSuccess = (_spreadsheet) => {
      if (!_spreadsheet._id) return this.showError('Failed to create');
      Navigation.push(`${Navigation.spreadsheets}/${_spreadsheet._id}`);
      return this.showSuccess(`Created!`);
    };
    SpreadsheetCache.onCreateFail = onCreateFail;
    SpreadsheetCache.onUpdate = onUpdate;
    SpreadsheetCache.onUpdateSuccess = onUpdateSuccess;
    SpreadsheetCache.onUpdateFail = onUpdateFail;
    SpreadsheetCache.onDelete = onDelete;
    SpreadsheetCache.onDeleteSuccess = onDeleteSuccess;
    SpreadsheetCache.onDeleteFail = onDeleteFail;

    // Google SpreadsheetCache
    GoogleSpreadsheetCache.onUpdate = onUpdate;
    GoogleSpreadsheetCache.onUpdateSuccess = onUpdateSuccess;
    GoogleSpreadsheetCache.onUpdateFail = onUpdateFail;

    // TeamCache
    TeamCache.onCreate = onCreate;
    TeamCache.onCreateSuccess = (_team) => {
      if (!_team._id) return this.showError('Failed to create');
      return this.showSuccess(`Created!`);
    };
    TeamCache.onCreateFail = onCreateFail;
    TeamCache.onUpdate = onUpdate;
    TeamCache.onUpdateSuccess = onUpdateSuccess;
    TeamCache.onUpdateFail = onUpdateFail;
    TeamCache.onDelete = onDelete;
    TeamCache.onDeleteSuccess = onDeleteSuccess;
    TeamCache.onDeleteFail = onDeleteFail;

    // CompanyCache
    CompanyCache.onUpdate = onUpdate;
    CompanyCache.onUpdateSuccess = onUpdateSuccess;
    CompanyCache.onUpdateFail = onUpdateFail;
    CompanyCache.onDelete = onDelete;
    CompanyCache.onDeleteSuccess = onDeleteSuccess;
    CompanyCache.onDeleteFail = onDeleteFail;

    // DatabaseCache
    DatabaseCache.onCreate = onCreate;
    DatabaseCache.onCreateSuccess = (_database) => {
      if (!_database._id) return this.showError('Failed to create');
      Navigation.push(`${Navigation.databases}/${_database._id}`);
      return this.showSuccess(`Created!`);
    };
    DatabaseCache.onCreateFail = onCreateFail;
    DatabaseCache.onUpdate = onUpdate;
    DatabaseCache.onUpdateSuccess = onUpdateSuccess;
    DatabaseCache.onUpdateFail = onUpdateFail;
    DatabaseCache.onDelete = onDelete;
    DatabaseCache.onDeleteSuccess = onDeleteSuccess;
    DatabaseCache.onDeleteFail = onDeleteFail;

    // DatabaseCollectionCache
    DatabaseCollectionCache.onCreate = onCreate;
    DatabaseCollectionCache.onCreateSuccess = (_databaseCollection) => {
      if (!_databaseCollection._id) return this.showError('Failed to create');
      Navigation.push(`${Navigation.databaseCollections}/${_databaseCollection._id}`);
      return this.showSuccess(`Created!`);
    };
    DatabaseCollectionCache.onCreateFail = onCreateFail;
    DatabaseCollectionCache.onUpdate = onUpdate;
    DatabaseCollectionCache.onUpdateSuccess = onUpdateSuccess;
    DatabaseCollectionCache.onUpdateFail = onUpdateFail;
    DatabaseCollectionCache.onDelete = onDelete;
    DatabaseCollectionCache.onDeleteSuccess = onDeleteSuccess;
    DatabaseCollectionCache.onDeleteFail = onDeleteFail;

    LogicBaseCollectionCache.onCreateSuccess = (_collection) => {
      if (!_collection._id) return this.showError('Failed to create');
      Navigation.push(`${Navigation.collections}/${_collection.u}`);
      return this.showSuccess(`Created!`);
    };
  }
  componentWillUnmount() {
    Navigation.offStateUpdate(this.navigationChanged);
  }
  navigationChanged = () => {
    this.clearAll();
  }
  showSuccess = (success) => this.setState({ success, error: null });
  showError = (error) => this.setState({ error, success: null });
  clearAll = () => this.setState({ error: null, success: null, status: '' });

  render() {
    const { hideStatusUpdates } = this.props;
    const { status, success, error } = this.state;
    if (hideStatusUpdates) return null;
    return (
      <StatusWrapper>
        {error && (
          <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>
        ) || success && (
          <div>{success}</div>
        ) || status && (
          <>
            <i className="fas fa-spinner fa-pulse" />
            <div>{status}</div>
          </>
        )}
        <ToastContainer
          position="top-right"
          autoClose={1800}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
      </StatusWrapper>
    );
  }
}
