export default class Service {
  _observers = new Set();

  get observerCount() {
    return this._observers.size;
  }

  onStateUpdate(cb) {
    this._observers.add(cb);
  }
  offStateUpdate(cb) {
    this._observers.delete(cb);
  }

  emitStateUpdate(_update) {
    this._observers.forEach((o) => {
      if (o.forceUpdate) return o.forceUpdate();
      return o(this, _update);
    });
  }
}
