/* eslint-disable no-nested-ternary */
import React from 'react';

import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';
import ClickableIcon from '../../components/ClickableIcon/index';


const ModalWrapper = styled.div`
  position: relative;
`;
const FullscreenButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: ${Constants.White};
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
`;
const FullscreenWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 4;
  display: flex;
  background-color: rgba(0,0,0,0.7);
  align-items: center;
  justify-content: center;
  > div {
    background-color: ${Constants.White};
  }
  > div:last-child {
    border-radius: 8px;
    padding-top: ${Constants.TreeContentHalfIndent};
    padding-right: ${Constants.TreeContentIconIndent};
    padding-bottom: ${Constants.TreeContentHalfIndent};
  }
`;

export default class ModalBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  }
  state = {
    fullscreen: false,
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
  }
  render() {
    const { node } = this.props;
    const { fullscreen } = this.state;
    return (
      <div>
        {fullscreen && <FullscreenWrapper>
          <FullscreenButton style={{ zIndex: 1000 }}>
            <ClickableIcon small onClick={() => this.setState({ fullscreen: false })} icon="fas fa-compress" />
          </FullscreenButton>
          <div>
            <CreateChildren node={node} />
          </div>
        </FullscreenWrapper>}
        <ModalWrapper>
          {!fullscreen && <FullscreenButton style={{ zIndex: 1000 }}>
            <ClickableIcon small onClick={() => this.setState({ fullscreen: true })} icon="fas fa-expand" />
          </FullscreenButton>}
          <CreateChildren node={node} />
        </ModalWrapper>
      </div>
    );
  }
}
