import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import CreateChildren from './CreateChildren';
import RenderedHtmlToTextArea from '../../components/RenderedHtmlToTextArea/index';

const Wrapper = styled.div`
  margin-left: ${Constants.TreeContentIconIndent};
`;
const RootWrapper = styled.div`
  > div:first-child {
    margin: ${Constants.defaultLinePadding} 0px;
  }
  display: flex;
  align-items: center;
  &:hover {
    opacity: 1 !Important;
  }
`;

export default class RootOrBranchesBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    placeholder: PropTypes.string,
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
  }
  changeValue = (value) => {
    const { node } = this.props;
    node.updateText(value);
  }
  render() {
    const { node, placeholder } = this.props;
    const expanded = GrayOut.isExpanded(node);
    return (
      <Wrapper>
        {<RootWrapper>
          <RenderedHtmlToTextArea node={node} placeholderText={placeholder} />
        </RootWrapper>}
        {(expanded) && <CreateChildren node={node} />}
      </Wrapper>
    );
  }
}
