import Node from './node';
import { FIXED_ATTR, NONE_ATTR, OPEN_ENDED_ATTR, REQUIRED_ATTR } from './nodeattributes';
import { FILE } from './nodetypes';
const selectOption = [{ name: 'Fixed Choices', value: FIXED_ATTR }, { name: 'Open Ended', value: OPEN_ENDED_ATTR }];

export default class FileGroup extends Node {
  constructor(_text, _attributes, _children, _owner) {
    /**
     * Children can be answers and categories
     */
    super(_text, _attributes, _children, _owner);
    this.showRequiredText = false;
    this.__defineNodeAttribute('required', [{ name: 'Optional', value: NONE_ATTR, default: true }, { name: REQUIRED_ATTR, value: REQUIRED_ATTR }]);
  }
  get selectOption() {
    return selectOption;
  }
  get openEnded() {
    return this.attributes.indexOf(OPEN_ENDED_ATTR) > 0;
  }
  get isRequired() {
    return this.attributes.includes(REQUIRED_ATTR);
  }
  get files() {
    return this.children.filter((_child) => _child.isType(FILE));
  }
  get answersChecked() {
    return this.children.filter((a) => a.checked);
  }
  onNodeUpdated(node, update) {
    if (!node.isOneOfTypes([FILE])) return null;
    if (update === 'AnswerChanged') {
      this.__updateAllParentsOnNodeUpdated();
      this.__updateTreeOnNodeUpdated();
      this.showRequiredText = false;
      return this.emitStateUpdate();
    }
  }
  addChild(_child, _index) {
    super.addChild(_child, _index);
    if (_child.text) {
      const index = this.children.findIndex((child) => !child.text);
      if (typeof index === 'number' && index >= 0) this.children.splice(index, 1);
    }
  }
  updateShowRequiredText(_showRequired) {
    this.showRequiredText = _showRequired;
    this.emitStateUpdate();
  }
  updateAnswerChoiceType(_attribute) {
    this.attributes = this.attributes.filter((_attr) => _attr !== FIXED_ATTR && _attr !== OPEN_ENDED_ATTR);
    this.addAttribute(_attribute);
  }
  clone() {
    return new FileGroup(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
}
