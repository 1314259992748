import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import { getLogicBaseAppsSearch } from '../../services/Pagination';
import WindowSize from '../../services/WindowSize';
import { PropTypes } from 'prop-types';

const Search = styled.div`
  width: 100%;
  max-width: 38rem;
  position: relative;
  overflow: hidden;
  > div:first-child {
    width: 100%;
    position: relative;
    background: white;
  }
  input {
    overflow: hidden;
    line-height: 18px;
    width: 100%;
  }
  i:first-child {
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
    background: white;
    color: #333;
    font-size: 14px;
    line-height: 20px;
  }
  i:last-child {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 18px;
    color: #C1C1C1;
    cursor: pointer;
    background: white;
  }
`;
const Trending = styled.div`
  padding: 0 1rem;
  overflow: scroll;
  background: white;
  > div {
    padding: 0.5rem 0;
    > div {
      padding: 0.5rem 0;
      cursor: pointer;
    }
    > div:hover {
      text-decoration: underline;
    }
  }
`;
const ClearIcon = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-size: 10px 10px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  width: 38px;
  height: 38px;
`

let searches = [];

export default class LogicBaseSearchBar extends React.PureComponent {
  static propTypes = {
    showTyping: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onSearching: PropTypes.func,
  }
  state = {
    showText: true,
    showSuggestions: false,
    defaultValue: Navigation.currentLocation[1] === 'search' && Navigation.currentLocation[2] && unescape(Navigation.currentLocation[2]) || Navigation.currentSearch.q || '',
    initialAutoFocus: false,
  }
  componentDidMount() {
    WindowSize.onStateUpdate(this);
    window.addEventListener('blur', this.windowBlurred);
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
    if (this.appsCache) this.appsCache.offStateUpdate(this);
    window.removeEventListener('blur', this.windowBlurred);
    const { mobile } = WindowSize;
    if (mobile) {
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
    }
  }
  windowBlurred = () => {
    this.endSearching();
  }
  onscroll = () => {
    if (this.__input) this.__input.blur();
  }
  onKeyDown = (e) => {
    if (e.key === 'Enter') this.onSearch();
    this.getTrending();
  }
  getTrending = () => {
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(this.performSearch, 250);
  }
  performSearch = () => {
    if (this.appsCache) this.appsCache.offStateUpdate(this);
    if (this.__input && this.__input.value) this.appsCache = getLogicBaseAppsSearch(encodeURIComponent(this.__input.value.trim()));
    // else this.appsCache = getLogicBaseLatestApps();
    if (this.appsCache) this.appsCache.onStateUpdate(this);
    this.forceUpdate();
  }
  onSearch = () => {
    if (!this.__input) return;
    const search = this.__input.value.trim();
    if (search) {
      if (Constants.isIphone) Navigation.push(`/search/${encodeURIComponent(search)}`);
      else Navigation.push(`/search?q=${encodeURIComponent(search)}`);
    }
    else Navigation.push('/search');
    this.endSearching();
  }
  endSearching = () => {
    const { onSearching } = this.props;
    if (this.__input) this.__input.blur();
    this.setState({ showText: !this.__input.value, showSuggestions: false });
    onSearching(false);
  }
  render() {
    const { mobile } = WindowSize;
    const { autoFocus, onSearching } = this.props;
    const { defaultValue, showSuggestions, initialAutoFocus } = this.state;
    if (!showSuggestions) {
      searches = [];
    }
    if (this.appsCache) {
      const latestSearch = this.appsCache.currentPage;
      searches = this.appsCache.allFound && latestSearch || searches;
    }
    const showFullscreen = mobile && showSuggestions;
    if (mobile) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
    } else {
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
    }
    const style = showFullscreen && { position: 'fixed', inset: 0, width: '100%', height: '100%', zIndex: 1, background: 'white' } || { margin: '0.5rem auto', border: showSuggestions && !mobile && '0' || '1px solid #C1C1C1', borderRadius: '1rem', transform: showSuggestions && !mobile && 'translateY(163px)', boxShadow: showSuggestions && !mobile && 'rgba(136, 152, 170, 0.1) 0px 0px 0px 1px, rgba(49, 49, 93, 0.1) 0px 15px 35px, rgba(0, 0, 0, 0.08) 0px 5px 15px' };
    const html = <Search style={style}>
      <div style={{ position: showFullscreen && 'fixed' || null, top: 0, left: 0, right: 0 }} onMouseDown={(e) => { e.stopPropagation(); }}>
        {showFullscreen && <i style={{ padding: '1rem' }} onClick={() => {
          this.endSearching();
        }} className="fas fa-arrow-left"></i>}
        <input
          key={showSuggestions ? 'searching' : 'notsearching'}
          style={{
            padding: showFullscreen ? `1rem 3rem 1rem 3rem` : `10px 3rem 10px 1rem`
          }}
          onBlur={() => {
            if (mobile) return;
            this.endSearching();
          }}
          enterKeyHint="search"
          defaultValue={defaultValue}
          onMouseDown={(e) => {
            if (!this.state.showSuggestions) {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ showText: false, showSuggestions: true });
              onSearching(true);
              this.performSearch();
            }
          }}
          ref={(e) => {
            this.__input = e;
            if (this.__input && showSuggestions) {
              setTimeout(() => {
                if (this.__input) this.__input.focus();
              });
            } else if (this.__input && autoFocus && !initialAutoFocus) {
              this.setState({ showText: false, showSuggestions: true, initialAutoFocus: true });
              onSearching(true);
              this.performSearch();
              this.__input.focus();
            }
          }}
          onKeyDown={this.onKeyDown}
          placeholder="Search"
        ></input>
        {!showSuggestions && <i style={{ padding: '10px 16px' }} onClick={this.onSearch} className="fas fa-search"></i> || <ClearIcon style={{ padding: showFullscreen ? '1.5rem 16px' : '10px 16px' }} onMouseDown={(e) => {
          e.preventDefault(); e.stopPropagation(); this.__input.value = ''; this.performSearch();
        }}></ClearIcon>}
      </div>
      {showSuggestions && <>
        <div style={{ background: '#C1C1C1', width: 'calc(100% - 2rem)', height: 1, margin: '0 1rem', position: showFullscreen && 'absolute' || null, left: 0, top: 50 }}></div>
        <Trending
          onTouchStart={mobile && this.onscroll || null}
          ref={(e) => { if (e && this.__input) { this.__input.focus(); } }}
          style={{
            height: showFullscreen ? 'calc(100% - 38px)' : '325px',
            marginTop: showFullscreen && 50 || null
          }}
        >
          <div>
            {searches.length > 0 && searches.map(({ url, title }) => (
              <div key={url} onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
                Navigation.push(`/${url}`);
                onSearching(false);
              }}>{title}</div>
            ))}
          </div>
        </Trending>
      </>}
    </Search>;
    if (showFullscreen) return <div>{ReactDOM.createPortal(html, document.body)}</div>;
    return html
  }
}
