import Model from './model';

export default class LogicBaseAppReport extends Model {
  constructor(_id, owner, app, description, status) {
    super();
    this._id = _id;
    this.owner = owner;
    this.app = app;
    this.description = description;
    this.status = status;
  }
  getJson = () => {
    const { _id, owner, app, description, status } = this;
    return {
      _id,
      o: owner,
      a: app,
      d: description,
      s: status
    }
  }
}
