import Node from './node';

import { NONE_ATTR, HIDE_DISPLAY_TEXT_ATTR, HIDE_PRINT_ATTR, OPEN_AI_ATTR, RESULT_ONLY_ATTR, MAX_WIDTH_ATTR, PADDING_ATTR, FONT_FAMILY_ATTR, FONT_SIZE_ATTR, FONT_SIZE_H1_ATTR, FONT_SIZE_H2_ATTR, FONT_SIZE_H3_ATTR } from './nodeattributes';

export default class Report extends Node {
  constructor(_text, _attributes, _children, _owner) {
    super(_text, _attributes, _children, _owner);
    this.__defineNodeAttribute('displayText', [{ name: 'Show Display Text', value: NONE_ATTR, default: true }, { name: 'Hide Display Text', value: HIDE_DISPLAY_TEXT_ATTR }]);
    this.__defineNodeAttribute('print', [{ name: 'Show Print', value: NONE_ATTR, default: true }, { name: 'Hide Print', value: HIDE_PRINT_ATTR }]);
    this.__defineNodeAttribute('contentGeneration', [{ name: 'User Generated', value: NONE_ATTR, default: true }, { name: 'Open AI Generated', value: OPEN_AI_ATTR }]);
    this.__defineNodeAttribute('openAIResult', [{ name: 'Full Summary', value: NONE_ATTR, default: true }, { name: 'Result Only', value: RESULT_ONLY_ATTR }]);
    this.__defineNodeProperty('fontFamily', FONT_FAMILY_ATTR);
    this.__defineNodeProperty('fontSize', FONT_SIZE_ATTR);
    this.__defineNodeProperty('fontSizeH1', FONT_SIZE_H1_ATTR);
    this.__defineNodeProperty('fontSizeH2', FONT_SIZE_H2_ATTR);
    this.__defineNodeProperty('fontSizeH3', FONT_SIZE_H3_ATTR);
  }
  get hidePrint() {
    return this.printOption === HIDE_PRINT_ATTR;
  }
  get useOpenAI() {
    return this.contentGenerationOption === OPEN_AI_ATTR;
  }
  get maxWidth() {
    return (this.attributes.find((a) => a.includes(MAX_WIDTH_ATTR)) || '').split('=')[1];
  }
  get padding() {
    return (this.attributes.find((a) => a.includes(PADDING_ATTR)) || '').split('=')[1];
  }
  clone() {
    return new Report(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
}
