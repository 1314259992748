import Server from './index';
import Api from './Api';
class ApiKeyApi extends Api {
  url = 'apikeys'
  async incrementApiKey(apiKey, domain) {
    const response = await Server.__request({
      uri: `/v1/${this.url}?apikey=${apiKey}&domain=${domain}`,
      method: 'PATCH',
      json: true,
      body: {},
    });
    return response && response.body;
  }
}

const singleton = new ApiKeyApi();
export default singleton;
