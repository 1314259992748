import Node from './node';
import findAllHandlebars from '../utils/parsevariables';

export default class Text extends Node {
  get textWithMatchedVariables() {
    const { textVariables, children } = this;
    let textToDisplay = this.text;
    const matchedVariables = findAllHandlebars(textToDisplay);
    // If no matched variables return text
    if (matchedVariables.length === 0) return textToDisplay;
    matchedVariables.forEach((_var) => {
      const child = children.find((c) => c.text === _var);
      if (textVariables && textVariables[_var]) textToDisplay = textToDisplay.replace(`{{${_var}}}`, textVariables[_var]);
      else if (child) textToDisplay = textToDisplay.replace(`{{${_var}}}`, `<div id="htmlbox__mount--${child.key}"></div>`);
    });
    return textToDisplay;
  }
  clone() {
    return new Text(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
}
