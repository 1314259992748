/* eslint-disable no-param-reassign */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import WysiwygWidget from '../../submodules/logictry_wysiwyg/src/js';
import { convertLogicSyntaxIntoStructuredText, convertStructuredTextIntoLogicSyntax } from '../../submodules/logictry_wysiwyg/src/utils';
import { TreeJsonToTreeText, TreeTextToTreeJson } from '../../models/tree';

const Wrapper = styled.div`
  // flex: 1;
  // overflow: auto;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export default class TreeDocBox extends React.PureComponent {
  static propTypes = {
    tree: PropTypes.object,
    onChange: PropTypes.func
  }
  componentWillUnmount() {
    if (this.wysiwygWidget) {
      this.wysiwygWidget.unmount();
      this.wysiwygWidget = null;
    }
  }
  onRef = (mountNode) => {
    if (!mountNode) return;
    const { tree, onChange } = this.props;
    const initialLogicSyntax = TreeTextToTreeJson(tree.getTreeTemplateText());
    const initialText = convertLogicSyntaxIntoStructuredText(initialLogicSyntax);
    this.wysiwygWidget = new WysiwygWidget(mountNode, {
      onBlur: null,
      height: 'calc(100vh - 90px)',
      width: '100%',
      focusOnInit: null,
      fullscreen: true,
      disabled: false,
      initialText,
      borderRadius: '0px',
      onTextChanged: (val) => {
        let newInitialLogicSyntax = JSON.parse(JSON.stringify(initialLogicSyntax));
        convertStructuredTextIntoLogicSyntax(val, newInitialLogicSyntax);
        const newTreeText = TreeJsonToTreeText(newInitialLogicSyntax);
        onChange(newTreeText);
      },
    }); 
  }

  render() {
    return (
      <Wrapper ref={this.onRef} />
    );
  }
}
