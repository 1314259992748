/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import MaxWidth from '../../components/MaxWidth';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb';
import SelectMenu from '../../components/SelectMenu/index';
import Scrollable from '../../components/Scrollable/index';

const SettingsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;

  ${Constants.MediaTabletAndDesktop} {
    padding: 20px;
  }
`;
const Section = styled.div`
  width: 100%;
  margin-top: 40px;
  border-bottom: ${Constants.BottomBorderDivider};

  > div {
    padding: 20px 10px;
    display: flex;

    &:last-child {
      border-bottom: unset;
    }
  }

  button {
    padding: 0px;
    color: ${Constants.PrimaryColor};
    font-size: ${Constants.SemiSmallFontSize};
  }

  i {
    color: ${Constants.PrimaryColor};
  }

  span {
    justify-content: unset;
  }
`;
const Title = styled.div`
  font-weight: bold;
  font-size: ${Constants.NormalText};
  align-items: center;
`;
const Row = styled.div`
  font-size: ${Constants.SemiSmallFontSize};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > *:nth-child(1) {
    min-width: 340px;
    margin: 0px;
    display: flex;
    align-items: center;
    > * {
      margin-right: 10px;
    }
  }
  > * {
    margin: 10px;
  }

  > *:nth-child(2) {
    padding-left: 16px;
  }

  ${Constants.MediaMobile} {
    flex-direction: column;
    align-items: flex-start;
  }

  > Button {
    color: ${Constants.DarkOrange};
  }
`;

export default class CompanyBillingPage extends React.PureComponent {
  render() {
    return (
      <Scrollable
        vertical
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <MaxWidth>
          <DrawerBreadcrumb></DrawerBreadcrumb>
          <SettingsWrapper>
            <SelectMenu value="July 2021" onChange={this.onChange} options={['June 2021', 'July 2021'].map((_role) => ({ value: _role, name: _role }))} />
            <Section>
              <Title>Platform Costs</Title>
              <Row>
                <div>User Subcriptions</div>
                <div>$75</div>
              </Row>
              <Row>
                <div>Viewer Subcriptions</div>
                <div>$75</div>
              </Row>
            </Section>
            <Section>
              <Title>Embed Costs</Title>
              <Row>
                <div>Page Loads</div>
                <div>$75</div>
              </Row>
              <Row>
                <div>Submissions</div>
                <div>$75</div>
              </Row>
            </Section>
            <Section>
              <Title>Implementation Services</Title>
              <Row>
                <div>PO #223424</div>
                <div>$7500</div>
              </Row>
            </Section>
          </SettingsWrapper>
        </MaxWidth>
      </Scrollable>
    );
  }
}
