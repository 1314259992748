/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-unresolved
import ObjectCache from './CacheService';
import LogicBaseCollectionSubscriptionApi from '../server/LogicBaseCollectionSubscriptionApi';
import UserAccount from '../UserAccount';
import LogicBaseCollectionSubscription from '../../models/logicbasecollectionsubscription';

class LogicBaseCollectionSubscriptionCache extends ObjectCache {
  api = LogicBaseCollectionSubscriptionApi;
  createObject = (_results) => {
    const results = JSON.parse(JSON.stringify(_results));
    return new LogicBaseCollectionSubscription(results._id, results.fr, results.c);
  }
  revertObject = (_object) => {
    const results = this.objectJson[_object._id];
    this.objects[_object._id] = this.createObject(results);
    this.emitStateUpdate();
  };
  getObjectJson = (_model) => _model.getJson();
}
const singleton = new LogicBaseCollectionSubscriptionCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
