/* eslint-disable */
const fullnameRegex = /^([\u0000-\uFFFF]){0,50}$/;
const passwordRegex = /^([\u0000-\uFFFF]){6,30}$/;
const usernameRegex = /^[a-zA-Z_](?!.*?\.{2})[\w.]{1,28}[\w]$/;
const emailRegex = /(.+)@(.+){1,}\.(.+){1,}/;

export function validateFullname(f) {
  return (fullnameRegex.test(f) !== true);
}

export function validatePassword(p) {
  if (passwordRegex.test(p) !== true) return true;
  if (p.indexOf(' ') > -1) return true;
  return false;
}

export function validateUsername(u) {
  if (usernameRegex.test(u) !== true) return true;
  if (u.indexOf(' ') > -1) return true;
  return false;
}

export function validateEmail(e) {
  if (emailRegex.test(e) !== true) return true;
  if (e.length < 5 || e.length > 256) return true;
  if (e.trim().match(' ')) return true;
  return false;
}
