/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-unresolved
import ObjectCache from './CacheService';
import LogicBaseCommentApi from '../server/LogicBaseCommentApi';
import UserAccount from '../UserAccount';
import LogicBaseComment from '../../models/logicbasecomment';

class LogicBaseCommentsCache extends ObjectCache {
  api = LogicBaseCommentApi;
  createObject = (_results) => {
    const results = JSON.parse(JSON.stringify(_results));
    return new LogicBaseComment(results._id, results.ct, results.o, results.a, results.c, results.t, results.l);
  }
  revertObject = (_object) => {
    const results = this.objectJson[_object._id];
    this.objects[_object._id] = this.createObject(results);
    this.emitStateUpdate();
  };
  getObjectJson = (_model) => _model.getJson();
}
const singleton = new LogicBaseCommentsCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
