import DatabaseCache from '../../services/cache/DatabaseCache';
import CreatePopup from './CreatePopup';



export default class CreateDatabasePopup extends CreatePopup {
  createTitle = "Create Database"
  saveAndClose = () => {
    const { url, title, description, name } = this.state;
    if (!(url || name)) return this.close();
    const database = DatabaseCache.createNew(title, description, name, url);
    // if (this.props.team) database.addTeam(this.props.team);
    DatabaseCache.create(database);
    return this.close();
  }
  showUrl = true;
  showName = 'Database Name';
}
