import Node from './node';

export default class File extends Node {
  addAnswer(_text, _votes, _owner, _attributes, _project) {
    if (_owner && this.text !== _text) this.owner = _owner;
    this.text = _text;
    this.votes = this.votes || [];
    if (_votes) {
      _votes.forEach((_vote) => {
        // Project is used for Analytics when multiple projects are being overlayed
        if (_project) {
          if (!this.votes.find((vote) => vote._id === _vote._id && vote.project === _project)) this.votes.push({ ..._vote, project: _project }); 
        } else if (!this.votes.find((vote) => vote._id && _vote._id)) this.votes.push(_vote);
      });
    }
    this.attributes = JSON.parse(JSON.stringify(_attributes));
    return this.emitStateUpdate();
  }
  updateUrl = (_text, _editing) => {
    if (!_editing) {
      if (_text) this.owner = this.parents[0].userLoggedIn;
      else this.owner = null;
      if (_text) this.addUserVote();
      else this.removeUserVote();
    }
    this.updateText(_text);
    this.__updateDirectParentOnNodeUpdated('AnswerChanged');
  }
  get checked() {
    return this.votes && this.votes.length > 0;
  }
  addUserVote() {
    this.__addUserVote();
    this.emitStateUpdate();
  }
  removeUserVote() {
    this.__removeUserVote();
    this.emitStateUpdate();
  }
  __addUserVote() {
    if (this.votes && this.votes[0] && this.votes[0]._id === this.parents[0].userLoggedIn) return;
    this.votes = [{ _id: this.parents[0].userLoggedIn, ts: Date.now() }];
  }
  __removeUserVote() {
    this.votes = [];
  }
  clone() {
    return new File(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
}
