/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
import Table from './Table';

export default class DataTableBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
  }
  render() {
    const { node } = this.props;
    // const { editing } = GrayOut;
    const data = node.parent;
    return <Table data={data} />;
  }
}
