/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
/* eslint-disable no-useless-escape */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import ScriptLoader from '../../services/ScriptLoader';

const Wrapper = styled.div`
  margin: 0px 0px 0px ${Constants.TreeContentIconIndent};
  > div:first-child {
    > div:first-child {
      margin: ${Constants.defaultLinePadding} 0px;
    }
    display: flex;
    align-items: center;
  }
`;

export default class ChartBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  };
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this.renderGraph);
    GrayOut.onStateUpdate(this);
    ScriptLoader.load('https://logictrystatic.s3.amazonaws.com/js/d3.min.js');
    ScriptLoader.onStateUpdate(this.renderGraph);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this.renderGraph);
    GrayOut.offStateUpdate(this);
    ScriptLoader.offStateUpdate(this.renderGraph);
  }
  renderGraph = () => {
    if (!this.mount) return;
    if (!ScriptLoader.isLoaded('https://logictrystatic.s3.amazonaws.com/js/d3.min.js')) return;
    Array.from(this.mount.children).forEach((c) => this.mount.removeChild(c));
    this.renderHorizontalBarChart();
  }

  renderHorizontalBarChart = () => {
    const { node } = this.props;
    if (!node.chartData) return;
    const parentRef = GrayOut.findPaneRef(this.mount);
    const { width: parentWidth } = parentRef.getBoundingClientRect();
    const width = parentWidth;
    const barHeight = 25
    const data = node.chartData.sort((a,b) => a.value > b.value ? -1 : 1);
    data.columns = ["", ""]
    data.format = ""
    data.y = ""
    const margin = ({top: 30, right: 30, bottom: 30, left: 150})
    const height = Math.ceil((data.length + 0.1) * barHeight) + margin.top + margin.bottom
    const x = window.d3.scaleLinear()
      .domain([0, window.d3.max(data, d => d.value)])
      .range([margin.left, width - margin.right])
    const y = window.d3.scaleBand()
      .domain(window.d3.range(data.length))
      .rangeRound([margin.top, height - margin.bottom])
      .padding(0.1)
    const xAxis = g => g
      .attr("transform", `translate(0,${margin.top})`)
      .call(window.d3.axisTop(x).ticks(width / 80, data.format))
      .call(_g => _g.select(".domain").remove())
    const yAxis = g => g
      .attr("transform", `translate(${margin.left},0)`)
      .call(window.d3.axisLeft(y).tickFormat(i => data[i].name).tickSizeOuter(0))

    const format = x.tickFormat(20, data.format)

    const svg = window.d3.create("svg")
      .attr("viewBox", [0, 0, width, height]);
  
    svg.append("g")
      .attr("fill", "steelblue")
      .selectAll("rect")
      .data(data)
      .join("rect")
      .attr("x", x(0))
      .attr("y", (d, i) => y(i))
      .attr("width", d => x(d.value) - x(0))
      .attr("height", y.bandwidth());
    
    svg.append("g")
      .attr("fill", "white")
      .attr("text-anchor", "end")
      .attr("font-family", "sans-serif")
      .attr("font-size", 12)
      .selectAll("text")
      .data(data)
      .join("text")
      .attr("x", d => x(d.value))
      .attr("y", (d, i) => y(i) + y.bandwidth() / 2)
      .attr("dy", "0.35em")
      .attr("dx", -4)
      .text(d => format(d.value))
      .call(text => text.filter(d => x(d.value) - x(0) < 20)
        .attr("dx", +4)
        .attr("fill", "black")
        .attr("text-anchor", "start"));

    svg.append("g")
      .call(xAxis);

    svg.append("g")
      .call(yAxis);

    this.mount.appendChild(svg.node());
  }
  renderVerticalBarChart = () => {
    const width = 500
    const height = 500
    const data = [
      {
        name: 'A',
        value: 1
      },
      {
        name: 'V',
        value: 2
      },
      {
        name: 'E',
        value: 3
      }
    ]
    data.columns = ["letter", "frequency"]
    data.format = "%"
    data.y = "↑ Frequency"
    const margin = ({top: 30, right: 0, bottom: 30, left: 40})
    const x = window.d3.scaleBand()
      .domain(window.d3.range(data.length))
      .range([margin.left, width - margin.right])
      .padding(0.1)
    const y = window.d3.scaleLinear()
      .domain([0, window.d3.max(data, d => d.value)]).nice()
      .range([height - margin.bottom, margin.top])
    const xAxis = g => g
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(window.d3.axisBottom(x).tickFormat(i => data[i].name).tickSizeOuter(0))
    const yAxis = (g) => g
      .attr("transform", `translate(${margin.left},0)`)
      .call(window.d3.axisLeft(y).ticks(null, data.format))
      .call((_g) => _g.select(".domain").remove())
      .call((_g) => _g.append("text")
        .attr("x", -margin.left)
        .attr("y", 10)
        .attr("fill", "currentColor")
        .attr("text-anchor", "start")
        .text(data.y))
    const color = "steelblue"
        



    const svg = window.d3.create("svg")
      .attr("viewBox", [0, 0, width, height]);

    svg.append("g")
      .attr("fill", color)
      .selectAll("rect")
      .data(data)
      .join("rect")
      .attr("x", (d, i) => x(i))
      .attr("y", d => y(d.value))
      .attr("height", d => y(0) - y(d.value))
      .attr("width", x.bandwidth());

    svg.append("g")
      .call(xAxis);

    svg.append("g")
      .call(yAxis);
    this.mount.appendChild(svg.node());
  }
  render() {
    return (
      <Wrapper ref={(e) => { this.mount = e; this.renderGraph(); }} />
    );
  }
}
