export default function findAllHandlebars(textToParse) {
  const matchedVariables = [];
  const __findAllHandlebars = (_text) => {
    const openBracket = _text.indexOf('{{');
    if (openBracket < 0) return;
    const closeBracket = _text.indexOf('}}', openBracket);
    if (closeBracket < 0) return;
    matchedVariables.push(_text.slice(openBracket + 2, closeBracket));
    __findAllHandlebars(_text.slice(closeBracket));
  } 
  __findAllHandlebars(textToParse);
  return matchedVariables;
}