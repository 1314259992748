/* eslint-disable no-nested-ternary */
import React from 'react';

import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import RawHtml from '../../components/RawHtml';
import Popup from '../../components/Popup';

const FullscreenWrapper = styled.div`
  position: fixed;
  z-index: 4;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  > div:first-child {
    position: absolute;
    top: 2rem;
    right: 4rem;
    bottom: 2rem;
    left: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > i {
    color: white;
    width: 4rem;
    height: 4rem;
    text-align: center;
    line-height: 4rem;
  }
  > i:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    font-size: ${Constants.LargeFontSize};
  }
  > i:nth-child(3) {
    position: absolute;
    top: calc(50vh - 2rem);
    right: 0;
    font-size: ${Constants.LargeFontSize};
  }
  > i:nth-child(4) {
    position: absolute;
    top: calc(50vh - 2rem);
    left: 0;
    font-size: ${Constants.LargeFontSize};
  }
`;
const I = styled.i``;
const PresentFullscreen = styled.div`
  display: flex;
  align-items: center;
  i {
    margin-right: 0.5rem;
  }
  div {
    text-decoration: underline;
  }
`;

const PresentationWrapper = styled.div`
  position: relative;
  margin-left: ${Constants.TreeContentIconIndent};
  margin-top: ${Constants.defaultLinePadding};
  margin-bottom: ${Constants.defaultLinePadding};
  width: 300px;
  > i {
    color: white;
    background: radial-gradient(rgba(65,181,22,0.5) 0%, rgba(65,181,22,0) 70%);
    text-align: center;
    line-height: 100px;
  }
`;

export default class PresentationBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  }
  state = {
    presenting: false,
    slide: 0,
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
    document.addEventListener('keydown', this.keyDown);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
    document.removeEventListener('keydown', this.keyDown);
  }
  togglePresenting = () => {
    const { presenting } = this.state;
    this.setState({ presenting: !presenting, slide: 0 })
  }
  nextSlide = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!this.allowNext) return;
    const { slide } = this.state;
    this.setState({ slide: slide + 1 });
  }
  previousSlide = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!this.allowBack) return;
    const { slide } = this.state;
    this.setState({ slide: slide - 1 });
  }
  keyDown = (e) => {
    if (e.key === 'ArrowRight') {
      this.nextSlide(e);
    } else if (e.key === 'ArrowLeft') {
      this.previousSlide(e);
    }
  }
  render() {
    const { node } = this.props;
    const { presenting, slide } = this.state;
    const child = node.children[0];
    if (!child || !child.children || child.children.length === 0) return null;
    const nonFilteredChildren = child.answers.filter((c) => !c.filteredByFilters);
    this.allowNext = nonFilteredChildren.length > slide + 1;
    this.allowBack = slide > 0;
    return (
      <div>
        <PresentationWrapper role="button" onMouseDown={this.togglePresenting}>
          <RawHtml style={{ display: 'inline-block', borderRadius: '0.5rem', overflow: 'hidden' }} html={nonFilteredChildren[0].formattedProjectText} />
          <PresentFullscreen>
            <I className="fas fa-play" />
            <div>Present Fullscreen</div>
          </PresentFullscreen>
        </PresentationWrapper>
        {presenting && <Popup showFullscreen>
          <FullscreenWrapper onMouseDown={this.togglePresenting}>
            <div>
              {nonFilteredChildren.map((nonFilteredChild, i) => {
                const { formattedProjectText } = nonFilteredChild;
                return (
                  <RawHtml key={formattedProjectText} style={{ position: 'absolute', top: 0, left: 0, pointerEvents: i === slide ? null : 'none', opacity: i === slide ? 1 : 0, width: '100%', height: '100%' }} html={formattedProjectText} />
                )
              })}
            </div>
            <I role="button" className="fas fa-times" onMouseDown={this.togglePresenting} />
            {this.allowNext && <I role="button" className="fas fa-chevron-right" onMouseDown={this.nextSlide} /> || <div></div>}
            {this.allowBack && <I role="button" className="fas fa-chevron-left" onMouseDown={this.previousSlide} /> || <div></div>}
          </FullscreenWrapper>
        </Popup>}
      </div>
    );
  }
}
