import React from 'react';
// import styled from 'styled-components';

import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import { AccountTemplatesOpen, AccountTemplatesInvitedOpen, AccountTemplatesDeleted } from '../../services/Pagination/index';
import Box from './Box';
import CreateTemplatePopup from './CreateTemplatePopup';
import TemplateTreeSettings from '../../components/ObjectSettings/TemplateTreeSettings';
import { getAccountTemplatesSearch } from '../../services/Pagination/index';


export default class TemplateTreesBox extends Box {
  name = 'Apps';
  missingTitleText = 'New App';
  paginationService = this.props.paginationService || this.props.showDeleted && AccountTemplatesDeleted || (this.props.searchText || this.props.filterText) && getAccountTemplatesSearch(this.props.searchText, null, this.props.filterText) || this.props.invitedTo && AccountTemplatesInvitedOpen || AccountTemplatesOpen;
  backgroundColor = Constants.PrimaryColor;
  boxIcon = 'fas fa-network-wired';
  createText = !this.props.invitedTo && 'New App';
  createNewPopup = () => <CreateTemplatePopup team={this.props.team} onClose={this.hideCreatePopup} />;
  open = (_tree) => {
    Navigation.push(`/apps/${_tree._id}`);
  }
  objectSettings = TemplateTreeSettings
}
