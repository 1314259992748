/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
import { ANSWER, CHECKLIST, CONDITIONAL, FILTER, FOR_EACH_ANSWER, QUESTION, CONDITIONAL_LOGIC, CONDITIONAL_OPERATOR, CALCULATION } from './nodetypes';
import { NODE_ATTR, AND_ATTR, OR_ATTR, NOT_ATTR, EQUALS_ATTR, NOT_EQUALS_ATTR, GREATER_THAN_ATTR, LESS_THAN_ATTR, CONSTANT_ATTR } from './nodeattributes';
import Logic from './logic';

export default class ConditionalLogic extends Logic {
  clone() {
    return new ConditionalLogic(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner);
  }
  __update() {
    const nodes = this.__recursivelyGetAllQuestionableNodes(this);
    this.__getAllConditionalsAndParse(this, nodes);
    this.__updateAllParentsOnNodeUpdated();
  }
  __getAllConditionalsAndParse(_node, nodes) {
    const conditionals = this.__recursivelyGetAllConditionals(_node);
    conditionals.forEach((conditional) => {
      const conditionalOperators = this.__recursivelyGetAllConditionalOperators(conditional);
      const passed = conditionalOperators.every((_conditional) => this.parseConditional(nodes, _conditional));
      conditional.updateConditionalValue(passed);
      if (passed) {
        nodes = [...nodes, ...this.__recursivelyGetAllQuestionableNodes(conditional)];
        this.__getAllConditionalsAndParse(conditional, nodes);
      }
    });
  }
  parseConditional(_nodes, _conditional) {
    const { typeOption, text } = _conditional;
    if (typeOption === NODE_ATTR) {
      const foundNodes = _nodes.filter((_node) => _node.text === text);
      if (foundNodes.length === 0) return null;
      const foundNode = foundNodes.slice(-1)[0];
      if (!foundNode) return null;
      if (foundNode.isType(CALCULATION)) return foundNode.calculatedValue;
      if (foundNode.isType(CONDITIONAL)) return foundNode.conditionalValue;
      const { answers } = foundNode;
      if (!answers) return null;
      const answerChecked = answers.filter((answer) => answer.checked).map((answer) => answer.text);
      if (!answerChecked || answerChecked.length === 0) return null;
      return answerChecked;
    }
    if (typeOption === CONSTANT_ATTR) return text;
    const conditionalOperators = this.__recursivelyGetAllConditionalOperators(_conditional);
    const values = conditionalOperators.map((conditional) => this.parseConditional(_nodes, conditional));
    if (typeOption === AND_ATTR) {
      return values.every((pass) => !!pass);
    }
    if (typeOption === OR_ATTR) {
      return values.some((pass) => !!pass);
    }
    if (typeOption === NOT_ATTR) {
      return !values[0];
    }
    if (typeOption === EQUALS_ATTR) {
      if (Array.isArray(values[0])) return !!values[0].find((v) => v === values[1]);
      else if (Array.isArray(values[1])) return !!values[1].find((v) => v === values[0]);
      else return values[0] === values[1];
    }
    if (typeOption === NOT_EQUALS_ATTR) {
      if (Array.isArray(values[0])) return !!values[0].every((v) => v !== values[1]);
      else if (Array.isArray(values[1])) return !!values[1].every((v) => v !== values[0]);
      else return values[0] !== values[1];
    }
    if (typeOption === GREATER_THAN_ATTR) {
      return parseFloat(values[0]) > parseFloat(values[1]);
    }
    if (typeOption === LESS_THAN_ATTR) {
      return parseFloat(values[0]) < parseFloat(values[1]);
    }
    return false;
  }
  __recursivelyGetAllQuestionableNodes(node) {
    let nodes = [];
    node.children.forEach((_child) => {
      if (_child.isType(CONDITIONAL) && !_child.conditionalValue) return;
      if (_child.isType(ANSWER) && !_child.hasUserVoted()) return;
      if (_child.isType(FOR_EACH_ANSWER)) return;
      if (_child.isType(CALCULATION) && !_child.calculatedValue) return;
      if (_child.isType(CONDITIONAL) && !_child.conditionalValue) return;
      if (_child.isOneOfTypes([CHECKLIST]) && !_child.items.find((c) => c.checked)) return;
      if (_child.isOneOfTypes([FILTER, QUESTION]) && _child.answersChecked.length === 0) return;
      if (_child.isOneOfTypes([CHECKLIST, FILTER, QUESTION, CALCULATION, CONDITIONAL])) {
        nodes.push(_child);
      }
      const childResults = this.__recursivelyGetAllQuestionableNodes(_child);
      nodes = [...nodes, ...childResults];
    });
    return nodes;
  }
  __recursivelyGetAllConditionals(node) {
    let nodes = [];
    node.children.forEach((_child) => {
      if (_child.isType(CONDITIONAL_LOGIC)) return;
      if (_child.isType(CONDITIONAL)) {
        nodes.push(_child);
        return;
      }
      const childResults = this.__recursivelyGetAllConditionals(_child);
      nodes = [...nodes, ...childResults];
    });
    return nodes;
  }
  __recursivelyGetAllConditionalOperators(node) {
    let nodes = [];
    node.children.forEach((_child) => {
      if (_child.isType(CONDITIONAL)) return;
      if (_child.isType(CONDITIONAL_OPERATOR)) {
        nodes.push(_child);
        return;
      }
      const childResults = this.__recursivelyGetAllConditionalOperators(_child);
      nodes = [...nodes, ...childResults];
    });
    return nodes;
  }
}
