/* eslint-disable no-nested-ternary */
import React from 'react';

import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import SelectMenu from '../../components/SelectMenu';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';
import GrayOut from '../../services/TreeDisplay';
import EditableDivWrapper from './EditableDivWrapper';
import AddNote from './AddNote';

const CHOOSE_ONE = 'Choose One';
const CHOOSE_MULTIPLE = 'Choose Multiple';
const CLEAR_CHOICES = 'Clear Choices';
const CLEAR_CHOICE = 'Clear Choice';

const DropdownSection = styled.div`
  
`;
const DropDownWrapper = styled.div`
  flex: 1;
  margin-left: 22px;
  display: flex;
  align-items: center;
  &:hover {
    > div:last-child {
      opacity: 1 !Important;
    }
  }
  .question-box-hide-till-hover {
    opacity: 0;
  }
  &:hover {
    .question-box-hide-till-hover {
      opacity: 1 !Important;
    }
  }
`;
const DropdownAnswer = styled.div`
  display: flex;
  align-items: center;
`;

export default class QuestionAnswerDropdownBox extends React.PureComponent {
  static propTypes = {
    question: PropTypes.object,
    answers: PropTypes.array,
  }
  componentDidMount() {
    const { answers } = this.props;
    answers.forEach((answer) => {
      answer.onStateUpdate(this);
      if (answer.displayText) answer.displayText.onStateUpdate(this);
    });
  }
  componentWillUnmount() {
    const { answers } = this.props;
    answers.forEach((answer) => {
      answer.offStateUpdate(this);
      if (answer.displayText) answer.displayText.offStateUpdate(this);
    });
  }
  state = {
    newAnswer: false,
  }
  onDropdownSelection = (value, answersChecked) => {
    if (value === CLEAR_CHOICES || value === CLEAR_CHOICE) {
      answersChecked.forEach((answerChecked) => answerChecked.toggleUserVote());
    } else {
      const { answers } = this.props;
      const answerFound = answers.find((a) => a.formattedProjectText === value);
      if (answerFound) answerFound.toggleUserVote();
    }
    this.setState({ newAnswer: false });
  }
  getAnswerChildren = (answer) => <CreateChildren node={answer} />;
  onAddNewAnswer = () => {
    const { question } = this.props;
    const { multipleAnswers } = question;
    this.setState({ newAnswer: multipleAnswers });
  }
  onDeleteAnswer = (answer) => {
    answer.removeUserVote();
  }
  render() {
    const { tree, editing } = GrayOut;
    const { newAnswer } = this.state;
    const { question, answers } = this.props;
    const { multipleAnswers, showChildrenAsSiblings, placeholderText } = question;
    const { root, isDisabled } = tree;
    const { showInlineNotes } = root;
    const answersChecked = answers.filter((_answer) => _answer.checked);
    const chooseText = answersChecked.length > 0 ? (multipleAnswers ? CLEAR_CHOICES : CLEAR_CHOICE) : (placeholderText && placeholderText.text || (multipleAnswers ? CHOOSE_MULTIPLE : CHOOSE_ONE));
    const options = [{ value: chooseText, name: chooseText }];
    answers.forEach((a) => options.push({ checked: a.checked, value: a.formattedProjectText, name: a.formattedProjectText }));
    const pointerEvents = isDisabled ? 'none' : null;

    if (editing || !multipleAnswers || !showChildrenAsSiblings) {
      // Sort by voted time
      answersChecked.sort((a, b) => {
        if (a.votes[0].ts > b.votes[0].ts) return 1;
        if (b.votes[0].ts > a.votes[0].ts) return -1;
        return 0;
      });
      return (
        <DropdownSection style={{ pointerEvents }}>
          {answersChecked.map((answerChecked) => {
            const value = answerChecked ? answerChecked.formattedProjectText : options[0].value;
            const answersChildren = this.getAnswerChildren(answerChecked);
            const { checked, note, filter, isUserAnswer } = answerChecked;
            return (
              <div key={answerChecked.formattedProjectText}>
                <DropdownAnswer>
                  <EditableDivWrapper showAddNewAnswer={multipleAnswers} onAddNewAnswer={this.onAddNewAnswer} onDeleteAnswer={this.onDeleteAnswer} key={answerChecked.key} node={answerChecked}>
                    <DropDownWrapper>
                      <SelectMenu options={options} onChange={(val) => this.onDropdownSelection(val, answersChecked)} value={value} placeholder="Select an option" />
                      <div className="question-box-hide-till-hover">
                        {showInlineNotes && !isDisabled && !editing && !filter && !isUserAnswer && checked && !note && <AddNote node={answerChecked} />}
                      </div>
                    </DropDownWrapper>
                  </EditableDivWrapper>
                </DropdownAnswer>
                {(editing || !showChildrenAsSiblings) && <div style={{ marginLeft: Constants.TreeContentIconIndent }}>{answersChildren}</div>}
              </div>
            );
          })}
          {(newAnswer || answersChecked.length === 0) && <DropDownWrapper>
            <SelectMenu options={options} onChange={(val) => this.onDropdownSelection(val, answersChecked)} value={chooseText} placeholder="Select an option" />
          </DropDownWrapper>}
        </DropdownSection>
      );
    }

    const answersCheckedText = answersChecked.map(({ formattedProjectText }) => formattedProjectText).join(' · ');
    const value = answersCheckedText || options[0].value;
    return (
      <DropdownSection style={{ pointerEvents }}>
        <div key={answersCheckedText}>
          <DropdownAnswer>
            <DropDownWrapper>
              <SelectMenu options={options} onChange={(val) => this.onDropdownSelection(val, answersChecked)} value={value} placeholder="Select an option" />
            </DropDownWrapper>
          </DropdownAnswer>
          {(editing || !showChildrenAsSiblings) && <div style={{ marginLeft: Constants.TreeContentIconIndent }}>
            {answersChecked.map((answerChecked) => this.getAnswerChildren(answerChecked))}
          </div>}
        </div>
      </DropdownSection>
    );
  }
}
