// Function to calculate the relative luminance of a color
function getRelativeLuminance(color) {
  // Normalize the color values
  const normalizedColor = color.map(value => {
    value /= 255; // Assuming color values are in the range 0-255
    if (value <= 0.03928) {
      value /= 12.92;
    } else {
      value = Math.pow((value + 0.055) / 1.055, 2.4);
    }
    return value;
  });

  // Calculate relative luminance
  return 0.2126 * normalizedColor[0] + 0.7152 * normalizedColor[1] + 0.0722 * normalizedColor[2];
}

// Function to determine text color (white or black) based on background color
export default function determineTextColor(background) {
  // Parse the background color (assuming it's in the format "#RRGGBB" or "rgb(r, g, b)")
  let color;
  if (background.charAt(0) === "#") {
    color = [
      parseInt(background.slice(1, 3), 16),
      parseInt(background.slice(3, 5), 16),
      parseInt(background.slice(5, 7), 16),
    ];
  } else if (background.startsWith("rgb(")) {
    color = background
      .match(/\d+/g)
      .map(value => parseInt(value, 10));
  }

  if (color) {
    // Calculate the relative luminance of the background color
    const luminance = getRelativeLuminance(color);

    // Determine text color based on luminance
    if (luminance > 0.5) {
      return "black"; // Use black text for light backgrounds
    } else {
      return "white"; // Use white text for dark backgrounds
    }
  } else {
    // Default to black text if color parsing fails
    return "black";
  }
}
