import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

import WindowSize from '../../services/WindowSize';
import UserAccount from '../../services/UserAccount';
import Constants from '../../submodules/logictry_config/constants';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

export default class FullScreenVerticalCenterContent extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
    alignTop: PropTypes.bool,
    fixHeight: PropTypes.bool,
    noPadding: PropTypes.bool,
    backgroundColor: PropTypes.string,
  }

  componentDidMount() {
    WindowSize.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
  }

  render() {
    const { mobile } = WindowSize;
    let minHeight = Constants.DesktopFullscreenHeight;
    const padding = this.props.noPadding ? '0px' : Constants.NormalSectionPadding;
    if (mobile) minHeight = Constants.MobileFullscreenHeight;

    return (
      <Wrapper style={{ padding, minHeight: mobile ? 'calc(100% + 2px)' : '100%', height: this.props.fixHeight ? minHeight : null, justifyContent: this.props.alignTop ? 'unset' : 'center', backgroundColor: this.props.backgroundColor }}>
        {this.props.children}
      </Wrapper>
    );
  }
}
