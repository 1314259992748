/* eslint-disable no-param-reassign */
import { OR_ATTR, AND_ATTR, FIXED_ATTR, OPEN_ENDED_ATTR, ALLOW_FILTERING_SELECTIONS_ATTR, PREVENT_FILTERING_RECOMPUTE_ATTR, ALLOW_SELECTIONS_ATTR, PREVENT_SELECTIONS_ATTR, SHOW_IF_FILTERED_ATTR, NONE_ATTR, HIDE_FILTERED_CHOICES_ATTR, SELECT_ALL_NOT_FILTERED_ATTR } from './nodeattributes';
import Questionable from './questionable';

export const SelectionTypes = [ALLOW_SELECTIONS_ATTR, PREVENT_SELECTIONS_ATTR];
export const SelectionOptions = [{ name: 'Allow Selections', value: ALLOW_SELECTIONS_ATTR }, { name: 'Prevent Selections', value: PREVENT_SELECTIONS_ATTR }];

export default class Filter extends Questionable {
  constructor(_text, _attributes, _children, _owner, _cloned) {
    // Forcing Fixed for all Filter(s)
    _attributes = _attributes.filter((_attr) => ![FIXED_ATTR, OPEN_ENDED_ATTR].includes(_attr));
    _attributes.push(FIXED_ATTR);
    // See if there are specified attributes in the text
    super(_text, _attributes, _children, _owner, _cloned);
    this.__defineNodeAttribute('showFilteredChoices', [{ name: 'Show Filtered Choices', value: NONE_ATTR, default: true }, { name: 'Hide Filtered Choices', value: HIDE_FILTERED_CHOICES_ATTR }])
    this.__defineNodeAttribute('showFiltered', [{ name: 'Show Always', value: NONE_ATTR, default: true }, { name: 'Show If Filtered', value: SHOW_IF_FILTERED_ATTR }]);
    this.__defineNodeAttribute('filteringLogic', [{ name: OR_ATTR, value: OR_ATTR, default: true }, { name: AND_ATTR, value: AND_ATTR }]);
    this.hide = true;
  }
  get hideFilteredChoices() {
    return this.attributes.includes(HIDE_FILTERED_CHOICES_ATTR);
  }
  get showOnlyIfFiltered() {
    return this.attributes.includes(SHOW_IF_FILTERED_ATTR);
  }
  get selectAllNotFiltered() {
    return this.attributes.includes(SELECT_ALL_NOT_FILTERED_ATTR);
  }
  get PreventFilterLogicUpdate() {
    return this.attributes.includes(PREVENT_FILTERING_RECOMPUTE_ATTR);
  }
  deleteChild(_child) {
    super.deleteChild(_child);
    this.checkFixedOpenEnded();
  }
  get allowFilteringSelections() {
    return this.attributes.includes(ALLOW_FILTERING_SELECTIONS_ATTR);
  }
  get answerChoiceTypeOptions() {
    return [{ name: 'Fixed Choices', value: FIXED_ATTR }];
  }
  get answerChoiceType() {
    return FIXED_ATTR;
  }
  get selectionType() {
    return this.attributes.find((a) => SelectionTypes.includes(a)) || ALLOW_SELECTIONS_ATTR;
  }
  get preventSelections() {
    return this.attributes.includes(PREVENT_SELECTIONS_ATTR);
  }
  updateSelectionType(_attribute) {
    return this.__updateAttribute(_attribute, SelectionTypes, ALLOW_SELECTIONS_ATTR);
  }
  __updateAnswerChoiceType() {
    this.attributes = this.attributes.filter((_attr) => ![FIXED_ATTR, OPEN_ENDED_ATTR].includes(_attr));
    this.addAttribute(FIXED_ATTR);
  }
  clone() {
    return new Filter(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner, this);
  }
}
