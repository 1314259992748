// This file contains all types and combinations
import 'intl';
import 'intl/locale-data/jsonp/en';

export const ACTION = 'Action';
export const ACTIONS = 'Actions';
export const ACTION_STEP = 'ActionStep';
export const AUTO_CREATE_LOGIC = 'AutoCreateLogic';
export const ANSWER = 'Answer';
export const AUTOFILL = 'Autofill';
export const BRANCH = 'Branch';
export const BRANCHES = 'Branches';
export const CALCULATION = 'Calculation';
export const CALCULATION_LOGIC = 'CalculationLogic';
export const CALCULATION_OPERATOR = 'CalculationOperator';
export const CALENDAR = 'Calendar';
export const CALENDAR_EVENT = 'CalendarEvent';
export const CALENDAR_EVENT_INFO = 'CalendarEventInfo';
export const CALENDAR_LOGIC = 'CalendarLogic';
export const CALENDAR_MAP = 'CalendarMap';
export const CALENDAR_TIME_ZONE = 'CalendarTimeZone';
export const CATEGORY = 'Category';
export const CHART = 'Chart';
export const CHART_LOGIC = 'ChartLogic';
export const CHATBOT = 'Chatbot';
export const CHECKLIST = 'Checklist';
export const CHECKLIST_ITEM = 'ChecklistItem';
export const COMMENT = 'Comment';
export const CONDITIONAL = 'Conditional';
export const CONDITIONAL_LOGIC = 'ConditionalLogic';
export const CONDITIONAL_OPERATOR = 'ConditionalOperator';
export const DATA = 'Data';
export const DATA_DERIVED = 'DataDerived';
export const DATA_DERIVED_TEXT = 'DataDerivedText';
export const DATA_SCHEMA = 'DataSchema';
export const DATA_SCHEMA_FIELD = 'DataSchemaField';
export const DATA_SCHEMA_FIELD_TITLE = 'DataSchemaFieldTitle';
export const DATA_SCHEMA_KEY = 'DataSchemaKey';
export const DATA_SCHEMA_VALUE = 'DataSchemaValue';
export const DATA_TABLE = 'DataTable';
export const DESCRIPTION = 'Description';
export const DISPLAY_TEXT = 'DisplayText';
export const EMAIL = 'Email';
export const EXPORT = 'Export';
export const FILE = 'File';
export const FILE_GROUP = 'FileGroup';
export const FILTER = 'Filter';
export const FILTER_LOGIC = 'FilterLogic';
export const FOR_EACH_ANSWER = 'ForEachAnswer';
export const GOOGLE_TAG_MANAGER = 'GoogleTagManager';
export const GOTO = 'Goto';
export const HOTJAR = 'Hotjar';
export const INFO = 'Info';
export const KEYWORDS = 'Keywords';
export const META = 'Meta';
export const MODAL = 'Modal';
export const NODE = 'Node';
export const NOTE = 'Note';
export const OBJECT_REF = 'ObjectRef';
export const PANE = 'Pane';
export const PLACEHOLDER_TEXT = 'PlaceholderText';
export const PRESENTATION = 'Presentation';
export const QUESTION = 'Question';
export const REPORT = 'Report';
export const ROOT = 'Root';
export const SCHEMA = 'Schema';
export const SEARCH = 'Search';
export const SEARCH_LOGIC = 'SearchLogic';
export const SEARCH_TEXT = 'SearchText';
export const SHOW_ALL_FILTERED_TEXT = 'ShowAllFilteredText';
export const SHOW_ALL_TEXT = 'ShowAllText';
export const SPLIT_PANE = 'SplitPane';
export const SPREADSHEET = 'Spreadsheet';
export const SUB_TREE = 'SubTree';
export const TAB = 'Tab';
export const TAB_BAR = 'TabBar';
export const TABLE = 'Table';
export const TEXT = 'Text';
export const TEXT_LOGIC = 'TextLogic';
export const TITLE = 'Title';
export const TREE = 'Tree';
export const VERSION = 'Version';
export const VIDEO = 'Video';
export const WIKI = 'Wiki';

// Node Children Types
export const ANSWER_CHILDREN_TYPES = [ACTION, AUTO_CREATE_LOGIC, BRANCH, CALCULATION, CALCULATION_LOGIC, CATEGORY, CHATBOT, CHECKLIST, CONDITIONAL, CONDITIONAL_LOGIC, COMMENT, DATA, DISPLAY_TEXT, FILE_GROUP, FILTER, FILTER_LOGIC, GOTO, INFO, KEYWORDS, NOTE, PLACEHOLDER_TEXT, QUESTION, SUB_TREE, TEXT];
export const BRANCHES_CHILDREN_TYPES = [BRANCH];
export const CALENDAR_CHILDREN_TYPES = [CALENDAR_EVENT];
export const CALENDAR_EVENT_CHILDREN_TYPES = [CALENDAR_EVENT_INFO];
export const CHECKLIST_CHILDREN_TYPES = [CHECKLIST_ITEM, COMMENT, DISPLAY_TEXT, INFO, NOTE];
export const FILE_GROUP_CHILDREN_TYPES = [FILE];
export const FOR_EACH_ANSWER_CHILDREN_TYPES = [FILTER, QUESTION];
export const LOGIC_CHILDREN_TYPES = [ACTION, AUTO_CREATE_LOGIC, BRANCH, CALCULATION, CALCULATION_LOGIC, CALENDAR, CALENDAR_LOGIC, CALENDAR_MAP, CATEGORY, CHART, CHART_LOGIC, CHATBOT, CHECKLIST, COMMENT, CONDITIONAL, CONDITIONAL_LOGIC, DATA, FILE_GROUP, FILTER, FILTER_LOGIC, GOTO, KEYWORDS, MODAL, PRESENTATION, QUESTION, SEARCH, SEARCH_LOGIC, SPLIT_PANE, SUB_TREE, TEXT, TEXT_LOGIC, VIDEO, WIKI];
export const META_CHILDREN_TYPES = [PLACEHOLDER_TEXT];
export const QUESTION_CHILDREN_TYPES = [ANSWER, AUTOFILL, COMMENT, DISPLAY_TEXT, FOR_EACH_ANSWER, KEYWORDS, INFO, NOTE, PLACEHOLDER_TEXT, SHOW_ALL_TEXT, SHOW_ALL_FILTERED_TEXT];
export const PANE_CHILDREN_TYPES = [ACTION, AUTO_CREATE_LOGIC, BRANCH, CALCULATION, CALCULATION_LOGIC, CALENDAR, CALENDAR_LOGIC, CALENDAR_MAP, CATEGORY, CHART, CHART_LOGIC, CHECKLIST, COMMENT, CONDITIONAL, CONDITIONAL_LOGIC, DATA, FILE_GROUP, FILTER, FILTER_LOGIC, GOTO, INFO, KEYWORDS, PRESENTATION, QUESTION, SEARCH, SEARCH_LOGIC, SPLIT_PANE, SUB_TREE, TEXT, TEXT_LOGIC, VIDEO, WIKI];
export const ROOT_CHILDREN_TYPES = [ACTION, AUTO_CREATE_LOGIC, AUTO_CREATE_LOGIC, BRANCH, CALCULATION, CALCULATION_LOGIC, CALENDAR, CALENDAR_LOGIC, CALENDAR_MAP, CATEGORY, CHART_LOGIC, CHATBOT, CHECKLIST, COMMENT, CONDITIONAL, CONDITIONAL_LOGIC, DATA, FILE_GROUP, FILTER, FILTER_LOGIC, GOTO, MODAL, PRESENTATION, QUESTION, SEARCH, SEARCH_LOGIC, SPLIT_PANE, SUB_TREE, TEXT, TEXT_LOGIC, VIDEO, WIKI];
export const SEARCH_CHILDREN_TYPES = [PLACEHOLDER_TEXT, SEARCH_TEXT];
export const SPLIT_PANE_CHILDREN_TYPES = [PANE];
export const STANDARD_CHILDREN_TYPES = [ACTION, AUTO_CREATE_LOGIC, BRANCH, CALCULATION, CALCULATION_LOGIC, CALENDAR, CALENDAR_LOGIC, CALENDAR_MAP, CATEGORY, CHART, CHART_LOGIC, CHATBOT, CHECKLIST, COMMENT, CONDITIONAL, CONDITIONAL_LOGIC, DATA, FILE_GROUP, FILTER, FILTER_LOGIC, GOTO, INFO, KEYWORDS, MODAL, PRESENTATION, QUESTION, SEARCH, SEARCH_LOGIC, SPLIT_PANE, SUB_TREE, TEXT, TEXT_LOGIC, VIDEO];
export const STANDARD_CHILDREN_WITH_DISPLAY_TEXT_TYPES = [...STANDARD_CHILDREN_TYPES, DISPLAY_TEXT];
export const CALCULATION_CHILDREN_TYPES = [CALCULATION_OPERATOR, COMMENT];
export const CONDITIONAL_CHILDREN_TYPES = [...LOGIC_CHILDREN_TYPES, CONDITIONAL_OPERATOR];
export const TEXT_CHILDREN_TYPES = [...STANDARD_CHILDREN_TYPES, CHATBOT, WIKI];
export const SUB_TREE_CHILDREN_TYPES = [OBJECT_REF];
export const WIKI_CHILDREN_TYPES = [CATEGORY];

export const TOP_LEVEL_NODES = [ROOT, BRANCHES, REPORT, EMAIL, ACTIONS];
export const ALWAYS_SHOW_WYSIWYG_TYPES = [NOTE];
export const PROJECT_ONLY_TYPES = [NOTE];
export const SHOW_IN_PROJECT_REPORTS = [ANSWER, CATEGORY, CHECKLIST, CHECKLIST_ITEM, FILE, FILE_GROUP, FILTER, INFO, NOTE, QUESTION, ROOT, SEARCH_TEXT, TEXT];
export const ONLY_ONE_CHILD_ALLOWED = [FOR_EACH_ANSWER, KEYWORDS, DISPLAY_TEXT, INFO, AUTOFILL, NOTE, SHOW_ALL_FILTERED_TEXT, SHOW_ALL_TEXT, DATA_DERIVED_TEXT, DATA_SCHEMA, DATA_SCHEMA_FIELD_TITLE];
export const ALLOW_HASH_KEY_INSTEAD_OF_TEXT_IN_PROJECT = [CATEGORY, TEXT];
export const FORCE_IFRAME_TYPES = [];

export const BASIC_PALETTE_TYPES = [ACTION, ANSWER, BRANCH, CATEGORY, CHATBOT, CHECKLIST, CHECKLIST_ITEM, INFO, QUESTION, TEXT, WIKI];
export const LAYOUT_PALETTE_TYPES = [MODAL, PANE, PRESENTATION, SPLIT_PANE, SUB_TREE, TAB, TAB_BAR, TABLE, WIKI];
export const LOGIC_PALETTE_TYPES = [ACTION, AUTO_CREATE_LOGIC, CALCULATION, CALCULATION_LOGIC, CALCULATION_OPERATOR, CONDITIONAL, CONDITIONAL_LOGIC, CONDITIONAL_OPERATOR, DATA, DATA_DERIVED, DATA_DERIVED_TEXT, DATA_SCHEMA, DATA_SCHEMA_FIELD, DATA_SCHEMA_FIELD_TITLE, DATA_SCHEMA_KEY, DATA_SCHEMA_VALUE, FILTER, FILTER_LOGIC, SEARCH, SEARCH_LOGIC, SEARCH_TEXT, SHOW_ALL_FILTERED_TEXT, SHOW_ALL_TEXT];
export const VISUALIZATION_PALETTE_TYPES = [CALENDAR, CALENDAR_EVENT, CALENDAR_EVENT_INFO, CALENDAR_LOGIC, CALENDAR_MAP, CHART, CHART_LOGIC];

export const BASIC_ACCOUNT_TYPES = [...BASIC_PALETTE_TYPES];
export const ADVANCED_ACCOUNT_TYPES = [...BASIC_ACCOUNT_TYPES, AUTO_CREATE_LOGIC, AUTOFILL, CALCULATION, CALCULATION_LOGIC, CALCULATION_OPERATOR, COMMENT, CONDITIONAL, CONDITIONAL_LOGIC, CONDITIONAL_OPERATOR, DATA, DATA_DERIVED, DATA_DERIVED_TEXT, DATA_SCHEMA, DATA_SCHEMA_FIELD, DATA_SCHEMA_FIELD_TITLE, DATA_SCHEMA_KEY, DATA_SCHEMA_VALUE, DISPLAY_TEXT, FILE, FILE_GROUP, FILTER, FILTER_LOGIC, FOR_EACH_ANSWER, KEYWORDS, NOTE, PANE, PLACEHOLDER_TEXT, SEARCH, SEARCH_LOGIC, SPLIT_PANE, SUB_TREE, TEXT_LOGIC].sort(new Intl.Collator('en').compare);
export const DEV_ACCOUNT_TYPES = [...ADVANCED_ACCOUNT_TYPES, ACTION_STEP, CHART, CHART_LOGIC, MODAL, PRESENTATION, VIDEO].sort(new Intl.Collator('en').compare);

// Node to Children Types Map
export const NODE_CHILDREN_TYPES = {};
NODE_CHILDREN_TYPES[ACTION] = [ACTION_STEP];
NODE_CHILDREN_TYPES[ACTIONS] = [ACTION];
NODE_CHILDREN_TYPES[ACTION_STEP] = [DATA];
NODE_CHILDREN_TYPES[AUTO_CREATE_LOGIC] = LOGIC_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[ANSWER] = ANSWER_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[AUTOFILL] = [];
NODE_CHILDREN_TYPES[BRANCH] = STANDARD_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[BRANCHES] = BRANCHES_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[CALCULATION] = CALCULATION_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[CALCULATION_LOGIC] = LOGIC_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[CALCULATION_OPERATOR] = [CALCULATION_OPERATOR];
NODE_CHILDREN_TYPES[CALENDAR] = CALENDAR_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[CALENDAR_EVENT] = CALENDAR_EVENT_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[CALENDAR_EVENT_INFO] = [];
NODE_CHILDREN_TYPES[CALENDAR_LOGIC] = LOGIC_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[CALENDAR_MAP] = [];
NODE_CHILDREN_TYPES[CATEGORY] = STANDARD_CHILDREN_WITH_DISPLAY_TEXT_TYPES;
NODE_CHILDREN_TYPES[CHART_LOGIC] = LOGIC_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[CHATBOT] = [ACTION, BRANCH, FILTER, QUESTION, TEXT];
NODE_CHILDREN_TYPES[CHECKLIST] = CHECKLIST_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[CHECKLIST_ITEM] = ANSWER_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[CONDITIONAL] = CONDITIONAL_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[CONDITIONAL_LOGIC] = LOGIC_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[CONDITIONAL_OPERATOR] = [CONDITIONAL_OPERATOR];
NODE_CHILDREN_TYPES[DATA] = [COMMENT, DATA_DERIVED, DATA_SCHEMA, DATA_TABLE];
NODE_CHILDREN_TYPES[DATA_DERIVED] = [DATA_DERIVED_TEXT];
NODE_CHILDREN_TYPES[DATA_DERIVED_TEXT] = [];
NODE_CHILDREN_TYPES[DATA_SCHEMA] = [DATA_SCHEMA_FIELD, DATA_SCHEMA_KEY, DATA_SCHEMA_VALUE];
NODE_CHILDREN_TYPES[DATA_SCHEMA_FIELD] = [DATA_SCHEMA_FIELD_TITLE];
NODE_CHILDREN_TYPES[DATA_SCHEMA_FIELD_TITLE] = [];
NODE_CHILDREN_TYPES[DATA_SCHEMA_KEY] = [DATA_SCHEMA_KEY, DATA_SCHEMA_VALUE];
NODE_CHILDREN_TYPES[DATA_SCHEMA_VALUE] = [];
NODE_CHILDREN_TYPES[DATA_TABLE] = [];
NODE_CHILDREN_TYPES[DESCRIPTION] = META_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[DISPLAY_TEXT] = [];
NODE_CHILDREN_TYPES[EMAIL] = [TEXT];
NODE_CHILDREN_TYPES[EXPORT] = STANDARD_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[FILE] = [];
NODE_CHILDREN_TYPES[FILE_GROUP] = FILE_GROUP_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[FILTER] = QUESTION_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[FILTER_LOGIC] = LOGIC_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[FOR_EACH_ANSWER] = ANSWER_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[GOTO] = [];
NODE_CHILDREN_TYPES[INFO] = [];
NODE_CHILDREN_TYPES[KEYWORDS] = [];
NODE_CHILDREN_TYPES[META] = META_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[MODAL] = STANDARD_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[NOTE] = [];
NODE_CHILDREN_TYPES[OBJECT_REF] = [];
NODE_CHILDREN_TYPES[PANE] = PANE_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[PLACEHOLDER_TEXT] = [];
NODE_CHILDREN_TYPES[PRESENTATION] = [FILTER];
NODE_CHILDREN_TYPES[QUESTION] = QUESTION_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[REPORT] = [TEXT];
NODE_CHILDREN_TYPES[ROOT] = ROOT_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[SCHEMA] = META_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[SEARCH] = SEARCH_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[SEARCH_LOGIC] = LOGIC_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[SEARCH_TEXT] = [];
NODE_CHILDREN_TYPES[SHOW_ALL_FILTERED_TEXT] = [];
NODE_CHILDREN_TYPES[SHOW_ALL_TEXT] = [];
NODE_CHILDREN_TYPES[SPLIT_PANE] = SPLIT_PANE_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[SUB_TREE] = SUB_TREE_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[TEXT] = TEXT_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[TEXT_LOGIC] = LOGIC_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[TITLE] = META_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[VERSION] = META_CHILDREN_TYPES;
NODE_CHILDREN_TYPES[WIKI] = WIKI_CHILDREN_TYPES;

export function getChildTypes(node) {
  if (!NODE_CHILDREN_TYPES[node.attributes[0]]) return DEV_ACCOUNT_TYPES;
  return [...NODE_CHILDREN_TYPES[node.attributes[0]], NODE];
}

export function checkIfChildIsAllowed(node, child) {
  // Undefined nodes are allowed to support deprecation
  if (!NODE_CHILDREN_TYPES[child.attributes[0]]) return true;
  return child.isOneOfTypes(getChildTypes(node));
}
export function findDynamicSizingContent(node) {
  const foundInChildren = node.children.find(findDynamicSizingContent);
  return foundInChildren || (node.isDynamicHeightElement ? node : null);
}
export function findForceIframeContent(node) {
  const foundInChildren = node.children.find(findForceIframeContent);
  return foundInChildren || node.isOneOfTypes(FORCE_IFRAME_TYPES);
}

export const NODE_DISPLAY_TEXT_TYPES = {};
