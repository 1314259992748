import Service from './Service';
import UserAccount from './UserAccount';
import SpreadsheetCache from './cache/SpreadsheetCache';

class AutoSaveSpreadsheet extends Service {
  autosaveTimeout = {};
  save = (spreadsheet) => SpreadsheetCache.update(spreadsheet);
  autoSave = (spreadsheet) => {
    const { isLocked } = spreadsheet;
    if (isLocked) return;
    if (this.autosaveTimeout[spreadsheet._id]) clearTimeout(this.autosaveTimeout[spreadsheet._id]);
    this.autosaveTimeout[spreadsheet._id] = setTimeout(() => {
      this.autosaveTimeout[spreadsheet._id] = null;
      // Dont save if not current version
      if (spreadsheet.currentVersionDisplayed) return;
      // Dont save if not created
      if (!spreadsheet._id && spreadsheet.sheets[0].data.every((row) => row.every((val) => !val.value))) return;
      // Dont save if not editor
      if (!spreadsheet.isEditor) return;
      // Autosave
      this.save(spreadsheet);
    }, 2000);
  }
}

const singleton = new AutoSaveSpreadsheet();
UserAccount.onStateUpdate(() => {
  if (!UserAccount.isLoggedIn) {
    Object.values(singleton.autosaveTimeout).forEach((autosave) => {
      clearTimeout(autosave);
    });
    singleton.autosaveTimeout = {};
  }
});
export default singleton;
