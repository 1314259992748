import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import Constants from '../../submodules/logictry_config/constants';
// import DealList from '../../components/DealList';
import MaxWidth from '../../components/MaxWidth';
import Notifications from '../../services/Notifications';
import Button from '../../components/Button';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';

const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > h1 {
    margin: 10px;
  }

  button {
    box-shadow: ${Constants.ButtonShadow};
    margin: 10px;
    padding: 20px;
  }

  > div > * {
    margin-bottom: 40px;
  }
`;


export default class NotificationPage extends React.PureComponent {

  render() {
    return (
      <MaxWidth>
        <NotificationWrapper>
          <FullScreenVerticalCenterContent>
            <h1>Notifications</h1>
            {Notifications.current.length > 0 ? Notifications.current.map((n) => (
              /* eslint-disable */
              <div key={n._id} style={{ flex: 1 }}>
                <Button
                  onClick={() => Notifications.notificationClicked(n)}
                  color="inherit"
                >
                  <div>{n.description}</div>
                </Button>
              </div>
              /* eslint-enable */
            )) : (
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div>You have no notifications</div>
              </div>
            )}
          </FullScreenVerticalCenterContent>
        </NotificationWrapper>
      </MaxWidth>
    );
  }
}
