/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
// eslint-disable-next-line import/no-cycle
import RawHtml from '../../components/RawHtml/index';

const Wrapper = styled.div`
  padding-left: ${Constants.TreeContentIconIndent};
  > div:first-child {
    padding-top: ${Constants.defaultLinePadding};
    padding-bottom: ${Constants.defaultLinePadding};
    min-height: 40px;
  }
  > div:nth-child(2) {
    padding-left: ${Constants.TreeContentIconIndent};
    padding-top: ${Constants.defaultLinePadding};
    padding-bottom: ${Constants.defaultLinePadding};
    min-height: 40px;
  }
`;

export default class CalculationBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
  }
  render() {
    const { node } = this.props;
    const { key, calculatedValueFormatted, isTextHidden, isNodeHidden, text } = node;

    if (isNodeHidden) return null;

    if (isTextHidden) {
      return (
        <Wrapper key={key}>
          <div>
            <RawHtml html={calculatedValueFormatted || ''} />
          </div>
        </Wrapper>
      );
    }

    return (
      <Wrapper key={key}>
        <div>
          {text}
        </div>
        <div>
          <RawHtml html={calculatedValueFormatted || ''} />
        </div>
      </Wrapper>
    )
  }
}
