/* eslint-disable react/no-danger */
import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadingIndicator from '../../components/LoadingIndicator';
// import Constants from '../../submodules/logictry_config/constants';
import TreeCache from '../../services/cache/TreeCache';
import Navigation from '../../services/Navigation';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb';
import { TreeModelToTreeJson } from '../../models/tree';

const Wrapper = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  > div {
    margin-left: 8px;
  }
`;

export default class ExportPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.treeId = Navigation.currentLocation[2];
  }
  componentDidMount() {
    TreeCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    TreeCache.offStateUpdate(this);
  }
  formatBlob = (json) => `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(json))}`;
  render() {
    const tree = TreeCache.getTree(this.treeId);
    if (!tree || tree.loading) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    if (tree.error) return <FullScreenVerticalCenterContent><h1>App Not Found</h1></FullScreenVerticalCenterContent>;
    const { spreadsheets, title } = tree;
    const treeJson = TreeModelToTreeJson(tree, true);
    return (
      <Wrapper>
        <DrawerBreadcrumb></DrawerBreadcrumb>
        <h1>Engine</h1>
        {/* <div><a href="/export/v0.15.29.zip">Version 0.15.29</a></div> */}
        <div>Ask Logictry</div>
        <h1>App</h1>
        <div><a href={this.formatBlob(treeJson)} download={`${tree._id}.json`}>App: {tree._id}</a></div>
        <h1>Spreadsheets</h1>
        {[...spreadsheets].map((id) => <div><a href={`/v1/spreadsheets/${id}?export=true`} download={`${id}.json`}>Spreadsheet: {id}</a></div>)}
      </Wrapper>
    );
  }
}
