import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import WindowSize from '../../services/WindowSize';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import LogicBaseAppCache from '../../services/cache/LogicBaseAppCache';
import UserCache from '../../services/cache/UserCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import { getAppLikes, getCollectionSubscribers, getCommentLikes } from '../../services/Pagination';
import UserProfileHeader from '../../components/UserProfileHeader';
import Scrollable from '../../components/Scrollable';
import LoadingIndicator from '../../components/LoadingIndicator';

const NotificationWrapper = styled.div`
  max-width: 52rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;

  > h1 {
    margin: 10px;
  }
`;
const Request = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  > * {
    margin: 0.5rem;
    ${Constants.MediaMobile} {
      margin: 0.5rem auto;
    }
  }
  > div:nth-child(2) {
    flex: 1;
  }
`;
const Content = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  > div {
    padding: 0rem 1rem;
    cursor: pointer;
    color: #666;
  }
`;

export default class LogicBaseSubscriberPage extends React.PureComponent {
  componentDidMount() {
    WindowSize.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    LogicBaseCollectionCache.onStateUpdate(this);
    LogicBaseAppCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    LogicBaseCollectionCache.offStateUpdate(this);
    LogicBaseAppCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    if (this.cache) this.cache.offStateUpdate(this);
  }
  onPagination = () => {
    if (this.cache.showMore && this.cache.allFound && this.cache.initialized) {
      this.cache.getMore();
    }
  }
  render() {
    const loading = <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const { mobile } = WindowSize;
    const { currentLocation } = Navigation;

    let id;
    let users;
    if (currentLocation[1] === 'comments') {
      id = Navigation.currentLocation[2];
      if (!this.cache) {
        this.cache = getCommentLikes(id);
        this.cache.onStateUpdate(this);
      }
      users = this.cache.currentPage.map(({ follower }) => follower);
    } else if (currentLocation[1] === 'collections') {
      id = Navigation.currentLocation[2];
      const collections = LogicBaseCollectionCache.query({ u: id.toLowerCase() });
      const collection = collections && collections[0];
      if (!collection) return loading;
      if (!this.cache) {
        this.cache = getCollectionSubscribers(collection._id);
        this.cache.onStateUpdate(this);
      }
      users = this.cache.currentPage.map(({ follower }) => follower);
    } else {
      id = Navigation.currentLocation[1];
      const publicApps = LogicBaseAppCache.query({ u: id.toLowerCase() });
      const publicApp = publicApps && publicApps[0];
      if (!publicApp) return loading;
      if (!this.cache) {
        this.cache = getAppLikes(publicApp._id);
        this.cache.onStateUpdate(this);
      }
      users = this.cache.currentPage.map(({ follower }) => follower);
    } 
    
    if (!users) return loading;
    this.users = UserCache.getUsersByIds(users);
    if (!this.cache.allFound) return loading;
    if (!this.users) return loading;
    return (
      <Scrollable
        key={id}
        vertical={!mobile}
        paginationHeight={400}
        onPagination={this.onPagination}
        style={{ position: mobile ? 'relative' : 'absolute', inset: 0, padding: '1rem 0 2rem' }}
        rememberScrollPosition={`logicbaseusers_${id}`}
      >
        <NotificationWrapper>
          {this.users && this.users.length > 0 ? this.users.map((user) => (
            <Request key={user._id}>
              <UserProfileHeader
                user={user}
                medium
              />
            </Request>
          )) : (
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <div>{currentLocation[1] === 'collections' ? 'No subscribers yet' : 'No likes yet'}</div>
            </div>
          )}
        </NotificationWrapper>
      </Scrollable>
    );
  }
}
