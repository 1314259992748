import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DrawerService from '../../services/Drawer';
import UserAccount from '../../services/UserAccount';
import Company from '../../services/Company';
import Navigation from '../../services/Navigation';
import Constants from '../../submodules/logictry_config/constants';
import Button from '../Button/index';
import WindowSize from '../../services/WindowSize';
import UserAvatar from '../UserAvatar';
import VerticalCenterDiv from '../../styledhtml/VerticalCenterDiv';
import ClickableDiv from '../ClickableDiv/index';
import Popup from '../Popup/index';
import MenuList from '../Popup/MenuList';
import MenuItem from '../Popup/MenuItem';
import Status from './Status';
import LoginButton from '../LoginButton';
import { AccountCompanies } from '../../services/Pagination/index';
// import Drawer from '../../applications/App/Drawer';


const OuterWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: ${Constants.DesktopHeaderHeight};
  z-index: 2;
`;
const Wrapper = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  top: 0px;
  left: 0px;
  right: 0px;
  justify-content: space-between;
  flex-direction: row;
  overflow: hidden;
  height: ${Constants.DesktopHeaderHeight};

  ${Constants.MediaTabletAndDesktop} {
    height: ${Constants.DesktopHeaderHeight};
  }
  i {
    color: ${Constants.DarkTextColor};
  }
  span {
    color: ${Constants.DarkTextColor};
  }
`;
const DrawerAndTitle = styled.div`
  display: flex;
  align-items: center;
  > button {
    line-height: 100%;
    margin-left: 1rem;
    min-width: 2rem;
  }
  > button:last-child {
    margin-right: 1rem;
  }
`;
const Logo = styled.div`
  height: 32px;
  width: 160px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: center;
`;

const NavbarBackground = window.logictry && window.logictry.company && window.logictry.company.theme && window.logictry.company.theme.NavbarBackground;

export default class Header extends React.PureComponent {
  static propTypes = {
    fixed: PropTypes.bool,
  }
  state = {
    open: false,
    teamDropdownText: 'Home',
  }

  componentDidMount() {
    UserAccount.onStateUpdate(this);
    Navigation.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    DrawerService.onStateUpdate(this);
    Company.onStateUpdate(this);
    AccountCompanies.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserAccount.offStateUpdate(this);
    Navigation.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    DrawerService.offStateUpdate(this);
    Company.offStateUpdate(this);
    AccountCompanies.offStateUpdate(this);
  }
  close = () => this.setState({ open: false, showTeams: false });
  clickedAccount = () => this.setState({ open: true });
  showTeams = () => this.setState({ showTeams: true });
  getButton = () => {
    if (!(UserAccount.isLoggedIn) || (UserAccount.account && !UserAccount.account.verified)) {
      return (
        <div style={{ marginRight: 16 }}>
          <LoginButton isApp />
        </div>
      );
    }
    return (
      <VerticalCenterDiv>
        <ClickableDiv style={{ width: 64 }} onClick={this.clickedAccount}>
          <UserAvatar buttonRef={(e) => { this.anchorEl = e; }} user={UserAccount.account} />
        </ClickableDiv>
      </VerticalCenterDiv>
    );
  }

  getLogo = (showMenu) => (UserAccount.isLoggedIn) ? (
    <DrawerAndTitle style={{ width: !WindowSize.mobile ? '272px' : null }}>
      {showMenu && <button onClick={this.toggleDrawer}>
        <i style={{ fontSize: Constants.NormalFontSize }} className="fas fa-bars"></i>
      </button>}
      {(!WindowSize.mobile || !showMenu) && <button onClick={() => Navigation.push(Navigation.home)}>
        <Logo style={{ backgroundImage: `url(${Constants.Logo})` }}></Logo>
      </button>}
    </DrawerAndTitle>
  ) : (
    <ClickableDiv
      onClick={() => {
        Navigation.push(Navigation.home);
      }}
      style={{ marginLeft: 16, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <Logo style={{ backgroundImage: `url(${Constants.Logo})` }}></Logo>
    </ClickableDiv>
  );
  toggleDrawer = () => DrawerService.toggle();
  backClicked = () => Navigation.back();
  getAccountPopup = () => {
    const { isAuthorizedToLogIn } = Company;
    const { currentPage } = AccountCompanies;
    return (
      <Popup
        allowBackgroundClose
        onClose={this.close}
        anchorEl={this.anchorEl}
        anchorBottomRight
        key={currentPage.length}
      >
        <MenuList>
          <MenuItem onClick={() => { this.close(); Navigation.push(Navigation.account); }}>
            Account
          </MenuItem>
          <MenuItem onClick={() => { this.close(); Navigation.push(Navigation.accountSettings); }}>
            Settings
          </MenuItem>
          <MenuItem onClick={() => { this.close(); Navigation.push(Navigation.submissions); }}>
            Submissions
          </MenuItem>
          <MenuItem onClick={() => { this.close(); Navigation.push(`${!isAuthorizedToLogIn && Navigation.submissions || Navigation.apps}?state=Deleted`); }}>
            Trash
          </MenuItem>
          <MenuItem onClick={() => { this.close(); UserAccount.logout() }}>
            Log Out
          </MenuItem>
          {Company.isExpected && Company.subscriptionVerified && (
            <>
              <div style={{ width: '100%', height: 1, backgroundColor: Constants.MediumTextGray }}></div>
              <MenuItem>
                <i style={{ marginRight: '0.5rem' }} className="fas fa-check" />{Company.company.longname}
              </MenuItem>
              {Company.isAuthorizedToLogIn && <MenuItem onClick={() => { this.close(); Navigation.push(Navigation.companySettings); }}>
                Company Settings
              </MenuItem>}
            </>
          )}
          {(currentPage && currentPage.length > 0 && (
            <>
              <div style={{ width: '100%', height: 1, backgroundColor: Constants.MediumTextGray }}></div>
              {Company.isExpected && Company.subscriptionVerified && < MenuItem onClick={() => { window.location = Constants.isDevelopment ? 'https://localhost/login' : 'https://logictry.com/login'; }}>
                Personal Account
              </MenuItem>}
              {currentPage.map(({ _id, longname, shortname }) => {
                const newUrl = Constants.isDevelopment ? `https://${shortname}.logictry.localhost/login` : `https://${shortname}.logictry.com/login`;
                if (Company.isExpected && _id === Company.company._id) return null;
                return (
                  <MenuItem key={shortname} onClick={() => { window.location = newUrl; }}>
                    {longname}
                  </MenuItem>
                )
              })}
            </>
          ))}
        </MenuList>
      </Popup>
    );
  }

  render() {
    const { company, companyTeams, isAuthorizedToLogIn } = Company;
    const showDrawer = (isAuthorizedToLogIn);
    const { fixed } = this.props;
    let { teamDropdownText } = this.state;
    const { mobile } = WindowSize;
    const { open } = DrawerService;
    const paddingTop = 'unset';
    const height = Constants.DesktopHeaderHeight;
    const boxShadow = (fixed && showDrawer) ? '0px 0px 2px 0 rgba(0,0,0,0.4)' : 'unset';
    const showPageContent = UserAccount.isLoggedIn && showDrawer && !(mobile && open);
    const { content } = Navigation.currentSearch;
    if (!Navigation.currentLocation[1]) {
      teamDropdownText = content ? `Your - ${content}` : 'Home'
    }
    if (company) {
      if (Navigation.currentLocation[1] === 'company') {
        teamDropdownText = 'Company Home';//content ? `Company ${content}` : 'Company Home';
      }
      if (Navigation.currentLocation[1] === 'teams') {
        const currentTeam = Navigation.currentLocation[2];
        if (currentTeam && companyTeams) {
          const foundTeam = companyTeams.find(({ _id }) => _id === currentTeam);
          if (foundTeam) {
            teamDropdownText = content ? `${foundTeam.longname} - ${content}` : 'Team Home';
          }
        }
      }
    }
    this.state.teamDropdownText = teamDropdownText;
    return (
      <OuterWrapper style={{ boxShadow, paddingTop, height }}>
        <Wrapper style={{ height, backgroundColor: !isAuthorizedToLogIn && NavbarBackground || Constants.White }}>
          {this.getLogo(showDrawer)}
          {showPageContent ? (
            <div style={{ flex: 1, textAlign: 'left' }}>{DrawerService.children}</div>
          ) : (
            <div style={{ flex: 1 }} />
          )}
          <Status />
          {this.getButton()}
        </Wrapper>
        {this.state.open && this.getAccountPopup()}
      </OuterWrapper>
    );
  }
}
