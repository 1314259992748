import Model from './model';

export default class Sheet extends Model {
  /**
   * 2-d array of data
   */
  data = [];
  /**
   * Choices dictionary
   */
  get choices() {
    return getChoicesFromData(this.data);
  }
  /**
   * Constructor
   */
  constructor(_data) {
    super();
    /**
     * Update the data
     */
    this.updateData(_data);
  }
  /**
   * Update data
   */
  updateData(_data) {
    // Prune the empty cells
    let maxRow = 0;
    const maxColumns = (new Array(_data.length)).fill(-1);
    _data.forEach((_row, i) => {
      _row.forEach((_cell, j) => {
        if (_cell.value) {
          maxRow = Math.max(maxRow, i);
          maxColumns[i] = Math.max(maxColumns[i], j);
        }
      });
    });
    this.data = _data.slice(0, maxRow + 1).map((_row, i) => _row.slice(0, maxColumns[i] + 1));
    this.emitStateUpdate();
  }
  /**
   * Find updates from choice
   */
  findUpdatesFromChoice(_questionText, _answerChoice) {
    if (!_questionText || !_answerChoice) return null;
    const choiceIndex = this.choices[_questionText].findIndex((_value) => _value === _answerChoice);
    if (choiceIndex < 0) return null;
    const updates = {};
    Object.keys(this.choices).forEach((_key) => {
      updates[_key] = this.choices[_key][choiceIndex];
    });
    return updates;
  }
}
export function getChoicesFromData(data) {
  const choices = {};
  if (data[0]) {
    data[0].forEach((_name, i) => {
      choices[_name.value.trim()] = data.slice(1).map((_value) => _value[i] ? _value[i].value : '');
    });
  }
  return choices;
}
