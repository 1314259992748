/* eslint-disable no-param-reassign */
import React from 'react';
import styled from 'styled-components';
// import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import Connect from '../../services/Connect';
import Navigation from '../../services/Navigation';
import ClickableIcon from '../ClickableIcon';

const FullscreenWrapper = styled.div`
  position: fixed;
  top: 8px;
  z-index: 4;
  background-color: ${Constants.White};
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
`;

export default class EmbedFullscreenButton extends React.PureComponent {
  toggleFullscreen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    Connect.toggleFullscreen();
  }
  componentDidMount() {
    document.addEventListener('keydown', this.keydown);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.keydown);
  }
  keydown = (e) => {
    if (e.key === 'Escape' && Connect.isFullscreen) Connect.toggleFullscreen();
  }
  render() {
    const { isWidget, isWidgetOnSameDomain } = Constants;
    if (!(isWidget && !isWidgetOnSameDomain && (Navigation.currentSearch.allowfullscreen !== 'false'))) return;
    return <FullscreenWrapper style={{ right: 8 }}>
      <ClickableIcon small onClick={this.toggleFullscreen} icon="fas fa-expand" />
    </FullscreenWrapper>
  }
}
