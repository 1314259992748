import Answerable from './answerable';

export default class ChecklistItem extends Answerable {
  constructor(_text, _attributes, _children, _owner, _votes, _cloned, _project) {
    /**
     * Children can be answers and categories
     */
    super(_text, _attributes, _children, _owner);
    this.cloned = _cloned;
    /**
     * The answer if new has an owner
     * Votes are the users that have upvoted the answer
     */
    this.votes = JSON.parse(JSON.stringify(_votes || []));
    if (_project) this.votes.forEach((vote) => {
      // eslint-disable-next-line no-param-reassign
      vote.project = _project; 
    });
  }
  get enabled() {
    return this.__enabled;
  }
  checkedChanged() {
    this.__updateDirectParentOnNodeUpdated();
  }
  updateEnabledDisabled(_enabled) {
    this.__enabled = _enabled;
    this.emitStateUpdate();
  }
  clone() {
    return new ChecklistItem(this.text, this.attributes, this.children.map((c) => c.clone()), this.owner, this.votes, this);
  }
  isMatchingAttributes() {
    return true;
  }
}
